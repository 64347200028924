import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Upload: any;
};

export type AcceptableEntityDocumentsType = {
  __typename?: 'AcceptableEntityDocumentsType';
  isRequired?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pk?: Maybe<Scalars['String']>;
};

export type AcceptableEntityType = {
  __typename?: 'AcceptableEntityType';
  documents?: Maybe<Array<AcceptableEntityDocumentsType>>;
  entity?: Maybe<Scalars['String']>;
  isSelected?: Maybe<Scalars['Boolean']>;
  pk?: Maybe<Scalars['String']>;
};

export type AdditionalDocumentType = {
  name?: Maybe<Scalars['String']>;
  s3Url?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type AdminCreateInvestorEntityParams = {
  enable?: Maybe<Scalars['Boolean']>;
  individual?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
};

export type AdminDeleteInvestorEntityParams = {
  pk?: Maybe<Scalars['String']>;
};

export type AdminGetComplianceEntitiesReturns = {
  __typename?: 'AdminGetComplianceEntitiesReturns';
  docusignTemplatesList?: Maybe<Array<AdminGetComplianceEntityType>>;
  errors?: Maybe<Array<ErrorType>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type AdminGetComplianceEntityType = {
  __typename?: 'AdminGetComplianceEntityType';
  count?: Maybe<Scalars['Float']>;
  enable?: Maybe<Scalars['Boolean']>;
  entityTypePk?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  templates?: Maybe<Array<ComplianceEntityTemplateType>>;
  uploadDocumentOptions?: Maybe<UploadDocumentOptionsType>;
};

export type AdminGetInvestor = {
  pk?: Maybe<Scalars['String']>;
};

export type AdminGetInvestorReturn = {
  __typename?: 'AdminGetInvestorReturn';
  errors?: Maybe<Array<ErrorType>>;
  investorInfo?: Maybe<AdminInvestorInfo>;
  success?: Maybe<Scalars['Boolean']>;
};

export type AdminGetInvestors = {
  entityType?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
};

export type AdminGetMyProfile = {
  pk?: Maybe<Scalars['String']>;
};

export type AdminGetMyProfileReturn = {
  __typename?: 'AdminGetMyProfileReturn';
  errors?: Maybe<Array<ErrorType>>;
  success?: Maybe<Scalars['Boolean']>;
  userInfo?: Maybe<AdminProfileUserInfo>;
};

export type AdminGetOfferingDetails = {
  webOfferingPK?: Maybe<Scalars['String']>;
};

export type AdminGetOfferingDetailsContentType = {
  __typename?: 'AdminGetOfferingDetailsContentType';
  closeDate?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  flex?: Maybe<FilterDataWidgetType>;
  fundTarget?: Maybe<Scalars['Float']>;
  introduction?: Maybe<Scalars['String']>;
  investmentType?: Maybe<FilterDataWidgetType>;
  location?: Maybe<AdminGetOfferingDetailsLocationType>;
  sponsorDescription?: Maybe<Scalars['String']>;
  strategy?: Maybe<FilterDataWidgetType>;
  widgets?: Maybe<Array<AdminWidgetType>>;
};

export type AdminGetOfferingDetailsLocationType = {
  __typename?: 'AdminGetOfferingDetailsLocationType';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['String']>;
  mapZoom?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type AdminGetOfferingDetailsReturn = {
  __typename?: 'AdminGetOfferingDetailsReturn';
  errors?: Maybe<Array<ErrorType>>;
  offering?: Maybe<AdminOfferingDetailsResult>;
  success?: Maybe<Scalars['Boolean']>;
};

export type AdminGetOfferings = {
  investmentType?: Maybe<Scalars['String']>;
  offeringType?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  strategy?: Maybe<Scalars['String']>;
};

export type AdminGetOfferingsOtherOpportunities = {
  webOfferingPK?: Maybe<Scalars['String']>;
};

export type AdminGetOfferingsOtherOpportunitiesReturn = {
  __typename?: 'AdminGetOfferingsOtherOpportunitiesReturn';
  errors?: Maybe<Array<ErrorType>>;
  otherOpportunities?: Maybe<Array<OtherOpportunitiesType>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type AdminGetOfferingsResult = {
  __typename?: 'AdminGetOfferingsResult';
  filterDataWidgets?: Maybe<Array<FilterDataWidgetType>>;
  offerings?: Maybe<Array<AdminOfferingType>>;
};

export type AdminGetOfferingsReturn = {
  __typename?: 'AdminGetOfferingsReturn';
  errors?: Maybe<Array<ErrorType>>;
  offerings?: Maybe<AdminGetOfferingsResult>;
  success?: Maybe<Scalars['Boolean']>;
};

export type AdminGetProspectDetails = {
  webProspectPK?: Maybe<Scalars['String']>;
};

export type AdminGetProspectDetailsReturns = {
  __typename?: 'AdminGetProspectDetailsReturns';
  errors?: Maybe<Array<ErrorType>>;
  result?: Maybe<AdminProspectDetailsType>;
  success?: Maybe<Scalars['Boolean']>;
};

export type AdminGetProspects = {
  entity?: Maybe<Scalars['String']>;
  property?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type AdminGetProspectsReturns = {
  __typename?: 'AdminGetProspectsReturns';
  entities?: Maybe<Array<Scalars['String']>>;
  errors?: Maybe<Array<ErrorType>>;
  properties?: Maybe<Array<Scalars['String']>>;
  prospects?: Maybe<Array<AdminSingleProspectType>>;
  status?: Maybe<Array<Scalars['String']>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type AdminGetRoles = {
  search?: Maybe<Scalars['String']>;
};

export type AdminGetRolesReturn = {
  __typename?: 'AdminGetRolesReturn';
  errors?: Maybe<Array<ErrorType>>;
  profiles?: Maybe<Array<AdminRolesType>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type AdminGetUsers = {
  role?: Maybe<Scalars['String']>;
};

export type AdminGetUsersReturn = {
  __typename?: 'AdminGetUsersReturn';
  errors?: Maybe<Array<ErrorType>>;
  success?: Maybe<Scalars['Boolean']>;
  users?: Maybe<Array<AdminUsersType>>;
};

export type AdminInvestorInfo = {
  __typename?: 'AdminInvestorInfo';
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  location?: Maybe<AdminLocation>;
  mobile?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type AdminLocation = {
  __typename?: 'AdminLocation';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  llc_name?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street_address?: Maybe<Scalars['String']>;
  street_address2?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
};

export type AdminOfferingDetailsGalleryImageType = {
  __typename?: 'AdminOfferingDetailsGalleryImageType';
  altText?: Maybe<Scalars['String']>;
  caption?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Float']>;
  url?: Maybe<Scalars['String']>;
};

export type AdminOfferingDetailsGalleryType = {
  __typename?: 'AdminOfferingDetailsGalleryType';
  imageWidgets?: Maybe<Array<AdminOfferingDetailsGalleryImageType>>;
  label?: Maybe<Scalars['String']>;
  subLabel?: Maybe<Scalars['String']>;
};

export type AdminOfferingDetailsResult = {
  __typename?: 'AdminOfferingDetailsResult';
  content?: Maybe<AdminGetOfferingDetailsContentType>;
  created?: Maybe<Scalars['String']>;
  disclosureStatement?: Maybe<Scalars['String']>;
  galleryImages?: Maybe<AdminOfferingDetailsGalleryType>;
  offeringTitle?: Maybe<Scalars['String']>;
  offeringType?: Maybe<Scalars['String']>;
  otherOpportunities?: Maybe<Array<OtherOpportunitiesType>>;
  percentFunded?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  publisher?: Maybe<Scalars['String']>;
  requirements?: Maybe<AdminOfferingRequirementsType>;
  status?: Maybe<Scalars['String']>;
  teaser?: Maybe<Scalars['Boolean']>;
  updated?: Maybe<Scalars['String']>;
  webOfferingPK?: Maybe<Scalars['String']>;
};

export type AdminOfferingRequirementsType = {
  __typename?: 'AdminOfferingRequirementsType';
  acceptableEntityTypes?: Maybe<Array<AcceptableEntityType>>;
  acceptedDocuments?: Maybe<Array<ComplianceEntityTemplateType>>;
  foreignInvestment?: Maybe<Scalars['Boolean']>;
  maxInvestment?: Maybe<Scalars['Float']>;
  minInvestment?: Maybe<Scalars['Float']>;
};

export type AdminOfferingStatusType = {
  __typename?: 'AdminOfferingStatusType';
  status?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedDate?: Maybe<Scalars['String']>;
};

export type AdminOfferingType = {
  __typename?: 'AdminOfferingType';
  closeDate?: Maybe<Scalars['String']>;
  details?: Maybe<Array<Scalars['String']>>;
  fundedAmount?: Maybe<Scalars['Float']>;
  fundedAmountPercent?: Maybe<Scalars['Float']>;
  fundingGoal?: Maybe<Scalars['Float']>;
  image?: Maybe<Scalars['String']>;
  investors?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  prospects?: Maybe<Scalars['Float']>;
  remainingAmount?: Maybe<Scalars['Float']>;
  remainingPercent?: Maybe<Scalars['Float']>;
  status?: Maybe<AdminOfferingStatusType>;
  type?: Maybe<Scalars['String']>;
  webOfferingPK?: Maybe<Scalars['String']>;
};

export type AdminPermissionType = {
  __typename?: 'AdminPermissionType';
  description?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
};

export type AdminProfileUserInfo = {
  __typename?: 'AdminProfileUserInfo';
  company?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  permissions?: Maybe<AdminPermissionType>;
  phone?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type AdminProspectDetailsType = {
  __typename?: 'AdminProspectDetailsType';
  account?: Maybe<ProspectDetailsAccountType>;
  agreementDocuments?: Maybe<ProspectDetailsAgreementDocumentsType>;
  complianceDocuments?: Maybe<ProspectDetailsComplianceDocumentsType>;
  distributions?: Maybe<ProspectDetailsDistributionsType>;
  formData?: Maybe<ProspectDetailsFormDataType>;
  investDate?: Maybe<Scalars['String']>;
  investedAmount?: Maybe<Scalars['Float']>;
  investorInformation?: Maybe<ProspectDetailsDocumentsType>;
  property?: Maybe<ProspectPropertyDetailType>;
  prospectImage?: Maybe<Scalars['String']>;
  prospectName?: Maybe<Scalars['String']>;
  prospectTag?: Maybe<Scalars['String']>;
  prospectTitle?: Maybe<Scalars['String']>;
  taxInformation?: Maybe<ProspectDetailsTaxInformationType>;
};

export type AdminPublishOffering = {
  contactPk?: Maybe<Scalars['String']>;
  webOfferingPK: Scalars['String'];
};

export type AdminPublishOfferingReturn = {
  __typename?: 'AdminPublishOfferingReturn';
  errors?: Maybe<Array<ErrorType>>;
  status?: Maybe<StatusType>;
  success?: Maybe<Scalars['Boolean']>;
};

export type AdminRolesType = {
  __typename?: 'AdminRolesType';
  description?: Maybe<Scalars['String']>;
  pk: Scalars['String'];
  role?: Maybe<Scalars['String']>;
};

export type AdminSaveComplianceEntitiesParams = {
  entityTypes?: Maybe<Array<SaveComplianceEntityType>>;
};

export type AdminSaveComplianceEntitiesReturns = {
  __typename?: 'AdminSaveComplianceEntitiesReturns';
  errors?: Maybe<Array<ErrorType>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type AdminSaveMyProfile = {
  company?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type AdminSaveMyProfileReturn = {
  __typename?: 'AdminSaveMyProfileReturn';
  errors?: Maybe<Array<ErrorType>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type AdminSaveOfferingDetails = {
  content?: Maybe<AdminSaveOfferingDetailsContentType>;
  galleryImages?: Maybe<AdminSaveOfferingDetailsGalleryType>;
  offeringTitle?: Maybe<Scalars['String']>;
  otherOpportunities?: Maybe<Array<AdminSaveOtherOpportunitiesType>>;
  requirements?: Maybe<AdminSaveOfferingRequirementsType>;
  teaser?: Maybe<Scalars['Boolean']>;
  webOfferingPK?: Maybe<Scalars['String']>;
};

export type AdminSaveOfferingDetailsAcceptableEntityType = {
  documents?: Maybe<Array<SaveAcceptableEntityDocumentsType>>;
  entity?: Maybe<Scalars['String']>;
  isSelected?: Maybe<Scalars['Boolean']>;
  pk?: Maybe<Scalars['String']>;
};

export type AdminSaveOfferingDetailsContentType = {
  closeDate?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  flex?: Maybe<Scalars['String']>;
  fundTarget?: Maybe<Scalars['Float']>;
  introduction?: Maybe<Scalars['String']>;
  investmentType?: Maybe<Scalars['String']>;
  location?: Maybe<AdminSaveOfferingDetailsLocationType>;
  sponsorDescription?: Maybe<Scalars['String']>;
  strategy?: Maybe<Scalars['String']>;
  widgets?: Maybe<Array<AdminSaveWidgetType>>;
};

export type AdminSaveOfferingDetailsGalleryImageType = {
  altText?: Maybe<Scalars['String']>;
  caption?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Float']>;
  url?: Maybe<Scalars['String']>;
};

export type AdminSaveOfferingDetailsGalleryType = {
  imageWidgets?: Maybe<Array<AdminSaveOfferingDetailsGalleryImageType>>;
  label?: Maybe<Scalars['String']>;
  subLabel?: Maybe<Scalars['String']>;
};

export type AdminSaveOfferingDetailsLocationType = {
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['String']>;
  mapZoom?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type AdminSaveOfferingDetailsRequiredDocumentsType = {
  category?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  required?: Maybe<Scalars['Boolean']>;
  templateId?: Maybe<Scalars['String']>;
  templatePk?: Maybe<Scalars['String']>;
};

export type AdminSaveOfferingDetailsReturn = {
  __typename?: 'AdminSaveOfferingDetailsReturn';
  errors?: Maybe<Array<ErrorType>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type AdminSaveOfferingRequirementsType = {
  acceptableEntityTypes?: Maybe<
    Array<AdminSaveOfferingDetailsAcceptableEntityType>
  >;
  acceptedDocuments?: Maybe<
    Array<AdminSaveOfferingDetailsRequiredDocumentsType>
  >;
  foreignInvestment?: Maybe<Scalars['Boolean']>;
  maxInvestment?: Maybe<Scalars['Float']>;
  minInvestment?: Maybe<Scalars['Float']>;
};

export type AdminSaveOtherOpportunitiesType = {
  webOfferingPK?: Maybe<Scalars['String']>;
};

export type AdminSaveWidgetChipType = {
  UItype?: Maybe<Scalars['String']>;
  chipPk?: Maybe<Scalars['String']>;
  column?: Maybe<Scalars['Float']>;
  customLabel?: Maybe<Scalars['String']>;
  displayChips?: Maybe<Array<AdminWidgetDisplayChipType>>;
  editable?: Maybe<Scalars['Boolean']>;
  isCustomChip?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  row?: Maybe<Scalars['Float']>;
  selected?: Maybe<Scalars['Boolean']>;
  value?: Maybe<Scalars['String']>;
};

export type AdminSaveWidgetDisplayChipType = {
  __typename?: 'AdminSaveWidgetDisplayChipType';
  UItype?: Maybe<Scalars['String']>;
  column?: Maybe<Scalars['Float']>;
  editable?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  row?: Maybe<Scalars['Float']>;
  topLine?: Maybe<Scalars['Boolean']>;
  value?: Maybe<Scalars['String']>;
  value2?: Maybe<Scalars['String']>;
};

export type AdminSaveWidgetDocType = {
  docPk?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type AdminSaveWidgetType = {
  chips?: Maybe<Array<AdminSaveWidgetChipType>>;
  docs?: Maybe<Array<AdminSaveWidgetDocType>>;
  key?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type AdminSingleProspectType = {
  __typename?: 'AdminSingleProspectType';
  amount?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  entity?: Maybe<Scalars['String']>;
  investor?: Maybe<Scalars['String']>;
  pk?: Maybe<Scalars['String']>;
  property?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type AdminUnpublishOffering = {
  contactPk?: Maybe<Scalars['String']>;
  webOfferingPK: Scalars['String'];
};

export type AdminUnpublishOfferingReturn = {
  __typename?: 'AdminUnpublishOfferingReturn';
  errors?: Maybe<Array<ErrorType>>;
  status?: Maybe<StatusType>;
  success?: Maybe<Scalars['Boolean']>;
};

export type AdminUsersType = {
  __typename?: 'AdminUsersType';
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  pk: Scalars['String'];
  role?: Maybe<Scalars['String']>;
};

export type AdminWidgetChipType = {
  __typename?: 'AdminWidgetChipType';
  UItype?: Maybe<Scalars['String']>;
  chipPk?: Maybe<Scalars['String']>;
  column?: Maybe<Scalars['Float']>;
  customLabel?: Maybe<Scalars['String']>;
  displayChips?: Maybe<Array<AdminSaveWidgetDisplayChipType>>;
  editable?: Maybe<Scalars['Boolean']>;
  isCustomChip?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  row?: Maybe<Scalars['Float']>;
  selected?: Maybe<Scalars['Boolean']>;
  value?: Maybe<Scalars['String']>;
};

export type AdminWidgetDisplayChipType = {
  UItype?: Maybe<Scalars['String']>;
  column?: Maybe<Scalars['Float']>;
  editable?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  row?: Maybe<Scalars['Float']>;
  topLine?: Maybe<Scalars['Boolean']>;
  value?: Maybe<Scalars['String']>;
  value2?: Maybe<Scalars['String']>;
};

export type AdminWidgetDocType = {
  __typename?: 'AdminWidgetDocType';
  docPk?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type AdminWidgetType = {
  __typename?: 'AdminWidgetType';
  chips?: Maybe<Array<AdminWidgetChipType>>;
  docs?: Maybe<Array<AdminWidgetDocType>>;
  key?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type AllowInvestmentParams = {
  reference?: Maybe<Scalars['String']>;
};

export type AnswersType = {
  answer1: Scalars['String'];
  answer2: Scalars['String'];
  answer3: Scalars['String'];
  answer4: Scalars['String'];
  answer5: Scalars['String'];
  answer6: Scalars['String'];
};

export type AuthForgotPassword = {
  /** Specify the  email */
  email?: Maybe<Scalars['String']>;
  reCaptchaCode?: Maybe<Scalars['String']>;
};

export type AuthResendMfaCode = {
  /** Method */
  method: Scalars['String'];
  reference?: Maybe<Scalars['String']>;
};

export type AuthResetPassword = {
  /** Specify the  password */
  password?: Maybe<Scalars['String']>;
  reCaptchaCode?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
};

export type AuthResetResendPasswordMfaCode = {
  /** Method */
  method: Scalars['String'];
  reference?: Maybe<Scalars['String']>;
};

export type AuthSendMfaCodeParams = {
  /** Method */
  method: Scalars['String'];
  reference?: Maybe<Scalars['String']>;
};

export type AuthSetPhone = {
  /** Specify the  Phone number */
  phone?: Maybe<Scalars['String']>;
};

export type BarGraphBar = {
  __typename?: 'BarGraphBar';
  subTitle1?: Maybe<Scalars['String']>;
  subTitle2?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  values?: Maybe<BarValues>;
};

export type BarGraphCard = {
  __typename?: 'BarGraphCard';
  bars?: Maybe<Array<BarGraphBar>>;
  legend?: Maybe<Array<BarGraphLegend>>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type BarGraphLegend = {
  __typename?: 'BarGraphLegend';
  color?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  textColor?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type BarValues = {
  __typename?: 'BarValues';
  cmmt?: Maybe<Scalars['Float']>;
  irr?: Maybe<Scalars['String']>;
  multiple?: Maybe<Scalars['String']>;
  nav?: Maybe<Scalars['Float']>;
  value1?: Maybe<Scalars['Float']>;
  value2?: Maybe<Scalars['Float']>;
  value3?: Maybe<Scalars['Float']>;
};

export type CardLinkDetail = {
  __typename?: 'CardLinkDetail';
  icon?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  linkDetails?: Maybe<Array<CardLinkDetail>>;
  title?: Maybe<Scalars['String']>;
};

export type CashFlowChartDetails = {
  __typename?: 'CashFlowChartDetails';
  amt?: Maybe<Scalars['Float']>;
  date?: Maybe<Scalars['String']>;
};

export type CashflowType = {
  __typename?: 'CashflowType';
  amt?: Maybe<Scalars['Float']>;
  date?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type CcContactAddCredentials = {
  credentials?: Maybe<CcContactAddCredentialsDetails>;
  reCaptchaCode?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
};

export type CcContactAddCredentialsDetails = {
  password?: Maybe<Scalars['String']>;
};

export type CheckDocStatus = {
  reference?: Maybe<Scalars['String']>;
  /** Specify the template ref */
  templateRef?: Maybe<Scalars['String']>;
};

export type CheckDocuSignStatus = {
  docJobReference?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  templateRef?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type CheckDocuSignStatusResult = {
  __typename?: 'CheckDocuSignStatusResult';
  errors?: Maybe<Array<ErrorType>>;
  status?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type CheckMfaCodeParams = {
  /** Digit Code */
  digitCodes: Scalars['String'];
  /** Method */
  method: Scalars['String'];
  reference?: Maybe<Scalars['String']>;
};

export type CheckReturnsType = {
  __typename?: 'CheckReturnsType';
  errors?: Maybe<Array<ErrorType>>;
  info?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type CheckTfaCodeParams = {
  /** Digit Code */
  digitCodes: Scalars['String'];
  reference?: Maybe<Scalars['String']>;
};

export type ComplianceEntityTemplateType = {
  __typename?: 'ComplianceEntityTemplateType';
  category?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  required?: Maybe<Scalars['Boolean']>;
  templateId?: Maybe<Scalars['String']>;
  templatePk?: Maybe<Scalars['String']>;
};

export type ContactContacts = {
  __typename?: 'ContactContacts';
  city?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  /** Specify the  email */
  email?: Maybe<Scalars['String']>;
  firstAddress?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  secondAddress?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type ContactCredentials = {
  __typename?: 'ContactCredentials';
  password?: Maybe<Scalars['String']>;
};

export type ContactJobResult = {
  __typename?: 'ContactJobResult';
  errors?: Maybe<Array<ErrorType>>;
  step?: Maybe<JobStep>;
  success?: Maybe<Scalars['Boolean']>;
  userJob?: Maybe<ContactJobResultDetails>;
};

export type ContactJobResultDetails = {
  __typename?: 'ContactJobResultDetails';
  ccTypes?: Maybe<Array<Scalars['String']>>;
  checked?: Maybe<Scalars['Boolean']>;
  contact?: Maybe<ContactContacts>;
  credentials?: Maybe<ContactCredentials>;
  emailVerified?: Maybe<Scalars['Boolean']>;
  investorType?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  person?: Maybe<Scalars['String']>;
  phoneVerified?: Maybe<Scalars['Boolean']>;
  relationship?: Maybe<Scalars['String']>;
  smsResends?: Maybe<SmsEmailCounter>;
  smsTries?: Maybe<SmsEmailCounter>;
};

export type ContactType = {
  city: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  country: Scalars['String'];
  firstAddress: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
  phoneVerified?: Maybe<Scalars['Boolean']>;
  secondAddress?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  zipCode: Scalars['String'];
};

export type ContactTypeReturn = {
  __typename?: 'ContactTypeReturn';
  city?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  firstAddress?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  phoneVerified?: Maybe<Scalars['Boolean']>;
  secondAddress?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type CreateAccountAccredParam = {
  /** Specify the account accreditation */
  accreditation: Scalars['String'];
};

export type CreateAccountConfirmEmail = {
  reference?: Maybe<Scalars['String']>;
  referrer?: Maybe<Scalars['String']>;
};

export type CreateAccountContact = {
  contact: ContactType;
  reference?: Maybe<Scalars['String']>;
};

export type CreateAccountCreds = {
  /** Specify the  email */
  email: Scalars['String'];
  /** Specify the  password */
  password: Scalars['String'];
  reCaptchaCode?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
};

export type CreateAccountInvestorType = {
  /** Specify the investor type */
  investorType: Scalars['String'];
  reference?: Maybe<Scalars['String']>;
};

export type CreateAccountQuestions = {
  answers: AnswersType;
  reference?: Maybe<Scalars['String']>;
};

export type CreateAccount_EmailValidation = {
  reference?: Maybe<Scalars['String']>;
};

export type CreateAccount_ResendSms = {
  reference?: Maybe<Scalars['String']>;
};

export type CreateDoc = {
  pingUri?: Maybe<Scalars['String']>;
  redirectUri?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  templateRef?: Maybe<Scalars['String']>;
};

export type CreateDocResult = {
  __typename?: 'CreateDocResult';
  docReference?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<ErrorType>>;
  success?: Maybe<Scalars['Boolean']>;
  url?: Maybe<Scalars['String']>;
};

export type CreateInvestmentCheckTfa = {
  reference?: Maybe<Scalars['String']>;
  tfaCode?: Maybe<Scalars['String']>;
};

export type CreateInvestmentInput = {
  amount: Scalars['Float'];
  webOfferingPK?: Maybe<Scalars['String']>;
};

export type CreateUrlForDocuSign = {
  pingUri?: Maybe<Scalars['String']>;
  redirectUri?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  templateRef?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type CreateUrlForDocuSignResult = {
  __typename?: 'CreateUrlForDocuSignResult';
  docReference?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<ErrorType>>;
  success?: Maybe<Scalars['Boolean']>;
  url?: Maybe<Scalars['String']>;
};

export type DatafilesType = {
  __typename?: 'DatafilesType';
  folder?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
};

export type DatePickerType = {
  __typename?: 'DatePickerType';
  date: Scalars['String'];
  name: Scalars['String'];
};

export type DocStatus = {
  __typename?: 'DocStatus';
  status?: Maybe<Scalars['String']>;
  step?: Maybe<StepType>;
  success?: Maybe<Scalars['Boolean']>;
};

export type DocType = {
  __typename?: 'DocType';
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type DonutCardDonut = {
  __typename?: 'DonutCardDonut';
  footnote?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  values?: Maybe<Array<DonutCardDonutValue>>;
};

export type DonutCardDonutValue = {
  __typename?: 'DonutCardDonutValue';
  color?: Maybe<Scalars['String']>;
  details?: Maybe<DonutCardDonutValueDetails>;
  legendIndex?: Maybe<Scalars['Float']>;
  title?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
};

export type DonutCardDonutValueDetails = {
  __typename?: 'DonutCardDonutValueDetails';
  subTitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type DonutChartCard = {
  __typename?: 'DonutChartCard';
  donuts?: Maybe<Array<DonutCardDonut>>;
  title?: Maybe<Scalars['String']>;
  titleOptions?: Maybe<Array<DonutTitleOption>>;
};

export type DonutTitleOption = {
  __typename?: 'DonutTitleOption';
  dataPoint?: Maybe<Scalars['String']>;
  hoverLabel?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
};

export type DropdownOption = {
  __typename?: 'DropdownOption';
  name: Scalars['String'];
  pk: Scalars['String'];
};

export type DynamicTableDataType = {
  __typename?: 'DynamicTableDataType';
  table?: Maybe<DynamicTableType>;
  tables?: Maybe<Array<DynamicTableType>>;
};

export type DynamicTableFiltersType = {
  __typename?: 'DynamicTableFiltersType';
  accessor?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  options?: Maybe<Array<Scalars['String']>>;
  placeholder?: Maybe<Scalars['String']>;
};

export type DynamicTableHeadersType = {
  __typename?: 'DynamicTableHeadersType';
  accessor?: Maybe<Scalars['String']>;
  group?: Maybe<Scalars['String']>;
  hidden?: Maybe<Scalars['Boolean']>;
  inactiveValue?: Maybe<Scalars['String']>;
  placeholder?: Maybe<Scalars['String']>;
  sortable?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  width?: Maybe<DynamicTableWidthType>;
};

export type DynamicTableReturn = {
  __typename?: 'DynamicTableReturn';
  data?: Maybe<DynamicTableDataType>;
  errors?: Maybe<Array<ErrorType>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type DynamicTableRowDataParamsType = {
  __typename?: 'DynamicTableRowDataParamsType';
  cashflows?: Maybe<Array<CashflowType>>;
  color?: Maybe<Scalars['String']>;
  fundAmount?: Maybe<Scalars['Float']>;
  fundAmountPercent?: Maybe<Scalars['Float']>;
  fundPk?: Maybe<Scalars['String']>;
  fundRemainingPercent?: Maybe<Scalars['Float']>;
  goal?: Maybe<Scalars['Float']>;
  investorEntityPk?: Maybe<Scalars['String']>;
  offeringType?: Maybe<Scalars['String']>;
  partnerPk?: Maybe<Scalars['String']>;
  pk?: Maybe<Scalars['String']>;
  portfolio?: Maybe<Scalars['String']>;
  portfolioPk?: Maybe<Scalars['String']>;
  topInvestor?: Maybe<Scalars['Boolean']>;
  url?: Maybe<Scalars['String']>;
  values?: Maybe<Array<Scalars['String']>>;
};

export type DynamicTableRowDataType = {
  __typename?: 'DynamicTableRowDataType';
  accessor?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  params?: Maybe<DynamicTableRowDataParamsType>;
  type?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type DynamicTableRowType = {
  __typename?: 'DynamicTableRowType';
  rowData?: Maybe<Array<DynamicTableRowDataType>>;
  value?: Maybe<Scalars['Float']>;
};

export type DynamicTableSectionType = {
  __typename?: 'DynamicTableSectionType';
  isActive?: Maybe<Scalars['Boolean']>;
  isUnallocated?: Maybe<Scalars['Boolean']>;
  rows?: Maybe<Array<DynamicTableRowType>>;
  title?: Maybe<Scalars['String']>;
};

export type DynamicTableTabsType = {
  __typename?: 'DynamicTableTabsType';
  accessor?: Maybe<Scalars['String']>;
  options?: Maybe<Array<Scalars['String']>>;
};

export type DynamicTableType = {
  __typename?: 'DynamicTableType';
  filters?: Maybe<Array<DynamicTableFiltersType>>;
  footnotes?: Maybe<Array<Scalars['String']>>;
  headers?: Maybe<Array<DynamicTableHeadersType>>;
  rows?: Maybe<Array<DynamicTableRowType>>;
  sections?: Maybe<Array<DynamicTableSectionType>>;
  tabs?: Maybe<DynamicTableTabsType>;
  title?: Maybe<Scalars['String']>;
};

export type DynamicTableWidthType = {
  __typename?: 'DynamicTableWidthType';
  fixedWidth?: Maybe<Scalars['Float']>;
};

export type EmptyObject = {
  _?: Maybe<Scalars['Boolean']>;
};

export type EntityInfo = {
  __typename?: 'EntityInfo';
  entityType?: Maybe<Scalars['String']>;
  taxId?: Maybe<Scalars['String']>;
};

export type ErrorType = {
  __typename?: 'ErrorType';
  code?: Maybe<Scalars['Float']>;
  message?: Maybe<Scalars['String']>;
  severity?: Maybe<Scalars['Float']>;
};

export type FilesInput = {
  key: Scalars['String'];
  value: Scalars['Upload'];
};

export type FilterDataWidgetType = {
  __typename?: 'FilterDataWidgetType';
  fieldName?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  options?: Maybe<Array<Scalars['String']>>;
  selectedValue?: Maybe<Scalars['String']>;
};

export type GetDocUrl = {
  /** the doc reference */
  docReference: Scalars['String'];
};

export type GetDocuSignFolderItemsType = {
  __typename?: 'GetDocuSignFolderItemsType';
  templateId?: Maybe<Scalars['String']>;
};

export type GetDocuSignFolderType = {
  __typename?: 'GetDocuSignFolderType';
  folderId?: Maybe<Scalars['String']>;
  folderItems?: Maybe<Array<GetDocuSignFolderItemsType>>;
  folders?: Maybe<Array<GetDocuSignFolderType>>;
  hasSubFolders?: Maybe<Scalars['String']>;
  itemCount?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type GetDocuSignFoldersReturns = {
  __typename?: 'GetDocuSignFoldersReturns';
  errors?: Maybe<Array<ErrorType>>;
  folders?: Maybe<Array<GetDocuSignFolderType>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type GetDocuSignTemplateType = {
  __typename?: 'GetDocuSignTemplateType';
  folderIds?: Maybe<Array<Scalars['String']>>;
  folderName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  templateId?: Maybe<Scalars['String']>;
};

export type GetDocuSignTemplatesReturns = {
  __typename?: 'GetDocuSignTemplatesReturns';
  errors?: Maybe<Array<ErrorType>>;
  success?: Maybe<Scalars['Boolean']>;
  templates?: Maybe<Array<GetDocuSignTemplateType>>;
};

export type GetDocusignAccessTokenReturns = {
  __typename?: 'GetDocusignAccessTokenReturns';
  accessToken?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<ErrorType>>;
  expiresIn?: Maybe<Scalars['Float']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type GetInvAmountLimit = {
  reference?: Maybe<Scalars['String']>;
};

export type GetInvAmountLimitResult = {
  __typename?: 'GetInvAmountLimitResult';
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type GetInvestmentJob = {
  reference?: Maybe<Scalars['String']>;
  webOfferingPK?: Maybe<Scalars['String']>;
};

export type GetInvestorReviewData = {
  reference?: Maybe<Scalars['String']>;
};

export type GetJsonFrame = {
  /** Json ref */
  jsonRef: Scalars['String'];
};

export type GetOfferingDetails = {
  webOfferingPK: Scalars['String'];
};

export type GetOfferingDetailsContentType = {
  __typename?: 'GetOfferingDetailsContentType';
  closeDate?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  flex?: Maybe<FilterDataWidgetType>;
  fundTarget?: Maybe<Scalars['Float']>;
  fundedAmount?: Maybe<Scalars['Float']>;
  introduction?: Maybe<Scalars['String']>;
  investmentType?: Maybe<FilterDataWidgetType>;
  location?: Maybe<AdminGetOfferingDetailsLocationType>;
  sponsorDescription?: Maybe<Scalars['String']>;
  strategy?: Maybe<FilterDataWidgetType>;
  widgets?: Maybe<Array<WidgetType>>;
};

export type GetOfferingDetailsReturn = {
  __typename?: 'GetOfferingDetailsReturn';
  errors?: Maybe<Array<ErrorType>>;
  offering?: Maybe<OfferingDetailsResult>;
  success?: Maybe<Scalars['Boolean']>;
};

export type GetOfferingInvestmentInfo = {
  webOfferingPK: Scalars['String'];
};

export type GetOfferingInvestmentInfoReturns = {
  __typename?: 'GetOfferingInvestmentInfoReturns';
  errors?: Maybe<Array<ErrorType>>;
  offering?: Maybe<GetOfferingInvestmentInfoType>;
  success?: Maybe<Scalars['Boolean']>;
};

export type GetOfferingInvestmentInfoType = {
  __typename?: 'GetOfferingInvestmentInfoType';
  disclaimer?: Maybe<Scalars['String']>;
  /** Specify the  email */
  email?: Maybe<Scalars['String']>;
  fundCurrent?: Maybe<Scalars['Float']>;
  fundTarget?: Maybe<Scalars['Float']>;
  images?: Maybe<Array<Scalars['String']>>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type GetOfferingsReturn = {
  __typename?: 'GetOfferingsReturn';
  errors?: Maybe<Array<ErrorType>>;
  offerings?: Maybe<Array<OfferingType>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type GetUserInfoInput = {
  userId?: Maybe<Scalars['String']>;
};

export type GetUserJob = {
  reference?: Maybe<Scalars['String']>;
};

export type GetccContactJob = {
  reference?: Maybe<Scalars['String']>;
};

export type IdentificationDocumentInfoReturn = {
  __typename?: 'IdentificationDocumentInfoReturn';
  documentId?: Maybe<Scalars['String']>;
  expirationDate?: Maybe<Scalars['String']>;
  picture1?: Maybe<PictureTypeReturn>;
  picture2?: Maybe<PictureTypeReturn>;
  type?: Maybe<Scalars['String']>;
};

export type IdentificationDocumentType = {
  documentId?: Maybe<Scalars['String']>;
  expirationDate?: Maybe<Scalars['String']>;
  picture1?: Maybe<PictureType>;
  picture2?: Maybe<PictureType>;
  type?: Maybe<Scalars['String']>;
};

export type InvAccreditation = {
  accreditation: InvAccreditationDetails;
  reference?: Maybe<Scalars['String']>;
};

export type InvAccreditationDetails = {
  type: Scalars['String'];
};

export type InvAddAdditionalDoc = {
  additionalDocument?: Maybe<AdditionalDocumentType>;
  reference?: Maybe<Scalars['String']>;
};

export type InvAddIdentificationDoc = {
  identificationDocument?: Maybe<IdentificationDocumentType>;
  reference?: Maybe<Scalars['String']>;
};

export type InvCommunicationsDetails = {
  __typename?: 'InvCommunicationsDetails';
  ccRoles?: Maybe<Array<Scalars['String']>>;
  contactPK?: Maybe<Scalars['String']>;
  /** Specify the  email */
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  middle?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  relationship?: Maybe<Scalars['String']>;
};

export type InvCommunicationsDetailsInput = {
  ccRoles?: Maybe<Array<Scalars['String']>>;
  contactPK?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  middle?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  relationship?: Maybe<Scalars['String']>;
};

export type InvCommunicationsInput = {
  communications: Array<InvCommunicationsDetailsInput>;
  reference?: Maybe<Scalars['String']>;
};

export type InvDistributionInstructionsDetails = {
  __typename?: 'InvDistributionInstructionsDetails';
  accountNumber?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  routingNumber?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type InvDistributionsDetailsInput = {
  accountNumber?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  routingNumber?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type InvDistributionsInput = {
  distributionInstructions?: Maybe<InvDistributionsDetailsInput>;
  reference?: Maybe<Scalars['String']>;
};

export type InvInteriorTabReturn = {
  __typename?: 'InvInteriorTabReturn';
  addressMap?: Maybe<LocationType>;
  datePicker?: Maybe<Array<DatePickerType>>;
  deck?: Maybe<DocType>;
  footnotes?: Maybe<Array<Scalars['String']>>;
  investmentBarChart?: Maybe<BarGraphCard>;
  investmentDocuments?: Maybe<DynamicTableType>;
  investorEntitiesPicker?: Maybe<Array<DropdownOption>>;
  logoLink?: Maybe<LogoLink>;
  milestones?: Maybe<Array<MilestonesType>>;
  myPerformance?: Maybe<DynamicTableDataType>;
  overview?: Maybe<Array<OverviewSectionType>>;
  pcoWebsite?: Maybe<Scalars['String']>;
  performance?: Maybe<DynamicTableDataType>;
  productImages?: Maybe<ProductImage>;
  settings?: Maybe<InvestorSettingType>;
};

export type InvPerfTableDetails = {
  __typename?: 'InvPerfTableDetails';
  Ann?: Maybe<Scalars['String']>;
  IRR?: Maybe<Scalars['String']>;
  committedCapital?: Maybe<Scalars['String']>;
  currentDistributions?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  entity?: Maybe<Scalars['String']>;
  equityMultiple?: Maybe<Scalars['String']>;
  investedCapital?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['String']>;
};

export type InvReview = {
  reference?: Maybe<Scalars['String']>;
};

export type InvSign = {
  reference?: Maybe<Scalars['String']>;
  webOfferingPK?: Maybe<Scalars['String']>;
};

export type Invest_ResendSms = {
  reference?: Maybe<Scalars['String']>;
};

export type InvestmentAccreditationType = {
  __typename?: 'InvestmentAccreditationType';
  type?: Maybe<Scalars['String']>;
};

export type InvestmentDataResultDetails = {
  __typename?: 'InvestmentDataResultDetails';
  allowedInvestorEntities?: Maybe<Array<Scalars['String']>>;
  allowedRelationship?: Maybe<Array<Scalars['String']>>;
  allowedccRoles?: Maybe<Array<Scalars['String']>>;
  investorAdditionalDocuments?: Maybe<UploadDocumentOptionsType>;
  investorCommunications?: Maybe<
    Array<InvestmentDataResultDetailsInvestorCommunications>
  >;
  investorCompliance?: Maybe<
    Array<InvestmentDataResultDetailsInvestorCompliance>
  >;
  investorEntities?: Maybe<Array<InvestmentDataResultDetailsInvestorEntities>>;
  signDocument?: Maybe<Array<InvestmentDataResultDetailsSignDocument>>;
};

export type InvestmentDataResultDetailsInvestorCommunications = {
  __typename?: 'InvestmentDataResultDetailsInvestorCommunications';
  ccRoles?: Maybe<Array<Scalars['String']>>;
  contactPk?: Maybe<Scalars['String']>;
  /** Specify the  email */
  email?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['String']>;
  middle?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  relationship?: Maybe<Scalars['String']>;
};

export type InvestmentDataResultDetailsInvestorCompliance = {
  __typename?: 'InvestmentDataResultDetailsInvestorCompliance';
  additionalDocuments?: Maybe<UploadDocumentOptionsType>;
  entityTypePk?: Maybe<Scalars['String']>;
  templates?: Maybe<Array<InvestmentDataResultDetailsTemplateType>>;
  type?: Maybe<Scalars['String']>;
};

export type InvestmentDataResultDetailsInvestorEntities = {
  __typename?: 'InvestmentDataResultDetailsInvestorEntities';
  accountNumber?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  distributionMethod?: Maybe<Scalars['String']>;
  entityType?: Maybe<Scalars['String']>;
  investorEntityPK?: Maybe<Scalars['String']>;
  isTaxDomicile?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  taxId?: Maybe<Scalars['String']>;
};

export type InvestmentDataResultDetailsSignDocument = {
  __typename?: 'InvestmentDataResultDetailsSignDocument';
  name?: Maybe<Scalars['String']>;
  required?: Maybe<Scalars['Boolean']>;
  templateId?: Maybe<Scalars['String']>;
};

export type InvestmentDataResultDetailsTemplateType = {
  __typename?: 'InvestmentDataResultDetailsTemplateType';
  category?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  required?: Maybe<Scalars['Boolean']>;
  templateId?: Maybe<Scalars['String']>;
  templatePk?: Maybe<Scalars['String']>;
};

export type InvestmentJobResult = {
  __typename?: 'InvestmentJobResult';
  errors?: Maybe<Array<ErrorType>>;
  investmentData?: Maybe<InvestmentDataResultDetails>;
  investmentJob?: Maybe<InvestmentJobResultDetails>;
  step?: Maybe<JobStep>;
  success?: Maybe<Scalars['Boolean']>;
};

export type InvestmentJobResultDetails = {
  __typename?: 'InvestmentJobResultDetails';
  accreditation?: Maybe<InvestmentAccreditationType>;
  amount?: Maybe<Scalars['Float']>;
  identificationDocument?: Maybe<IdentificationDocumentInfoReturn>;
  investorEntityInfo?: Maybe<InvestmentJobResultDetailsInvestorEntityInfo>;
  maxStepAllowed?: Maybe<Scalars['Float']>;
  phoneVerified?: Maybe<Scalars['Boolean']>;
  review?: Maybe<Scalars['Boolean']>;
  sign?: Maybe<SignReturn>;
  smsResends?: Maybe<SmsEmailCounter>;
  tfaCode_timestamp?: Maybe<Scalars['Float']>;
  userReference?: Maybe<Scalars['String']>;
  webOfferingPK?: Maybe<Scalars['String']>;
};

export type InvestmentJobResultDetailsInvestorEntityInfo = {
  __typename?: 'InvestmentJobResultDetailsInvestorEntityInfo';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  communications?: Maybe<Array<InvCommunicationsDetails>>;
  country?: Maybe<Scalars['String']>;
  distributions?: Maybe<InvDistributionInstructionsDetails>;
  entityType?: Maybe<Scalars['String']>;
  investorEntityPK?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  taxId?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type InvestmentOverviewCard = {
  __typename?: 'InvestmentOverviewCard';
  barGraphCard?: Maybe<BarGraphCard>;
  donutChartCard?: Maybe<DonutChartCard>;
  kpiCard?: Maybe<KpiCard>;
  lineGraphCard?: Maybe<LineGraphCard>;
  quickLinkCard?: Maybe<QuickLinkCard>;
};

export type InvestmentOverviewCards = {
  __typename?: 'InvestmentOverviewCards';
  card?: Maybe<Array<InvestmentOverviewCard>>;
};

export type InvestorReviewCommunicationsContactType = {
  __typename?: 'InvestorReviewCommunicationsContactType';
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type InvestorReviewCommunicationsType = {
  __typename?: 'InvestorReviewCommunicationsType';
  cc?: Maybe<Array<InvestorReviewCommunicationsContactType>>;
  primary?: Maybe<Array<InvestorReviewCommunicationsContactType>>;
};

export type InvestorReviewDataResult = {
  __typename?: 'InvestorReviewDataResult';
  communications?: Maybe<InvestorReviewCommunicationsType>;
  distribution?: Maybe<InvestorReviewDistributionType>;
  errors?: Maybe<Array<ErrorType>>;
  investorInfo?: Maybe<InvestorReviewInfoType>;
  success?: Maybe<Scalars['Boolean']>;
};

export type InvestorReviewDistributionType = {
  __typename?: 'InvestorReviewDistributionType';
  distributionMethod?: Maybe<Scalars['String']>;
};

export type InvestorReviewInfoType = {
  __typename?: 'InvestorReviewInfoType';
  entityLegalName?: Maybe<Scalars['String']>;
  entityType?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  taxId?: Maybe<Scalars['String']>;
};

export type InvestorSettingType = {
  __typename?: 'InvestorSettingType';
  contacts?: Maybe<Array<Scalars['String']>>;
  investorEntityName?: Maybe<Scalars['String']>;
  investorEntityPk?: Maybe<Scalars['String']>;
  method?: Maybe<Scalars['String']>;
};

export type JobStep = {
  __typename?: 'JobStep';
  index?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
};

export type KpiCard = {
  __typename?: 'KPICard';
  linkDetails?: Maybe<Array<CardLinkDetail>>;
  subValue?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type LineGraphCard = {
  __typename?: 'LineGraphCard';
  lines?: Maybe<Array<LineGraphCardLine>>;
  title?: Maybe<Scalars['String']>;
};

export type LineGraphCardLine = {
  __typename?: 'LineGraphCardLine';
  title?: Maybe<Scalars['String']>;
  value?: Maybe<Array<LineValue>>;
};

export type LineValue = {
  __typename?: 'LineValue';
  amt?: Maybe<Scalars['Float']>;
  date?: Maybe<Scalars['String']>;
};

export type LocationType = {
  __typename?: 'LocationType';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  postalCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type LoginParams = {
  /** Specify the  email */
  email?: Maybe<Scalars['String']>;
  /** Specify the  password */
  password?: Maybe<Scalars['String']>;
  reCaptchaCode?: Maybe<Scalars['String']>;
};

export type LogoLink = {
  __typename?: 'LogoLink';
  note?: Maybe<Scalars['String']>;
  s3key?: Maybe<Scalars['String']>;
};

export type MilestonesType = {
  __typename?: 'MilestonesType';
  date?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type MonitoringAddMyList = {
  offeringPk?: Maybe<Scalars['String']>;
};

export type MonitoringAddMyListReturn = {
  __typename?: 'MonitoringAddMyListReturn';
  success?: Maybe<Scalars['Boolean']>;
};

export type MonitoringDeleteFromMyList = {
  offeringPk?: Maybe<Scalars['String']>;
};

export type MonitoringDeleteFromMyListReturn = {
  __typename?: 'MonitoringDeleteFromMyListReturn';
  success?: Maybe<Scalars['Boolean']>;
};

export type MonitoringGetAllocationChartParams = {
  advisorPk?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  investorEntityPk?: Maybe<Scalars['String']>;
  investorPk?: Maybe<Scalars['String']>;
};

export type MonitoringGetAllocationChartReturn = {
  __typename?: 'MonitoringGetAllocationChartReturn';
  commercial?: Maybe<Scalars['Float']>;
  errors?: Maybe<Array<ErrorType>>;
  industrial?: Maybe<Scalars['Float']>;
  multifamily?: Maybe<Scalars['Float']>;
  other?: Maybe<Scalars['Float']>;
  retail?: Maybe<Scalars['Float']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type MonitoringGetCashFlowChartParams = {
  advisorPk?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  investorEntityPk?: Maybe<Scalars['String']>;
  investorPk?: Maybe<Scalars['String']>;
};

export type MonitoringGetCashFlowChartReturn = {
  __typename?: 'MonitoringGetCashFlowChartReturn';
  cmmts?: Maybe<Array<CashFlowChartDetails>>;
  contribs?: Maybe<Array<CashFlowChartDetails>>;
  distribs?: Maybe<Array<CashFlowChartDetails>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type MonitoringGetChartOfferingDetails = {
  date?: Maybe<Scalars['String']>;
  fundPk?: Maybe<Scalars['String']>;
  investorPk?: Maybe<Scalars['String']>;
};

export type MonitoringGetChartOfferingDetailsReturn = {
  __typename?: 'MonitoringGetChartOfferingDetailsReturn';
  contribs?: Maybe<Scalars['Float']>;
  distribs?: Maybe<Scalars['Float']>;
  errors?: Maybe<Array<ErrorType>>;
  occupancy?: Maybe<Scalars['Float']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type MonitoringGetDatePicker = {
  __typename?: 'MonitoringGetDatePicker';
  datePicker?: Maybe<Array<DatePickerType>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type MonitoringGetInvChartsReturn = {
  __typename?: 'MonitoringGetInvChartsReturn';
  success?: Maybe<Scalars['Boolean']>;
};

export type MonitoringGetInvDocuments = {
  category?: Maybe<Array<Scalars['String']>>;
  date?: Maybe<Scalars['String']>;
  investorEntityPk?: Maybe<Scalars['String']>;
  investorPk?: Maybe<Scalars['String']>;
};

export type MonitoringGetInvDocumentsReturn = {
  __typename?: 'MonitoringGetInvDocumentsReturn';
  errors?: Maybe<Array<ErrorType>>;
  success?: Maybe<Scalars['Boolean']>;
  table?: Maybe<DynamicTableType>;
};

export type MonitoringGetInvInteriorPageHeaderMenuReturn = {
  __typename?: 'MonitoringGetInvInteriorPageHeaderMenuReturn';
  errors?: Maybe<Array<ErrorType>>;
  options?: Maybe<Array<DropdownOption>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type MonitoringGetInvInteriorTabParams = {
  advisorPk?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  fundPk?: Maybe<Scalars['String']>;
  investorEntityPk?: Maybe<Scalars['String']>;
  partnerPk?: Maybe<Scalars['String']>;
  portfolioPk?: Maybe<Scalars['String']>;
  tabNum: Scalars['String'];
};

export type MonitoringGetInvInteriorTabReturn = {
  __typename?: 'MonitoringGetInvInteriorTabReturn';
  errors?: Maybe<Array<ErrorType>>;
  success?: Maybe<Scalars['Boolean']>;
  tab?: Maybe<InvInteriorTabReturn>;
};

export type MonitoringGetInvOverview = {
  date?: Maybe<Scalars['String']>;
  fundPk?: Maybe<Scalars['String']>;
};

export type MonitoringGetInvOverviewReturn = {
  __typename?: 'MonitoringGetInvOverviewReturn';
  dateOverview?: Maybe<Scalars['String']>;
  document?: Maybe<DocType>;
  image?: Maybe<Scalars['String']>;
  investorEntities?: Maybe<Array<Scalars['String']>>;
  location?: Maybe<LocationType>;
  milestones?: Maybe<Array<MilestonesType>>;
  overview?: Maybe<MonitoringOverviewType>;
  success?: Maybe<Scalars['Boolean']>;
};

export type MonitoringGetInvPerformance = {
  fundPk?: Maybe<Scalars['String']>;
};

export type MonitoringGetInvPerformanceReturn = {
  __typename?: 'MonitoringGetInvPerformanceReturn';
  Contributions?: Maybe<Scalars['Float']>;
  Distributions?: Maybe<Scalars['Float']>;
  filter?: Maybe<Array<Scalars['String']>>;
  image?: Maybe<Scalars['String']>;
  notes?: Maybe<Array<Scalars['String']>>;
  success?: Maybe<Scalars['Boolean']>;
  table?: Maybe<Array<InvPerfTableDetails>>;
  title?: Maybe<Scalars['String']>;
};

export type MonitoringGetInvPropertyDetails = {
  fundPk?: Maybe<Scalars['String']>;
};

export type MonitoringGetInvPropertyDetailsReturn = {
  __typename?: 'MonitoringGetInvPropertyDetailsReturn';
  success?: Maybe<Scalars['Boolean']>;
};

export type MonitoringGetInvSetting = {
  fundPk?: Maybe<Scalars['String']>;
  investorEntityPk?: Maybe<Scalars['String']>;
};

export type MonitoringGetInvSettingReturn = {
  __typename?: 'MonitoringGetInvSettingReturn';
  disclosure?: Maybe<Array<Scalars['String']>>;
  errors?: Maybe<Array<ErrorType>>;
  settings?: Maybe<InvestorSettingType>;
  success?: Maybe<Scalars['String']>;
};

export type MonitoringGetInvTable = {
  advisorPk?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  investorEntityPk?: Maybe<Scalars['String']>;
  investorPk?: Maybe<Scalars['String']>;
  rowPks?: Maybe<Array<Scalars['String']>>;
  type?: Maybe<Scalars['String']>;
};

export type MonitoringGetInvTableCommittedCap = {
  date?: Maybe<Scalars['String']>;
  partnerPk?: Maybe<Scalars['String']>;
};

export type MonitoringGetInvTableCommittedCapReturn = {
  __typename?: 'MonitoringGetInvTableCommittedCapReturn';
  cashflows?: Maybe<Array<CashflowType>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type MonitoringGetInvTableContribs = {
  date?: Maybe<Scalars['String']>;
  partnerPk?: Maybe<Scalars['String']>;
};

export type MonitoringGetInvTableContribsReturn = {
  __typename?: 'MonitoringGetInvTableContribsReturn';
  cashflows?: Maybe<Array<CashflowType>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type MonitoringGetInvTableDistribs = {
  date?: Maybe<Scalars['String']>;
  partnerPk?: Maybe<Scalars['String']>;
};

export type MonitoringGetInvTableDistribsReturn = {
  __typename?: 'MonitoringGetInvTableDistribsReturn';
  cashflows?: Maybe<Array<CashflowType>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type MonitoringGetInvTableReturn = {
  __typename?: 'MonitoringGetInvTableReturn';
  data?: Maybe<DynamicTableType>;
  statuses?: Maybe<Array<Scalars['String']>>;
  success?: Maybe<Scalars['Boolean']>;
  types?: Maybe<Array<Scalars['String']>>;
};

export type MonitoringGetInvestmentOverviewParams = {
  date?: Maybe<Scalars['String']>;
  investorEntityPk?: Maybe<Scalars['String']>;
  investorPk?: Maybe<Scalars['String']>;
  rowPks?: Maybe<Array<Scalars['String']>>;
};

export type MonitoringGetInvestmentOverviewReturn = {
  __typename?: 'MonitoringGetInvestmentOverviewReturn';
  errors?: Maybe<Array<ErrorType>>;
  investmentOverview?: Maybe<Array<InvestmentOverviewCards>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type MonitoringGetInvestorEntitiesParams = {
  fundPk?: Maybe<Scalars['String']>;
};

export type MonitoringGetInvestorEntitiesReturn = {
  __typename?: 'MonitoringGetInvestorEntitiesReturn';
  errors?: Maybe<Array<ErrorType>>;
  investorEntities?: Maybe<Array<DropdownOption>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type MonitoringGetMyList = {
  userId?: Maybe<Scalars['String']>;
};

export type MonitoringGetMyListReturn = {
  __typename?: 'MonitoringGetMyListReturn';
  offerings?: Maybe<Array<MyListType>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type MonitoringGetMyPerformanceParams = {
  date: Scalars['String'];
  fundPk: Scalars['String'];
  partnerPk: Scalars['String'];
};

export type MonitoringGetMyPerformanceReturns = {
  __typename?: 'MonitoringGetMyPerformanceReturns';
  contribs?: Maybe<Scalars['Float']>;
  distribs?: Maybe<Scalars['Float']>;
  errors?: Maybe<Array<ErrorType>>;
  fundPk?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  performanceCharts?: Maybe<BarGraphCard>;
  success?: Maybe<Scalars['Boolean']>;
  tables?: Maybe<Array<DynamicTableType>>;
};

export type MonitoringGetOccupancyChartParams = {
  advisorPk?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  investorEntityPk?: Maybe<Scalars['String']>;
  investorPk?: Maybe<Scalars['String']>;
};

export type MonitoringGetOccupancyChartReturn = {
  __typename?: 'MonitoringGetOccupancyChartReturn';
  errors?: Maybe<Array<ErrorType>>;
  offerings?: Maybe<Array<OfferingsType>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type MonitoringImageType = {
  __typename?: 'MonitoringImageType';
  altText?: Maybe<Scalars['String']>;
  caption?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Float']>;
  url?: Maybe<Scalars['String']>;
};

export type MonitoringOverviewType = {
  __typename?: 'MonitoringOverviewType';
  images?: Maybe<Array<Scalars['String']>>;
  overviewSections?: Maybe<Array<OverviewSectionType>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  adminActivateAccount: ReturnsType;
  adminCreateInvestorEntityType: ReturnsType;
  adminCreateNote: ReturnsType;
  adminDeleteInvestorEntityType: ReturnsType;
  adminDeleteNotes: ReturnsType;
  adminPauseAccount: ReturnsType;
  adminPublishOffering: AdminPublishOfferingReturn;
  adminResetPassword: ReturnsType;
  adminSaveDocusignTemplates: AdminSaveComplianceEntitiesReturns;
  adminSaveMyProfile: AdminSaveMyProfileReturn;
  adminSaveOfferingDetails: AdminSaveOfferingDetailsReturn;
  adminUnpublishOffering: AdminUnpublishOfferingReturn;
  adminUpdateNote: ReturnsType;
  allowInvestment: ReturnsType;
  authCheckMfaCode: ReturnsType;
  authForgotPassword: ReturnsType;
  authResendMfaCode: ReturnsType;
  authResetCheckMfaCode: ReturnsType;
  authResetPassword: ReturnsType;
  authResetResendMfaCode: ReturnsType;
  authResetSendMfaCode: ReturnsType;
  authSendMfaCode: ReturnsType;
  authSetPhone: ReturnsType;
  ccContactAddCredentials: ReturnsType;
  ccContactInfo: ReturnsType;
  ccContactResendSms: ReturnsType;
  checkContactTfaCode: ReturnsType;
  checkDigitCodes: ReturnsType;
  checkDocStatus: DocStatus;
  checkDocuSignStatus: CheckDocuSignStatusResult;
  checkEmailAddressValidation: ReturnsType;
  checkInvDocStatus: DocStatus;
  checkTfaCode: ReturnsType;
  confirmEmail: ReturnsType;
  createAccountAccred: ReturnsType;
  createAccountContact: ReturnsType;
  createAccountCreds: ReturnsType;
  createAccountInvestorType: ReturnsType;
  createAccountQuestions: ReturnsType;
  createDoc: CreateDocResult;
  createInvDoc: CreateDocResult;
  createUrlForDocuSign: CreateUrlForDocuSignResult;
  getCcContactJob: ContactJobResult;
  getDocUrl: ReturnsType;
  getDocumentsByUser: MonitoringGetInvDocumentsReturn;
  getInvAmountLimit: GetInvAmountLimitResult;
  getInvestmentJob: InvestmentJobResult;
  getInvestorReviewData: InvestorReviewDataResult;
  getJsonFrame: ReturnsType;
  getTaxDocumentsByUser: MonitoringGetInvDocumentsReturn;
  getUserInfo: UserJobResult;
  getUserJob: UserJobResult;
  invAccreditation: ReturnsType;
  invAddAdditionalDoc: ReturnsType;
  invAddIdentificationDoc: ReturnsType;
  invCommunications: ReturnsType;
  invDistributions: ReturnsType;
  invPortalGetInteriorTab: MonitoringGetInvInteriorTabReturn;
  invPortalGetPageHeaderMenu: MonitoringGetInvInteriorPageHeaderMenuReturn;
  invResendSms: ReturnsType;
  invReview: ReturnsType;
  invSign: ReturnsType;
  login: ReturnsType;
  logout: ReturnsType;
  logoutEverywhere: ReturnsType;
  logoutEverywhereElse: ReturnsType;
  monitoringAddMyList: MonitoringAddMyListReturn;
  monitoringDeleteFromMyList: MonitoringDeleteFromMyListReturn;
  monitoringGetDatePicker: MonitoringGetDatePicker;
  monitoringGetInvCharts: MonitoringGetInvChartsReturn;
  monitoringGetInvOverview: MonitoringGetInvOverviewReturn;
  monitoringGetInvPerformance: MonitoringGetInvPerformanceReturn;
  monitoringGetInvPropertyDetails: MonitoringGetInvPropertyDetailsReturn;
  monitoringGetInvTable: MonitoringGetInvTableReturn;
  monitoringGetInvTableCommittedCap: MonitoringGetInvTableCommittedCapReturn;
  monitoringGetInvTableContribs: MonitoringGetInvTableContribsReturn;
  monitoringGetInvTableDistribs: MonitoringGetInvTableDistribsReturn;
  monitoringGetInvestmentOverview: MonitoringGetInvestmentOverviewReturn;
  monitoringGetInvestorEntities: MonitoringGetInvestorEntitiesReturn;
  monitoringGetMyList: MonitoringGetMyListReturn;
  newInvEntityInvestorInfo: ReturnsType;
  newInvestment: ReturnsType;
  onboardingCheckMfaCode: ReturnsType;
  onboardingConfigureContactMFA: ReturnsType;
  onboardingEmailConfirmed: ReturnsType;
  onboardingResendMfaCode: ReturnsType;
  onboardingResetPassword: ReturnsType;
  refreshTokens: ReturnsType;
  resendSms: ReturnsType;
  salesrepActivateAccount: ReturnsType;
  salesrepCreateAdvisor: SalesrepCreateAdvisorReturn;
  salesrepCreateContact: ReturnsType;
  salesrepCreateNote: ReturnsType;
  salesrepCreateProspect: SalesrepCreateProspectReturn;
  salesrepDeleteInvestor: SalesrepDeleteInvestorReturn;
  salesrepDeleteNotes: ReturnsType;
  salesrepPauseAccount: ReturnsType;
  salesrepResetPassword: ReturnsType;
  salesrepSetNewPassword: SalesrepSetNewPasswordReturn;
  salesrepUpdateNote: ReturnsType;
  saveMakeInvestmentData: ReturnsType;
  settingColumnState: ReturnsType;
  settingsCheckEmailVerified: ReturnsTypeStepName;
  settingsCheckPassword: ReturnsType;
  settingsCheckTfaCode: ReturnsType;
  settingsGetEntityInfo: SettingsGetEntityInfoReturn;
  settingsGetInvestingEntities: SettingsInvestingEntities;
  settingsGetInvestmentTaxId: SettingsGetInvTaxIdReturn;
  settingsGetPersonalInfo: SettingsPersonalInfo;
  settingsResendTfaCode: ReturnsType;
  settingsUpdateAddress: ReturnsType;
  settingsUpdateCheckTfaCode: ReturnsType;
  settingsUpdateDistributions: ReturnsType;
  settingsUpdateEmail: ReturnsType;
  settingsUpdateEntityInfo: ReturnsType;
  settingsUpdateInvestmentTaxId: ReturnsType;
  settingsUpdateMobile: ReturnsType;
  settingsUpdateName: ReturnsType;
  settingsUpdateOptionalInfo: ReturnsType;
  settingsUpdatePassword: ReturnsType;
  settingsUploadProfileImage: ReturnsType;
  utilitiesGetTestDataFiles: UtilitiesGetTestDataFilesReturn;
  utilitiesRestartBackendServer: ReturnsType;
};

export type MutationAdminActivateAccountArgs = {
  arg0: SalesrepActivateAccount;
};

export type MutationAdminCreateInvestorEntityTypeArgs = {
  arg0: AdminCreateInvestorEntityParams;
};

export type MutationAdminCreateNoteArgs = {
  arg0: SalesrepCreateNote;
};

export type MutationAdminDeleteInvestorEntityTypeArgs = {
  arg0: AdminDeleteInvestorEntityParams;
};

export type MutationAdminDeleteNotesArgs = {
  arg0: SalesrepDeleteNotes;
};

export type MutationAdminPauseAccountArgs = {
  arg0: SalesrepPauseAccount;
};

export type MutationAdminPublishOfferingArgs = {
  arg0: AdminPublishOffering;
};

export type MutationAdminResetPasswordArgs = {
  arg0: SalesrepResetPassword;
};

export type MutationAdminSaveDocusignTemplatesArgs = {
  arg0: AdminSaveComplianceEntitiesParams;
};

export type MutationAdminSaveMyProfileArgs = {
  arg0: AdminSaveMyProfile;
};

export type MutationAdminSaveOfferingDetailsArgs = {
  arg0: AdminSaveOfferingDetails;
};

export type MutationAdminUnpublishOfferingArgs = {
  arg0: AdminUnpublishOffering;
};

export type MutationAdminUpdateNoteArgs = {
  arg0: SalesrepUpdateNote;
};

export type MutationAllowInvestmentArgs = {
  arg0: AllowInvestmentParams;
};

export type MutationAuthCheckMfaCodeArgs = {
  arg0: CheckMfaCodeParams;
};

export type MutationAuthForgotPasswordArgs = {
  arg0: AuthForgotPassword;
};

export type MutationAuthResendMfaCodeArgs = {
  arg0: AuthResendMfaCode;
};

export type MutationAuthResetCheckMfaCodeArgs = {
  arg0: CheckMfaCodeParams;
};

export type MutationAuthResetPasswordArgs = {
  arg0: AuthResetPassword;
};

export type MutationAuthResetResendMfaCodeArgs = {
  arg0: AuthResetResendPasswordMfaCode;
};

export type MutationAuthResetSendMfaCodeArgs = {
  arg0: AuthSendMfaCodeParams;
};

export type MutationAuthSendMfaCodeArgs = {
  arg0: AuthSendMfaCodeParams;
};

export type MutationAuthSetPhoneArgs = {
  arg0: AuthSetPhone;
};

export type MutationCcContactAddCredentialsArgs = {
  arg0: CcContactAddCredentials;
};

export type MutationCcContactInfoArgs = {
  arg0: CreateAccountContact;
};

export type MutationCcContactResendSmsArgs = {
  arg0: CreateAccount_ResendSms;
};

export type MutationCheckContactTfaCodeArgs = {
  arg0: CheckTfaCodeParams;
};

export type MutationCheckDigitCodesArgs = {
  arg0: CheckTfaCodeParams;
};

export type MutationCheckDocStatusArgs = {
  arg0: CheckDocStatus;
};

export type MutationCheckDocuSignStatusArgs = {
  arg0: CheckDocuSignStatus;
};

export type MutationCheckEmailAddressValidationArgs = {
  arg0: CreateAccount_EmailValidation;
};

export type MutationCheckInvDocStatusArgs = {
  arg0: CheckDocStatus;
};

export type MutationCheckTfaCodeArgs = {
  arg0: CreateInvestmentCheckTfa;
};

export type MutationConfirmEmailArgs = {
  arg0: CreateAccountConfirmEmail;
};

export type MutationCreateAccountAccredArgs = {
  arg0: CreateAccountAccredParam;
};

export type MutationCreateAccountContactArgs = {
  arg0: CreateAccountContact;
};

export type MutationCreateAccountCredsArgs = {
  arg0: CreateAccountCreds;
};

export type MutationCreateAccountInvestorTypeArgs = {
  arg0: CreateAccountInvestorType;
};

export type MutationCreateAccountQuestionsArgs = {
  arg0: CreateAccountQuestions;
};

export type MutationCreateDocArgs = {
  arg0: CreateDoc;
};

export type MutationCreateInvDocArgs = {
  arg0: CreateDoc;
};

export type MutationCreateUrlForDocuSignArgs = {
  arg0: CreateUrlForDocuSign;
};

export type MutationGetCcContactJobArgs = {
  arg0: GetccContactJob;
};

export type MutationGetDocUrlArgs = {
  arg0: GetDocUrl;
};

export type MutationGetDocumentsByUserArgs = {
  arg0: MonitoringGetInvDocuments;
};

export type MutationGetInvAmountLimitArgs = {
  arg0: GetInvAmountLimit;
};

export type MutationGetInvestmentJobArgs = {
  arg0: GetInvestmentJob;
};

export type MutationGetInvestorReviewDataArgs = {
  arg0: GetInvestorReviewData;
};

export type MutationGetJsonFrameArgs = {
  arg0: GetJsonFrame;
};

export type MutationGetTaxDocumentsByUserArgs = {
  arg0: MonitoringGetInvDocuments;
};

export type MutationGetUserInfoArgs = {
  arg0: GetUserInfoInput;
};

export type MutationGetUserJobArgs = {
  arg0: GetUserJob;
};

export type MutationInvAccreditationArgs = {
  arg0: InvAccreditation;
};

export type MutationInvAddAdditionalDocArgs = {
  arg0: InvAddAdditionalDoc;
};

export type MutationInvAddIdentificationDocArgs = {
  arg0: InvAddIdentificationDoc;
};

export type MutationInvCommunicationsArgs = {
  arg0: InvCommunicationsInput;
};

export type MutationInvDistributionsArgs = {
  arg0: InvDistributionsInput;
};

export type MutationInvPortalGetInteriorTabArgs = {
  arg0: MonitoringGetInvInteriorTabParams;
};

export type MutationInvPortalGetPageHeaderMenuArgs = {
  arg0: EmptyObject;
};

export type MutationInvResendSmsArgs = {
  arg0: Invest_ResendSms;
};

export type MutationInvReviewArgs = {
  arg0: InvReview;
};

export type MutationInvSignArgs = {
  arg0: InvSign;
};

export type MutationLoginArgs = {
  arg0: LoginParams;
};

export type MutationLogoutEverywhereArgs = {
  investorPk: Scalars['String'];
};

export type MutationMonitoringAddMyListArgs = {
  arg0: MonitoringAddMyList;
};

export type MutationMonitoringDeleteFromMyListArgs = {
  arg0: MonitoringDeleteFromMyList;
};

export type MutationMonitoringGetDatePickerArgs = {
  arg0: EmptyObject;
};

export type MutationMonitoringGetInvChartsArgs = {
  arg0: EmptyObject;
};

export type MutationMonitoringGetInvOverviewArgs = {
  arg0: MonitoringGetInvOverview;
};

export type MutationMonitoringGetInvPerformanceArgs = {
  arg0: MonitoringGetInvPerformance;
};

export type MutationMonitoringGetInvPropertyDetailsArgs = {
  arg0: MonitoringGetInvPropertyDetails;
};

export type MutationMonitoringGetInvTableArgs = {
  arg0: MonitoringGetInvTable;
};

export type MutationMonitoringGetInvTableCommittedCapArgs = {
  arg0: MonitoringGetInvTableCommittedCap;
};

export type MutationMonitoringGetInvTableContribsArgs = {
  arg0: MonitoringGetInvTableContribs;
};

export type MutationMonitoringGetInvTableDistribsArgs = {
  arg0: MonitoringGetInvTableDistribs;
};

export type MutationMonitoringGetInvestmentOverviewArgs = {
  arg0: MonitoringGetInvestmentOverviewParams;
};

export type MutationMonitoringGetInvestorEntitiesArgs = {
  arg0: MonitoringGetInvestorEntitiesParams;
};

export type MutationMonitoringGetMyListArgs = {
  arg0: MonitoringGetMyList;
};

export type MutationNewInvEntityInvestorInfoArgs = {
  arg0: NewInvEntityInvestorInfo;
};

export type MutationNewInvestmentArgs = {
  arg0: CreateInvestmentInput;
};

export type MutationOnboardingCheckMfaCodeArgs = {
  arg0: CheckMfaCodeParams;
};

export type MutationOnboardingConfigureContactMfaArgs = {
  arg0: OnboardingConfigureContactMfaParam;
};

export type MutationOnboardingEmailConfirmedArgs = {
  arg0: OnboardingEmailConfirmedParam;
};

export type MutationOnboardingResendMfaCodeArgs = {
  arg0: AuthResendMfaCode;
};

export type MutationOnboardingResetPasswordArgs = {
  arg0: AuthResetPassword;
};

export type MutationResendSmsArgs = {
  arg0: CreateAccount_ResendSms;
};

export type MutationSalesrepActivateAccountArgs = {
  arg0: SalesrepActivateAccount;
};

export type MutationSalesrepCreateAdvisorArgs = {
  arg0: SalesrepCreateAdvisor;
};

export type MutationSalesrepCreateContactArgs = {
  arg0: SalesrepCreateContact;
};

export type MutationSalesrepCreateNoteArgs = {
  arg0: SalesrepCreateNote;
};

export type MutationSalesrepCreateProspectArgs = {
  arg0: SalesrepCreateProspect;
};

export type MutationSalesrepDeleteInvestorArgs = {
  arg0: SalesrepDeleteInvestor;
};

export type MutationSalesrepDeleteNotesArgs = {
  arg0: SalesrepDeleteNotes;
};

export type MutationSalesrepPauseAccountArgs = {
  arg0: SalesrepPauseAccount;
};

export type MutationSalesrepResetPasswordArgs = {
  arg0: SalesrepResetPassword;
};

export type MutationSalesrepSetNewPasswordArgs = {
  arg0: SalesrepSetNewPassword;
};

export type MutationSalesrepUpdateNoteArgs = {
  arg0: SalesrepUpdateNote;
};

export type MutationSaveMakeInvestmentDataArgs = {
  arg0: SaveMakeInvestment;
};

export type MutationSettingColumnStateArgs = {
  arg0: SettingsColumnState;
};

export type MutationSettingsCheckEmailVerifiedArgs = {
  arg0: EmptyObject;
};

export type MutationSettingsCheckPasswordArgs = {
  arg0: SettingsCheckPassword;
};

export type MutationSettingsCheckTfaCodeArgs = {
  arg0: CheckTfaCodeParams;
};

export type MutationSettingsGetEntityInfoArgs = {
  arg0: SettingsGetEntityInfo;
};

export type MutationSettingsGetInvestingEntitiesArgs = {
  arg0: EmptyObject;
};

export type MutationSettingsGetInvestmentTaxIdArgs = {
  arg0: SettingsGetInvTaxId;
};

export type MutationSettingsGetPersonalInfoArgs = {
  arg0: EmptyObject;
};

export type MutationSettingsResendTfaCodeArgs = {
  arg0: EmptyObject;
};

export type MutationSettingsUpdateAddressArgs = {
  arg0: SettingsUpdateAddress;
};

export type MutationSettingsUpdateCheckTfaCodeArgs = {
  arg0: CheckTfaCodeParams;
};

export type MutationSettingsUpdateDistributionsArgs = {
  arg0: SettingsUpdateDistributions;
};

export type MutationSettingsUpdateEmailArgs = {
  arg0: SettingsUpdateEmail;
};

export type MutationSettingsUpdateEntityInfoArgs = {
  arg0: SettingsUpdateEntityInfo;
};

export type MutationSettingsUpdateInvestmentTaxIdArgs = {
  arg0: SettingsUpdateInvTaxId;
};

export type MutationSettingsUpdateMobileArgs = {
  arg0: SettingsUpdateMobile;
};

export type MutationSettingsUpdateNameArgs = {
  arg0: SettingsUpdateName;
};

export type MutationSettingsUpdateOptionalInfoArgs = {
  arg0: SettingsPersonalOptInfoInput;
};

export type MutationSettingsUpdatePasswordArgs = {
  arg0: SettingsUpdatePassword;
};

export type MutationSettingsUploadProfileImageArgs = {
  arg0: SettingsUploadProfileImage;
};

export type MutationUtilitiesGetTestDataFilesArgs = {
  arg0: EmptyObject;
};

export type MutationUtilitiesRestartBackendServerArgs = {
  arg0: UtilitiesRestartBackendServer;
};

export type MyListType = {
  __typename?: 'MyListType';
  closedDate?: Maybe<Scalars['String']>;
  flexField?: Maybe<Scalars['String']>;
  fundedAmountPercent?: Maybe<Scalars['Float']>;
  images?: Maybe<Array<MonitoringImageType>>;
  investmentType?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  pk?: Maybe<Scalars['String']>;
  strategy?: Maybe<Scalars['String']>;
};

export type NewInvEntityInvestorInfo = {
  investorEntityInfo?: Maybe<NewInvEntityInvestorInfoParams>;
  reference?: Maybe<Scalars['String']>;
};

export type NewInvEntityInvestorInfoParams = {
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  entityType?: Maybe<Scalars['String']>;
  investorEntityPK?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  taxId?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type OfferingDetailsResult = {
  __typename?: 'OfferingDetailsResult';
  content?: Maybe<GetOfferingDetailsContentType>;
  galleryImages?: Maybe<AdminOfferingDetailsGalleryType>;
  offeringTitle?: Maybe<Scalars['String']>;
  offeringType?: Maybe<Scalars['String']>;
  otherOpportunities?: Maybe<Array<OtherOpportunitiesType>>;
  percentFunded?: Maybe<Scalars['Float']>;
  webOfferingPK?: Maybe<Scalars['String']>;
};

export type OfferingType = {
  __typename?: 'OfferingType';
  closeDate?: Maybe<Scalars['String']>;
  filterDataWidgets?: Maybe<Array<FilterDataWidgetType>>;
  fundTarget?: Maybe<Scalars['Float']>;
  imageUrls?: Maybe<Array<AdminOfferingDetailsGalleryImageType>>;
  inWatchlist?: Maybe<Scalars['Boolean']>;
  offeringTitle?: Maybe<Scalars['String']>;
  percentFunded?: Maybe<Scalars['Float']>;
  teaser?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  webOfferingPK?: Maybe<Scalars['String']>;
};

export type OfferingsType = {
  __typename?: 'OfferingsType';
  closeDate?: Maybe<Scalars['String']>;
  flexField?: Maybe<Scalars['String']>;
  fundedAmount?: Maybe<Scalars['Float']>;
  fundedAmountPercent?: Maybe<Scalars['Float']>;
  fundingGoal?: Maybe<Scalars['Float']>;
  image?: Maybe<Scalars['String']>;
  investmentType?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  pk?: Maybe<Scalars['String']>;
  remainingAmount?: Maybe<Scalars['Float']>;
  remainingPercent?: Maybe<Scalars['Float']>;
  status?: Maybe<SalesrepOfferingStatus>;
  strategy?: Maybe<Scalars['String']>;
  submissions?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['String']>;
};

export type OnboardingConfigureContactMfaParam = {
  /** Method */
  method: Scalars['String'];
  /** Offered MFA methods */
  offeredMfaMethods: Array<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  reCaptchaCode?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
};

export type OnboardingEmailConfirmedParam = {
  /** Specify the  email */
  emailVerifCode?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
};

export type OtherOpportunitiesImageType = {
  __typename?: 'OtherOpportunitiesImageType';
  altText?: Maybe<Scalars['String']>;
  caption?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Float']>;
  pk?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type OtherOpportunitiesType = {
  __typename?: 'OtherOpportunitiesType';
  closeDate?: Maybe<Scalars['String']>;
  filterDataWidgets?: Maybe<Array<FilterDataWidgetType>>;
  fundTarget?: Maybe<Scalars['Float']>;
  imageUrls?: Maybe<Array<OtherOpportunitiesImageType>>;
  isSelected?: Maybe<Scalars['Boolean']>;
  offeringTitle?: Maybe<Scalars['String']>;
  percentFunded?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['String']>;
  webOfferingPK?: Maybe<Scalars['String']>;
};

export type OverviewSectionType = {
  __typename?: 'OverviewSectionType';
  content?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
};

export type PictureType = {
  name?: Maybe<Scalars['String']>;
  s3Url?: Maybe<Scalars['String']>;
};

export type PictureTypeReturn = {
  __typename?: 'PictureTypeReturn';
  name?: Maybe<Scalars['String']>;
  s3Url?: Maybe<Scalars['String']>;
};

export type ProductImage = {
  __typename?: 'ProductImage';
  content?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Scalars['String']>>;
  label?: Maybe<Scalars['String']>;
};

export type ProspectDetailsAccountInfoType = {
  __typename?: 'ProspectDetailsAccountInfoType';
  label?: Maybe<Scalars['String']>;
  protected?: Maybe<Scalars['Boolean']>;
  value?: Maybe<Scalars['String']>;
};

export type ProspectDetailsAccountType = {
  __typename?: 'ProspectDetailsAccountType';
  label?: Maybe<Scalars['String']>;
  widgets?: Maybe<Array<ProspectDetailsDocumentsWidgetType>>;
};

export type ProspectDetailsAgreementDocsWidgetType = {
  __typename?: 'ProspectDetailsAgreementDocsWidgetType';
  file?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  widget?: Maybe<Scalars['String']>;
};

export type ProspectDetailsAgreementDocumentsType = {
  __typename?: 'ProspectDetailsAgreementDocumentsType';
  label?: Maybe<Scalars['String']>;
  widgets?: Maybe<Array<ProspectDetailsAgreementDocsWidgetType>>;
};

export type ProspectDetailsComplianceDocsWidgetFilesType = {
  __typename?: 'ProspectDetailsComplianceDocsWidgetFilesType';
  label?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type ProspectDetailsComplianceDocsWidgetType = {
  __typename?: 'ProspectDetailsComplianceDocsWidgetType';
  apiCheck?: Maybe<Scalars['Boolean']>;
  files?: Maybe<Array<ProspectDetailsComplianceDocsWidgetFilesType>>;
  label?: Maybe<Scalars['String']>;
  widget?: Maybe<Scalars['String']>;
};

export type ProspectDetailsComplianceDocumentsType = {
  __typename?: 'ProspectDetailsComplianceDocumentsType';
  label?: Maybe<Scalars['String']>;
  widgets?: Maybe<Array<ProspectDetailsComplianceDocsWidgetType>>;
};

export type ProspectDetailsDistributionWidgetType = {
  __typename?: 'ProspectDetailsDistributionWidgetType';
  accountInfo?: Maybe<Array<ProspectDetailsAccountInfoType>>;
  accountLabel?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  widget?: Maybe<Scalars['String']>;
};

export type ProspectDetailsDistributionsType = {
  __typename?: 'ProspectDetailsDistributionsType';
  label?: Maybe<Scalars['String']>;
  widgets?: Maybe<Array<ProspectDetailsDistributionWidgetType>>;
};

export type ProspectDetailsDocumentsType = {
  __typename?: 'ProspectDetailsDocumentsType';
  label?: Maybe<Scalars['String']>;
  widgets?: Maybe<Array<ProspectDetailsDocumentsWidgetType>>;
};

export type ProspectDetailsDocumentsWidgetType = {
  __typename?: 'ProspectDetailsDocumentsWidgetType';
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  widget?: Maybe<Scalars['String']>;
};

export type ProspectDetailsFormDataType = {
  __typename?: 'ProspectDetailsFormDataType';
  agreementDocumentsStatusList?: Maybe<Array<Scalars['String']>>;
};

export type ProspectDetailsTaxInformationType = {
  __typename?: 'ProspectDetailsTaxInformationType';
  label?: Maybe<Scalars['String']>;
  widgets?: Maybe<Array<ProspectDetailsTaxInformationWidgetType>>;
};

export type ProspectDetailsTaxInformationWidgetType = {
  __typename?: 'ProspectDetailsTaxInformationWidgetType';
  label?: Maybe<Scalars['String']>;
  protected?: Maybe<Scalars['Boolean']>;
  value?: Maybe<Scalars['String']>;
  widget?: Maybe<Scalars['String']>;
};

export type ProspectPropertyDetailType = {
  __typename?: 'ProspectPropertyDetailType';
  image?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  activeUserInfo: UserJobResult;
  adminGetContacts: SalesrepGetContactsReturn;
  adminGetDocusignTemplates: AdminGetComplianceEntitiesReturns;
  adminGetEntities: SalesrepGetEntitiesReturn;
  adminGetInvestments: SalesrepGetInvestmentsReturn;
  adminGetInvestor: AdminGetInvestorReturn;
  adminGetInvestors: DynamicTableReturn;
  adminGetMyProfile: AdminGetMyProfileReturn;
  adminGetNotes: SalesrepGetNotesReturn;
  adminGetOfferingDetails: AdminGetOfferingDetailsReturn;
  adminGetOfferings: AdminGetOfferingsReturn;
  adminGetOfferingsOtherOpportunities: AdminGetOfferingsOtherOpportunitiesReturn;
  adminGetProspectDetails: AdminGetProspectDetailsReturns;
  adminGetProspectList: AdminGetProspectsReturns;
  adminGetProspects: SalesrepGetProspectsReturn;
  adminGetRoles: AdminGetRolesReturn;
  adminGetUsers: AdminGetUsersReturn;
  getDocusignAccessToken: GetDocusignAccessTokenReturns;
  getDocusignFolder: GetDocuSignFoldersReturns;
  getDocusignTemplates: GetDocuSignTemplatesReturns;
  getOfferingDetails: GetOfferingDetailsReturn;
  getOfferingInvestmentInfo: GetOfferingInvestmentInfoReturns;
  getOfferings: GetOfferingsReturn;
  monitoringGetAllocationChart: MonitoringGetAllocationChartReturn;
  monitoringGetCashFlowChart: MonitoringGetCashFlowChartReturn;
  monitoringGetChartOfferingDetails: MonitoringGetChartOfferingDetailsReturn;
  monitoringGetInvSetting: MonitoringGetInvSettingReturn;
  monitoringGetMyPerformance: MonitoringGetMyPerformanceReturns;
  monitoringGetOccupancyChart: MonitoringGetOccupancyChartReturn;
  salesrepCheckCompanyAndContactNotDuplicate: CheckReturnsType;
  salesrepCheckContactNotDuplicate: CheckReturnsType;
  salesrepDesktopGetContacts: DynamicTableReturn;
  salesrepDesktopGetEntities: SalesrepDesktopGetEntitiesReturns;
  salesrepDesktopGetInvestments: SalesrepDesktopGetInvestmentsReturns;
  salesrepGetAdvisor: SalesrepGetAdvisorReturn;
  salesrepGetAdvisors: DynamicTableReturn;
  salesrepGetContact: SalesrepGetContactReturn;
  salesrepGetContacts: SalesrepGetContactsReturn;
  salesrepGetEntities: SalesrepGetEntitiesReturn;
  salesrepGetEntity: SalesrepGetEntityReturn;
  salesrepGetInvestment: SalesrepGetInvestmentReturn;
  salesrepGetInvestments: SalesrepGetInvestmentsReturn;
  salesrepGetInvestor: SalesrepGetInvestorReturn;
  salesrepGetInvestors: DynamicTableReturn;
  salesrepGetNotes: SalesrepGetNotesReturn;
  salesrepGetOfferingDetails: GetOfferingDetailsReturn;
  salesrepGetOfferings: SalesrepGetOfferingsReturn;
  salesrepGetProspects: SalesrepGetProspectsReturn;
  salesrepGetWatchList: SalesrepGetWatchListReturn;
  salesrepLogoutInvestor: SalesrepLogoutInvestorReturn;
};

export type QueryAdminGetContactsArgs = {
  arg0: SalesrepGetContacts;
};

export type QueryAdminGetDocusignTemplatesArgs = {
  arg0: EmptyObject;
};

export type QueryAdminGetEntitiesArgs = {
  arg0: SalesrepGetEntities;
};

export type QueryAdminGetInvestmentsArgs = {
  arg0: SalesrepGetInvestments;
};

export type QueryAdminGetInvestorArgs = {
  arg0: AdminGetInvestor;
};

export type QueryAdminGetInvestorsArgs = {
  arg0: AdminGetInvestors;
};

export type QueryAdminGetMyProfileArgs = {
  arg0: AdminGetMyProfile;
};

export type QueryAdminGetNotesArgs = {
  arg0: SalesrepGetNotes;
};

export type QueryAdminGetOfferingDetailsArgs = {
  arg0: AdminGetOfferingDetails;
};

export type QueryAdminGetOfferingsArgs = {
  arg0: AdminGetOfferings;
};

export type QueryAdminGetOfferingsOtherOpportunitiesArgs = {
  arg0: AdminGetOfferingsOtherOpportunities;
};

export type QueryAdminGetProspectDetailsArgs = {
  arg0: AdminGetProspectDetails;
};

export type QueryAdminGetProspectListArgs = {
  arg0: AdminGetProspects;
};

export type QueryAdminGetProspectsArgs = {
  arg0: SalesrepGetProspects;
};

export type QueryAdminGetRolesArgs = {
  arg0: AdminGetRoles;
};

export type QueryAdminGetUsersArgs = {
  arg0: AdminGetUsers;
};

export type QueryGetDocusignAccessTokenArgs = {
  arg0: EmptyObject;
};

export type QueryGetDocusignFolderArgs = {
  arg0: EmptyObject;
};

export type QueryGetDocusignTemplatesArgs = {
  arg0: EmptyObject;
};

export type QueryGetOfferingDetailsArgs = {
  arg0: GetOfferingDetails;
};

export type QueryGetOfferingInvestmentInfoArgs = {
  arg0: GetOfferingInvestmentInfo;
};

export type QueryGetOfferingsArgs = {
  arg0: EmptyObject;
};

export type QueryMonitoringGetAllocationChartArgs = {
  arg0: MonitoringGetAllocationChartParams;
};

export type QueryMonitoringGetCashFlowChartArgs = {
  arg0: MonitoringGetCashFlowChartParams;
};

export type QueryMonitoringGetChartOfferingDetailsArgs = {
  arg0: MonitoringGetChartOfferingDetails;
};

export type QueryMonitoringGetInvSettingArgs = {
  arg0: MonitoringGetInvSetting;
};

export type QueryMonitoringGetMyPerformanceArgs = {
  arg0: MonitoringGetMyPerformanceParams;
};

export type QueryMonitoringGetOccupancyChartArgs = {
  arg0: MonitoringGetOccupancyChartParams;
};

export type QuerySalesrepCheckCompanyAndContactNotDuplicateArgs = {
  arg0: SalesrepCheckCompanyAndContactNotDuplicate;
};

export type QuerySalesrepCheckContactNotDuplicateArgs = {
  arg0: SalesrepCheckContactNotDuplicate;
};

export type QuerySalesrepDesktopGetContactsArgs = {
  arg0: SalesrepDesktopGetContacts;
};

export type QuerySalesrepDesktopGetEntitiesArgs = {
  arg0: SalesrepDesktopGetEntities;
};

export type QuerySalesrepDesktopGetInvestmentsArgs = {
  arg0: SalesrepDesktopGetInvestments;
};

export type QuerySalesrepGetAdvisorArgs = {
  arg0: SalesrepGetAdvisor;
};

export type QuerySalesrepGetAdvisorsArgs = {
  arg0: SalesrepGetAdvisors;
};

export type QuerySalesrepGetContactArgs = {
  arg0: SalesrepGetContact;
};

export type QuerySalesrepGetContactsArgs = {
  arg0: SalesrepGetContacts;
};

export type QuerySalesrepGetEntitiesArgs = {
  arg0: SalesrepGetEntities;
};

export type QuerySalesrepGetEntityArgs = {
  arg0: SalesrepGetEntity;
};

export type QuerySalesrepGetInvestmentArgs = {
  arg0: SalesrepGetInvestment;
};

export type QuerySalesrepGetInvestmentsArgs = {
  arg0: SalesrepGetInvestments;
};

export type QuerySalesrepGetInvestorArgs = {
  arg0: SalesrepGetInvestor;
};

export type QuerySalesrepGetInvestorsArgs = {
  arg0: SalesrepGetInvestors;
};

export type QuerySalesrepGetNotesArgs = {
  arg0: SalesrepGetNotes;
};

export type QuerySalesrepGetOfferingDetailsArgs = {
  arg0: GetOfferingDetails;
};

export type QuerySalesrepGetOfferingsArgs = {
  arg0: SalesrepGetOfferings;
};

export type QuerySalesrepGetProspectsArgs = {
  arg0: SalesrepGetProspects;
};

export type QuerySalesrepGetWatchListArgs = {
  arg0: SalesrepGetWatchList;
};

export type QuerySalesrepLogoutInvestorArgs = {
  arg0: SalesrepLogoutInvestor;
};

export type QuickLinkCard = {
  __typename?: 'QuickLinkCard';
  details?: Maybe<Array<QuickLinkCardDetail>>;
  footnote?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type QuickLinkCardDetail = {
  __typename?: 'QuickLinkCardDetail';
  linkDetails?: Maybe<Array<CardLinkDetail>>;
  subTitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type ReturnsType = {
  __typename?: 'ReturnsType';
  availableMfaMethods?: Maybe<Array<Scalars['String']>>;
  errors?: Maybe<Array<ErrorType>>;
  licenses?: Maybe<Array<Scalars['String']>>;
  offeredMfaMethods?: Maybe<Array<Scalars['String']>>;
  preferredMfaMethod?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  step?: Maybe<StepType>;
  success?: Maybe<Scalars['Boolean']>;
  userId?: Maybe<Scalars['String']>;
  webOfferingPK?: Maybe<Scalars['String']>;
};

export type ReturnsTypeStepName = {
  __typename?: 'ReturnsTypeStepName';
  errors?: Maybe<Array<ErrorType>>;
  reference?: Maybe<Scalars['String']>;
  step?: Maybe<JobStep>;
  success?: Maybe<Scalars['Boolean']>;
};

export type SmsEmailCounter = {
  __typename?: 'SMSEmailCounter';
  attempts?: Maybe<Scalars['Float']>;
  left?: Maybe<Scalars['Float']>;
  timeLeft?: Maybe<Scalars['String']>;
};

export type SalesInvestmentsByEntity = {
  __typename?: 'SalesInvestmentsByEntity';
  cmmt?: Maybe<Scalars['Float']>;
  complianceDocs?: Maybe<Array<SalesrepDocsType>>;
  contrib?: Maybe<Scalars['Float']>;
  distrib?: Maybe<Scalars['Float']>;
  image?: Maybe<Scalars['String']>;
  irr?: Maybe<Scalars['String']>;
  multiple?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nav?: Maybe<Scalars['Float']>;
  offeringDocs?: Maybe<Array<SalesrepDocsType>>;
  pk?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type SalesRepAddresseType = {
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type SalesRepContactAddressType = {
  __typename?: 'SalesRepContactAddressType';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  block?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  line?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type SalesRepContactEntityAddressType = {
  __typename?: 'SalesRepContactEntityAddressType';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  block?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  line?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type SalesRepContactEntityType = {
  __typename?: 'SalesRepContactEntityType';
  address?: Maybe<SalesRepContactEntityAddressType>;
  commPreference?: Maybe<Scalars['String']>;
  commTypes?: Maybe<Array<Scalars['String']>>;
  entityLogo?: Maybe<Scalars['String']>;
  entityName?: Maybe<Scalars['String']>;
  entityType?: Maybe<Scalars['String']>;
  pk?: Maybe<Scalars['String']>;
};

export type SalesRepContactGroups = {
  __typename?: 'SalesRepContactGroups';
  content?: Maybe<Array<SalesRepContactGroupsContent>>;
  title?: Maybe<Scalars['String']>;
};

export type SalesRepContactGroupsContent = {
  __typename?: 'SalesRepContactGroupsContent';
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  values?: Maybe<Array<Scalars['String']>>;
};

export type SalesRepContactType = {
  __typename?: 'SalesRepContactType';
  address?: Maybe<SalesRepContactAddressType>;
  company?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  /** Specify the  email */
  email?: Maybe<Scalars['String']>;
  entities?: Maybe<Array<SalesRepContactEntityType>>;
  linkedIn?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  pk?: Maybe<Scalars['String']>;
  primary?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
};

export type SalesRepEntityDetails = {
  __typename?: 'SalesRepEntityDetails';
  content?: Maybe<Array<SalesrepInfoContentCustomValues>>;
  title?: Maybe<Scalars['String']>;
};

export type SalesrepActivateAccount = {
  password?: Maybe<Scalars['String']>;
  pk?: Maybe<Scalars['String']>;
  reCaptchaCode?: Maybe<Scalars['String']>;
};

export type SalesrepCheckCompanyAndContactNotDuplicate = {
  company?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type SalesrepCheckContactNotDuplicate = {
  email?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type SalesrepCreateAdvisor = {
  company?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  saveAnyway?: Maybe<Scalars['String']>;
};

export type SalesrepCreateAdvisorReturn = {
  __typename?: 'SalesrepCreateAdvisorReturn';
  errors?: Maybe<Array<ErrorType>>;
  info?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type SalesrepCreateContact = {
  addresses?: Maybe<Array<SalesRepAddresseType>>;
  companyPk?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['String']>;
  linkedin?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type SalesrepCreateNote = {
  date?: Maybe<Scalars['String']>;
  followUpDate?: Maybe<Scalars['String']>;
  followUpDone?: Maybe<Scalars['Boolean']>;
  followUpType?: Maybe<Scalars['String']>;
  investorPk?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type SalesrepCreateProspect = {
  company?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  saveAnyway?: Maybe<Scalars['String']>;
};

export type SalesrepCreateProspectReturn = {
  __typename?: 'SalesrepCreateProspectReturn';
  errors?: Maybe<Array<ErrorType>>;
  info?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type SalesrepDeleteInvestor = {
  pk?: Maybe<Scalars['String']>;
};

export type SalesrepDeleteInvestorReturn = {
  __typename?: 'SalesrepDeleteInvestorReturn';
  errors?: Maybe<Array<ErrorType>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type SalesrepDeleteNotes = {
  notes?: Maybe<Array<Scalars['String']>>;
};

export type SalesrepDesktopEntities = {
  __typename?: 'SalesrepDesktopEntities';
  entityDetails?: Maybe<SalesRepEntityDetails>;
  entityName?: Maybe<Scalars['String']>;
  table?: Maybe<DynamicTableType>;
};

export type SalesrepDesktopGetContacts = {
  pk: Scalars['String'];
};

export type SalesrepDesktopGetEntities = {
  pk: Scalars['String'];
};

export type SalesrepDesktopGetEntitiesReturns = {
  __typename?: 'SalesrepDesktopGetEntitiesReturns';
  entities?: Maybe<Array<SalesrepDesktopEntities>>;
  errors?: Maybe<Array<ErrorType>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type SalesrepDesktopGetInvestments = {
  pk: Scalars['String'];
};

export type SalesrepDesktopGetInvestmentsReturns = {
  __typename?: 'SalesrepDesktopGetInvestmentsReturns';
  errors?: Maybe<Array<ErrorType>>;
  investorEntities?: Maybe<Array<SalesrepDesktopInvestments>>;
  success?: Maybe<Scalars['Boolean']>;
  table?: Maybe<DynamicTableType>;
};

export type SalesrepDesktopInvestments = {
  __typename?: 'SalesrepDesktopInvestments';
  entityName?: Maybe<Scalars['String']>;
  subTitle?: Maybe<Scalars['String']>;
  table?: Maybe<DynamicTableType>;
  tags?: Maybe<Array<Scalars['String']>>;
};

export type SalesrepDocsType = {
  __typename?: 'SalesrepDocsType';
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type SalesrepEntity = {
  __typename?: 'SalesrepEntity';
  address?: Maybe<SalesrepEntityAddress>;
  contacts?: Maybe<Array<SalesrepEntityContacts>>;
  contactsLength?: Maybe<Scalars['String']>;
  entityName?: Maybe<Scalars['String']>;
  entityType?: Maybe<Scalars['String']>;
  pk?: Maybe<Scalars['String']>;
  table?: Maybe<DynamicTableType>;
};

export type SalesrepEntityAddress = {
  __typename?: 'SalesrepEntityAddress';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  block?: Maybe<Scalars['String']>;
  blockFull?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  line?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type SalesrepEntityContacts = {
  __typename?: 'SalesrepEntityContacts';
  commTypes?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  primary?: Maybe<Scalars['Boolean']>;
};

export type SalesrepGetAdvisor = {
  pk: Scalars['String'];
};

export type SalesrepGetAdvisorReturn = {
  __typename?: 'SalesrepGetAdvisorReturn';
  errors?: Maybe<Array<ErrorType>>;
  investorInfo?: Maybe<Array<SalesrepInvestorInfo>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type SalesrepGetAdvisors = {
  device?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
};

export type SalesrepGetContact = {
  parentPk?: Maybe<Scalars['String']>;
  pk?: Maybe<Scalars['String']>;
};

export type SalesrepGetContactReturn = {
  __typename?: 'SalesrepGetContactReturn';
  contactName?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<ErrorType>>;
  groups?: Maybe<Array<SalesRepContactGroups>>;
  parentName?: Maybe<Scalars['String']>;
  pk?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type SalesrepGetContacts = {
  device?: Maybe<Scalars['String']>;
  entityPK?: Maybe<Scalars['String']>;
  pk?: Maybe<Scalars['String']>;
};

export type SalesrepGetContactsReturn = {
  __typename?: 'SalesrepGetContactsReturn';
  contacts?: Maybe<Array<SalesRepContactType>>;
  entitiesFilter?: Maybe<Array<Scalars['String']>>;
  errors?: Maybe<Array<ErrorType>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type SalesrepGetEntities = {
  device?: Maybe<Scalars['String']>;
  pk?: Maybe<Scalars['String']>;
};

export type SalesrepGetEntitiesReturn = {
  __typename?: 'SalesrepGetEntitiesReturn';
  entities?: Maybe<Array<SalesrepEntity>>;
  errors?: Maybe<Array<ErrorType>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type SalesrepGetEntity = {
  pk?: Maybe<Scalars['String']>;
};

export type SalesrepGetEntityReturn = {
  __typename?: 'SalesrepGetEntityReturn';
  entity?: Maybe<Array<SalesrepInvestorInfo>>;
  entityName?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<ErrorType>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type SalesrepGetInvestment = {
  pk?: Maybe<Scalars['String']>;
};

export type SalesrepGetInvestmentReturn = {
  __typename?: 'SalesrepGetInvestmentReturn';
  errors?: Maybe<Array<ErrorType>>;
  investments?: Maybe<Array<SalesrepInvestmentType>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type SalesrepGetInvestments = {
  device?: Maybe<Scalars['String']>;
  offering?: Maybe<Scalars['String']>;
  pk?: Maybe<Scalars['String']>;
};

export type SalesrepGetInvestmentsReturn = {
  __typename?: 'SalesrepGetInvestmentsReturn';
  errors?: Maybe<Array<ErrorType>>;
  investorEntities?: Maybe<Array<SalesrepInvestmentsType>>;
  offerings?: Maybe<Array<Scalars['String']>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type SalesrepGetInvestor = {
  pk?: Maybe<Scalars['String']>;
};

export type SalesrepGetInvestorReturn = {
  __typename?: 'SalesrepGetInvestorReturn';
  errors?: Maybe<Array<ErrorType>>;
  investorInfo?: Maybe<Array<SalesrepInvestorInfo>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type SalesrepGetInvestors = {
  device?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
};

export type SalesrepGetNotes = {
  pk?: Maybe<Scalars['String']>;
};

export type SalesrepGetNotesReturn = {
  __typename?: 'SalesrepGetNotesReturn';
  errors?: Maybe<Array<ErrorType>>;
  filterFollowUpTypes?: Maybe<Array<Scalars['String']>>;
  filterTypes?: Maybe<Array<Scalars['String']>>;
  followUpTypes?: Maybe<Array<Scalars['String']>>;
  notes?: Maybe<Array<SalesrepNote>>;
  success?: Maybe<Scalars['Boolean']>;
  types?: Maybe<Array<Scalars['String']>>;
};

export type SalesrepGetOfferings = {
  investmentType?: Maybe<Scalars['String']>;
  propertyType?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  strategyType?: Maybe<Scalars['String']>;
};

export type SalesrepGetOfferingsReturn = {
  __typename?: 'SalesrepGetOfferingsReturn';
  errors?: Maybe<Array<ErrorType>>;
  success?: Maybe<Scalars['Boolean']>;
  table?: Maybe<DynamicTableType>;
};

export type SalesrepGetProspects = {
  entity?: Maybe<Scalars['String']>;
  pk?: Maybe<Scalars['String']>;
  property?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type SalesrepGetProspectsReturn = {
  __typename?: 'SalesrepGetProspectsReturn';
  entities?: Maybe<Array<Scalars['String']>>;
  errors?: Maybe<Array<ErrorType>>;
  investments?: Maybe<Array<SalesrepProspectsInvestmentsType>>;
  properties?: Maybe<Array<Scalars['String']>>;
  status?: Maybe<Array<Scalars['String']>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type SalesrepGetWatchList = {
  pk?: Maybe<Scalars['String']>;
};

export type SalesrepGetWatchListReturn = {
  __typename?: 'SalesrepGetWatchListReturn';
  errors?: Maybe<Array<ErrorType>>;
  properties?: Maybe<Array<SalesrepWatch>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type SalesrepInfoContent = {
  __typename?: 'SalesrepInfoContent';
  customValues?: Maybe<Array<SalesrepInfoContentCustomValues>>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  values?: Maybe<Array<Scalars['String']>>;
};

export type SalesrepInfoContentCustomValues = {
  __typename?: 'SalesrepInfoContentCustomValues';
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  values?: Maybe<Array<Scalars['String']>>;
};

export type SalesrepInvestmentType = {
  __typename?: 'SalesrepInvestmentType';
  cmmt?: Maybe<Scalars['Float']>;
  contrib?: Maybe<Scalars['Float']>;
  distrib?: Maybe<Scalars['Float']>;
  image?: Maybe<Scalars['String']>;
  irr?: Maybe<Scalars['String']>;
  multiple?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nav?: Maybe<Scalars['Float']>;
  pk?: Maybe<Scalars['String']>;
};

export type SalesrepInvestmentsType = {
  __typename?: 'SalesrepInvestmentsType';
  cmmt?: Maybe<Scalars['Float']>;
  contrib?: Maybe<Scalars['Float']>;
  date?: Maybe<Scalars['String']>;
  distrib?: Maybe<Scalars['Float']>;
  investments?: Maybe<Array<SalesInvestmentsByEntity>>;
  investmentsLength?: Maybe<Scalars['String']>;
  irr?: Maybe<Scalars['String']>;
  multiple?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nav?: Maybe<Scalars['Float']>;
  pk?: Maybe<Scalars['String']>;
};

export type SalesrepInvestorInfo = {
  __typename?: 'SalesrepInvestorInfo';
  content?: Maybe<Array<SalesrepInfoContent>>;
  email?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type SalesrepLogoutInvestor = {
  investorPk?: Maybe<Scalars['String']>;
};

export type SalesrepLogoutInvestorReturn = {
  __typename?: 'SalesrepLogoutInvestorReturn';
  errors?: Maybe<Array<ErrorType>>;
  investorUserId?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type SalesrepNote = {
  __typename?: 'SalesrepNote';
  date?: Maybe<Scalars['String']>;
  followUpDate?: Maybe<Scalars['String']>;
  followUpDone?: Maybe<Scalars['Boolean']>;
  followUpType?: Maybe<Scalars['String']>;
  initials?: Maybe<Scalars['String']>;
  lastSaved?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  pk?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type SalesrepOfferingStatus = {
  __typename?: 'SalesrepOfferingStatus';
  status?: Maybe<Scalars['String']>;
  updatedDate?: Maybe<Scalars['String']>;
};

export type SalesrepPauseAccount = {
  password?: Maybe<Scalars['String']>;
  pk?: Maybe<Scalars['String']>;
  reCaptchaCode?: Maybe<Scalars['String']>;
};

export type SalesrepProspectsAmountType = {
  __typename?: 'SalesrepProspectsAmountType';
  amount?: Maybe<Scalars['String']>;
  percentage?: Maybe<Scalars['String']>;
};

export type SalesrepProspectsCloseType = {
  __typename?: 'SalesrepProspectsCloseType';
  date?: Maybe<Scalars['String']>;
  in_days?: Maybe<Scalars['String']>;
};

export type SalesrepProspectsInvestmentsType = {
  __typename?: 'SalesrepProspectsInvestmentsType';
  entityLogo?: Maybe<Scalars['String']>;
  entityName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  identityType?: Maybe<Scalars['String']>;
  prospectsListByEntity?: Maybe<Array<SalesrepProspectsListType>>;
};

export type SalesrepProspectsListType = {
  __typename?: 'SalesrepProspectsListType';
  close?: Maybe<SalesrepProspectsCloseType>;
  complianceDocs?: Maybe<Array<SalesrepDocsType>>;
  date?: Maybe<Scalars['String']>;
  entityOwner?: Maybe<Scalars['String']>;
  goal?: Maybe<SalesrepProspectsAmountType>;
  id?: Maybe<Scalars['String']>;
  offeringDocs?: Maybe<Array<SalesrepDocsType>>;
  prospectImage?: Maybe<Scalars['String']>;
  prospectName?: Maybe<Scalars['String']>;
  raised?: Maybe<SalesrepProspectsAmountType>;
  status?: Maybe<Scalars['String']>;
  submission?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['String']>;
  unfunded?: Maybe<SalesrepProspectsAmountType>;
};

export type SalesrepResetPassword = {
  password?: Maybe<Scalars['String']>;
  pk?: Maybe<Scalars['String']>;
  reCaptchaCode?: Maybe<Scalars['String']>;
};

export type SalesrepSetNewPassword = {
  newpassword?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  verifypassword?: Maybe<Scalars['String']>;
};

export type SalesrepSetNewPasswordReturn = {
  __typename?: 'SalesrepSetNewPasswordReturn';
  errors?: Maybe<Array<ErrorType>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type SalesrepUpdateNote = {
  date?: Maybe<Scalars['String']>;
  followUpDate?: Maybe<Scalars['String']>;
  followUpDone?: Maybe<Scalars['Boolean']>;
  followUpType?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  pk?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type SalesrepWatch = {
  __typename?: 'SalesrepWatch';
  added?: Maybe<SalesrepWatchDate>;
  close?: Maybe<SalesrepWatchDate>;
  goal?: Maybe<SalesrepWatchAmount>;
  id?: Maybe<Scalars['String']>;
  lastViewed?: Maybe<SalesrepWatchDate>;
  propertyImage?: Maybe<Scalars['String']>;
  propertyName?: Maybe<Scalars['String']>;
  raised?: Maybe<SalesrepWatchAmount>;
  unfunded?: Maybe<SalesrepWatchAmount>;
};

export type SalesrepWatchAmount = {
  __typename?: 'SalesrepWatchAmount';
  amount?: Maybe<Scalars['Float']>;
  percentage?: Maybe<Scalars['Float']>;
};

export type SalesrepWatchDate = {
  __typename?: 'SalesrepWatchDate';
  date?: Maybe<Scalars['String']>;
  inDays?: Maybe<Scalars['Float']>;
};

export type SaveAcceptableEntityDocumentsType = {
  isRequired?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  pk?: Maybe<Scalars['String']>;
};

export type SaveComplianceEntityTemplateType = {
  category?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  required?: Maybe<Scalars['Boolean']>;
  templateId?: Maybe<Scalars['String']>;
  templatePk?: Maybe<Scalars['String']>;
};

export type SaveComplianceEntityType = {
  enable?: Maybe<Scalars['Boolean']>;
  entityTypePk?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  templates?: Maybe<Array<SaveComplianceEntityTemplateType>>;
  uploadDocumentOptions?: Maybe<SaveComplianceUploadDocumentOptionsType>;
};

export type SaveComplianceOptionType = {
  checked?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
};

export type SaveComplianceUploadDocumentOptionsType = {
  options?: Maybe<Array<SaveComplianceOptionType>>;
};

export type SaveMakeInvestment = {
  reference?: Maybe<Scalars['String']>;
  referrer?: Maybe<Scalars['String']>;
};

export type SettingsCheckPassword = {
  /** Specify the  password */
  password: Scalars['String'];
  reCaptchaCode?: Maybe<Scalars['String']>;
};

export type SettingsColumnState = {
  name?: Maybe<Scalars['String']>;
  state?: Maybe<SettingsColumnStateType>;
};

export type SettingsColumnStateHeaders = {
  accessor?: Maybe<Scalars['String']>;
  hidden?: Maybe<Scalars['Boolean']>;
};

export type SettingsColumnStateType = {
  headers?: Maybe<Array<SettingsColumnStateHeaders>>;
};

export type SettingsDistribution = {
  __typename?: 'SettingsDistribution';
  accountNumber?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  method?: Maybe<Scalars['String']>;
  routingNumber?: Maybe<Scalars['String']>;
};

export type SettingsDistributionType = {
  accountNumber?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  routingNumber?: Maybe<Scalars['String']>;
};

export type SettingsGetEntityInfo = {
  entityId: Scalars['String'];
};

export type SettingsGetEntityInfoReturn = {
  __typename?: 'SettingsGetEntityInfoReturn';
  distribution?: Maybe<SettingsDistribution>;
  entityInfo?: Maybe<EntityInfo>;
  errors?: Maybe<Array<ErrorType>>;
  offerings?: Maybe<Array<Scalars['String']>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type SettingsGetInvTaxId = {
  pk?: Maybe<Scalars['String']>;
};

export type SettingsGetInvTaxIdReturn = {
  __typename?: 'SettingsGetInvTaxIdReturn';
  errors?: Maybe<Array<ErrorType>>;
  success?: Maybe<Scalars['Boolean']>;
  taxId?: Maybe<Scalars['String']>;
};

export type SettingsGetPersonalInfoDetails = {
  __typename?: 'SettingsGetPersonalInfoDetails';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  addressBlock?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  /** Specify the  email */
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  middle?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type SettingsInvestingEntities = {
  __typename?: 'SettingsInvestingEntities';
  investingEntities?: Maybe<Array<SettingsInvestingEntitiesItem>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type SettingsInvestingEntitiesItem = {
  __typename?: 'SettingsInvestingEntitiesItem';
  image?: Maybe<Scalars['String']>;
  investorEntityPK?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  offerings?: Maybe<Array<Scalars['String']>>;
  people?: Maybe<Array<Scalars['String']>>;
  title: Scalars['String'];
};

export type SettingsPersonalInfo = {
  __typename?: 'SettingsPersonalInfo';
  errors?: Maybe<Array<ErrorType>>;
  success?: Maybe<Scalars['Boolean']>;
  userInfo?: Maybe<SettingsGetPersonalInfoDetails>;
};

export type SettingsPersonalOptInfoInput = {
  userInfo: SettingsPersonalOptInfoInputDetails;
};

export type SettingsPersonalOptInfoInputDetails = {
  organization?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type SettingsUpdateAddress = {
  userInfo: SettingsUpdateAddressDetails;
};

export type SettingsUpdateAddressDetails = {
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type SettingsUpdateDistributions = {
  distribution?: Maybe<SettingsDistributionType>;
  investorEntityPK?: Maybe<Scalars['String']>;
};

export type SettingsUpdateEmail = {
  userInfo: SettingsUpdateEmailDetails;
};

export type SettingsUpdateEmailDetails = {
  /** Specify the  email */
  email?: Maybe<Scalars['String']>;
};

export type SettingsUpdateEntityInfo = {
  country?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['String']>;
  entityType?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  taxIdEin?: Maybe<Scalars['String']>;
  taxIdTin?: Maybe<Scalars['String']>;
};

export type SettingsUpdateInvTaxId = {
  pk?: Maybe<Scalars['String']>;
  taxId?: Maybe<Scalars['String']>;
};

export type SettingsUpdateMobile = {
  userInfo: SettingsUpdateMobileDetails;
};

export type SettingsUpdateMobileDetails = {
  phone?: Maybe<Scalars['String']>;
};

export type SettingsUpdateName = {
  userInfo: SettingsUserInfo;
};

export type SettingsUpdatePassword = {
  userInfo: SettingsUpdatePasswordDetails;
};

export type SettingsUpdatePasswordDetails = {
  /** Specify the  password */
  newPassword: Scalars['String'];
  /** Specify the  password */
  password: Scalars['String'];
};

export type SettingsUploadProfileImage = {
  files?: Maybe<Array<FilesInput>>;
};

export type SettingsUserInfo = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  middle?: Maybe<Scalars['String']>;
};

export type SignReturn = {
  __typename?: 'SignReturn';
  docJobReference?: Maybe<Scalars['String']>;
  documentTemplatePk?: Maybe<Scalars['String']>;
  isSigned?: Maybe<Scalars['Boolean']>;
};

export type StatusType = {
  __typename?: 'StatusType';
  publisher?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['String']>;
};

export type StepType = {
  __typename?: 'StepType';
  maxStepAllowed?: Maybe<JobStep>;
};

export type UploadDocumentOptionType = {
  __typename?: 'UploadDocumentOptionType';
  checked?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
};

export type UploadDocumentOptionsType = {
  __typename?: 'UploadDocumentOptionsType';
  options?: Maybe<Array<UploadDocumentOptionType>>;
};

export type UserInvestor = {
  __typename?: 'UserInvestor';
  email?: Maybe<Scalars['String']>;
  investor?: Maybe<Scalars['String']>;
  pk?: Maybe<Scalars['String']>;
};

export type UserJobResult = {
  __typename?: 'UserJobResult';
  defaultRoute?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<ErrorType>>;
  investors?: Maybe<Array<UserInvestor>>;
  isAdvisor?: Maybe<Scalars['Boolean']>;
  licenses?: Maybe<Array<Scalars['String']>>;
  returningInvestor?: Maybe<Scalars['Boolean']>;
  routes?: Maybe<Array<Scalars['String']>>;
  step?: Maybe<JobStep>;
  success?: Maybe<Scalars['Boolean']>;
  userId?: Maybe<Scalars['String']>;
  userJob?: Maybe<UserJobResultDetails>;
};

export type UserJobResultDetails = {
  __typename?: 'UserJobResultDetails';
  accreditation?: Maybe<Scalars['String']>;
  contact?: Maybe<ContactTypeReturn>;
  documentSigned?: Maybe<Scalars['Boolean']>;
  /** Specify the  email */
  email?: Maybe<Scalars['String']>;
  emailResends?: Maybe<SmsEmailCounter>;
  emailTries?: Maybe<SmsEmailCounter>;
  emailVerified?: Maybe<Scalars['Boolean']>;
  investorType?: Maybe<Scalars['String']>;
  phoneVerified?: Maybe<Scalars['Boolean']>;
  smsResends?: Maybe<SmsEmailCounter>;
  smsTries?: Maybe<SmsEmailCounter>;
  step?: Maybe<Scalars['Float']>;
};

export type UtilitiesGetTestDataFilesReturn = {
  __typename?: 'UtilitiesGetTestDataFilesReturn';
  currentClient?: Maybe<Scalars['String']>;
  datafiles?: Maybe<Array<DatafilesType>>;
  errors?: Maybe<Array<ErrorType>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type UtilitiesRestartBackendServer = {
  dataFilePath: Scalars['String'];
};

export type WidgetSelectedType = {
  __typename?: 'WidgetSelectedType';
  col?: Maybe<Scalars['Float']>;
  docPk?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Float']>;
  percentage?: Maybe<Scalars['String']>;
  row?: Maybe<Scalars['Float']>;
  topLine?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type WidgetType = {
  __typename?: 'WidgetType';
  label?: Maybe<Scalars['String']>;
  selected?: Maybe<Array<WidgetSelectedType>>;
  type?: Maybe<Scalars['String']>;
};

export type AdminSaveComplianceEntitiesMutationVariables = Exact<{
  data: AdminSaveComplianceEntitiesParams;
}>;

export type AdminSaveComplianceEntitiesMutation = {
  __typename?: 'Mutation';
} & {
  adminSaveDocusignTemplates: {
    __typename?: 'AdminSaveComplianceEntitiesReturns';
  } & Pick<AdminSaveComplianceEntitiesReturns, 'success'> & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
    };
};

export type AdminCreateInvestorEntityMutationVariables = Exact<{
  data: AdminCreateInvestorEntityParams;
}>;

export type AdminCreateInvestorEntityMutation = { __typename?: 'Mutation' } & {
  adminCreateInvestorEntityType: { __typename?: 'ReturnsType' } & Pick<
    ReturnsType,
    'success'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
    };
};

export type AdminDeleteInvestorEntityMutationVariables = Exact<{
  data: AdminDeleteInvestorEntityParams;
}>;

export type AdminDeleteInvestorEntityMutation = { __typename?: 'Mutation' } & {
  adminDeleteInvestorEntityType: { __typename?: 'ReturnsType' } & Pick<
    ReturnsType,
    'success'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
    };
};

export type GetDocuSignFoldersQueryVariables = Exact<{
  data: EmptyObject;
}>;

export type GetDocuSignFoldersQuery = { __typename?: 'Query' } & {
  getDocusignFolder: { __typename?: 'GetDocuSignFoldersReturns' } & Pick<
    GetDocuSignFoldersReturns,
    'success'
  > & {
      folders?: Maybe<
        Array<
          { __typename?: 'GetDocuSignFolderType' } & Pick<
            GetDocuSignFolderType,
            'name' | 'type' | 'hasSubFolders' | 'folderId' | 'itemCount'
          > & {
              folderItems?: Maybe<
                Array<
                  { __typename?: 'GetDocuSignFolderItemsType' } & Pick<
                    GetDocuSignFolderItemsType,
                    'templateId'
                  >
                >
              >;
              folders?: Maybe<
                Array<
                  { __typename?: 'GetDocuSignFolderType' } & Pick<
                    GetDocuSignFolderType,
                    'name' | 'type' | 'hasSubFolders' | 'folderId' | 'itemCount'
                  > & {
                      folderItems?: Maybe<
                        Array<
                          { __typename?: 'GetDocuSignFolderItemsType' } & Pick<
                            GetDocuSignFolderItemsType,
                            'templateId'
                          >
                        >
                      >;
                    }
                >
              >;
            }
        >
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
    };
};

export type GetDocuSignTemplatesQueryVariables = Exact<{
  data: EmptyObject;
}>;

export type GetDocuSignTemplatesQuery = { __typename?: 'Query' } & {
  getDocusignTemplates: { __typename?: 'GetDocuSignTemplatesReturns' } & Pick<
    GetDocuSignTemplatesReturns,
    'success'
  > & {
      templates?: Maybe<
        Array<
          { __typename?: 'GetDocuSignTemplateType' } & Pick<
            GetDocuSignTemplateType,
            'templateId' | 'name' | 'folderIds' | 'folderName'
          >
        >
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
    };
};

export type AdminGetComplianceEntitiesQueryVariables = Exact<{
  data: EmptyObject;
}>;

export type AdminGetComplianceEntitiesQuery = { __typename?: 'Query' } & {
  adminGetDocusignTemplates: {
    __typename?: 'AdminGetComplianceEntitiesReturns';
  } & Pick<AdminGetComplianceEntitiesReturns, 'success'> & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
      docusignTemplatesList?: Maybe<
        Array<
          { __typename?: 'AdminGetComplianceEntityType' } & Pick<
            AdminGetComplianceEntityType,
            'entityTypePk' | 'name' | 'enable' | 'count'
          > & {
              uploadDocumentOptions?: Maybe<
                { __typename?: 'UploadDocumentOptionsType' } & {
                  options?: Maybe<
                    Array<
                      { __typename?: 'UploadDocumentOptionType' } & Pick<
                        UploadDocumentOptionType,
                        'label' | 'checked'
                      >
                    >
                  >;
                }
              >;
              templates?: Maybe<
                Array<
                  { __typename?: 'ComplianceEntityTemplateType' } & Pick<
                    ComplianceEntityTemplateType,
                    | 'templatePk'
                    | 'name'
                    | 'reference'
                    | 'category'
                    | 'required'
                    | 'templateId'
                  >
                >
              >;
            }
        >
      >;
    };
};

export type AdminSaveMyProfileMutationVariables = Exact<{
  data: AdminSaveMyProfile;
}>;

export type AdminSaveMyProfileMutation = { __typename?: 'Mutation' } & {
  adminSaveMyProfile: { __typename?: 'AdminSaveMyProfileReturn' } & Pick<
    AdminSaveMyProfileReturn,
    'success'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<ErrorType, 'code' | 'message'>
        >
      >;
    };
};

export type AdminPublishOfferingMutationVariables = Exact<{
  data: AdminPublishOffering;
}>;

export type AdminPublishOfferingMutation = { __typename?: 'Mutation' } & {
  adminPublishOffering: { __typename?: 'AdminPublishOfferingReturn' } & Pick<
    AdminPublishOfferingReturn,
    'success'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<ErrorType, 'code' | 'message'>
        >
      >;
      status?: Maybe<
        { __typename?: 'StatusType' } & Pick<
          StatusType,
          'publisher' | 'status' | 'updated'
        >
      >;
    };
};

export type AdminUnpublishOfferingMutationVariables = Exact<{
  data: AdminUnpublishOffering;
}>;

export type AdminUnpublishOfferingMutation = { __typename?: 'Mutation' } & {
  adminUnpublishOffering: {
    __typename?: 'AdminUnpublishOfferingReturn';
  } & Pick<AdminUnpublishOfferingReturn, 'success'> & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<ErrorType, 'code' | 'message'>
        >
      >;
      status?: Maybe<
        { __typename?: 'StatusType' } & Pick<
          StatusType,
          'publisher' | 'status' | 'updated'
        >
      >;
    };
};

export type AdminSaveOfferingDetailsMutationVariables = Exact<{
  data: AdminSaveOfferingDetails;
}>;

export type AdminSaveOfferingDetailsMutation = { __typename?: 'Mutation' } & {
  adminSaveOfferingDetails: {
    __typename?: 'AdminSaveOfferingDetailsReturn';
  } & Pick<AdminSaveOfferingDetailsReturn, 'success'> & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<ErrorType, 'code' | 'message'>
        >
      >;
    };
};

export type AdminDeleteNotesMutationVariables = Exact<{
  data: SalesrepDeleteNotes;
}>;

export type AdminDeleteNotesMutation = { __typename?: 'Mutation' } & {
  adminDeleteNotes: { __typename?: 'ReturnsType' } & Pick<
    ReturnsType,
    'success'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
    };
};

export type AdminUpdateNoteMutationVariables = Exact<{
  data: SalesrepUpdateNote;
}>;

export type AdminUpdateNoteMutation = { __typename?: 'Mutation' } & {
  adminUpdateNote: { __typename?: 'ReturnsType' } & Pick<
    ReturnsType,
    'success'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
    };
};

export type AdminCreateNoteMutationVariables = Exact<{
  data: SalesrepCreateNote;
}>;

export type AdminCreateNoteMutation = { __typename?: 'Mutation' } & {
  adminCreateNote: { __typename?: 'ReturnsType' } & Pick<
    ReturnsType,
    'success'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
    };
};

export type AdminActivateAccountMutationVariables = Exact<{
  data: SalesrepActivateAccount;
}>;

export type AdminActivateAccountMutation = { __typename?: 'Mutation' } & {
  adminActivateAccount: { __typename?: 'ReturnsType' } & Pick<
    ReturnsType,
    'success'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
    };
};

export type AdminResetPasswordMutationVariables = Exact<{
  data: SalesrepResetPassword;
}>;

export type AdminResetPasswordMutation = { __typename?: 'Mutation' } & {
  adminResetPassword: { __typename?: 'ReturnsType' } & Pick<
    ReturnsType,
    'success'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
    };
};

export type AdminPauseAccountMutationVariables = Exact<{
  data: SalesrepPauseAccount;
}>;

export type AdminPauseAccountMutation = { __typename?: 'Mutation' } & {
  adminPauseAccount: { __typename?: 'ReturnsType' } & Pick<
    ReturnsType,
    'success'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
    };
};

export type AdminGetUsersQueryVariables = Exact<{
  data: AdminGetUsers;
}>;

export type AdminGetUsersQuery = { __typename?: 'Query' } & {
  adminGetUsers: { __typename?: 'AdminGetUsersReturn' } & Pick<
    AdminGetUsersReturn,
    'success'
  > & {
      users?: Maybe<
        Array<
          { __typename?: 'AdminUsersType' } & Pick<
            AdminUsersType,
            'pk' | 'firstName' | 'lastName' | 'email' | 'role'
          >
        >
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
    };
};

export type AdminGetRolesQueryVariables = Exact<{
  data: AdminGetRoles;
}>;

export type AdminGetRolesQuery = { __typename?: 'Query' } & {
  adminGetRoles: { __typename?: 'AdminGetRolesReturn' } & Pick<
    AdminGetRolesReturn,
    'success'
  > & {
      profiles?: Maybe<
        Array<
          { __typename?: 'AdminRolesType' } & Pick<
            AdminRolesType,
            'pk' | 'role' | 'description'
          >
        >
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
    };
};

export type AdminGetProspectsQueryVariables = Exact<{
  data: AdminGetProspects;
}>;

export type AdminGetProspectsQuery = { __typename?: 'Query' } & {
  adminGetProspectList: { __typename?: 'AdminGetProspectsReturns' } & Pick<
    AdminGetProspectsReturns,
    'success' | 'entities' | 'properties' | 'status'
  > & {
      prospects?: Maybe<
        Array<
          { __typename?: 'AdminSingleProspectType' } & Pick<
            AdminSingleProspectType,
            | 'pk'
            | 'date'
            | 'investor'
            | 'entity'
            | 'amount'
            | 'property'
            | 'status'
          >
        >
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
    };
};

export type AdminGetProspectDetailsQueryVariables = Exact<{
  data: AdminGetProspectDetails;
}>;

export type AdminGetProspectDetailsQuery = { __typename?: 'Query' } & {
  adminGetProspectDetails: {
    __typename?: 'AdminGetProspectDetailsReturns';
  } & Pick<AdminGetProspectDetailsReturns, 'success'> & {
      result?: Maybe<
        { __typename?: 'AdminProspectDetailsType' } & Pick<
          AdminProspectDetailsType,
          | 'prospectName'
          | 'prospectTitle'
          | 'prospectTag'
          | 'prospectImage'
          | 'investedAmount'
          | 'investDate'
        > & {
            property?: Maybe<
              { __typename?: 'ProspectPropertyDetailType' } & Pick<
                ProspectPropertyDetailType,
                'label' | 'image' | 'url'
              >
            >;
            complianceDocuments?: Maybe<
              { __typename?: 'ProspectDetailsComplianceDocumentsType' } & Pick<
                ProspectDetailsComplianceDocumentsType,
                'label'
              > & {
                  widgets?: Maybe<
                    Array<
                      {
                        __typename?: 'ProspectDetailsComplianceDocsWidgetType';
                      } & Pick<
                        ProspectDetailsComplianceDocsWidgetType,
                        'widget' | 'label' | 'apiCheck'
                      > & {
                          files?: Maybe<
                            Array<
                              {
                                __typename?: 'ProspectDetailsComplianceDocsWidgetFilesType';
                              } & Pick<
                                ProspectDetailsComplianceDocsWidgetFilesType,
                                'label' | 'url'
                              >
                            >
                          >;
                        }
                    >
                  >;
                }
            >;
            agreementDocuments?: Maybe<
              { __typename?: 'ProspectDetailsAgreementDocumentsType' } & Pick<
                ProspectDetailsAgreementDocumentsType,
                'label'
              > & {
                  widgets?: Maybe<
                    Array<
                      {
                        __typename?: 'ProspectDetailsAgreementDocsWidgetType';
                      } & Pick<
                        ProspectDetailsAgreementDocsWidgetType,
                        'widget' | 'label' | 'file' | 'status'
                      >
                    >
                  >;
                }
            >;
            investorInformation?: Maybe<
              { __typename?: 'ProspectDetailsDocumentsType' } & Pick<
                ProspectDetailsDocumentsType,
                'label'
              > & {
                  widgets?: Maybe<
                    Array<
                      {
                        __typename?: 'ProspectDetailsDocumentsWidgetType';
                      } & Pick<
                        ProspectDetailsDocumentsWidgetType,
                        'widget' | 'label' | 'value'
                      >
                    >
                  >;
                }
            >;
            distributions?: Maybe<
              { __typename?: 'ProspectDetailsDistributionsType' } & Pick<
                ProspectDetailsDistributionsType,
                'label'
              > & {
                  widgets?: Maybe<
                    Array<
                      {
                        __typename?: 'ProspectDetailsDistributionWidgetType';
                      } & Pick<
                        ProspectDetailsDistributionWidgetType,
                        'widget' | 'label'
                      > & {
                          accountInfo?: Maybe<
                            Array<
                              {
                                __typename?: 'ProspectDetailsAccountInfoType';
                              } & Pick<
                                ProspectDetailsAccountInfoType,
                                'label' | 'protected'
                              >
                            >
                          >;
                        }
                    >
                  >;
                }
            >;
            taxInformation?: Maybe<
              { __typename?: 'ProspectDetailsTaxInformationType' } & Pick<
                ProspectDetailsTaxInformationType,
                'label'
              > & {
                  widgets?: Maybe<
                    Array<
                      {
                        __typename?: 'ProspectDetailsTaxInformationWidgetType';
                      } & Pick<
                        ProspectDetailsTaxInformationWidgetType,
                        'widget' | 'label' | 'protected'
                      >
                    >
                  >;
                }
            >;
            account?: Maybe<
              { __typename?: 'ProspectDetailsAccountType' } & Pick<
                ProspectDetailsAccountType,
                'label'
              > & {
                  widgets?: Maybe<
                    Array<
                      {
                        __typename?: 'ProspectDetailsDocumentsWidgetType';
                      } & Pick<
                        ProspectDetailsDocumentsWidgetType,
                        'widget' | 'label' | 'value'
                      >
                    >
                  >;
                }
            >;
            formData?: Maybe<
              { __typename?: 'ProspectDetailsFormDataType' } & Pick<
                ProspectDetailsFormDataType,
                'agreementDocumentsStatusList'
              >
            >;
          }
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
    };
};

export type AdminGetMyProfileQueryVariables = Exact<{ [key: string]: never }>;

export type AdminGetMyProfileQuery = { __typename?: 'Query' } & {
  adminGetMyProfile: { __typename?: 'AdminGetMyProfileReturn' } & Pick<
    AdminGetMyProfileReturn,
    'success'
  > & {
      userInfo?: Maybe<
        { __typename?: 'AdminProfileUserInfo' } & Pick<
          AdminProfileUserInfo,
          | 'image'
          | 'firstName'
          | 'lastName'
          | 'email'
          | 'phone'
          | 'title'
          | 'company'
        > & {
            permissions?: Maybe<
              { __typename?: 'AdminPermissionType' } & Pick<
                AdminPermissionType,
                'role' | 'description'
              >
            >;
          }
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<ErrorType, 'code' | 'message'>
        >
      >;
    };
};

export type AdminGetOfferingsQueryVariables = Exact<{
  data: AdminGetOfferings;
}>;

export type AdminGetOfferingsQuery = { __typename?: 'Query' } & {
  adminGetOfferings: { __typename?: 'AdminGetOfferingsReturn' } & Pick<
    AdminGetOfferingsReturn,
    'success'
  > & {
      offerings?: Maybe<
        { __typename?: 'AdminGetOfferingsResult' } & {
          filterDataWidgets?: Maybe<
            Array<
              { __typename?: 'FilterDataWidgetType' } & Pick<
                FilterDataWidgetType,
                'label' | 'selectedValue' | 'options' | 'fieldName'
              >
            >
          >;
          offerings?: Maybe<
            Array<
              { __typename?: 'AdminOfferingType' } & Pick<
                AdminOfferingType,
                | 'webOfferingPK'
                | 'name'
                | 'type'
                | 'details'
                | 'closeDate'
                | 'image'
                | 'prospects'
                | 'fundingGoal'
                | 'fundedAmount'
                | 'remainingAmount'
                | 'fundedAmountPercent'
                | 'remainingPercent'
                | 'investors'
              > & {
                  status?: Maybe<
                    { __typename?: 'AdminOfferingStatusType' } & Pick<
                      AdminOfferingStatusType,
                      'status' | 'updatedBy' | 'updatedDate'
                    >
                  >;
                }
            >
          >;
        }
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
    };
};

export type AdminGetOfferingDetailsQueryVariables = Exact<{
  data: AdminGetOfferingDetails;
}>;

export type AdminGetOfferingDetailsQuery = { __typename?: 'Query' } & {
  adminGetOfferingDetails: {
    __typename?: 'AdminGetOfferingDetailsReturn';
  } & Pick<AdminGetOfferingDetailsReturn, 'success'> & {
      offering?: Maybe<
        { __typename?: 'AdminOfferingDetailsResult' } & Pick<
          AdminOfferingDetailsResult,
          | 'webOfferingPK'
          | 'offeringTitle'
          | 'offeringType'
          | 'publisher'
          | 'created'
          | 'updated'
          | 'percentFunded'
          | 'status'
          | 'published'
          | 'teaser'
          | 'disclosureStatement'
        > & {
            content?: Maybe<
              { __typename?: 'AdminGetOfferingDetailsContentType' } & Pick<
                AdminGetOfferingDetailsContentType,
                | 'description'
                | 'fundTarget'
                | 'closeDate'
                | 'sponsorDescription'
                | 'introduction'
              > & {
                  investmentType?: Maybe<
                    { __typename?: 'FilterDataWidgetType' } & Pick<
                      FilterDataWidgetType,
                      'label' | 'selectedValue' | 'options'
                    >
                  >;
                  strategy?: Maybe<
                    { __typename?: 'FilterDataWidgetType' } & Pick<
                      FilterDataWidgetType,
                      'label' | 'selectedValue' | 'options'
                    >
                  >;
                  flex?: Maybe<
                    { __typename?: 'FilterDataWidgetType' } & Pick<
                      FilterDataWidgetType,
                      'label' | 'selectedValue' | 'options'
                    >
                  >;
                  location?: Maybe<
                    {
                      __typename?: 'AdminGetOfferingDetailsLocationType';
                    } & Pick<
                      AdminGetOfferingDetailsLocationType,
                      | 'address1'
                      | 'address2'
                      | 'city'
                      | 'state'
                      | 'postalCode'
                      | 'country'
                      | 'lat'
                      | 'long'
                      | 'mapZoom'
                    >
                  >;
                  widgets?: Maybe<
                    Array<
                      { __typename?: 'AdminWidgetType' } & Pick<
                        AdminWidgetType,
                        'key' | 'label' | 'type'
                      > & {
                          chips?: Maybe<
                            Array<
                              { __typename?: 'AdminWidgetChipType' } & Pick<
                                AdminWidgetChipType,
                                | 'chipPk'
                                | 'UItype'
                                | 'row'
                                | 'column'
                                | 'label'
                                | 'value'
                                | 'editable'
                                | 'selected'
                                | 'customLabel'
                                | 'isCustomChip'
                              > & {
                                  displayChips?: Maybe<
                                    Array<
                                      {
                                        __typename?: 'AdminSaveWidgetDisplayChipType';
                                      } & Pick<
                                        AdminSaveWidgetDisplayChipType,
                                        | 'UItype'
                                        | 'row'
                                        | 'column'
                                        | 'label'
                                        | 'value'
                                        | 'value2'
                                        | 'topLine'
                                      >
                                    >
                                  >;
                                }
                            >
                          >;
                          docs?: Maybe<
                            Array<
                              { __typename?: 'AdminWidgetDocType' } & Pick<
                                AdminWidgetDocType,
                                'fileName' | 'url' | 'order' | 'type' | 'docPk'
                              >
                            >
                          >;
                        }
                    >
                  >;
                }
            >;
            galleryImages?: Maybe<
              { __typename?: 'AdminOfferingDetailsGalleryType' } & Pick<
                AdminOfferingDetailsGalleryType,
                'label' | 'subLabel'
              > & {
                  imageWidgets?: Maybe<
                    Array<
                      {
                        __typename?: 'AdminOfferingDetailsGalleryImageType';
                      } & Pick<
                        AdminOfferingDetailsGalleryImageType,
                        'label' | 'url' | 'caption' | 'altText' | 'order'
                      >
                    >
                  >;
                }
            >;
            otherOpportunities?: Maybe<
              Array<
                { __typename?: 'OtherOpportunitiesType' } & Pick<
                  OtherOpportunitiesType,
                  | 'webOfferingPK'
                  | 'isSelected'
                  | 'type'
                  | 'offeringTitle'
                  | 'fundTarget'
                  | 'percentFunded'
                  | 'closeDate'
                > & {
                    imageUrls?: Maybe<
                      Array<
                        { __typename?: 'OtherOpportunitiesImageType' } & Pick<
                          OtherOpportunitiesImageType,
                          | 'pk'
                          | 'label'
                          | 'caption'
                          | 'url'
                          | 'altText'
                          | 'order'
                        >
                      >
                    >;
                    filterDataWidgets?: Maybe<
                      Array<
                        { __typename?: 'FilterDataWidgetType' } & Pick<
                          FilterDataWidgetType,
                          'label' | 'selectedValue'
                        >
                      >
                    >;
                  }
              >
            >;
            requirements?: Maybe<
              { __typename?: 'AdminOfferingRequirementsType' } & Pick<
                AdminOfferingRequirementsType,
                'minInvestment' | 'maxInvestment' | 'foreignInvestment'
              > & {
                  acceptedDocuments?: Maybe<
                    Array<
                      { __typename?: 'ComplianceEntityTemplateType' } & Pick<
                        ComplianceEntityTemplateType,
                        | 'name'
                        | 'required'
                        | 'templateId'
                        | 'templatePk'
                        | 'reference'
                        | 'category'
                      >
                    >
                  >;
                  acceptableEntityTypes?: Maybe<
                    Array<
                      { __typename?: 'AcceptableEntityType' } & Pick<
                        AcceptableEntityType,
                        'pk' | 'entity' | 'isSelected'
                      > & {
                          documents?: Maybe<
                            Array<
                              {
                                __typename?: 'AcceptableEntityDocumentsType';
                              } & Pick<
                                AcceptableEntityDocumentsType,
                                'name' | 'isRequired' | 'pk'
                              >
                            >
                          >;
                        }
                    >
                  >;
                }
            >;
          }
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
    };
};

export type MonitoringGetInvSettingQueryVariables = Exact<{
  data: MonitoringGetInvSetting;
}>;

export type MonitoringGetInvSettingQuery = { __typename?: 'Query' } & {
  monitoringGetInvSetting: {
    __typename?: 'MonitoringGetInvSettingReturn';
  } & Pick<MonitoringGetInvSettingReturn, 'success' | 'disclosure'> & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
      settings?: Maybe<
        { __typename?: 'InvestorSettingType' } & Pick<
          InvestorSettingType,
          'investorEntityPk' | 'investorEntityName' | 'method' | 'contacts'
        >
      >;
    };
};

export type AdminGetOfferingsOtherOpportunitiesQueryVariables = Exact<{
  data: AdminGetOfferingsOtherOpportunities;
}>;

export type AdminGetOfferingsOtherOpportunitiesQuery = {
  __typename?: 'Query';
} & {
  adminGetOfferingsOtherOpportunities: {
    __typename?: 'AdminGetOfferingsOtherOpportunitiesReturn';
  } & Pick<AdminGetOfferingsOtherOpportunitiesReturn, 'success'> & {
      otherOpportunities?: Maybe<
        Array<
          { __typename?: 'OtherOpportunitiesType' } & Pick<
            OtherOpportunitiesType,
            | 'webOfferingPK'
            | 'isSelected'
            | 'type'
            | 'offeringTitle'
            | 'fundTarget'
            | 'percentFunded'
            | 'closeDate'
          > & {
              imageUrls?: Maybe<
                Array<
                  { __typename?: 'OtherOpportunitiesImageType' } & Pick<
                    OtherOpportunitiesImageType,
                    'pk' | 'label' | 'caption' | 'url' | 'altText' | 'order'
                  >
                >
              >;
              filterDataWidgets?: Maybe<
                Array<
                  { __typename?: 'FilterDataWidgetType' } & Pick<
                    FilterDataWidgetType,
                    'label' | 'selectedValue'
                  >
                >
              >;
            }
        >
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
    };
};

export type AdminGetInvestorQueryVariables = Exact<{
  data: AdminGetInvestor;
}>;

export type AdminGetInvestorQuery = { __typename?: 'Query' } & {
  adminGetInvestor: { __typename?: 'AdminGetInvestorReturn' } & Pick<
    AdminGetInvestorReturn,
    'success'
  > & {
      investorInfo?: Maybe<
        { __typename?: 'AdminInvestorInfo' } & Pick<
          AdminInvestorInfo,
          'id' | 'name' | 'title' | 'mobile' | 'email'
        > & {
            location?: Maybe<
              { __typename?: 'AdminLocation' } & Pick<
                AdminLocation,
                | 'street_address'
                | 'street_address2'
                | 'country'
                | 'city'
                | 'zipcode'
                | 'state'
                | 'llc_name'
              >
            >;
          }
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
    };
};

export type AdminGetInvestorsQueryVariables = Exact<{
  data: AdminGetInvestors;
}>;

export type AdminGetInvestorsQuery = { __typename?: 'Query' } & {
  adminGetInvestors: { __typename?: 'DynamicTableReturn' } & Pick<
    DynamicTableReturn,
    'success'
  > & {
      data?: Maybe<
        { __typename?: 'DynamicTableDataType' } & {
          table?: Maybe<
            { __typename?: 'DynamicTableType' } & Pick<
              DynamicTableType,
              'title'
            > & {
                filters?: Maybe<
                  Array<
                    { __typename?: 'DynamicTableFiltersType' } & Pick<
                      DynamicTableFiltersType,
                      'label' | 'placeholder' | 'accessor' | 'options'
                    >
                  >
                >;
                tabs?: Maybe<
                  { __typename?: 'DynamicTableTabsType' } & Pick<
                    DynamicTableTabsType,
                    'accessor' | 'options'
                  >
                >;
                headers?: Maybe<
                  Array<
                    { __typename?: 'DynamicTableHeadersType' } & Pick<
                      DynamicTableHeadersType,
                      | 'value'
                      | 'accessor'
                      | 'sortable'
                      | 'placeholder'
                      | 'type'
                      | 'hidden'
                    > & {
                        width?: Maybe<
                          { __typename?: 'DynamicTableWidthType' } & Pick<
                            DynamicTableWidthType,
                            'fixedWidth'
                          >
                        >;
                      }
                  >
                >;
                rows?: Maybe<
                  Array<
                    { __typename?: 'DynamicTableRowType' } & Pick<
                      DynamicTableRowType,
                      'value'
                    > & {
                        rowData?: Maybe<
                          Array<
                            { __typename?: 'DynamicTableRowDataType' } & Pick<
                              DynamicTableRowDataType,
                              'value' | 'type' | 'accessor' | 'isActive'
                            > & {
                                params?: Maybe<
                                  {
                                    __typename?: 'DynamicTableRowDataParamsType';
                                  } & Pick<
                                    DynamicTableRowDataParamsType,
                                    | 'pk'
                                    | 'values'
                                    | 'topInvestor'
                                    | 'partnerPk'
                                  >
                                >;
                              }
                          >
                        >;
                      }
                  >
                >;
              }
          >;
        }
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
    };
};

export type AdminGetInvestmentsQueryVariables = Exact<{
  data: SalesrepGetInvestments;
}>;

export type AdminGetInvestmentsQuery = { __typename?: 'Query' } & {
  adminGetInvestments: { __typename?: 'SalesrepGetInvestmentsReturn' } & Pick<
    SalesrepGetInvestmentsReturn,
    'success' | 'offerings'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
      investorEntities?: Maybe<
        Array<
          { __typename?: 'SalesrepInvestmentsType' } & Pick<
            SalesrepInvestmentsType,
            | 'pk'
            | 'name'
            | 'date'
            | 'cmmt'
            | 'contrib'
            | 'distrib'
            | 'nav'
            | 'irr'
          > & {
              investments?: Maybe<
                Array<
                  { __typename?: 'SalesInvestmentsByEntity' } & Pick<
                    SalesInvestmentsByEntity,
                    | 'pk'
                    | 'name'
                    | 'image'
                    | 'status'
                    | 'cmmt'
                    | 'contrib'
                    | 'distrib'
                    | 'nav'
                    | 'irr'
                  > & {
                      offeringDocs?: Maybe<
                        Array<
                          { __typename?: 'SalesrepDocsType' } & Pick<
                            SalesrepDocsType,
                            'name' | 'url'
                          >
                        >
                      >;
                      complianceDocs?: Maybe<
                        Array<
                          { __typename?: 'SalesrepDocsType' } & Pick<
                            SalesrepDocsType,
                            'name' | 'url'
                          >
                        >
                      >;
                    }
                >
              >;
            }
        >
      >;
    };
};

export type AdminGetInvestorProspectsQueryVariables = Exact<{
  data: SalesrepGetProspects;
}>;

export type AdminGetInvestorProspectsQuery = { __typename?: 'Query' } & {
  adminGetProspects: { __typename?: 'SalesrepGetProspectsReturn' } & Pick<
    SalesrepGetProspectsReturn,
    'success' | 'entities' | 'properties' | 'status'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
      investments?: Maybe<
        Array<
          { __typename?: 'SalesrepProspectsInvestmentsType' } & Pick<
            SalesrepProspectsInvestmentsType,
            'id' | 'entityName' | 'identityType' | 'entityLogo'
          > & {
              prospectsListByEntity?: Maybe<
                Array<
                  { __typename?: 'SalesrepProspectsListType' } & Pick<
                    SalesrepProspectsListType,
                    | 'id'
                    | 'prospectName'
                    | 'prospectImage'
                    | 'entityOwner'
                    | 'status'
                    | 'submission'
                    | 'date'
                    | 'time'
                  > & {
                      goal?: Maybe<
                        { __typename?: 'SalesrepProspectsAmountType' } & Pick<
                          SalesrepProspectsAmountType,
                          'amount' | 'percentage'
                        >
                      >;
                      raised?: Maybe<
                        { __typename?: 'SalesrepProspectsAmountType' } & Pick<
                          SalesrepProspectsAmountType,
                          'amount' | 'percentage'
                        >
                      >;
                      unfunded?: Maybe<
                        { __typename?: 'SalesrepProspectsAmountType' } & Pick<
                          SalesrepProspectsAmountType,
                          'amount' | 'percentage'
                        >
                      >;
                      close?: Maybe<
                        { __typename?: 'SalesrepProspectsCloseType' } & Pick<
                          SalesrepProspectsCloseType,
                          'date' | 'in_days'
                        >
                      >;
                      offeringDocs?: Maybe<
                        Array<
                          { __typename?: 'SalesrepDocsType' } & Pick<
                            SalesrepDocsType,
                            'name' | 'url'
                          >
                        >
                      >;
                      complianceDocs?: Maybe<
                        Array<
                          { __typename?: 'SalesrepDocsType' } & Pick<
                            SalesrepDocsType,
                            'name' | 'url'
                          >
                        >
                      >;
                    }
                >
              >;
            }
        >
      >;
    };
};

export type AdminGetContactsQueryVariables = Exact<{
  data: SalesrepGetContacts;
}>;

export type AdminGetContactsQuery = { __typename?: 'Query' } & {
  adminGetContacts: { __typename?: 'SalesrepGetContactsReturn' } & Pick<
    SalesrepGetContactsReturn,
    'success' | 'entitiesFilter'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
      contacts?: Maybe<
        Array<
          { __typename?: 'SalesRepContactType' } & Pick<
            SalesRepContactType,
            | 'pk'
            | 'contactName'
            | 'primary'
            | 'company'
            | 'title'
            | 'email'
            | 'mobile'
          > & {
              address?: Maybe<
                { __typename?: 'SalesRepContactAddressType' } & Pick<
                  SalesRepContactAddressType,
                  | 'name'
                  | 'address1'
                  | 'address2'
                  | 'city'
                  | 'state'
                  | 'zip'
                  | 'country'
                  | 'block'
                  | 'line'
                >
              >;
              entities?: Maybe<
                Array<
                  { __typename?: 'SalesRepContactEntityType' } & Pick<
                    SalesRepContactEntityType,
                    | 'pk'
                    | 'entityName'
                    | 'entityType'
                    | 'entityLogo'
                    | 'commPreference'
                    | 'commTypes'
                  > & {
                      address?: Maybe<
                        {
                          __typename?: 'SalesRepContactEntityAddressType';
                        } & Pick<
                          SalesRepContactEntityAddressType,
                          | 'name'
                          | 'address1'
                          | 'address2'
                          | 'city'
                          | 'state'
                          | 'zip'
                          | 'country'
                          | 'block'
                          | 'line'
                        >
                      >;
                    }
                >
              >;
            }
        >
      >;
    };
};

export type AdminGetEntitiesQueryVariables = Exact<{
  data: SalesrepGetEntities;
}>;

export type AdminGetEntitiesQuery = { __typename?: 'Query' } & {
  adminGetEntities: { __typename?: 'SalesrepGetEntitiesReturn' } & Pick<
    SalesrepGetEntitiesReturn,
    'success'
  > & {
      entities?: Maybe<
        Array<
          { __typename?: 'SalesrepEntity' } & Pick<
            SalesrepEntity,
            'pk' | 'entityName' | 'entityType'
          > & {
              address?: Maybe<
                { __typename?: 'SalesrepEntityAddress' } & Pick<
                  SalesrepEntityAddress,
                  | 'name'
                  | 'address1'
                  | 'address2'
                  | 'city'
                  | 'state'
                  | 'zip'
                  | 'country'
                  | 'line'
                  | 'block'
                  | 'blockFull'
                >
              >;
              contacts?: Maybe<
                Array<
                  { __typename?: 'SalesrepEntityContacts' } & Pick<
                    SalesrepEntityContacts,
                    'name' | 'primary' | 'email' | 'mobile' | 'commTypes'
                  >
                >
              >;
            }
        >
      >;
    };
};

export type AdminGetNotesQueryVariables = Exact<{
  data: SalesrepGetNotes;
}>;

export type AdminGetNotesQuery = { __typename?: 'Query' } & {
  adminGetNotes: { __typename?: 'SalesrepGetNotesReturn' } & Pick<
    SalesrepGetNotesReturn,
    'success' | 'types'
  > & {
      notes?: Maybe<
        Array<
          { __typename?: 'SalesrepNote' } & Pick<
            SalesrepNote,
            'pk' | 'date' | 'note' | 'subject' | 'type'
          >
        >
      >;
    };
};

export type GetDocusignAccessTokenQueryVariables = Exact<{
  data: EmptyObject;
}>;

export type GetDocusignAccessTokenQuery = { __typename?: 'Query' } & {
  getDocusignAccessToken: {
    __typename?: 'GetDocusignAccessTokenReturns';
  } & Pick<
    GetDocusignAccessTokenReturns,
    'success' | 'accessToken' | 'expiresIn'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
    };
};

export type CreateAccountAccredMutationVariables = Exact<{
  accreditation: CreateAccountAccredParam;
}>;

export type CreateAccountAccredMutation = { __typename?: 'Mutation' } & {
  createAccountAccred: { __typename?: 'ReturnsType' } & Pick<
    ReturnsType,
    'success' | 'reference'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
      step?: Maybe<
        { __typename?: 'StepType' } & {
          maxStepAllowed?: Maybe<
            { __typename?: 'JobStep' } & Pick<JobStep, 'name' | 'index'>
          >;
        }
      >;
    };
};

export type CreateAccountAccredInvTypeMutationVariables = Exact<{
  investorType: CreateAccountInvestorType;
}>;

export type CreateAccountAccredInvTypeMutation = { __typename?: 'Mutation' } & {
  createAccountInvestorType: { __typename?: 'ReturnsType' } & Pick<
    ReturnsType,
    'success' | 'reference'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
      step?: Maybe<
        { __typename?: 'StepType' } & {
          maxStepAllowed?: Maybe<
            { __typename?: 'JobStep' } & Pick<JobStep, 'name' | 'index'>
          >;
        }
      >;
    };
};

export type CreateAccountCredsMutationVariables = Exact<{
  credentials: CreateAccountCreds;
}>;

export type CreateAccountCredsMutation = { __typename?: 'Mutation' } & {
  createAccountCreds: { __typename?: 'ReturnsType' } & Pick<
    ReturnsType,
    'success' | 'reference'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
      step?: Maybe<
        { __typename?: 'StepType' } & {
          maxStepAllowed?: Maybe<
            { __typename?: 'JobStep' } & Pick<JobStep, 'name' | 'index'>
          >;
        }
      >;
    };
};

export type CreateAccountContactMutationVariables = Exact<{
  contact: CreateAccountContact;
}>;

export type CreateAccountContactMutation = { __typename?: 'Mutation' } & {
  createAccountContact: { __typename?: 'ReturnsType' } & Pick<
    ReturnsType,
    'success' | 'reference'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
      step?: Maybe<
        { __typename?: 'StepType' } & {
          maxStepAllowed?: Maybe<
            { __typename?: 'JobStep' } & Pick<JobStep, 'name' | 'index'>
          >;
        }
      >;
    };
};

export type CreateAccountQuestionsMutationVariables = Exact<{
  questions: CreateAccountQuestions;
}>;

export type CreateAccountQuestionsMutation = { __typename?: 'Mutation' } & {
  createAccountQuestions: { __typename?: 'ReturnsType' } & Pick<
    ReturnsType,
    'success' | 'userId'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
      step?: Maybe<
        { __typename?: 'StepType' } & {
          maxStepAllowed?: Maybe<
            { __typename?: 'JobStep' } & Pick<JobStep, 'name' | 'index'>
          >;
        }
      >;
    };
};

export type CreateAccountConfirmEmailMutationVariables = Exact<{
  [key: string]: never;
}>;

export type CreateAccountConfirmEmailMutation = { __typename?: 'Mutation' } & {
  confirmEmail: { __typename?: 'ReturnsType' } & Pick<
    ReturnsType,
    'success' | 'reference'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
      step?: Maybe<
        { __typename?: 'StepType' } & {
          maxStepAllowed?: Maybe<
            { __typename?: 'JobStep' } & Pick<JobStep, 'name' | 'index'>
          >;
        }
      >;
    };
};

export type CreateAccountDigitCodeMutationVariables = Exact<{
  digitsCode: CheckTfaCodeParams;
}>;

export type CreateAccountDigitCodeMutation = { __typename?: 'Mutation' } & {
  checkDigitCodes: { __typename?: 'ReturnsType' } & Pick<
    ReturnsType,
    'success' | 'reference'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
      step?: Maybe<
        { __typename?: 'StepType' } & {
          maxStepAllowed?: Maybe<
            { __typename?: 'JobStep' } & Pick<JobStep, 'name' | 'index'>
          >;
        }
      >;
    };
};

export type CreateAcountCreateDocMutationVariables = Exact<{
  data: CreateDoc;
}>;

export type CreateAcountCreateDocMutation = { __typename?: 'Mutation' } & {
  createDoc: { __typename?: 'CreateDocResult' } & Pick<
    CreateDocResult,
    'success' | 'docReference' | 'url'
  >;
};

export type CreateAccountDocStatusMutationVariables = Exact<{
  data: CheckDocStatus;
}>;

export type CreateAccountDocStatusMutation = { __typename?: 'Mutation' } & {
  checkDocStatus: { __typename?: 'DocStatus' } & Pick<
    DocStatus,
    'success' | 'status'
  >;
};

export type CreateAccountResendSmsMutationVariables = Exact<{
  [key: string]: never;
}>;

export type CreateAccountResendSmsMutation = { __typename?: 'Mutation' } & {
  resendSms: { __typename?: 'ReturnsType' } & Pick<
    ReturnsType,
    'success' | 'reference'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
      step?: Maybe<
        { __typename?: 'StepType' } & {
          maxStepAllowed?: Maybe<
            { __typename?: 'JobStep' } & Pick<JobStep, 'name' | 'index'>
          >;
        }
      >;
    };
};

export type CreateAccountGetUserJobMutationVariables = Exact<{
  [key: string]: never;
}>;

export type CreateAccountGetUserJobMutation = { __typename?: 'Mutation' } & {
  getUserJob: { __typename?: 'UserJobResult' } & Pick<
    UserJobResult,
    'success'
  > & {
      step?: Maybe<
        { __typename?: 'JobStep' } & Pick<JobStep, 'name' | 'index'>
      >;
      userJob?: Maybe<
        { __typename?: 'UserJobResultDetails' } & Pick<
          UserJobResultDetails,
          | 'accreditation'
          | 'step'
          | 'investorType'
          | 'email'
          | 'emailVerified'
          | 'phoneVerified'
          | 'documentSigned'
        > & {
            contact?: Maybe<
              { __typename?: 'ContactTypeReturn' } & Pick<
                ContactTypeReturn,
                | 'firstName'
                | 'lastName'
                | 'phone'
                | 'phoneVerified'
                | 'title'
                | 'company'
                | 'firstAddress'
                | 'secondAddress'
                | 'country'
                | 'city'
                | 'state'
                | 'zipCode'
              >
            >;
            smsResends?: Maybe<
              { __typename?: 'SMSEmailCounter' } & Pick<
                SmsEmailCounter,
                'attempts' | 'left' | 'timeLeft'
              >
            >;
            smsTries?: Maybe<
              { __typename?: 'SMSEmailCounter' } & Pick<
                SmsEmailCounter,
                'attempts' | 'left' | 'timeLeft'
              >
            >;
            emailResends?: Maybe<
              { __typename?: 'SMSEmailCounter' } & Pick<
                SmsEmailCounter,
                'attempts' | 'left' | 'timeLeft'
              >
            >;
            emailTries?: Maybe<
              { __typename?: 'SMSEmailCounter' } & Pick<
                SmsEmailCounter,
                'attempts' | 'left' | 'timeLeft'
              >
            >;
          }
      >;
    };
};

export type CreateAccountEmailCheckValidationMutationVariables = Exact<{
  [key: string]: never;
}>;

export type CreateAccountEmailCheckValidationMutation = {
  __typename?: 'Mutation';
} & {
  checkEmailAddressValidation: { __typename?: 'ReturnsType' } & Pick<
    ReturnsType,
    'success'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
      step?: Maybe<
        { __typename?: 'StepType' } & {
          maxStepAllowed?: Maybe<
            { __typename?: 'JobStep' } & Pick<JobStep, 'name' | 'index'>
          >;
        }
      >;
    };
};

export type CcGetccContactJobMutationVariables = Exact<{
  [key: string]: never;
}>;

export type CcGetccContactJobMutation = { __typename?: 'Mutation' } & {
  getCcContactJob: { __typename?: 'ContactJobResult' } & Pick<
    ContactJobResult,
    'success'
  > & {
      userJob?: Maybe<
        { __typename?: 'ContactJobResultDetails' } & Pick<
          ContactJobResultDetails,
          | 'person'
          | 'relationship'
          | 'organization'
          | 'investorType'
          | 'emailVerified'
          | 'phoneVerified'
          | 'checked'
          | 'ccTypes'
        > & {
            contact?: Maybe<
              { __typename?: 'ContactContacts' } & Pick<
                ContactContacts,
                | 'email'
                | 'firstName'
                | 'lastName'
                | 'phone'
                | 'title'
                | 'company'
                | 'firstAddress'
                | 'secondAddress'
                | 'country'
                | 'city'
                | 'state'
                | 'zipCode'
              >
            >;
            smsResends?: Maybe<
              { __typename?: 'SMSEmailCounter' } & Pick<
                SmsEmailCounter,
                'attempts' | 'left' | 'timeLeft'
              >
            >;
            smsTries?: Maybe<
              { __typename?: 'SMSEmailCounter' } & Pick<
                SmsEmailCounter,
                'attempts' | 'left' | 'timeLeft'
              >
            >;
          }
      >;
      step?: Maybe<
        { __typename?: 'JobStep' } & Pick<JobStep, 'name' | 'index'>
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
    };
};

export type CcContactAddCredentialsMutationVariables = Exact<{
  data: CcContactAddCredentials;
}>;

export type CcContactAddCredentialsMutation = { __typename?: 'Mutation' } & {
  ccContactAddCredentials: { __typename?: 'ReturnsType' } & Pick<
    ReturnsType,
    'success'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
      step?: Maybe<
        { __typename?: 'StepType' } & {
          maxStepAllowed?: Maybe<
            { __typename?: 'JobStep' } & Pick<JobStep, 'name' | 'index'>
          >;
        }
      >;
    };
};

export type CcCreateAccountResendSmsMutationVariables = Exact<{
  [key: string]: never;
}>;

export type CcCreateAccountResendSmsMutation = { __typename?: 'Mutation' } & {
  ccContactResendSms: { __typename?: 'ReturnsType' } & Pick<
    ReturnsType,
    'success'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
      step?: Maybe<
        { __typename?: 'StepType' } & {
          maxStepAllowed?: Maybe<
            { __typename?: 'JobStep' } & Pick<JobStep, 'name' | 'index'>
          >;
        }
      >;
    };
};

export type CcCreateAccountSixDigitCodeMutationVariables = Exact<{
  data: CheckTfaCodeParams;
}>;

export type CcCreateAccountSixDigitCodeMutation = {
  __typename?: 'Mutation';
} & {
  checkContactTfaCode: { __typename?: 'ReturnsType' } & Pick<
    ReturnsType,
    'success'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
      step?: Maybe<
        { __typename?: 'StepType' } & {
          maxStepAllowed?: Maybe<
            { __typename?: 'JobStep' } & Pick<JobStep, 'name' | 'index'>
          >;
        }
      >;
    };
};

export type CcCreateAccountContactMutationVariables = Exact<{
  data: CreateAccountContact;
}>;

export type CcCreateAccountContactMutation = { __typename?: 'Mutation' } & {
  ccContactInfo: { __typename?: 'ReturnsType' } & Pick<
    ReturnsType,
    'success'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
      step?: Maybe<
        { __typename?: 'StepType' } & {
          maxStepAllowed?: Maybe<
            { __typename?: 'JobStep' } & Pick<JobStep, 'name' | 'index'>
          >;
        }
      >;
    };
};

export type SigninMutationVariables = Exact<{
  data: LoginParams;
}>;

export type SigninMutation = { __typename?: 'Mutation' } & {
  login: { __typename?: 'ReturnsType' } & Pick<
    ReturnsType,
    | 'success'
    | 'userId'
    | 'licenses'
    | 'availableMfaMethods'
    | 'preferredMfaMethod'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
    };
};

export type SigninSendMfaMutationVariables = Exact<{
  data: AuthSendMfaCodeParams;
}>;

export type SigninSendMfaMutation = { __typename?: 'Mutation' } & {
  authSendMfaCode: { __typename?: 'ReturnsType' } & Pick<
    ReturnsType,
    'success' | 'reference'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
    };
};

export type SigninCheckMfaMutationVariables = Exact<{
  data: CheckMfaCodeParams;
}>;

export type SigninCheckMfaMutation = { __typename?: 'Mutation' } & {
  authCheckMfaCode: { __typename?: 'ReturnsType' } & Pick<
    ReturnsType,
    'success' | 'userId' | 'licenses'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
    };
};

export type SigninResendMutationVariables = Exact<{
  data: AuthResendMfaCode;
}>;

export type SigninResendMutation = { __typename?: 'Mutation' } & {
  authResendMfaCode: { __typename?: 'ReturnsType' } & Pick<
    ReturnsType,
    'success'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
    };
};

export type UserLogoutMutationVariables = Exact<{ [key: string]: never }>;

export type UserLogoutMutation = { __typename?: 'Mutation' } & {
  logout: { __typename?: 'ReturnsType' } & Pick<ReturnsType, 'success'> & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
    };
};

export type UserLogoutEverywhereMutationVariables = Exact<{
  investorPk: Scalars['String'];
}>;

export type UserLogoutEverywhereMutation = { __typename?: 'Mutation' } & {
  logoutEverywhere: { __typename?: 'ReturnsType' } & Pick<
    ReturnsType,
    'success'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
    };
};

export type UserLogoutEverywhereElseMutationVariables = Exact<{
  [key: string]: never;
}>;

export type UserLogoutEverywhereElseMutation = { __typename?: 'Mutation' } & {
  logoutEverywhereElse: { __typename?: 'ReturnsType' } & Pick<
    ReturnsType,
    'success'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
    };
};

export type UserRefreshTokenMutationVariables = Exact<{ [key: string]: never }>;

export type UserRefreshTokenMutation = { __typename?: 'Mutation' } & {
  refreshTokens: { __typename?: 'ReturnsType' } & Pick<
    ReturnsType,
    'success' | 'userId'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
    };
};

export type UserForgotPasswordMutationVariables = Exact<{
  data: AuthForgotPassword;
}>;

export type UserForgotPasswordMutation = { __typename?: 'Mutation' } & {
  authForgotPassword: { __typename?: 'ReturnsType' } & Pick<
    ReturnsType,
    'success'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
    };
};

export type UserResetPasswordSendMfaMutationVariables = Exact<{
  data: AuthSendMfaCodeParams;
}>;

export type UserResetPasswordSendMfaMutation = { __typename?: 'Mutation' } & {
  authResetSendMfaCode: { __typename?: 'ReturnsType' } & Pick<
    ReturnsType,
    'success' | 'reference'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
    };
};

export type UserResetPasswordMutationVariables = Exact<{
  data: AuthResetPassword;
}>;

export type UserResetPasswordMutation = { __typename?: 'Mutation' } & {
  authResetPassword: { __typename?: 'ReturnsType' } & Pick<
    ReturnsType,
    'success'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
    };
};

export type UserResetPasswordMfaMutationVariables = Exact<{
  data: CheckMfaCodeParams;
}>;

export type UserResetPasswordMfaMutation = { __typename?: 'Mutation' } & {
  authResetCheckMfaCode: { __typename?: 'ReturnsType' } & Pick<
    ReturnsType,
    'success'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
    };
};

export type UserResetPasswordResendMfaMutationVariables = Exact<{
  data: AuthResetResendPasswordMfaCode;
}>;

export type UserResetPasswordResendMfaMutation = { __typename?: 'Mutation' } & {
  authResetResendMfaCode: { __typename?: 'ReturnsType' } & Pick<
    ReturnsType,
    'success'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
    };
};

export type GetUserInfoMutationVariables = Exact<{ [key: string]: never }>;

export type GetUserInfoMutation = { __typename?: 'Mutation' } & {
  getUserInfo: { __typename?: 'UserJobResult' } & Pick<
    UserJobResult,
    | 'success'
    | 'defaultRoute'
    | 'routes'
    | 'licenses'
    | 'isAdvisor'
    | 'returningInvestor'
  > & {
      investors?: Maybe<
        Array<
          { __typename?: 'UserInvestor' } & Pick<
            UserInvestor,
            'investor' | 'pk' | 'email'
          >
        >
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'message' | 'code' | 'severity'
          >
        >
      >;
      userJob?: Maybe<
        { __typename?: 'UserJobResultDetails' } & Pick<
          UserJobResultDetails,
          | 'accreditation'
          | 'investorType'
          | 'email'
          | 'emailVerified'
          | 'phoneVerified'
          | 'documentSigned'
        > & {
            contact?: Maybe<
              { __typename?: 'ContactTypeReturn' } & Pick<
                ContactTypeReturn,
                | 'firstName'
                | 'lastName'
                | 'phone'
                | 'phoneVerified'
                | 'title'
                | 'company'
                | 'firstAddress'
                | 'secondAddress'
                | 'country'
                | 'city'
                | 'state'
                | 'zipCode'
              >
            >;
          }
      >;
    };
};

export type MonitoringGetInvOverviewMutationVariables = Exact<{
  data: MonitoringGetInvOverview;
}>;

export type MonitoringGetInvOverviewMutation = { __typename?: 'Mutation' } & {
  monitoringGetInvOverview: {
    __typename?: 'MonitoringGetInvOverviewReturn';
  } & Pick<
    MonitoringGetInvOverviewReturn,
    'success' | 'dateOverview' | 'image' | 'investorEntities'
  > & {
      location?: Maybe<
        { __typename?: 'LocationType' } & Pick<
          LocationType,
          | 'address1'
          | 'address2'
          | 'city'
          | 'state'
          | 'postalCode'
          | 'country'
          | 'latitude'
          | 'longitude'
        >
      >;
      overview?: Maybe<
        { __typename?: 'MonitoringOverviewType' } & Pick<
          MonitoringOverviewType,
          'images'
        > & {
            overviewSections?: Maybe<
              Array<
                { __typename?: 'OverviewSectionType' } & Pick<
                  OverviewSectionType,
                  'label' | 'content'
                >
              >
            >;
          }
      >;
      document?: Maybe<
        { __typename?: 'DocType' } & Pick<DocType, 'title' | 'url'>
      >;
      milestones?: Maybe<
        Array<
          { __typename?: 'MilestonesType' } & Pick<
            MilestonesType,
            'date' | 'title' | 'note'
          >
        >
      >;
    };
};

export type MonitoringGetDatePickerMutationVariables = Exact<{
  [key: string]: never;
}>;

export type MonitoringGetDatePickerMutation = { __typename?: 'Mutation' } & {
  monitoringGetDatePicker: { __typename?: 'MonitoringGetDatePicker' } & Pick<
    MonitoringGetDatePicker,
    'success'
  > & {
      datePicker?: Maybe<
        Array<
          { __typename?: 'DatePickerType' } & Pick<
            DatePickerType,
            'date' | 'name'
          >
        >
      >;
    };
};

export type MonitoringGetInvestorEntitiesMutationVariables = Exact<{
  data: MonitoringGetInvestorEntitiesParams;
}>;

export type MonitoringGetInvestorEntitiesMutation = {
  __typename?: 'Mutation';
} & {
  monitoringGetInvestorEntities: {
    __typename?: 'MonitoringGetInvestorEntitiesReturn';
  } & Pick<MonitoringGetInvestorEntitiesReturn, 'success'> & {
      investorEntities?: Maybe<
        Array<
          { __typename?: 'DropdownOption' } & Pick<
            DropdownOption,
            'pk' | 'name'
          >
        >
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
    };
};

export type GetDocumentsByUserMutationVariables = Exact<{
  data: MonitoringGetInvDocuments;
}>;

export type GetDocumentsByUserMutation = { __typename?: 'Mutation' } & {
  getDocumentsByUser: { __typename?: 'MonitoringGetInvDocumentsReturn' } & Pick<
    MonitoringGetInvDocumentsReturn,
    'success'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
      table?: Maybe<
        { __typename?: 'DynamicTableType' } & Pick<
          DynamicTableType,
          'title'
        > & {
            headers?: Maybe<
              Array<
                { __typename?: 'DynamicTableHeadersType' } & Pick<
                  DynamicTableHeadersType,
                  | 'value'
                  | 'accessor'
                  | 'sortable'
                  | 'placeholder'
                  | 'type'
                  | 'hidden'
                > & {
                    width?: Maybe<
                      { __typename?: 'DynamicTableWidthType' } & Pick<
                        DynamicTableWidthType,
                        'fixedWidth'
                      >
                    >;
                  }
              >
            >;
            filters?: Maybe<
              Array<
                { __typename?: 'DynamicTableFiltersType' } & Pick<
                  DynamicTableFiltersType,
                  'label' | 'placeholder' | 'accessor' | 'options'
                >
              >
            >;
            tabs?: Maybe<
              { __typename?: 'DynamicTableTabsType' } & Pick<
                DynamicTableTabsType,
                'accessor' | 'options'
              >
            >;
            rows?: Maybe<
              Array<
                { __typename?: 'DynamicTableRowType' } & Pick<
                  DynamicTableRowType,
                  'value'
                > & {
                    rowData?: Maybe<
                      Array<
                        { __typename?: 'DynamicTableRowDataType' } & Pick<
                          DynamicTableRowDataType,
                          'value' | 'type' | 'accessor' | 'isActive'
                        > & {
                            params?: Maybe<
                              {
                                __typename?: 'DynamicTableRowDataParamsType';
                              } & Pick<
                                DynamicTableRowDataParamsType,
                                | 'pk'
                                | 'values'
                                | 'topInvestor'
                                | 'partnerPk'
                                | 'url'
                              >
                            >;
                          }
                      >
                    >;
                  }
              >
            >;
          }
      >;
    };
};

export type GetTaxDocumentsByUserMutationVariables = Exact<{
  data: MonitoringGetInvDocuments;
}>;

export type GetTaxDocumentsByUserMutation = { __typename?: 'Mutation' } & {
  getTaxDocumentsByUser: {
    __typename?: 'MonitoringGetInvDocumentsReturn';
  } & Pick<MonitoringGetInvDocumentsReturn, 'success'> & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
      table?: Maybe<
        { __typename?: 'DynamicTableType' } & Pick<
          DynamicTableType,
          'title'
        > & {
            tabs?: Maybe<
              { __typename?: 'DynamicTableTabsType' } & Pick<
                DynamicTableTabsType,
                'accessor' | 'options'
              >
            >;
            filters?: Maybe<
              Array<
                { __typename?: 'DynamicTableFiltersType' } & Pick<
                  DynamicTableFiltersType,
                  'label' | 'placeholder' | 'accessor' | 'options'
                >
              >
            >;
            headers?: Maybe<
              Array<
                { __typename?: 'DynamicTableHeadersType' } & Pick<
                  DynamicTableHeadersType,
                  | 'value'
                  | 'accessor'
                  | 'sortable'
                  | 'placeholder'
                  | 'type'
                  | 'hidden'
                > & {
                    width?: Maybe<
                      { __typename?: 'DynamicTableWidthType' } & Pick<
                        DynamicTableWidthType,
                        'fixedWidth'
                      >
                    >;
                  }
              >
            >;
            rows?: Maybe<
              Array<
                { __typename?: 'DynamicTableRowType' } & Pick<
                  DynamicTableRowType,
                  'value'
                > & {
                    rowData?: Maybe<
                      Array<
                        { __typename?: 'DynamicTableRowDataType' } & Pick<
                          DynamicTableRowDataType,
                          'value' | 'type' | 'accessor' | 'isActive'
                        > & {
                            params?: Maybe<
                              {
                                __typename?: 'DynamicTableRowDataParamsType';
                              } & Pick<
                                DynamicTableRowDataParamsType,
                                'pk' | 'values' | 'topInvestor' | 'partnerPk'
                              >
                            >;
                          }
                      >
                    >;
                  }
              >
            >;
          }
      >;
    };
};

export type UserOnboardingConfigureContactMfaMutationVariables = Exact<{
  data: OnboardingConfigureContactMfaParam;
}>;

export type UserOnboardingConfigureContactMfaMutation = {
  __typename?: 'Mutation';
} & {
  onboardingConfigureContactMFA: { __typename?: 'ReturnsType' } & Pick<
    ReturnsType,
    'success' | 'reference'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
    };
};

export type UserOnboardingCheckMfaCodeMutationVariables = Exact<{
  data: CheckMfaCodeParams;
}>;

export type UserOnboardingCheckMfaCodeMutation = { __typename?: 'Mutation' } & {
  onboardingCheckMfaCode: { __typename?: 'ReturnsType' } & Pick<
    ReturnsType,
    'success'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
    };
};

export type UserOnboardingResendMfaCodeMutationVariables = Exact<{
  data: AuthResendMfaCode;
}>;

export type UserOnboardingResendMfaCodeMutation = {
  __typename?: 'Mutation';
} & {
  onboardingResendMfaCode: { __typename?: 'ReturnsType' } & Pick<
    ReturnsType,
    'success'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
    };
};

export type UserOnboardingResetPasswordMutationVariables = Exact<{
  data: AuthResetPassword;
}>;

export type UserOnboardingResetPasswordMutation = {
  __typename?: 'Mutation';
} & {
  onboardingResetPassword: { __typename?: 'ReturnsType' } & Pick<
    ReturnsType,
    'success' | 'reference' | 'userId'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
    };
};

export type SalesrepUpdateNoteMutationVariables = Exact<{
  data: SalesrepUpdateNote;
}>;

export type SalesrepUpdateNoteMutation = { __typename?: 'Mutation' } & {
  salesrepUpdateNote: { __typename?: 'ReturnsType' } & Pick<
    ReturnsType,
    'success'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
    };
};

export type SalesrepCreateNoteMutationVariables = Exact<{
  data: SalesrepCreateNote;
}>;

export type SalesrepCreateNoteMutation = { __typename?: 'Mutation' } & {
  salesrepCreateNote: { __typename?: 'ReturnsType' } & Pick<
    ReturnsType,
    'success'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
    };
};

export type SalesrepDeleteNotesMutationVariables = Exact<{
  data: SalesrepDeleteNotes;
}>;

export type SalesrepDeleteNotesMutation = { __typename?: 'Mutation' } & {
  salesrepDeleteNotes: { __typename?: 'ReturnsType' } & Pick<
    ReturnsType,
    'success'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
    };
};

export type SalesrepSetNewPasswordMutationVariables = Exact<{
  data: SalesrepSetNewPassword;
}>;

export type SalesrepSetNewPasswordMutation = { __typename?: 'Mutation' } & {
  salesrepSetNewPassword: {
    __typename?: 'SalesrepSetNewPasswordReturn';
  } & Pick<SalesrepSetNewPasswordReturn, 'success'> & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
    };
};

export type SalesrepCreateProspectMutationVariables = Exact<{
  data: SalesrepCreateProspect;
}>;

export type SalesrepCreateProspectMutation = { __typename?: 'Mutation' } & {
  salesrepCreateProspect: {
    __typename?: 'SalesrepCreateProspectReturn';
  } & Pick<SalesrepCreateProspectReturn, 'success' | 'info'> & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
    };
};

export type SalesrepCreateAdvisorMutationVariables = Exact<{
  data: SalesrepCreateAdvisor;
}>;

export type SalesrepCreateAdvisorMutation = { __typename?: 'Mutation' } & {
  salesrepCreateAdvisor: { __typename?: 'SalesrepCreateAdvisorReturn' } & Pick<
    SalesrepCreateAdvisorReturn,
    'success' | 'info'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
    };
};

export type SalesrepCreateContactMutationVariables = Exact<{
  data: SalesrepCreateContact;
}>;

export type SalesrepCreateContactMutation = { __typename?: 'Mutation' } & {
  salesrepCreateContact: { __typename?: 'ReturnsType' } & Pick<
    ReturnsType,
    'success'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
    };
};

export type SalesrepDeleteInvestorMutationVariables = Exact<{
  data: SalesrepDeleteInvestor;
}>;

export type SalesrepDeleteInvestorMutation = { __typename?: 'Mutation' } & {
  salesrepDeleteInvestor: {
    __typename?: 'SalesrepDeleteInvestorReturn';
  } & Pick<SalesrepDeleteInvestorReturn, 'success'> & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
    };
};

export type SalesrepGetInvestorQueryVariables = Exact<{
  data: SalesrepGetInvestor;
}>;

export type SalesrepGetInvestorQuery = { __typename?: 'Query' } & {
  salesrepGetInvestor: { __typename?: 'SalesrepGetInvestorReturn' } & Pick<
    SalesrepGetInvestorReturn,
    'success'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
      investorInfo?: Maybe<
        Array<
          { __typename?: 'SalesrepInvestorInfo' } & Pick<
            SalesrepInvestorInfo,
            'title' | 'tag'
          > & {
              content?: Maybe<
                Array<
                  { __typename?: 'SalesrepInfoContent' } & Pick<
                    SalesrepInfoContent,
                    'title' | 'type' | 'values'
                  >
                >
              >;
            }
        >
      >;
    };
};

export type SalesrepGetEntityQueryVariables = Exact<{
  data: SalesrepGetEntity;
}>;

export type SalesrepGetEntityQuery = { __typename?: 'Query' } & {
  salesrepGetEntity: { __typename?: 'SalesrepGetEntityReturn' } & Pick<
    SalesrepGetEntityReturn,
    'success' | 'entityName'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
      entity?: Maybe<
        Array<
          { __typename?: 'SalesrepInvestorInfo' } & Pick<
            SalesrepInvestorInfo,
            'title' | 'tag'
          > & {
              content?: Maybe<
                Array<
                  { __typename?: 'SalesrepInfoContent' } & Pick<
                    SalesrepInfoContent,
                    'title' | 'type' | 'values'
                  > & {
                      customValues?: Maybe<
                        Array<
                          {
                            __typename?: 'SalesrepInfoContentCustomValues';
                          } & Pick<
                            SalesrepInfoContentCustomValues,
                            'title' | 'type' | 'values'
                          >
                        >
                      >;
                    }
                >
              >;
            }
        >
      >;
    };
};

export type SalesrepGetInvestorsQueryVariables = Exact<{
  data: SalesrepGetInvestors;
}>;

export type SalesrepGetInvestorsQuery = { __typename?: 'Query' } & {
  salesrepGetInvestors: { __typename?: 'DynamicTableReturn' } & Pick<
    DynamicTableReturn,
    'success'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
      data?: Maybe<
        { __typename?: 'DynamicTableDataType' } & {
          table?: Maybe<
            { __typename?: 'DynamicTableType' } & Pick<
              DynamicTableType,
              'title'
            > & {
                filters?: Maybe<
                  Array<
                    { __typename?: 'DynamicTableFiltersType' } & Pick<
                      DynamicTableFiltersType,
                      'label' | 'placeholder' | 'accessor' | 'options'
                    >
                  >
                >;
                tabs?: Maybe<
                  { __typename?: 'DynamicTableTabsType' } & Pick<
                    DynamicTableTabsType,
                    'accessor' | 'options'
                  >
                >;
                headers?: Maybe<
                  Array<
                    { __typename?: 'DynamicTableHeadersType' } & Pick<
                      DynamicTableHeadersType,
                      | 'value'
                      | 'accessor'
                      | 'sortable'
                      | 'placeholder'
                      | 'type'
                      | 'hidden'
                    > & {
                        width?: Maybe<
                          { __typename?: 'DynamicTableWidthType' } & Pick<
                            DynamicTableWidthType,
                            'fixedWidth'
                          >
                        >;
                      }
                  >
                >;
                rows?: Maybe<
                  Array<
                    { __typename?: 'DynamicTableRowType' } & Pick<
                      DynamicTableRowType,
                      'value'
                    > & {
                        rowData?: Maybe<
                          Array<
                            { __typename?: 'DynamicTableRowDataType' } & Pick<
                              DynamicTableRowDataType,
                              'value' | 'type' | 'accessor' | 'isActive'
                            > & {
                                params?: Maybe<
                                  {
                                    __typename?: 'DynamicTableRowDataParamsType';
                                  } & Pick<
                                    DynamicTableRowDataParamsType,
                                    | 'pk'
                                    | 'values'
                                    | 'topInvestor'
                                    | 'partnerPk'
                                  >
                                >;
                              }
                          >
                        >;
                      }
                  >
                >;
              }
          >;
        }
      >;
    };
};

export type SalesrepGetAdvisorsQueryVariables = Exact<{
  data: SalesrepGetAdvisors;
}>;

export type SalesrepGetAdvisorsQuery = { __typename?: 'Query' } & {
  salesrepGetAdvisors: { __typename?: 'DynamicTableReturn' } & Pick<
    DynamicTableReturn,
    'success'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
      data?: Maybe<
        { __typename?: 'DynamicTableDataType' } & {
          table?: Maybe<
            { __typename?: 'DynamicTableType' } & Pick<
              DynamicTableType,
              'title'
            > & {
                filters?: Maybe<
                  Array<
                    { __typename?: 'DynamicTableFiltersType' } & Pick<
                      DynamicTableFiltersType,
                      'label' | 'placeholder' | 'accessor' | 'options'
                    >
                  >
                >;
                tabs?: Maybe<
                  { __typename?: 'DynamicTableTabsType' } & Pick<
                    DynamicTableTabsType,
                    'accessor' | 'options'
                  >
                >;
                headers?: Maybe<
                  Array<
                    { __typename?: 'DynamicTableHeadersType' } & Pick<
                      DynamicTableHeadersType,
                      | 'value'
                      | 'accessor'
                      | 'sortable'
                      | 'placeholder'
                      | 'type'
                      | 'hidden'
                    > & {
                        width?: Maybe<
                          { __typename?: 'DynamicTableWidthType' } & Pick<
                            DynamicTableWidthType,
                            'fixedWidth'
                          >
                        >;
                      }
                  >
                >;
                rows?: Maybe<
                  Array<
                    { __typename?: 'DynamicTableRowType' } & Pick<
                      DynamicTableRowType,
                      'value'
                    > & {
                        rowData?: Maybe<
                          Array<
                            { __typename?: 'DynamicTableRowDataType' } & Pick<
                              DynamicTableRowDataType,
                              'value' | 'type' | 'accessor' | 'isActive'
                            > & {
                                params?: Maybe<
                                  {
                                    __typename?: 'DynamicTableRowDataParamsType';
                                  } & Pick<DynamicTableRowDataParamsType, 'pk'>
                                >;
                              }
                          >
                        >;
                      }
                  >
                >;
              }
          >;
        }
      >;
    };
};

export type SalesrepGetInvestmentsQueryVariables = Exact<{
  data: SalesrepGetInvestments;
}>;

export type SalesrepGetInvestmentsQuery = { __typename?: 'Query' } & {
  salesrepGetInvestments: {
    __typename?: 'SalesrepGetInvestmentsReturn';
  } & Pick<SalesrepGetInvestmentsReturn, 'success' | 'offerings'> & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
      investorEntities?: Maybe<
        Array<
          { __typename?: 'SalesrepInvestmentsType' } & Pick<
            SalesrepInvestmentsType,
            | 'pk'
            | 'name'
            | 'date'
            | 'cmmt'
            | 'contrib'
            | 'distrib'
            | 'nav'
            | 'irr'
            | 'multiple'
            | 'investmentsLength'
          > & {
              investments?: Maybe<
                Array<
                  { __typename?: 'SalesInvestmentsByEntity' } & Pick<
                    SalesInvestmentsByEntity,
                    | 'pk'
                    | 'name'
                    | 'image'
                    | 'status'
                    | 'cmmt'
                    | 'contrib'
                    | 'distrib'
                    | 'nav'
                    | 'irr'
                    | 'multiple'
                  > & {
                      offeringDocs?: Maybe<
                        Array<
                          { __typename?: 'SalesrepDocsType' } & Pick<
                            SalesrepDocsType,
                            'name' | 'url'
                          >
                        >
                      >;
                      complianceDocs?: Maybe<
                        Array<
                          { __typename?: 'SalesrepDocsType' } & Pick<
                            SalesrepDocsType,
                            'name' | 'url'
                          >
                        >
                      >;
                    }
                >
              >;
            }
        >
      >;
    };
};

export type SalesrepGetInvestmentQueryVariables = Exact<{
  data: SalesrepGetInvestment;
}>;

export type SalesrepGetInvestmentQuery = { __typename?: 'Query' } & {
  salesrepGetInvestment: { __typename?: 'SalesrepGetInvestmentReturn' } & Pick<
    SalesrepGetInvestmentReturn,
    'success'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
      investments?: Maybe<
        Array<
          { __typename?: 'SalesrepInvestmentType' } & Pick<
            SalesrepInvestmentType,
            | 'pk'
            | 'name'
            | 'image'
            | 'cmmt'
            | 'contrib'
            | 'distrib'
            | 'nav'
            | 'multiple'
            | 'irr'
          >
        >
      >;
    };
};

export type SalesrepResetPasswordMutationVariables = Exact<{
  data: SalesrepResetPassword;
}>;

export type SalesrepResetPasswordMutation = { __typename?: 'Mutation' } & {
  salesrepResetPassword: { __typename?: 'ReturnsType' } & Pick<
    ReturnsType,
    'success'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
    };
};

export type SalesrepPauseAccountMutationVariables = Exact<{
  data: SalesrepPauseAccount;
}>;

export type SalesrepPauseAccountMutation = { __typename?: 'Mutation' } & {
  salesrepPauseAccount: { __typename?: 'ReturnsType' } & Pick<
    ReturnsType,
    'success'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
    };
};

export type SalesrepActivateAccountMutationVariables = Exact<{
  data: SalesrepActivateAccount;
}>;

export type SalesrepActivateAccountMutation = { __typename?: 'Mutation' } & {
  salesrepActivateAccount: { __typename?: 'ReturnsType' } & Pick<
    ReturnsType,
    'success'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
    };
};

export type SalesrepGetProspectsQueryVariables = Exact<{
  data: SalesrepGetProspects;
}>;

export type SalesrepGetProspectsQuery = { __typename?: 'Query' } & {
  salesrepGetProspects: { __typename?: 'SalesrepGetProspectsReturn' } & Pick<
    SalesrepGetProspectsReturn,
    'success' | 'entities' | 'properties' | 'status'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
      investments?: Maybe<
        Array<
          { __typename?: 'SalesrepProspectsInvestmentsType' } & Pick<
            SalesrepProspectsInvestmentsType,
            'id' | 'entityName' | 'identityType' | 'entityLogo'
          > & {
              prospectsListByEntity?: Maybe<
                Array<
                  { __typename?: 'SalesrepProspectsListType' } & Pick<
                    SalesrepProspectsListType,
                    | 'id'
                    | 'prospectName'
                    | 'prospectImage'
                    | 'entityOwner'
                    | 'status'
                    | 'submission'
                    | 'date'
                    | 'time'
                  > & {
                      goal?: Maybe<
                        { __typename?: 'SalesrepProspectsAmountType' } & Pick<
                          SalesrepProspectsAmountType,
                          'amount' | 'percentage'
                        >
                      >;
                      raised?: Maybe<
                        { __typename?: 'SalesrepProspectsAmountType' } & Pick<
                          SalesrepProspectsAmountType,
                          'amount' | 'percentage'
                        >
                      >;
                      unfunded?: Maybe<
                        { __typename?: 'SalesrepProspectsAmountType' } & Pick<
                          SalesrepProspectsAmountType,
                          'amount' | 'percentage'
                        >
                      >;
                      close?: Maybe<
                        { __typename?: 'SalesrepProspectsCloseType' } & Pick<
                          SalesrepProspectsCloseType,
                          'date' | 'in_days'
                        >
                      >;
                      offeringDocs?: Maybe<
                        Array<
                          { __typename?: 'SalesrepDocsType' } & Pick<
                            SalesrepDocsType,
                            'name' | 'url'
                          >
                        >
                      >;
                      complianceDocs?: Maybe<
                        Array<
                          { __typename?: 'SalesrepDocsType' } & Pick<
                            SalesrepDocsType,
                            'name' | 'url'
                          >
                        >
                      >;
                    }
                >
              >;
            }
        >
      >;
    };
};

export type SalesrepGetContactsQueryVariables = Exact<{
  data: SalesrepGetContacts;
}>;

export type SalesrepGetContactsQuery = { __typename?: 'Query' } & {
  salesrepGetContacts: { __typename?: 'SalesrepGetContactsReturn' } & Pick<
    SalesrepGetContactsReturn,
    'success' | 'entitiesFilter'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
      contacts?: Maybe<
        Array<
          { __typename?: 'SalesRepContactType' } & Pick<
            SalesRepContactType,
            | 'pk'
            | 'contactName'
            | 'primary'
            | 'company'
            | 'title'
            | 'linkedIn'
            | 'email'
            | 'mobile'
          > & {
              address?: Maybe<
                { __typename?: 'SalesRepContactAddressType' } & Pick<
                  SalesRepContactAddressType,
                  | 'name'
                  | 'address1'
                  | 'address2'
                  | 'city'
                  | 'state'
                  | 'zip'
                  | 'country'
                  | 'block'
                  | 'line'
                >
              >;
              entities?: Maybe<
                Array<
                  { __typename?: 'SalesRepContactEntityType' } & Pick<
                    SalesRepContactEntityType,
                    | 'pk'
                    | 'entityName'
                    | 'entityType'
                    | 'entityLogo'
                    | 'commPreference'
                    | 'commTypes'
                  > & {
                      address?: Maybe<
                        {
                          __typename?: 'SalesRepContactEntityAddressType';
                        } & Pick<
                          SalesRepContactEntityAddressType,
                          | 'name'
                          | 'address1'
                          | 'address2'
                          | 'city'
                          | 'state'
                          | 'zip'
                          | 'country'
                          | 'block'
                          | 'line'
                        >
                      >;
                    }
                >
              >;
            }
        >
      >;
    };
};

export type SalesrepGetOfferingsQueryVariables = Exact<{
  data: SalesrepGetOfferings;
}>;

export type SalesrepGetOfferingsQuery = { __typename?: 'Query' } & {
  salesrepGetOfferings: { __typename?: 'SalesrepGetOfferingsReturn' } & Pick<
    SalesrepGetOfferingsReturn,
    'success'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
      table?: Maybe<
        { __typename?: 'DynamicTableType' } & Pick<
          DynamicTableType,
          'title'
        > & {
            filters?: Maybe<
              Array<
                { __typename?: 'DynamicTableFiltersType' } & Pick<
                  DynamicTableFiltersType,
                  'label' | 'placeholder' | 'accessor' | 'options'
                >
              >
            >;
            tabs?: Maybe<
              { __typename?: 'DynamicTableTabsType' } & Pick<
                DynamicTableTabsType,
                'accessor' | 'options'
              >
            >;
            headers?: Maybe<
              Array<
                { __typename?: 'DynamicTableHeadersType' } & Pick<
                  DynamicTableHeadersType,
                  | 'value'
                  | 'accessor'
                  | 'sortable'
                  | 'placeholder'
                  | 'type'
                  | 'hidden'
                > & {
                    width?: Maybe<
                      { __typename?: 'DynamicTableWidthType' } & Pick<
                        DynamicTableWidthType,
                        'fixedWidth'
                      >
                    >;
                  }
              >
            >;
            rows?: Maybe<
              Array<
                { __typename?: 'DynamicTableRowType' } & Pick<
                  DynamicTableRowType,
                  'value'
                > & {
                    rowData?: Maybe<
                      Array<
                        { __typename?: 'DynamicTableRowDataType' } & Pick<
                          DynamicTableRowDataType,
                          'value' | 'type' | 'accessor' | 'isActive'
                        > & {
                            params?: Maybe<
                              {
                                __typename?: 'DynamicTableRowDataParamsType';
                              } & Pick<
                                DynamicTableRowDataParamsType,
                                | 'pk'
                                | 'url'
                                | 'goal'
                                | 'fundAmount'
                                | 'fundAmountPercent'
                                | 'fundRemainingPercent'
                                | 'color'
                              >
                            >;
                          }
                      >
                    >;
                  }
              >
            >;
          }
      >;
    };
};

export type SalesrepGetNotesQueryVariables = Exact<{
  data: SalesrepGetNotes;
}>;

export type SalesrepGetNotesQuery = { __typename?: 'Query' } & {
  salesrepGetNotes: { __typename?: 'SalesrepGetNotesReturn' } & Pick<
    SalesrepGetNotesReturn,
    | 'success'
    | 'types'
    | 'followUpTypes'
    | 'filterTypes'
    | 'filterFollowUpTypes'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
      notes?: Maybe<
        Array<
          { __typename?: 'SalesrepNote' } & Pick<
            SalesrepNote,
            | 'pk'
            | 'date'
            | 'note'
            | 'subject'
            | 'type'
            | 'initials'
            | 'followUpType'
            | 'followUpDate'
            | 'followUpDone'
            | 'lastSaved'
          >
        >
      >;
    };
};

export type SalesrepGetEntitiesQueryVariables = Exact<{
  data: SalesrepGetEntities;
}>;

export type SalesrepGetEntitiesQuery = { __typename?: 'Query' } & {
  salesrepGetEntities: { __typename?: 'SalesrepGetEntitiesReturn' } & Pick<
    SalesrepGetEntitiesReturn,
    'success'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
      entities?: Maybe<
        Array<
          { __typename?: 'SalesrepEntity' } & Pick<
            SalesrepEntity,
            'pk' | 'entityName' | 'entityType' | 'contactsLength'
          > & {
              address?: Maybe<
                { __typename?: 'SalesrepEntityAddress' } & Pick<
                  SalesrepEntityAddress,
                  | 'name'
                  | 'address1'
                  | 'address2'
                  | 'city'
                  | 'state'
                  | 'zip'
                  | 'country'
                  | 'line'
                  | 'block'
                  | 'blockFull'
                >
              >;
              contacts?: Maybe<
                Array<
                  { __typename?: 'SalesrepEntityContacts' } & Pick<
                    SalesrepEntityContacts,
                    'name' | 'primary' | 'email' | 'mobile' | 'commTypes'
                  >
                >
              >;
              table?: Maybe<
                { __typename?: 'DynamicTableType' } & Pick<
                  DynamicTableType,
                  'title'
                > & {
                    filters?: Maybe<
                      Array<
                        { __typename?: 'DynamicTableFiltersType' } & Pick<
                          DynamicTableFiltersType,
                          'label' | 'placeholder' | 'accessor' | 'options'
                        >
                      >
                    >;
                    tabs?: Maybe<
                      { __typename?: 'DynamicTableTabsType' } & Pick<
                        DynamicTableTabsType,
                        'accessor' | 'options'
                      >
                    >;
                    headers?: Maybe<
                      Array<
                        { __typename?: 'DynamicTableHeadersType' } & Pick<
                          DynamicTableHeadersType,
                          | 'value'
                          | 'accessor'
                          | 'sortable'
                          | 'placeholder'
                          | 'type'
                          | 'hidden'
                        > & {
                            width?: Maybe<
                              { __typename?: 'DynamicTableWidthType' } & Pick<
                                DynamicTableWidthType,
                                'fixedWidth'
                              >
                            >;
                          }
                      >
                    >;
                    rows?: Maybe<
                      Array<
                        { __typename?: 'DynamicTableRowType' } & Pick<
                          DynamicTableRowType,
                          'value'
                        > & {
                            rowData?: Maybe<
                              Array<
                                {
                                  __typename?: 'DynamicTableRowDataType';
                                } & Pick<
                                  DynamicTableRowDataType,
                                  'value' | 'type' | 'accessor' | 'isActive'
                                > & {
                                    params?: Maybe<
                                      {
                                        __typename?: 'DynamicTableRowDataParamsType';
                                      } & Pick<
                                        DynamicTableRowDataParamsType,
                                        | 'pk'
                                        | 'values'
                                        | 'topInvestor'
                                        | 'partnerPk'
                                      >
                                    >;
                                  }
                              >
                            >;
                          }
                      >
                    >;
                  }
              >;
            }
        >
      >;
    };
};

export type SalesrepGetWatchListQueryVariables = Exact<{
  data: SalesrepGetWatchList;
}>;

export type SalesrepGetWatchListQuery = { __typename?: 'Query' } & {
  salesrepGetWatchList: { __typename?: 'SalesrepGetWatchListReturn' } & Pick<
    SalesrepGetWatchListReturn,
    'success'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
      properties?: Maybe<
        Array<
          { __typename?: 'SalesrepWatch' } & Pick<
            SalesrepWatch,
            'id' | 'propertyName' | 'propertyImage'
          > & {
              added?: Maybe<
                { __typename?: 'SalesrepWatchDate' } & Pick<
                  SalesrepWatchDate,
                  'date' | 'inDays'
                >
              >;
              lastViewed?: Maybe<
                { __typename?: 'SalesrepWatchDate' } & Pick<
                  SalesrepWatchDate,
                  'date' | 'inDays'
                >
              >;
              goal?: Maybe<
                { __typename?: 'SalesrepWatchAmount' } & Pick<
                  SalesrepWatchAmount,
                  'amount' | 'percentage'
                >
              >;
              raised?: Maybe<
                { __typename?: 'SalesrepWatchAmount' } & Pick<
                  SalesrepWatchAmount,
                  'amount' | 'percentage'
                >
              >;
              unfunded?: Maybe<
                { __typename?: 'SalesrepWatchAmount' } & Pick<
                  SalesrepWatchAmount,
                  'amount' | 'percentage'
                >
              >;
              close?: Maybe<
                { __typename?: 'SalesrepWatchDate' } & Pick<
                  SalesrepWatchDate,
                  'date' | 'inDays'
                >
              >;
            }
        >
      >;
    };
};

export type SalesrepGetOfferingDetailsQueryVariables = Exact<{
  data: GetOfferingDetails;
}>;

export type SalesrepGetOfferingDetailsQuery = { __typename?: 'Query' } & {
  salesrepGetOfferingDetails: {
    __typename?: 'GetOfferingDetailsReturn';
  } & Pick<GetOfferingDetailsReturn, 'success'> & {
      offering?: Maybe<
        { __typename?: 'OfferingDetailsResult' } & Pick<
          OfferingDetailsResult,
          'webOfferingPK' | 'offeringTitle' | 'offeringType' | 'percentFunded'
        > & {
            content?: Maybe<
              { __typename?: 'GetOfferingDetailsContentType' } & Pick<
                GetOfferingDetailsContentType,
                | 'description'
                | 'fundTarget'
                | 'fundedAmount'
                | 'closeDate'
                | 'sponsorDescription'
                | 'introduction'
              > & {
                  investmentType?: Maybe<
                    { __typename?: 'FilterDataWidgetType' } & Pick<
                      FilterDataWidgetType,
                      'label' | 'selectedValue' | 'options'
                    >
                  >;
                  strategy?: Maybe<
                    { __typename?: 'FilterDataWidgetType' } & Pick<
                      FilterDataWidgetType,
                      'label' | 'selectedValue' | 'options'
                    >
                  >;
                  flex?: Maybe<
                    { __typename?: 'FilterDataWidgetType' } & Pick<
                      FilterDataWidgetType,
                      'label' | 'selectedValue' | 'options'
                    >
                  >;
                  location?: Maybe<
                    {
                      __typename?: 'AdminGetOfferingDetailsLocationType';
                    } & Pick<
                      AdminGetOfferingDetailsLocationType,
                      | 'address1'
                      | 'address2'
                      | 'city'
                      | 'state'
                      | 'postalCode'
                      | 'country'
                      | 'lat'
                      | 'long'
                      | 'mapZoom'
                    >
                  >;
                  widgets?: Maybe<
                    Array<
                      { __typename?: 'WidgetType' } & Pick<
                        WidgetType,
                        'label' | 'type'
                      > & {
                          selected?: Maybe<
                            Array<
                              { __typename?: 'WidgetSelectedType' } & Pick<
                                WidgetSelectedType,
                                | 'label'
                                | 'value'
                                | 'percentage'
                                | 'row'
                                | 'col'
                                | 'topLine'
                                | 'fileName'
                                | 'docPk'
                                | 'type'
                                | 'url'
                                | 'order'
                              >
                            >
                          >;
                        }
                    >
                  >;
                }
            >;
            galleryImages?: Maybe<
              { __typename?: 'AdminOfferingDetailsGalleryType' } & Pick<
                AdminOfferingDetailsGalleryType,
                'label' | 'subLabel'
              > & {
                  imageWidgets?: Maybe<
                    Array<
                      {
                        __typename?: 'AdminOfferingDetailsGalleryImageType';
                      } & Pick<
                        AdminOfferingDetailsGalleryImageType,
                        'label' | 'url' | 'caption' | 'altText' | 'order'
                      >
                    >
                  >;
                }
            >;
            otherOpportunities?: Maybe<
              Array<
                { __typename?: 'OtherOpportunitiesType' } & Pick<
                  OtherOpportunitiesType,
                  | 'webOfferingPK'
                  | 'type'
                  | 'offeringTitle'
                  | 'fundTarget'
                  | 'percentFunded'
                  | 'closeDate'
                > & {
                    imageUrls?: Maybe<
                      Array<
                        { __typename?: 'OtherOpportunitiesImageType' } & Pick<
                          OtherOpportunitiesImageType,
                          | 'pk'
                          | 'label'
                          | 'caption'
                          | 'url'
                          | 'altText'
                          | 'order'
                        >
                      >
                    >;
                    filterDataWidgets?: Maybe<
                      Array<
                        { __typename?: 'FilterDataWidgetType' } & Pick<
                          FilterDataWidgetType,
                          'label' | 'selectedValue'
                        >
                      >
                    >;
                  }
              >
            >;
          }
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
    };
};

export type SalesrepGetContactDetailsQueryVariables = Exact<{
  data: SalesrepGetContact;
}>;

export type SalesrepGetContactDetailsQuery = { __typename?: 'Query' } & {
  salesrepGetContact: { __typename?: 'SalesrepGetContactReturn' } & Pick<
    SalesrepGetContactReturn,
    'success' | 'pk' | 'contactName' | 'parentName'
  > & {
      groups?: Maybe<
        Array<
          { __typename?: 'SalesRepContactGroups' } & Pick<
            SalesRepContactGroups,
            'title'
          > & {
              content?: Maybe<
                Array<
                  { __typename?: 'SalesRepContactGroupsContent' } & Pick<
                    SalesRepContactGroupsContent,
                    'title' | 'type' | 'values'
                  >
                >
              >;
            }
        >
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
    };
};

export type SalesrepGetAdvisorQueryVariables = Exact<{
  data: SalesrepGetAdvisor;
}>;

export type SalesrepGetAdvisorQuery = { __typename?: 'Query' } & {
  salesrepGetAdvisor: { __typename?: 'SalesrepGetAdvisorReturn' } & Pick<
    SalesrepGetAdvisorReturn,
    'success'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
      investorInfo?: Maybe<
        Array<
          { __typename?: 'SalesrepInvestorInfo' } & Pick<
            SalesrepInvestorInfo,
            'title' | 'tag'
          > & {
              content?: Maybe<
                Array<
                  { __typename?: 'SalesrepInfoContent' } & Pick<
                    SalesrepInfoContent,
                    'title' | 'type' | 'values'
                  >
                >
              >;
            }
        >
      >;
    };
};

export type SalesrepDesktopGetEntitiesQueryVariables = Exact<{
  data: SalesrepDesktopGetEntities;
}>;

export type SalesrepDesktopGetEntitiesQuery = { __typename?: 'Query' } & {
  salesrepDesktopGetEntities: {
    __typename?: 'SalesrepDesktopGetEntitiesReturns';
  } & Pick<SalesrepDesktopGetEntitiesReturns, 'success'> & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
      entities?: Maybe<
        Array<
          { __typename?: 'SalesrepDesktopEntities' } & Pick<
            SalesrepDesktopEntities,
            'entityName'
          > & {
              entityDetails?: Maybe<
                { __typename?: 'SalesRepEntityDetails' } & Pick<
                  SalesRepEntityDetails,
                  'title'
                > & {
                    content?: Maybe<
                      Array<
                        {
                          __typename?: 'SalesrepInfoContentCustomValues';
                        } & Pick<
                          SalesrepInfoContentCustomValues,
                          'title' | 'type' | 'values'
                        >
                      >
                    >;
                  }
              >;
              table?: Maybe<
                { __typename?: 'DynamicTableType' } & Pick<
                  DynamicTableType,
                  'title'
                > & {
                    filters?: Maybe<
                      Array<
                        { __typename?: 'DynamicTableFiltersType' } & Pick<
                          DynamicTableFiltersType,
                          'label' | 'placeholder' | 'accessor' | 'options'
                        >
                      >
                    >;
                    tabs?: Maybe<
                      { __typename?: 'DynamicTableTabsType' } & Pick<
                        DynamicTableTabsType,
                        'accessor' | 'options'
                      >
                    >;
                    headers?: Maybe<
                      Array<
                        { __typename?: 'DynamicTableHeadersType' } & Pick<
                          DynamicTableHeadersType,
                          | 'value'
                          | 'accessor'
                          | 'sortable'
                          | 'placeholder'
                          | 'type'
                          | 'hidden'
                        > & {
                            width?: Maybe<
                              { __typename?: 'DynamicTableWidthType' } & Pick<
                                DynamicTableWidthType,
                                'fixedWidth'
                              >
                            >;
                          }
                      >
                    >;
                    rows?: Maybe<
                      Array<
                        { __typename?: 'DynamicTableRowType' } & Pick<
                          DynamicTableRowType,
                          'value'
                        > & {
                            rowData?: Maybe<
                              Array<
                                {
                                  __typename?: 'DynamicTableRowDataType';
                                } & Pick<
                                  DynamicTableRowDataType,
                                  'value' | 'type' | 'accessor' | 'isActive'
                                > & {
                                    params?: Maybe<
                                      {
                                        __typename?: 'DynamicTableRowDataParamsType';
                                      } & Pick<
                                        DynamicTableRowDataParamsType,
                                        | 'pk'
                                        | 'values'
                                        | 'topInvestor'
                                        | 'partnerPk'
                                      >
                                    >;
                                  }
                              >
                            >;
                          }
                      >
                    >;
                  }
              >;
            }
        >
      >;
    };
};

export type SalesrepDesktopGetContactsQueryVariables = Exact<{
  data: SalesrepDesktopGetContacts;
}>;

export type SalesrepDesktopGetContactsQuery = { __typename?: 'Query' } & {
  salesrepDesktopGetContacts: { __typename?: 'DynamicTableReturn' } & Pick<
    DynamicTableReturn,
    'success'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
      data?: Maybe<
        { __typename?: 'DynamicTableDataType' } & {
          table?: Maybe<
            { __typename?: 'DynamicTableType' } & Pick<
              DynamicTableType,
              'title'
            > & {
                filters?: Maybe<
                  Array<
                    { __typename?: 'DynamicTableFiltersType' } & Pick<
                      DynamicTableFiltersType,
                      'label' | 'placeholder' | 'accessor' | 'options'
                    >
                  >
                >;
                tabs?: Maybe<
                  { __typename?: 'DynamicTableTabsType' } & Pick<
                    DynamicTableTabsType,
                    'accessor' | 'options'
                  >
                >;
                headers?: Maybe<
                  Array<
                    { __typename?: 'DynamicTableHeadersType' } & Pick<
                      DynamicTableHeadersType,
                      | 'value'
                      | 'accessor'
                      | 'sortable'
                      | 'placeholder'
                      | 'type'
                      | 'hidden'
                    > & {
                        width?: Maybe<
                          { __typename?: 'DynamicTableWidthType' } & Pick<
                            DynamicTableWidthType,
                            'fixedWidth'
                          >
                        >;
                      }
                  >
                >;
                rows?: Maybe<
                  Array<
                    { __typename?: 'DynamicTableRowType' } & Pick<
                      DynamicTableRowType,
                      'value'
                    > & {
                        rowData?: Maybe<
                          Array<
                            { __typename?: 'DynamicTableRowDataType' } & Pick<
                              DynamicTableRowDataType,
                              'value' | 'type' | 'accessor' | 'isActive'
                            > & {
                                params?: Maybe<
                                  {
                                    __typename?: 'DynamicTableRowDataParamsType';
                                  } & Pick<
                                    DynamicTableRowDataParamsType,
                                    | 'pk'
                                    | 'values'
                                    | 'topInvestor'
                                    | 'partnerPk'
                                  >
                                >;
                              }
                          >
                        >;
                      }
                  >
                >;
              }
          >;
        }
      >;
    };
};

export type SalesrepDesktopGetInvestmentsQueryVariables = Exact<{
  data: SalesrepDesktopGetInvestments;
}>;

export type SalesrepDesktopGetInvestmentsQuery = { __typename?: 'Query' } & {
  salesrepDesktopGetInvestments: {
    __typename?: 'SalesrepDesktopGetInvestmentsReturns';
  } & Pick<SalesrepDesktopGetInvestmentsReturns, 'success'> & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
      investorEntities?: Maybe<
        Array<
          { __typename?: 'SalesrepDesktopInvestments' } & Pick<
            SalesrepDesktopInvestments,
            'entityName' | 'subTitle' | 'tags'
          > & {
              table?: Maybe<
                { __typename?: 'DynamicTableType' } & Pick<
                  DynamicTableType,
                  'title'
                > & {
                    filters?: Maybe<
                      Array<
                        { __typename?: 'DynamicTableFiltersType' } & Pick<
                          DynamicTableFiltersType,
                          'label' | 'placeholder' | 'accessor' | 'options'
                        >
                      >
                    >;
                    tabs?: Maybe<
                      { __typename?: 'DynamicTableTabsType' } & Pick<
                        DynamicTableTabsType,
                        'accessor' | 'options'
                      >
                    >;
                    headers?: Maybe<
                      Array<
                        { __typename?: 'DynamicTableHeadersType' } & Pick<
                          DynamicTableHeadersType,
                          | 'value'
                          | 'accessor'
                          | 'sortable'
                          | 'placeholder'
                          | 'type'
                          | 'hidden'
                        > & {
                            width?: Maybe<
                              { __typename?: 'DynamicTableWidthType' } & Pick<
                                DynamicTableWidthType,
                                'fixedWidth'
                              >
                            >;
                          }
                      >
                    >;
                    rows?: Maybe<
                      Array<
                        { __typename?: 'DynamicTableRowType' } & Pick<
                          DynamicTableRowType,
                          'value'
                        > & {
                            rowData?: Maybe<
                              Array<
                                {
                                  __typename?: 'DynamicTableRowDataType';
                                } & Pick<
                                  DynamicTableRowDataType,
                                  'value' | 'type' | 'accessor' | 'isActive'
                                > & {
                                    params?: Maybe<
                                      {
                                        __typename?: 'DynamicTableRowDataParamsType';
                                      } & Pick<
                                        DynamicTableRowDataParamsType,
                                        | 'pk'
                                        | 'values'
                                        | 'topInvestor'
                                        | 'partnerPk'
                                      >
                                    >;
                                  }
                              >
                            >;
                          }
                      >
                    >;
                  }
              >;
            }
        >
      >;
      table?: Maybe<
        { __typename?: 'DynamicTableType' } & Pick<
          DynamicTableType,
          'title'
        > & {
            filters?: Maybe<
              Array<
                { __typename?: 'DynamicTableFiltersType' } & Pick<
                  DynamicTableFiltersType,
                  'label' | 'placeholder' | 'accessor' | 'options'
                >
              >
            >;
            tabs?: Maybe<
              { __typename?: 'DynamicTableTabsType' } & Pick<
                DynamicTableTabsType,
                'accessor' | 'options'
              >
            >;
            headers?: Maybe<
              Array<
                { __typename?: 'DynamicTableHeadersType' } & Pick<
                  DynamicTableHeadersType,
                  | 'value'
                  | 'accessor'
                  | 'sortable'
                  | 'placeholder'
                  | 'type'
                  | 'hidden'
                > & {
                    width?: Maybe<
                      { __typename?: 'DynamicTableWidthType' } & Pick<
                        DynamicTableWidthType,
                        'fixedWidth'
                      >
                    >;
                  }
              >
            >;
            rows?: Maybe<
              Array<
                { __typename?: 'DynamicTableRowType' } & Pick<
                  DynamicTableRowType,
                  'value'
                > & {
                    rowData?: Maybe<
                      Array<
                        { __typename?: 'DynamicTableRowDataType' } & Pick<
                          DynamicTableRowDataType,
                          'value' | 'type' | 'accessor' | 'isActive'
                        > & {
                            params?: Maybe<
                              {
                                __typename?: 'DynamicTableRowDataParamsType';
                              } & Pick<
                                DynamicTableRowDataParamsType,
                                'pk' | 'values' | 'topInvestor' | 'partnerPk'
                              >
                            >;
                          }
                      >
                    >;
                  }
              >
            >;
          }
      >;
    };
};

export type SalesrepCheckContactNotDuplicateQueryVariables = Exact<{
  data: SalesrepCheckContactNotDuplicate;
}>;

export type SalesrepCheckContactNotDuplicateQuery = { __typename?: 'Query' } & {
  salesrepCheckContactNotDuplicate: { __typename?: 'CheckReturnsType' } & Pick<
    CheckReturnsType,
    'success' | 'status' | 'info'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
    };
};

export type SalesrepCheckCompanyAndContactNotDuplicateQueryVariables = Exact<{
  data: SalesrepCheckCompanyAndContactNotDuplicate;
}>;

export type SalesrepCheckCompanyAndContactNotDuplicateQuery = {
  __typename?: 'Query';
} & {
  salesrepCheckCompanyAndContactNotDuplicate: {
    __typename?: 'CheckReturnsType';
  } & Pick<CheckReturnsType, 'success' | 'status' | 'info'> & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
    };
};

export type GetOfferingsQueryVariables = Exact<{
  data: EmptyObject;
}>;

export type GetOfferingsQuery = { __typename?: 'Query' } & {
  getOfferings: { __typename?: 'GetOfferingsReturn' } & Pick<
    GetOfferingsReturn,
    'success'
  > & {
      offerings?: Maybe<
        Array<
          { __typename?: 'OfferingType' } & Pick<
            OfferingType,
            | 'webOfferingPK'
            | 'offeringTitle'
            | 'type'
            | 'fundTarget'
            | 'percentFunded'
            | 'closeDate'
            | 'inWatchlist'
            | 'teaser'
          > & {
              imageUrls?: Maybe<
                Array<
                  {
                    __typename?: 'AdminOfferingDetailsGalleryImageType';
                  } & Pick<
                    AdminOfferingDetailsGalleryImageType,
                    'label' | 'url' | 'caption' | 'altText' | 'order'
                  >
                >
              >;
              filterDataWidgets?: Maybe<
                Array<
                  { __typename?: 'FilterDataWidgetType' } & Pick<
                    FilterDataWidgetType,
                    'label' | 'selectedValue'
                  >
                >
              >;
            }
        >
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
    };
};

export type MonitoringGetMyListMutationVariables = Exact<{
  data: MonitoringGetMyList;
}>;

export type MonitoringGetMyListMutation = { __typename?: 'Mutation' } & {
  monitoringGetMyList: { __typename?: 'MonitoringGetMyListReturn' } & Pick<
    MonitoringGetMyListReturn,
    'success'
  > & {
      offerings?: Maybe<
        Array<
          { __typename?: 'MyListType' } & Pick<
            MyListType,
            | 'pk'
            | 'name'
            | 'fundedAmountPercent'
            | 'closedDate'
            | 'investmentType'
            | 'strategy'
            | 'flexField'
          > & {
              images?: Maybe<
                Array<
                  { __typename?: 'MonitoringImageType' } & Pick<
                    MonitoringImageType,
                    'url' | 'label' | 'order' | 'altText' | 'caption'
                  >
                >
              >;
            }
        >
      >;
    };
};

export type MonitoringGetInvTableDistribsMutationVariables = Exact<{
  data: MonitoringGetInvTableDistribs;
}>;

export type MonitoringGetInvTableDistribsMutation = {
  __typename?: 'Mutation';
} & {
  monitoringGetInvTableDistribs: {
    __typename?: 'MonitoringGetInvTableDistribsReturn';
  } & Pick<MonitoringGetInvTableDistribsReturn, 'success'> & {
      cashflows?: Maybe<
        Array<
          { __typename?: 'CashflowType' } & Pick<
            CashflowType,
            'date' | 'name' | 'amt'
          >
        >
      >;
    };
};

export type MonitoringGetInvTableContribsMutationVariables = Exact<{
  data: MonitoringGetInvTableContribs;
}>;

export type MonitoringGetInvTableContribsMutation = {
  __typename?: 'Mutation';
} & {
  monitoringGetInvTableContribs: {
    __typename?: 'MonitoringGetInvTableContribsReturn';
  } & Pick<MonitoringGetInvTableContribsReturn, 'success'> & {
      cashflows?: Maybe<
        Array<
          { __typename?: 'CashflowType' } & Pick<
            CashflowType,
            'date' | 'name' | 'amt'
          >
        >
      >;
    };
};

export type MonitoringGetInvTableCommittedCapMutationVariables = Exact<{
  data: MonitoringGetInvTableCommittedCap;
}>;

export type MonitoringGetInvTableCommittedCapMutation = {
  __typename?: 'Mutation';
} & {
  monitoringGetInvTableCommittedCap: {
    __typename?: 'MonitoringGetInvTableCommittedCapReturn';
  } & Pick<MonitoringGetInvTableCommittedCapReturn, 'success'> & {
      cashflows?: Maybe<
        Array<
          { __typename?: 'CashflowType' } & Pick<
            CashflowType,
            'date' | 'name' | 'amt'
          >
        >
      >;
    };
};

export type MonitoringGetInvTableMutationVariables = Exact<{
  data: MonitoringGetInvTable;
}>;

export type MonitoringGetInvTableMutation = { __typename?: 'Mutation' } & {
  monitoringGetInvTable: { __typename?: 'MonitoringGetInvTableReturn' } & Pick<
    MonitoringGetInvTableReturn,
    'success' | 'types' | 'statuses'
  > & {
      data?: Maybe<
        { __typename?: 'DynamicTableType' } & Pick<
          DynamicTableType,
          'title' | 'footnotes'
        > & {
            tabs?: Maybe<
              { __typename?: 'DynamicTableTabsType' } & Pick<
                DynamicTableTabsType,
                'accessor' | 'options'
              >
            >;
            filters?: Maybe<
              Array<
                { __typename?: 'DynamicTableFiltersType' } & Pick<
                  DynamicTableFiltersType,
                  'options' | 'label' | 'accessor' | 'placeholder'
                >
              >
            >;
            headers?: Maybe<
              Array<
                { __typename?: 'DynamicTableHeadersType' } & Pick<
                  DynamicTableHeadersType,
                  | 'value'
                  | 'inactiveValue'
                  | 'accessor'
                  | 'sortable'
                  | 'placeholder'
                  | 'type'
                  | 'hidden'
                  | 'group'
                > & {
                    width?: Maybe<
                      { __typename?: 'DynamicTableWidthType' } & Pick<
                        DynamicTableWidthType,
                        'fixedWidth'
                      >
                    >;
                  }
              >
            >;
            sections?: Maybe<
              Array<
                { __typename?: 'DynamicTableSectionType' } & Pick<
                  DynamicTableSectionType,
                  'title' | 'isActive' | 'isUnallocated'
                > & {
                    rows?: Maybe<
                      Array<
                        { __typename?: 'DynamicTableRowType' } & Pick<
                          DynamicTableRowType,
                          'value'
                        > & {
                            rowData?: Maybe<
                              Array<
                                {
                                  __typename?: 'DynamicTableRowDataType';
                                } & Pick<
                                  DynamicTableRowDataType,
                                  'value' | 'type' | 'accessor' | 'isActive'
                                > & {
                                    params?: Maybe<
                                      {
                                        __typename?: 'DynamicTableRowDataParamsType';
                                      } & Pick<
                                        DynamicTableRowDataParamsType,
                                        | 'fundPk'
                                        | 'values'
                                        | 'topInvestor'
                                        | 'partnerPk'
                                        | 'offeringType'
                                        | 'portfolioPk'
                                        | 'portfolio'
                                        | 'color'
                                        | 'investorEntityPk'
                                      > & {
                                          cashflows?: Maybe<
                                            Array<
                                              {
                                                __typename?: 'CashflowType';
                                              } & Pick<
                                                CashflowType,
                                                'date' | 'name' | 'amt'
                                              >
                                            >
                                          >;
                                        }
                                    >;
                                  }
                              >
                            >;
                          }
                      >
                    >;
                  }
              >
            >;
          }
      >;
    };
};

export type MonitoringGetInvestmentOverviewMutationVariables = Exact<{
  data: MonitoringGetInvestmentOverviewParams;
}>;

export type MonitoringGetInvestmentOverviewMutation = {
  __typename?: 'Mutation';
} & {
  monitoringGetInvestmentOverview: {
    __typename?: 'MonitoringGetInvestmentOverviewReturn';
  } & Pick<MonitoringGetInvestmentOverviewReturn, 'success'> & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
      investmentOverview?: Maybe<
        Array<
          { __typename?: 'InvestmentOverviewCards' } & {
            card?: Maybe<
              Array<
                { __typename?: 'InvestmentOverviewCard' } & {
                  quickLinkCard?: Maybe<
                    { __typename?: 'QuickLinkCard' } & Pick<
                      QuickLinkCard,
                      'title' | 'type' | 'footnote'
                    > & {
                        details?: Maybe<
                          Array<
                            { __typename?: 'QuickLinkCardDetail' } & Pick<
                              QuickLinkCardDetail,
                              'title' | 'type' | 'subTitle'
                            > & {
                                linkDetails?: Maybe<
                                  Array<
                                    { __typename?: 'CardLinkDetail' } & Pick<
                                      CardLinkDetail,
                                      'title' | 'link' | 'icon'
                                    > & {
                                        linkDetails?: Maybe<
                                          Array<
                                            {
                                              __typename?: 'CardLinkDetail';
                                            } & Pick<
                                              CardLinkDetail,
                                              'title' | 'link' | 'icon'
                                            > & {
                                                linkDetails?: Maybe<
                                                  Array<
                                                    {
                                                      __typename?: 'CardLinkDetail';
                                                    } & Pick<
                                                      CardLinkDetail,
                                                      'title' | 'link' | 'icon'
                                                    > & {
                                                        linkDetails?: Maybe<
                                                          Array<
                                                            {
                                                              __typename?: 'CardLinkDetail';
                                                            } & Pick<
                                                              CardLinkDetail,
                                                              | 'title'
                                                              | 'link'
                                                              | 'icon'
                                                            >
                                                          >
                                                        >;
                                                      }
                                                  >
                                                >;
                                              }
                                          >
                                        >;
                                      }
                                  >
                                >;
                              }
                          >
                        >;
                      }
                  >;
                  donutChartCard?: Maybe<
                    { __typename?: 'DonutChartCard' } & Pick<
                      DonutChartCard,
                      'title'
                    > & {
                        titleOptions?: Maybe<
                          Array<
                            { __typename?: 'DonutTitleOption' } & Pick<
                              DonutTitleOption,
                              'dataPoint' | 'label' | 'hoverLabel' | 'key'
                            >
                          >
                        >;
                        donuts?: Maybe<
                          Array<
                            { __typename?: 'DonutCardDonut' } & Pick<
                              DonutCardDonut,
                              'title' | 'key' | 'footnote'
                            > & {
                                values?: Maybe<
                                  Array<
                                    {
                                      __typename?: 'DonutCardDonutValue';
                                    } & Pick<
                                      DonutCardDonutValue,
                                      | 'title'
                                      | 'value'
                                      | 'color'
                                      | 'legendIndex'
                                    > & {
                                        details?: Maybe<
                                          {
                                            __typename?: 'DonutCardDonutValueDetails';
                                          } & Pick<
                                            DonutCardDonutValueDetails,
                                            'title' | 'subTitle'
                                          >
                                        >;
                                      }
                                  >
                                >;
                              }
                          >
                        >;
                      }
                  >;
                  lineGraphCard?: Maybe<
                    { __typename?: 'LineGraphCard' } & Pick<
                      LineGraphCard,
                      'title'
                    > & {
                        lines?: Maybe<
                          Array<
                            { __typename?: 'LineGraphCardLine' } & Pick<
                              LineGraphCardLine,
                              'title'
                            > & {
                                value?: Maybe<
                                  Array<
                                    { __typename?: 'LineValue' } & Pick<
                                      LineValue,
                                      'date' | 'amt'
                                    >
                                  >
                                >;
                              }
                          >
                        >;
                      }
                  >;
                  barGraphCard?: Maybe<
                    { __typename?: 'BarGraphCard' } & Pick<
                      BarGraphCard,
                      'title' | 'type'
                    > & {
                        legend?: Maybe<
                          Array<
                            { __typename?: 'BarGraphLegend' } & Pick<
                              BarGraphLegend,
                              'id' | 'title' | 'color' | 'textColor'
                            >
                          >
                        >;
                        bars?: Maybe<
                          Array<
                            { __typename?: 'BarGraphBar' } & Pick<
                              BarGraphBar,
                              'title' | 'subTitle1' | 'subTitle2'
                            > & {
                                values?: Maybe<
                                  { __typename?: 'BarValues' } & Pick<
                                    BarValues,
                                    | 'value1'
                                    | 'value2'
                                    | 'value3'
                                    | 'irr'
                                    | 'multiple'
                                    | 'nav'
                                    | 'cmmt'
                                  >
                                >;
                              }
                          >
                        >;
                      }
                  >;
                  kpiCard?: Maybe<
                    { __typename?: 'KPICard' } & Pick<
                      KpiCard,
                      'title' | 'value' | 'subValue'
                    > & {
                        linkDetails?: Maybe<
                          Array<
                            { __typename?: 'CardLinkDetail' } & Pick<
                              CardLinkDetail,
                              'title' | 'link' | 'icon'
                            >
                          >
                        >;
                      }
                  >;
                }
              >
            >;
          }
        >
      >;
    };
};

export type MonitoringGetCashFlowChartQueryVariables = Exact<{
  data: MonitoringGetCashFlowChartParams;
}>;

export type MonitoringGetCashFlowChartQuery = { __typename?: 'Query' } & {
  monitoringGetCashFlowChart: {
    __typename?: 'MonitoringGetCashFlowChartReturn';
  } & Pick<MonitoringGetCashFlowChartReturn, 'success'> & {
      cmmts?: Maybe<
        Array<
          { __typename?: 'CashFlowChartDetails' } & Pick<
            CashFlowChartDetails,
            'date' | 'amt'
          >
        >
      >;
      contribs?: Maybe<
        Array<
          { __typename?: 'CashFlowChartDetails' } & Pick<
            CashFlowChartDetails,
            'date' | 'amt'
          >
        >
      >;
      distribs?: Maybe<
        Array<
          { __typename?: 'CashFlowChartDetails' } & Pick<
            CashFlowChartDetails,
            'date' | 'amt'
          >
        >
      >;
    };
};

export type MonitoringGetAllocationChartQueryVariables = Exact<{
  data: MonitoringGetAllocationChartParams;
}>;

export type MonitoringGetAllocationChartQuery = { __typename?: 'Query' } & {
  monitoringGetAllocationChart: {
    __typename?: 'MonitoringGetAllocationChartReturn';
  } & Pick<
    MonitoringGetAllocationChartReturn,
    'success' | 'commercial' | 'industrial' | 'multifamily' | 'retail' | 'other'
  >;
};

export type MonitoringGetOccupancyChartQueryVariables = Exact<{
  data: MonitoringGetOccupancyChartParams;
}>;

export type MonitoringGetOccupancyChartQuery = { __typename?: 'Query' } & {
  monitoringGetOccupancyChart: {
    __typename?: 'MonitoringGetOccupancyChartReturn';
  } & Pick<MonitoringGetOccupancyChartReturn, 'success'> & {
      offerings?: Maybe<
        Array<
          { __typename?: 'OfferingsType' } & Pick<OfferingsType, 'pk' | 'name'>
        >
      >;
    };
};

export type CreateInvestmentNewInvestmentMutationVariables = Exact<{
  data: CreateInvestmentInput;
}>;

export type CreateInvestmentNewInvestmentMutation = {
  __typename?: 'Mutation';
} & {
  newInvestment: { __typename?: 'ReturnsType' } & Pick<
    ReturnsType,
    'success'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
      step?: Maybe<
        { __typename?: 'StepType' } & {
          maxStepAllowed?: Maybe<
            { __typename?: 'JobStep' } & Pick<JobStep, 'index' | 'name'>
          >;
        }
      >;
    };
};

export type CheckInvest2faCodeMutationVariables = Exact<{
  data: CreateInvestmentCheckTfa;
}>;

export type CheckInvest2faCodeMutation = { __typename?: 'Mutation' } & {
  checkTfaCode: { __typename?: 'ReturnsType' } & Pick<
    ReturnsType,
    'success'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
      step?: Maybe<
        { __typename?: 'StepType' } & {
          maxStepAllowed?: Maybe<
            { __typename?: 'JobStep' } & Pick<JobStep, 'name' | 'index'>
          >;
        }
      >;
    };
};

export type InvResendSmsMutationVariables = Exact<{ [key: string]: never }>;

export type InvResendSmsMutation = { __typename?: 'Mutation' } & {
  invResendSms: { __typename?: 'ReturnsType' } & Pick<
    ReturnsType,
    'success'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
      step?: Maybe<
        { __typename?: 'StepType' } & {
          maxStepAllowed?: Maybe<
            { __typename?: 'JobStep' } & Pick<JobStep, 'index' | 'name'>
          >;
        }
      >;
    };
};

export type GetInvestmentJobMutationVariables = Exact<{
  data: GetInvestmentJob;
}>;

export type GetInvestmentJobMutation = { __typename?: 'Mutation' } & {
  getInvestmentJob: { __typename?: 'InvestmentJobResult' } & Pick<
    InvestmentJobResult,
    'success'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
      investmentJob?: Maybe<
        { __typename?: 'InvestmentJobResultDetails' } & Pick<
          InvestmentJobResultDetails,
          | 'webOfferingPK'
          | 'amount'
          | 'userReference'
          | 'phoneVerified'
          | 'tfaCode_timestamp'
          | 'maxStepAllowed'
        > & {
            smsResends?: Maybe<
              { __typename?: 'SMSEmailCounter' } & Pick<
                SmsEmailCounter,
                'attempts' | 'left' | 'timeLeft'
              >
            >;
            investorEntityInfo?: Maybe<
              {
                __typename?: 'InvestmentJobResultDetailsInvestorEntityInfo';
              } & Pick<
                InvestmentJobResultDetailsInvestorEntityInfo,
                | 'investorEntityPK'
                | 'entityType'
                | 'name'
                | 'country'
                | 'city'
                | 'state'
                | 'address1'
                | 'address2'
                | 'zipCode'
                | 'taxId'
              > & {
                  communications?: Maybe<
                    Array<
                      { __typename?: 'InvCommunicationsDetails' } & Pick<
                        InvCommunicationsDetails,
                        | 'contactPK'
                        | 'ccRoles'
                        | 'email'
                        | 'firstName'
                        | 'lastName'
                        | 'middle'
                        | 'phone'
                        | 'relationship'
                      >
                    >
                  >;
                  distributions?: Maybe<
                    {
                      __typename?: 'InvDistributionInstructionsDetails';
                    } & Pick<
                      InvDistributionInstructionsDetails,
                      | 'paymentType'
                      | 'routingNumber'
                      | 'bankName'
                      | 'accountNumber'
                      | 'accountType'
                      | 'address1'
                      | 'address2'
                      | 'city'
                      | 'state'
                      | 'zipCode'
                    >
                  >;
                }
            >;
            accreditation?: Maybe<
              { __typename?: 'InvestmentAccreditationType' } & Pick<
                InvestmentAccreditationType,
                'type'
              >
            >;
            identificationDocument?: Maybe<
              { __typename?: 'IdentificationDocumentInfoReturn' } & Pick<
                IdentificationDocumentInfoReturn,
                'type' | 'documentId' | 'expirationDate'
              > & {
                  picture1?: Maybe<
                    { __typename?: 'PictureTypeReturn' } & Pick<
                      PictureTypeReturn,
                      'name' | 's3Url'
                    >
                  >;
                  picture2?: Maybe<
                    { __typename?: 'PictureTypeReturn' } & Pick<
                      PictureTypeReturn,
                      'name' | 's3Url'
                    >
                  >;
                }
            >;
            sign?: Maybe<
              { __typename?: 'SignReturn' } & Pick<
                SignReturn,
                'isSigned' | 'documentTemplatePk' | 'docJobReference'
              >
            >;
          }
      >;
      investmentData?: Maybe<
        { __typename?: 'InvestmentDataResultDetails' } & Pick<
          InvestmentDataResultDetails,
          'allowedRelationship' | 'allowedccRoles' | 'allowedInvestorEntities'
        > & {
            investorEntities?: Maybe<
              Array<
                {
                  __typename?: 'InvestmentDataResultDetailsInvestorEntities';
                } & Pick<
                  InvestmentDataResultDetailsInvestorEntities,
                  | 'investorEntityPK'
                  | 'name'
                  | 'entityType'
                  | 'taxId'
                  | 'distributionMethod'
                  | 'bankName'
                  | 'accountNumber'
                  | 'isTaxDomicile'
                >
              >
            >;
            investorCommunications?: Maybe<
              Array<
                {
                  __typename?: 'InvestmentDataResultDetailsInvestorCommunications';
                } & Pick<
                  InvestmentDataResultDetailsInvestorCommunications,
                  | 'contactPk'
                  | 'ccRoles'
                  | 'email'
                  | 'first'
                  | 'middle'
                  | 'last'
                  | 'phone'
                  | 'relationship'
                >
              >
            >;
            investorCompliance?: Maybe<
              Array<
                {
                  __typename?: 'InvestmentDataResultDetailsInvestorCompliance';
                } & Pick<
                  InvestmentDataResultDetailsInvestorCompliance,
                  'type' | 'entityTypePk'
                > & {
                    templates?: Maybe<
                      Array<
                        {
                          __typename?: 'InvestmentDataResultDetailsTemplateType';
                        } & Pick<
                          InvestmentDataResultDetailsTemplateType,
                          | 'templatePk'
                          | 'name'
                          | 'reference'
                          | 'category'
                          | 'required'
                          | 'templateId'
                        >
                      >
                    >;
                    additionalDocuments?: Maybe<
                      { __typename?: 'UploadDocumentOptionsType' } & {
                        options?: Maybe<
                          Array<
                            { __typename?: 'UploadDocumentOptionType' } & Pick<
                              UploadDocumentOptionType,
                              'label' | 'checked'
                            >
                          >
                        >;
                      }
                    >;
                  }
              >
            >;
            investorAdditionalDocuments?: Maybe<
              { __typename?: 'UploadDocumentOptionsType' } & {
                options?: Maybe<
                  Array<
                    { __typename?: 'UploadDocumentOptionType' } & Pick<
                      UploadDocumentOptionType,
                      'label' | 'checked'
                    >
                  >
                >;
              }
            >;
            signDocument?: Maybe<
              Array<
                {
                  __typename?: 'InvestmentDataResultDetailsSignDocument';
                } & Pick<
                  InvestmentDataResultDetailsSignDocument,
                  'name' | 'required' | 'templateId'
                >
              >
            >;
          }
      >;
      step?: Maybe<
        { __typename?: 'JobStep' } & Pick<JobStep, 'name' | 'index'>
      >;
    };
};

export type CreateInvestmentAllowInvestmentMutationVariables = Exact<{
  data: AllowInvestmentParams;
}>;

export type CreateInvestmentAllowInvestmentMutation = {
  __typename?: 'Mutation';
} & {
  allowInvestment: { __typename?: 'ReturnsType' } & Pick<
    ReturnsType,
    'success'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
    };
};

export type NewInvEntityInvestorInfoMutationVariables = Exact<{
  data: NewInvEntityInvestorInfo;
}>;

export type NewInvEntityInvestorInfoMutation = { __typename?: 'Mutation' } & {
  newInvEntityInvestorInfo: { __typename?: 'ReturnsType' } & Pick<
    ReturnsType,
    'success'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
      step?: Maybe<
        { __typename?: 'StepType' } & {
          maxStepAllowed?: Maybe<
            { __typename?: 'JobStep' } & Pick<JobStep, 'name' | 'index'>
          >;
        }
      >;
    };
};

export type CreateInvestmentInvAccreditationMutationVariables = Exact<{
  data: InvAccreditation;
}>;

export type CreateInvestmentInvAccreditationMutation = {
  __typename?: 'Mutation';
} & {
  invAccreditation: { __typename?: 'ReturnsType' } & Pick<
    ReturnsType,
    'success'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
      step?: Maybe<
        { __typename?: 'StepType' } & {
          maxStepAllowed?: Maybe<
            { __typename?: 'JobStep' } & Pick<JobStep, 'name' | 'index'>
          >;
        }
      >;
    };
};

export type CreateInvestmentCreateInvDocMutationVariables = Exact<{
  data: CreateDoc;
}>;

export type CreateInvestmentCreateInvDocMutation = {
  __typename?: 'Mutation';
} & {
  createInvDoc: { __typename?: 'CreateDocResult' } & Pick<
    CreateDocResult,
    'success' | 'docReference' | 'url'
  >;
};

export type CreateInvestmentCheckInvDocStatusMutationVariables = Exact<{
  data: CheckDocStatus;
}>;

export type CreateInvestmentCheckInvDocStatusMutation = {
  __typename?: 'Mutation';
} & {
  checkInvDocStatus: { __typename?: 'DocStatus' } & Pick<
    DocStatus,
    'success' | 'status'
  > & {
      step?: Maybe<
        { __typename?: 'StepType' } & {
          maxStepAllowed?: Maybe<
            { __typename?: 'JobStep' } & Pick<JobStep, 'name' | 'index'>
          >;
        }
      >;
    };
};

export type CreateInvestmentCommunicationsMutationVariables = Exact<{
  data: InvCommunicationsInput;
}>;

export type CreateInvestmentCommunicationsMutation = {
  __typename?: 'Mutation';
} & {
  invCommunications: { __typename?: 'ReturnsType' } & Pick<
    ReturnsType,
    'success'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
      step?: Maybe<
        { __typename?: 'StepType' } & {
          maxStepAllowed?: Maybe<
            { __typename?: 'JobStep' } & Pick<JobStep, 'name' | 'index'>
          >;
        }
      >;
    };
};

export type CreateInvestmentInvAddIdentificationDocMutationVariables = Exact<{
  data: InvAddIdentificationDoc;
}>;

export type CreateInvestmentInvAddIdentificationDocMutation = {
  __typename?: 'Mutation';
} & {
  invAddIdentificationDoc: { __typename?: 'ReturnsType' } & Pick<
    ReturnsType,
    'success'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
      step?: Maybe<
        { __typename?: 'StepType' } & {
          maxStepAllowed?: Maybe<
            { __typename?: 'JobStep' } & Pick<JobStep, 'name' | 'index'>
          >;
        }
      >;
    };
};

export type InvAddAdditionalDocMutationVariables = Exact<{
  data: InvAddAdditionalDoc;
}>;

export type InvAddAdditionalDocMutation = { __typename?: 'Mutation' } & {
  invAddAdditionalDoc: { __typename?: 'ReturnsType' } & Pick<
    ReturnsType,
    'success'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
      step?: Maybe<
        { __typename?: 'StepType' } & {
          maxStepAllowed?: Maybe<
            { __typename?: 'JobStep' } & Pick<JobStep, 'name' | 'index'>
          >;
        }
      >;
    };
};

export type CreateInvestmentDistributionsMutationVariables = Exact<{
  data: InvDistributionsInput;
}>;

export type CreateInvestmentDistributionsMutation = {
  __typename?: 'Mutation';
} & {
  invDistributions: { __typename?: 'ReturnsType' } & Pick<
    ReturnsType,
    'success'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
      step?: Maybe<
        { __typename?: 'StepType' } & {
          maxStepAllowed?: Maybe<
            { __typename?: 'JobStep' } & Pick<JobStep, 'index' | 'name'>
          >;
        }
      >;
    };
};

export type CreateInvReviewMutationVariables = Exact<{ [key: string]: never }>;

export type CreateInvReviewMutation = { __typename?: 'Mutation' } & {
  invReview: { __typename?: 'ReturnsType' } & Pick<ReturnsType, 'success'> & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
      step?: Maybe<
        { __typename?: 'StepType' } & {
          maxStepAllowed?: Maybe<
            { __typename?: 'JobStep' } & Pick<JobStep, 'name' | 'index'>
          >;
        }
      >;
    };
};

export type CreateInvSignMutationVariables = Exact<{ [key: string]: never }>;

export type CreateInvSignMutation = { __typename?: 'Mutation' } & {
  invSign: { __typename?: 'ReturnsType' } & Pick<ReturnsType, 'success'> & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
      step?: Maybe<
        { __typename?: 'StepType' } & {
          maxStepAllowed?: Maybe<
            { __typename?: 'JobStep' } & Pick<JobStep, 'name' | 'index'>
          >;
        }
      >;
    };
};

export type GetInvestorReviewDataMutationVariables = Exact<{
  [key: string]: never;
}>;

export type GetInvestorReviewDataMutation = { __typename?: 'Mutation' } & {
  getInvestorReviewData: { __typename?: 'InvestorReviewDataResult' } & Pick<
    InvestorReviewDataResult,
    'success'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
      investorInfo?: Maybe<
        { __typename?: 'InvestorReviewInfoType' } & Pick<
          InvestorReviewInfoType,
          'entityLegalName' | 'name' | 'entityType' | 'taxId'
        >
      >;
      distribution?: Maybe<
        { __typename?: 'InvestorReviewDistributionType' } & Pick<
          InvestorReviewDistributionType,
          'distributionMethod'
        >
      >;
      communications?: Maybe<
        { __typename?: 'InvestorReviewCommunicationsType' } & {
          primary?: Maybe<
            Array<
              { __typename?: 'InvestorReviewCommunicationsContactType' } & Pick<
                InvestorReviewCommunicationsContactType,
                'name' | 'email'
              >
            >
          >;
          cc?: Maybe<
            Array<
              { __typename?: 'InvestorReviewCommunicationsContactType' } & Pick<
                InvestorReviewCommunicationsContactType,
                'name' | 'email'
              >
            >
          >;
        }
      >;
    };
};

export type CreateUrlForDocuSignMutationVariables = Exact<{
  data: CreateUrlForDocuSign;
}>;

export type CreateUrlForDocuSignMutation = { __typename?: 'Mutation' } & {
  createUrlForDocuSign: { __typename?: 'CreateUrlForDocuSignResult' } & Pick<
    CreateUrlForDocuSignResult,
    'success' | 'docReference' | 'url'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
    };
};

export type CheckDocuSignStatusMutationVariables = Exact<{
  data: CheckDocuSignStatus;
}>;

export type CheckDocuSignStatusMutation = { __typename?: 'Mutation' } & {
  checkDocuSignStatus: { __typename?: 'CheckDocuSignStatusResult' } & Pick<
    CheckDocuSignStatusResult,
    'success' | 'status'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
    };
};

export type SaveMakeInvestmentMutationVariables = Exact<{
  data: SaveMakeInvestment;
}>;

export type SaveMakeInvestmentMutation = { __typename?: 'Mutation' } & {
  saveMakeInvestmentData: { __typename?: 'ReturnsType' } & Pick<
    ReturnsType,
    'success'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
      step?: Maybe<
        { __typename?: 'StepType' } & {
          maxStepAllowed?: Maybe<
            { __typename?: 'JobStep' } & Pick<JobStep, 'name' | 'index'>
          >;
        }
      >;
    };
};

export type GetOfferingInvestmentInfoQueryVariables = Exact<{
  arg0: GetOfferingInvestmentInfo;
}>;

export type GetOfferingInvestmentInfoQuery = { __typename?: 'Query' } & {
  getOfferingInvestmentInfo: {
    __typename?: 'GetOfferingInvestmentInfoReturns';
  } & Pick<GetOfferingInvestmentInfoReturns, 'success'> & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
      offering?: Maybe<
        { __typename?: 'GetOfferingInvestmentInfoType' } & Pick<
          GetOfferingInvestmentInfoType,
          | 'name'
          | 'images'
          | 'min'
          | 'max'
          | 'phone'
          | 'email'
          | 'disclaimer'
          | 'fundTarget'
          | 'fundCurrent'
        >
      >;
    };
};

export type MonitoringAddMyListMutationVariables = Exact<{
  data: MonitoringAddMyList;
}>;

export type MonitoringAddMyListMutation = { __typename?: 'Mutation' } & {
  monitoringAddMyList: { __typename?: 'MonitoringAddMyListReturn' } & Pick<
    MonitoringAddMyListReturn,
    'success'
  >;
};

export type MonitoringDeleteFromMyListMutationVariables = Exact<{
  data: MonitoringDeleteFromMyList;
}>;

export type MonitoringDeleteFromMyListMutation = { __typename?: 'Mutation' } & {
  monitoringDeleteFromMyList: {
    __typename?: 'MonitoringDeleteFromMyListReturn';
  } & Pick<MonitoringDeleteFromMyListReturn, 'success'>;
};

export type MonitoringGetInvInteriorPageHeaderMenuMutationVariables = Exact<{
  data: EmptyObject;
}>;

export type MonitoringGetInvInteriorPageHeaderMenuMutation = {
  __typename?: 'Mutation';
} & {
  invPortalGetPageHeaderMenu: {
    __typename?: 'MonitoringGetInvInteriorPageHeaderMenuReturn';
  } & Pick<MonitoringGetInvInteriorPageHeaderMenuReturn, 'success'> & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
      options?: Maybe<
        Array<
          { __typename?: 'DropdownOption' } & Pick<
            DropdownOption,
            'pk' | 'name'
          >
        >
      >;
    };
};

export type MonitoringGetInvInteriorTabMutationVariables = Exact<{
  data: MonitoringGetInvInteriorTabParams;
}>;

export type MonitoringGetInvInteriorTabMutation = {
  __typename?: 'Mutation';
} & {
  invPortalGetInteriorTab: {
    __typename?: 'MonitoringGetInvInteriorTabReturn';
  } & Pick<MonitoringGetInvInteriorTabReturn, 'success'> & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
      tab?: Maybe<
        { __typename?: 'InvInteriorTabReturn' } & Pick<
          InvInteriorTabReturn,
          'pcoWebsite' | 'footnotes'
        > & {
            datePicker?: Maybe<
              Array<
                { __typename?: 'DatePickerType' } & Pick<
                  DatePickerType,
                  'date' | 'name'
                >
              >
            >;
            settings?: Maybe<
              { __typename?: 'InvestorSettingType' } & Pick<
                InvestorSettingType,
                | 'investorEntityPk'
                | 'investorEntityName'
                | 'contacts'
                | 'method'
              >
            >;
            investorEntitiesPicker?: Maybe<
              Array<
                { __typename?: 'DropdownOption' } & Pick<
                  DropdownOption,
                  'pk' | 'name'
                >
              >
            >;
            logoLink?: Maybe<
              { __typename?: 'LogoLink' } & Pick<LogoLink, 's3key' | 'note'>
            >;
            addressMap?: Maybe<
              { __typename?: 'LocationType' } & Pick<
                LocationType,
                | 'address1'
                | 'address2'
                | 'city'
                | 'state'
                | 'postalCode'
                | 'country'
                | 'latitude'
                | 'longitude'
              >
            >;
            overview?: Maybe<
              Array<
                { __typename?: 'OverviewSectionType' } & Pick<
                  OverviewSectionType,
                  'label' | 'content'
                >
              >
            >;
            milestones?: Maybe<
              Array<
                { __typename?: 'MilestonesType' } & Pick<
                  MilestonesType,
                  'date' | 'title' | 'note'
                >
              >
            >;
            investmentBarChart?: Maybe<
              { __typename?: 'BarGraphCard' } & Pick<BarGraphCard, 'type'> & {
                  bars?: Maybe<
                    Array<
                      { __typename?: 'BarGraphBar' } & Pick<
                        BarGraphBar,
                        'title' | 'subTitle1' | 'subTitle2'
                      > & {
                          values?: Maybe<
                            { __typename?: 'BarValues' } & Pick<
                              BarValues,
                              | 'value1'
                              | 'value2'
                              | 'value3'
                              | 'irr'
                              | 'multiple'
                              | 'nav'
                              | 'cmmt'
                            >
                          >;
                        }
                    >
                  >;
                  legend?: Maybe<
                    Array<
                      { __typename?: 'BarGraphLegend' } & Pick<
                        BarGraphLegend,
                        'id' | 'title' | 'color' | 'textColor'
                      >
                    >
                  >;
                }
            >;
            myPerformance?: Maybe<
              { __typename?: 'DynamicTableDataType' } & {
                tables?: Maybe<
                  Array<
                    { __typename?: 'DynamicTableType' } & Pick<
                      DynamicTableType,
                      'title'
                    > & {
                        tabs?: Maybe<
                          { __typename?: 'DynamicTableTabsType' } & Pick<
                            DynamicTableTabsType,
                            'accessor' | 'options'
                          >
                        >;
                        filters?: Maybe<
                          Array<
                            { __typename?: 'DynamicTableFiltersType' } & Pick<
                              DynamicTableFiltersType,
                              'options' | 'label' | 'accessor' | 'placeholder'
                            >
                          >
                        >;
                        headers?: Maybe<
                          Array<
                            { __typename?: 'DynamicTableHeadersType' } & Pick<
                              DynamicTableHeadersType,
                              | 'value'
                              | 'accessor'
                              | 'sortable'
                              | 'placeholder'
                              | 'type'
                              | 'hidden'
                              | 'group'
                            > & {
                                width?: Maybe<
                                  {
                                    __typename?: 'DynamicTableWidthType';
                                  } & Pick<DynamicTableWidthType, 'fixedWidth'>
                                >;
                              }
                          >
                        >;
                        rows?: Maybe<
                          Array<
                            { __typename?: 'DynamicTableRowType' } & Pick<
                              DynamicTableRowType,
                              'value'
                            > & {
                                rowData?: Maybe<
                                  Array<
                                    {
                                      __typename?: 'DynamicTableRowDataType';
                                    } & Pick<
                                      DynamicTableRowDataType,
                                      'value' | 'type' | 'accessor' | 'isActive'
                                    > & {
                                        params?: Maybe<
                                          {
                                            __typename?: 'DynamicTableRowDataParamsType';
                                          } & Pick<
                                            DynamicTableRowDataParamsType,
                                            | 'fundPk'
                                            | 'values'
                                            | 'topInvestor'
                                            | 'partnerPk'
                                            | 'offeringType'
                                            | 'portfolioPk'
                                            | 'portfolio'
                                            | 'color'
                                          >
                                        >;
                                      }
                                  >
                                >;
                              }
                          >
                        >;
                        sections?: Maybe<
                          Array<
                            { __typename?: 'DynamicTableSectionType' } & Pick<
                              DynamicTableSectionType,
                              'title'
                            > & {
                                rows?: Maybe<
                                  Array<
                                    {
                                      __typename?: 'DynamicTableRowType';
                                    } & Pick<DynamicTableRowType, 'value'> & {
                                        rowData?: Maybe<
                                          Array<
                                            {
                                              __typename?: 'DynamicTableRowDataType';
                                            } & Pick<
                                              DynamicTableRowDataType,
                                              | 'value'
                                              | 'type'
                                              | 'accessor'
                                              | 'isActive'
                                            > & {
                                                params?: Maybe<
                                                  {
                                                    __typename?: 'DynamicTableRowDataParamsType';
                                                  } & Pick<
                                                    DynamicTableRowDataParamsType,
                                                    | 'fundPk'
                                                    | 'values'
                                                    | 'topInvestor'
                                                    | 'partnerPk'
                                                    | 'offeringType'
                                                    | 'portfolioPk'
                                                    | 'portfolio'
                                                    | 'color'
                                                  >
                                                >;
                                              }
                                          >
                                        >;
                                      }
                                  >
                                >;
                              }
                          >
                        >;
                      }
                  >
                >;
              }
            >;
            performance?: Maybe<
              { __typename?: 'DynamicTableDataType' } & {
                tables?: Maybe<
                  Array<
                    { __typename?: 'DynamicTableType' } & Pick<
                      DynamicTableType,
                      'title'
                    > & {
                        tabs?: Maybe<
                          { __typename?: 'DynamicTableTabsType' } & Pick<
                            DynamicTableTabsType,
                            'accessor' | 'options'
                          >
                        >;
                        filters?: Maybe<
                          Array<
                            { __typename?: 'DynamicTableFiltersType' } & Pick<
                              DynamicTableFiltersType,
                              'options' | 'label' | 'accessor' | 'placeholder'
                            >
                          >
                        >;
                        headers?: Maybe<
                          Array<
                            { __typename?: 'DynamicTableHeadersType' } & Pick<
                              DynamicTableHeadersType,
                              | 'value'
                              | 'accessor'
                              | 'sortable'
                              | 'placeholder'
                              | 'type'
                              | 'hidden'
                              | 'group'
                            > & {
                                width?: Maybe<
                                  {
                                    __typename?: 'DynamicTableWidthType';
                                  } & Pick<DynamicTableWidthType, 'fixedWidth'>
                                >;
                              }
                          >
                        >;
                        rows?: Maybe<
                          Array<
                            { __typename?: 'DynamicTableRowType' } & Pick<
                              DynamicTableRowType,
                              'value'
                            > & {
                                rowData?: Maybe<
                                  Array<
                                    {
                                      __typename?: 'DynamicTableRowDataType';
                                    } & Pick<
                                      DynamicTableRowDataType,
                                      'value' | 'type' | 'accessor' | 'isActive'
                                    > & {
                                        params?: Maybe<
                                          {
                                            __typename?: 'DynamicTableRowDataParamsType';
                                          } & Pick<
                                            DynamicTableRowDataParamsType,
                                            | 'fundPk'
                                            | 'values'
                                            | 'topInvestor'
                                            | 'partnerPk'
                                            | 'offeringType'
                                            | 'portfolioPk'
                                            | 'portfolio'
                                            | 'color'
                                          >
                                        >;
                                      }
                                  >
                                >;
                              }
                          >
                        >;
                        sections?: Maybe<
                          Array<
                            { __typename?: 'DynamicTableSectionType' } & Pick<
                              DynamicTableSectionType,
                              'title'
                            > & {
                                rows?: Maybe<
                                  Array<
                                    {
                                      __typename?: 'DynamicTableRowType';
                                    } & Pick<DynamicTableRowType, 'value'> & {
                                        rowData?: Maybe<
                                          Array<
                                            {
                                              __typename?: 'DynamicTableRowDataType';
                                            } & Pick<
                                              DynamicTableRowDataType,
                                              | 'value'
                                              | 'type'
                                              | 'accessor'
                                              | 'isActive'
                                            > & {
                                                params?: Maybe<
                                                  {
                                                    __typename?: 'DynamicTableRowDataParamsType';
                                                  } & Pick<
                                                    DynamicTableRowDataParamsType,
                                                    | 'fundPk'
                                                    | 'values'
                                                    | 'topInvestor'
                                                    | 'partnerPk'
                                                    | 'offeringType'
                                                    | 'portfolioPk'
                                                    | 'portfolio'
                                                    | 'color'
                                                  >
                                                >;
                                              }
                                          >
                                        >;
                                      }
                                  >
                                >;
                              }
                          >
                        >;
                      }
                  >
                >;
              }
            >;
            productImages?: Maybe<
              { __typename?: 'ProductImage' } & Pick<
                ProductImage,
                'label' | 'content' | 'images'
              >
            >;
            deck?: Maybe<
              { __typename?: 'DocType' } & Pick<DocType, 'title' | 'url'>
            >;
            investmentDocuments?: Maybe<
              { __typename?: 'DynamicTableType' } & Pick<
                DynamicTableType,
                'title'
              > & {
                  tabs?: Maybe<
                    { __typename?: 'DynamicTableTabsType' } & Pick<
                      DynamicTableTabsType,
                      'accessor' | 'options'
                    >
                  >;
                  filters?: Maybe<
                    Array<
                      { __typename?: 'DynamicTableFiltersType' } & Pick<
                        DynamicTableFiltersType,
                        'options' | 'label' | 'accessor' | 'placeholder'
                      >
                    >
                  >;
                  headers?: Maybe<
                    Array<
                      { __typename?: 'DynamicTableHeadersType' } & Pick<
                        DynamicTableHeadersType,
                        | 'value'
                        | 'accessor'
                        | 'sortable'
                        | 'placeholder'
                        | 'type'
                        | 'hidden'
                        | 'group'
                      > & {
                          width?: Maybe<
                            { __typename?: 'DynamicTableWidthType' } & Pick<
                              DynamicTableWidthType,
                              'fixedWidth'
                            >
                          >;
                        }
                    >
                  >;
                  rows?: Maybe<
                    Array<
                      { __typename?: 'DynamicTableRowType' } & Pick<
                        DynamicTableRowType,
                        'value'
                      > & {
                          rowData?: Maybe<
                            Array<
                              { __typename?: 'DynamicTableRowDataType' } & Pick<
                                DynamicTableRowDataType,
                                'value' | 'type' | 'accessor' | 'isActive'
                              > & {
                                  params?: Maybe<
                                    {
                                      __typename?: 'DynamicTableRowDataParamsType';
                                    } & Pick<
                                      DynamicTableRowDataParamsType,
                                      | 'fundPk'
                                      | 'values'
                                      | 'topInvestor'
                                      | 'partnerPk'
                                      | 'offeringType'
                                      | 'portfolioPk'
                                      | 'portfolio'
                                      | 'color'
                                      | 'url'
                                    >
                                  >;
                                }
                            >
                          >;
                        }
                    >
                  >;
                  sections?: Maybe<
                    Array<
                      { __typename?: 'DynamicTableSectionType' } & Pick<
                        DynamicTableSectionType,
                        'title'
                      > & {
                          rows?: Maybe<
                            Array<
                              { __typename?: 'DynamicTableRowType' } & Pick<
                                DynamicTableRowType,
                                'value'
                              > & {
                                  rowData?: Maybe<
                                    Array<
                                      {
                                        __typename?: 'DynamicTableRowDataType';
                                      } & Pick<
                                        DynamicTableRowDataType,
                                        | 'value'
                                        | 'type'
                                        | 'accessor'
                                        | 'isActive'
                                      > & {
                                          params?: Maybe<
                                            {
                                              __typename?: 'DynamicTableRowDataParamsType';
                                            } & Pick<
                                              DynamicTableRowDataParamsType,
                                              | 'fundPk'
                                              | 'values'
                                              | 'topInvestor'
                                              | 'partnerPk'
                                              | 'offeringType'
                                              | 'portfolioPk'
                                              | 'portfolio'
                                              | 'color'
                                            >
                                          >;
                                        }
                                    >
                                  >;
                                }
                            >
                          >;
                        }
                    >
                  >;
                }
            >;
          }
      >;
    };
};

export type GetOfferingDetailsQueryVariables = Exact<{
  data: GetOfferingDetails;
}>;

export type GetOfferingDetailsQuery = { __typename?: 'Query' } & {
  getOfferingDetails: { __typename?: 'GetOfferingDetailsReturn' } & Pick<
    GetOfferingDetailsReturn,
    'success'
  > & {
      offering?: Maybe<
        { __typename?: 'OfferingDetailsResult' } & Pick<
          OfferingDetailsResult,
          'webOfferingPK' | 'offeringTitle' | 'offeringType' | 'percentFunded'
        > & {
            content?: Maybe<
              { __typename?: 'GetOfferingDetailsContentType' } & Pick<
                GetOfferingDetailsContentType,
                | 'description'
                | 'fundTarget'
                | 'fundedAmount'
                | 'closeDate'
                | 'sponsorDescription'
                | 'introduction'
              > & {
                  investmentType?: Maybe<
                    { __typename?: 'FilterDataWidgetType' } & Pick<
                      FilterDataWidgetType,
                      'label' | 'selectedValue' | 'options'
                    >
                  >;
                  strategy?: Maybe<
                    { __typename?: 'FilterDataWidgetType' } & Pick<
                      FilterDataWidgetType,
                      'label' | 'selectedValue' | 'options'
                    >
                  >;
                  flex?: Maybe<
                    { __typename?: 'FilterDataWidgetType' } & Pick<
                      FilterDataWidgetType,
                      'label' | 'selectedValue' | 'options'
                    >
                  >;
                  location?: Maybe<
                    {
                      __typename?: 'AdminGetOfferingDetailsLocationType';
                    } & Pick<
                      AdminGetOfferingDetailsLocationType,
                      | 'address1'
                      | 'address2'
                      | 'city'
                      | 'state'
                      | 'postalCode'
                      | 'country'
                      | 'lat'
                      | 'long'
                      | 'mapZoom'
                    >
                  >;
                  widgets?: Maybe<
                    Array<
                      { __typename?: 'WidgetType' } & Pick<
                        WidgetType,
                        'label' | 'type'
                      > & {
                          selected?: Maybe<
                            Array<
                              { __typename?: 'WidgetSelectedType' } & Pick<
                                WidgetSelectedType,
                                | 'label'
                                | 'value'
                                | 'percentage'
                                | 'row'
                                | 'col'
                                | 'topLine'
                                | 'fileName'
                                | 'docPk'
                                | 'type'
                                | 'url'
                                | 'order'
                              >
                            >
                          >;
                        }
                    >
                  >;
                }
            >;
            galleryImages?: Maybe<
              { __typename?: 'AdminOfferingDetailsGalleryType' } & Pick<
                AdminOfferingDetailsGalleryType,
                'label' | 'subLabel'
              > & {
                  imageWidgets?: Maybe<
                    Array<
                      {
                        __typename?: 'AdminOfferingDetailsGalleryImageType';
                      } & Pick<
                        AdminOfferingDetailsGalleryImageType,
                        'label' | 'url' | 'caption' | 'altText' | 'order'
                      >
                    >
                  >;
                }
            >;
            otherOpportunities?: Maybe<
              Array<
                { __typename?: 'OtherOpportunitiesType' } & Pick<
                  OtherOpportunitiesType,
                  | 'webOfferingPK'
                  | 'type'
                  | 'offeringTitle'
                  | 'fundTarget'
                  | 'percentFunded'
                  | 'closeDate'
                > & {
                    imageUrls?: Maybe<
                      Array<
                        { __typename?: 'OtherOpportunitiesImageType' } & Pick<
                          OtherOpportunitiesImageType,
                          | 'pk'
                          | 'label'
                          | 'caption'
                          | 'url'
                          | 'altText'
                          | 'order'
                        >
                      >
                    >;
                    filterDataWidgets?: Maybe<
                      Array<
                        { __typename?: 'FilterDataWidgetType' } & Pick<
                          FilterDataWidgetType,
                          'label' | 'selectedValue'
                        >
                      >
                    >;
                  }
              >
            >;
          }
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
    };
};

export type MonitoringGetMyPerformanceQueryVariables = Exact<{
  data: MonitoringGetMyPerformanceParams;
}>;

export type MonitoringGetMyPerformanceQuery = { __typename?: 'Query' } & {
  monitoringGetMyPerformance: {
    __typename?: 'MonitoringGetMyPerformanceReturns';
  } & Pick<
    MonitoringGetMyPerformanceReturns,
    'success' | 'fundPk' | 'contribs' | 'distribs' | 'image'
  > & {
      performanceCharts?: Maybe<
        { __typename?: 'BarGraphCard' } & Pick<
          BarGraphCard,
          'title' | 'type'
        > & {
            legend?: Maybe<
              Array<
                { __typename?: 'BarGraphLegend' } & Pick<
                  BarGraphLegend,
                  'id' | 'title' | 'color'
                >
              >
            >;
            bars?: Maybe<
              Array<
                { __typename?: 'BarGraphBar' } & Pick<
                  BarGraphBar,
                  'title' | 'subTitle1' | 'subTitle2'
                > & {
                    values?: Maybe<
                      { __typename?: 'BarValues' } & Pick<
                        BarValues,
                        'value1' | 'value2' | 'value3' | 'irr' | 'multiple'
                      >
                    >;
                  }
              >
            >;
          }
      >;
      tables?: Maybe<
        Array<
          { __typename?: 'DynamicTableType' } & Pick<
            DynamicTableType,
            'title'
          > & {
              filters?: Maybe<
                Array<
                  { __typename?: 'DynamicTableFiltersType' } & Pick<
                    DynamicTableFiltersType,
                    'label' | 'placeholder' | 'accessor' | 'options'
                  >
                >
              >;
              tabs?: Maybe<
                { __typename?: 'DynamicTableTabsType' } & Pick<
                  DynamicTableTabsType,
                  'accessor' | 'options'
                >
              >;
              headers?: Maybe<
                Array<
                  { __typename?: 'DynamicTableHeadersType' } & Pick<
                    DynamicTableHeadersType,
                    | 'value'
                    | 'accessor'
                    | 'sortable'
                    | 'placeholder'
                    | 'type'
                    | 'group'
                  > & {
                      width?: Maybe<
                        { __typename?: 'DynamicTableWidthType' } & Pick<
                          DynamicTableWidthType,
                          'fixedWidth'
                        >
                      >;
                    }
                >
              >;
              rows?: Maybe<
                Array<
                  { __typename?: 'DynamicTableRowType' } & Pick<
                    DynamicTableRowType,
                    'value'
                  > & {
                      rowData?: Maybe<
                        Array<
                          { __typename?: 'DynamicTableRowDataType' } & Pick<
                            DynamicTableRowDataType,
                            'value' | 'type' | 'accessor' | 'isActive'
                          > & {
                              params?: Maybe<
                                {
                                  __typename?: 'DynamicTableRowDataParamsType';
                                } & Pick<
                                  DynamicTableRowDataParamsType,
                                  'pk' | 'fundPk' | 'values'
                                >
                              >;
                            }
                        >
                      >;
                    }
                >
              >;
            }
        >
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
    };
};

export type SettingGetPersonalInfoMutationVariables = Exact<{
  [key: string]: never;
}>;

export type SettingGetPersonalInfoMutation = { __typename?: 'Mutation' } & {
  settingsGetPersonalInfo: { __typename?: 'SettingsPersonalInfo' } & Pick<
    SettingsPersonalInfo,
    'success'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
      userInfo?: Maybe<
        { __typename?: 'SettingsGetPersonalInfoDetails' } & Pick<
          SettingsGetPersonalInfoDetails,
          | 'firstName'
          | 'lastName'
          | 'middle'
          | 'email'
          | 'phone'
          | 'title'
          | 'company'
          | 'address1'
          | 'address2'
          | 'country'
          | 'city'
          | 'state'
          | 'zipCode'
          | 'addressBlock'
        >
      >;
    };
};

export type SettingsResetPasswordTfaMutationVariables = Exact<{
  data: CheckTfaCodeParams;
}>;

export type SettingsResetPasswordTfaMutation = { __typename?: 'Mutation' } & {
  settingsCheckTfaCode: { __typename?: 'ReturnsType' } & Pick<
    ReturnsType,
    'success'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
    };
};

export type SettingsResetPasswordResendTfaMutationVariables = Exact<{
  [key: string]: never;
}>;

export type SettingsResetPasswordResendTfaMutation = {
  __typename?: 'Mutation';
} & {
  settingsResendTfaCode: { __typename?: 'ReturnsType' } & Pick<
    ReturnsType,
    'success'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
    };
};

export type SettingsIsEmailVerifiedMutationVariables = Exact<{
  [key: string]: never;
}>;

export type SettingsIsEmailVerifiedMutation = { __typename?: 'Mutation' } & {
  settingsCheckEmailVerified: { __typename?: 'ReturnsTypeStepName' } & Pick<
    ReturnsTypeStepName,
    'success'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
      step?: Maybe<{ __typename?: 'JobStep' } & Pick<JobStep, 'name'>>;
    };
};

export type SettingsGetInvestingEntitiesMutationVariables = Exact<{
  [key: string]: never;
}>;

export type SettingsGetInvestingEntitiesMutation = {
  __typename?: 'Mutation';
} & {
  settingsGetInvestingEntities: {
    __typename?: 'SettingsInvestingEntities';
  } & Pick<SettingsInvestingEntities, 'success'> & {
      investingEntities?: Maybe<
        Array<
          { __typename?: 'SettingsInvestingEntitiesItem' } & Pick<
            SettingsInvestingEntitiesItem,
            | 'investorEntityPK'
            | 'name'
            | 'image'
            | 'title'
            | 'offerings'
            | 'people'
          >
        >
      >;
    };
};

export type SettingsGetEntityInfoMutationVariables = Exact<{
  data: SettingsGetEntityInfo;
}>;

export type SettingsGetEntityInfoMutation = { __typename?: 'Mutation' } & {
  settingsGetEntityInfo: { __typename?: 'SettingsGetEntityInfoReturn' } & Pick<
    SettingsGetEntityInfoReturn,
    'success' | 'offerings'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
      entityInfo?: Maybe<
        { __typename?: 'EntityInfo' } & Pick<EntityInfo, 'entityType' | 'taxId'>
      >;
      distribution?: Maybe<
        { __typename?: 'SettingsDistribution' } & Pick<
          SettingsDistribution,
          | 'method'
          | 'routingNumber'
          | 'bankName'
          | 'accountNumber'
          | 'accountType'
        >
      >;
    };
};

export type SettingsCheckPasswordMutationVariables = Exact<{
  data: SettingsCheckPassword;
}>;

export type SettingsCheckPasswordMutation = { __typename?: 'Mutation' } & {
  settingsCheckPassword: { __typename?: 'ReturnsType' } & Pick<
    ReturnsType,
    'success'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
      step?: Maybe<
        { __typename?: 'StepType' } & {
          maxStepAllowed?: Maybe<
            { __typename?: 'JobStep' } & Pick<JobStep, 'name' | 'index'>
          >;
        }
      >;
    };
};

export type SettingsUpdateNameMutationVariables = Exact<{
  data: SettingsUpdateName;
}>;

export type SettingsUpdateNameMutation = { __typename?: 'Mutation' } & {
  settingsUpdateName: { __typename?: 'ReturnsType' } & Pick<
    ReturnsType,
    'success'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
      step?: Maybe<
        { __typename?: 'StepType' } & {
          maxStepAllowed?: Maybe<
            { __typename?: 'JobStep' } & Pick<JobStep, 'name' | 'index'>
          >;
        }
      >;
    };
};

export type SettingsUpdateEmailMutationVariables = Exact<{
  data: SettingsUpdateEmail;
}>;

export type SettingsUpdateEmailMutation = { __typename?: 'Mutation' } & {
  settingsUpdateEmail: { __typename?: 'ReturnsType' } & Pick<
    ReturnsType,
    'success'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
      step?: Maybe<
        { __typename?: 'StepType' } & {
          maxStepAllowed?: Maybe<
            { __typename?: 'JobStep' } & Pick<JobStep, 'index' | 'name'>
          >;
        }
      >;
    };
};

export type SettingsCheckEmailVerifiedMutationVariables = Exact<{
  [key: string]: never;
}>;

export type SettingsCheckEmailVerifiedMutation = { __typename?: 'Mutation' } & {
  settingsCheckEmailVerified: { __typename?: 'ReturnsTypeStepName' } & Pick<
    ReturnsTypeStepName,
    'success'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
      step?: Maybe<
        { __typename?: 'JobStep' } & Pick<JobStep, 'name' | 'index'>
      >;
    };
};

export type SettingsUpdateMobileMutationVariables = Exact<{
  data: SettingsUpdateMobile;
}>;

export type SettingsUpdateMobileMutation = { __typename?: 'Mutation' } & {
  settingsUpdateMobile: { __typename?: 'ReturnsType' } & Pick<
    ReturnsType,
    'success'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
      step?: Maybe<
        { __typename?: 'StepType' } & {
          maxStepAllowed?: Maybe<
            { __typename?: 'JobStep' } & Pick<JobStep, 'index' | 'name'>
          >;
        }
      >;
    };
};

export type SettingsUpdateOptionalInfoMutationVariables = Exact<{
  data: SettingsPersonalOptInfoInput;
}>;

export type SettingsUpdateOptionalInfoMutation = { __typename?: 'Mutation' } & {
  settingsUpdateOptionalInfo: { __typename?: 'ReturnsType' } & Pick<
    ReturnsType,
    'success'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
      step?: Maybe<
        { __typename?: 'StepType' } & {
          maxStepAllowed?: Maybe<
            { __typename?: 'JobStep' } & Pick<JobStep, 'name' | 'index'>
          >;
        }
      >;
    };
};

export type SettingsCheckTfaCodeMutationVariables = Exact<{
  data: CheckTfaCodeParams;
}>;

export type SettingsCheckTfaCodeMutation = { __typename?: 'Mutation' } & {
  settingsCheckTfaCode: { __typename?: 'ReturnsType' } & Pick<
    ReturnsType,
    'success'
  > & {
      step?: Maybe<
        { __typename?: 'StepType' } & {
          maxStepAllowed?: Maybe<
            { __typename?: 'JobStep' } & Pick<JobStep, 'name' | 'index'>
          >;
        }
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
    };
};

export type SettingsUpdateCheckTfaCodeMutationVariables = Exact<{
  data: CheckTfaCodeParams;
}>;

export type SettingsUpdateCheckTfaCodeMutation = { __typename?: 'Mutation' } & {
  settingsUpdateCheckTfaCode: { __typename?: 'ReturnsType' } & Pick<
    ReturnsType,
    'success'
  > & {
      step?: Maybe<
        { __typename?: 'StepType' } & {
          maxStepAllowed?: Maybe<
            { __typename?: 'JobStep' } & Pick<JobStep, 'name' | 'index'>
          >;
        }
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
    };
};

export type SettingsUpdateAddressMutationVariables = Exact<{
  data: SettingsUpdateAddress;
}>;

export type SettingsUpdateAddressMutation = { __typename?: 'Mutation' } & {
  settingsUpdateAddress: { __typename?: 'ReturnsType' } & Pick<
    ReturnsType,
    'success'
  > & {
      step?: Maybe<
        { __typename?: 'StepType' } & {
          maxStepAllowed?: Maybe<
            { __typename?: 'JobStep' } & Pick<JobStep, 'name' | 'index'>
          >;
        }
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
    };
};

export type SettingsUpdateEntityInfoMutationVariables = Exact<{
  data: SettingsUpdateEntityInfo;
}>;

export type SettingsUpdateEntityInfoMutation = { __typename?: 'Mutation' } & {
  settingsUpdateEntityInfo: { __typename?: 'ReturnsType' } & Pick<
    ReturnsType,
    'success'
  > & {
      step?: Maybe<
        { __typename?: 'StepType' } & {
          maxStepAllowed?: Maybe<
            { __typename?: 'JobStep' } & Pick<JobStep, 'name' | 'index'>
          >;
        }
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
    };
};

export type SettingsUpdateInvestmentTaxIdMutationVariables = Exact<{
  data: SettingsUpdateInvTaxId;
}>;

export type SettingsUpdateInvestmentTaxIdMutation = {
  __typename?: 'Mutation';
} & {
  settingsUpdateInvestmentTaxId: { __typename?: 'ReturnsType' } & Pick<
    ReturnsType,
    'success'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
    };
};

export type SettingsUpdatePasswordMutationVariables = Exact<{
  data: SettingsUpdatePassword;
}>;

export type SettingsUpdatePasswordMutation = { __typename?: 'Mutation' } & {
  settingsUpdatePassword: { __typename?: 'ReturnsType' } & Pick<
    ReturnsType,
    'success'
  > & {
      step?: Maybe<
        { __typename?: 'StepType' } & {
          maxStepAllowed?: Maybe<
            { __typename?: 'JobStep' } & Pick<JobStep, 'name' | 'index'>
          >;
        }
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
    };
};

export type SettingsResendTfaCodeMutationVariables = Exact<{
  [key: string]: never;
}>;

export type SettingsResendTfaCodeMutation = { __typename?: 'Mutation' } & {
  settingsResendTfaCode: { __typename?: 'ReturnsType' } & Pick<
    ReturnsType,
    'success'
  > & {
      step?: Maybe<
        { __typename?: 'StepType' } & {
          maxStepAllowed?: Maybe<
            { __typename?: 'JobStep' } & Pick<JobStep, 'name' | 'index'>
          >;
        }
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
    };
};

export type SettingsGetInvestmentTaxIdMutationVariables = Exact<{
  data: SettingsGetInvTaxId;
}>;

export type SettingsGetInvestmentTaxIdMutation = { __typename?: 'Mutation' } & {
  settingsGetInvestmentTaxId: {
    __typename?: 'SettingsGetInvTaxIdReturn';
  } & Pick<SettingsGetInvTaxIdReturn, 'success' | 'taxId'> & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
    };
};

export type SettingsUpdateDistributionsMutationVariables = Exact<{
  data: SettingsUpdateDistributions;
}>;

export type SettingsUpdateDistributionsMutation = {
  __typename?: 'Mutation';
} & {
  settingsUpdateDistributions: { __typename?: 'ReturnsType' } & Pick<
    ReturnsType,
    'success'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
    };
};

export type SettingsColumnStateMutationVariables = Exact<{
  data: SettingsColumnState;
}>;

export type SettingsColumnStateMutation = { __typename?: 'Mutation' } & {
  settingColumnState: { __typename?: 'ReturnsType' } & Pick<
    ReturnsType,
    'success'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
    };
};

export type UtilitiesGetTestDataFilesMutationVariables = Exact<{
  [key: string]: never;
}>;

export type UtilitiesGetTestDataFilesMutation = { __typename?: 'Mutation' } & {
  utilitiesGetTestDataFiles: {
    __typename?: 'UtilitiesGetTestDataFilesReturn';
  } & Pick<UtilitiesGetTestDataFilesReturn, 'success' | 'currentClient'> & {
      datafiles?: Maybe<
        Array<
          { __typename?: 'DatafilesType' } & Pick<
            DatafilesType,
            'folder' | 'path'
          >
        >
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
    };
};

export type UtilitiesRestartBackendServerMutationVariables = Exact<{
  data: UtilitiesRestartBackendServer;
}>;

export type UtilitiesRestartBackendServerMutation = {
  __typename?: 'Mutation';
} & {
  utilitiesRestartBackendServer: { __typename?: 'ReturnsType' } & Pick<
    ReturnsType,
    'success'
  > & {
      errors?: Maybe<
        Array<
          { __typename?: 'ErrorType' } & Pick<
            ErrorType,
            'code' | 'message' | 'severity'
          >
        >
      >;
    };
};

export const AdminSaveComplianceEntitiesDocument = gql`
  mutation AdminSaveComplianceEntities(
    $data: AdminSaveComplianceEntitiesParams!
  ) {
    adminSaveDocusignTemplates(arg0: $data) {
      success
      errors {
        code
        message
        severity
      }
    }
  }
`;
export type AdminSaveComplianceEntitiesMutationFn = Apollo.MutationFunction<
  AdminSaveComplianceEntitiesMutation,
  AdminSaveComplianceEntitiesMutationVariables
>;

/**
 * __useAdminSaveComplianceEntitiesMutation__
 *
 * To run a mutation, you first call `useAdminSaveComplianceEntitiesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminSaveComplianceEntitiesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminSaveComplianceEntitiesMutation, { data, loading, error }] = useAdminSaveComplianceEntitiesMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAdminSaveComplianceEntitiesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminSaveComplianceEntitiesMutation,
    AdminSaveComplianceEntitiesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AdminSaveComplianceEntitiesMutation,
    AdminSaveComplianceEntitiesMutationVariables
  >(AdminSaveComplianceEntitiesDocument, options);
}
export type AdminSaveComplianceEntitiesMutationHookResult = ReturnType<
  typeof useAdminSaveComplianceEntitiesMutation
>;
export type AdminSaveComplianceEntitiesMutationResult =
  Apollo.MutationResult<AdminSaveComplianceEntitiesMutation>;
export type AdminSaveComplianceEntitiesMutationOptions =
  Apollo.BaseMutationOptions<
    AdminSaveComplianceEntitiesMutation,
    AdminSaveComplianceEntitiesMutationVariables
  >;
export const AdminCreateInvestorEntityDocument = gql`
  mutation AdminCreateInvestorEntity($data: AdminCreateInvestorEntityParams!) {
    adminCreateInvestorEntityType(arg0: $data) {
      success
      errors {
        code
        message
        severity
      }
    }
  }
`;
export type AdminCreateInvestorEntityMutationFn = Apollo.MutationFunction<
  AdminCreateInvestorEntityMutation,
  AdminCreateInvestorEntityMutationVariables
>;

/**
 * __useAdminCreateInvestorEntityMutation__
 *
 * To run a mutation, you first call `useAdminCreateInvestorEntityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminCreateInvestorEntityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminCreateInvestorEntityMutation, { data, loading, error }] = useAdminCreateInvestorEntityMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAdminCreateInvestorEntityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminCreateInvestorEntityMutation,
    AdminCreateInvestorEntityMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AdminCreateInvestorEntityMutation,
    AdminCreateInvestorEntityMutationVariables
  >(AdminCreateInvestorEntityDocument, options);
}
export type AdminCreateInvestorEntityMutationHookResult = ReturnType<
  typeof useAdminCreateInvestorEntityMutation
>;
export type AdminCreateInvestorEntityMutationResult =
  Apollo.MutationResult<AdminCreateInvestorEntityMutation>;
export type AdminCreateInvestorEntityMutationOptions =
  Apollo.BaseMutationOptions<
    AdminCreateInvestorEntityMutation,
    AdminCreateInvestorEntityMutationVariables
  >;
export const AdminDeleteInvestorEntityDocument = gql`
  mutation AdminDeleteInvestorEntity($data: AdminDeleteInvestorEntityParams!) {
    adminDeleteInvestorEntityType(arg0: $data) {
      success
      errors {
        code
        message
        severity
      }
    }
  }
`;
export type AdminDeleteInvestorEntityMutationFn = Apollo.MutationFunction<
  AdminDeleteInvestorEntityMutation,
  AdminDeleteInvestorEntityMutationVariables
>;

/**
 * __useAdminDeleteInvestorEntityMutation__
 *
 * To run a mutation, you first call `useAdminDeleteInvestorEntityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminDeleteInvestorEntityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminDeleteInvestorEntityMutation, { data, loading, error }] = useAdminDeleteInvestorEntityMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAdminDeleteInvestorEntityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminDeleteInvestorEntityMutation,
    AdminDeleteInvestorEntityMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AdminDeleteInvestorEntityMutation,
    AdminDeleteInvestorEntityMutationVariables
  >(AdminDeleteInvestorEntityDocument, options);
}
export type AdminDeleteInvestorEntityMutationHookResult = ReturnType<
  typeof useAdminDeleteInvestorEntityMutation
>;
export type AdminDeleteInvestorEntityMutationResult =
  Apollo.MutationResult<AdminDeleteInvestorEntityMutation>;
export type AdminDeleteInvestorEntityMutationOptions =
  Apollo.BaseMutationOptions<
    AdminDeleteInvestorEntityMutation,
    AdminDeleteInvestorEntityMutationVariables
  >;
export const GetDocuSignFoldersDocument = gql`
  query GetDocuSignFolders($data: EmptyObject!) {
    getDocusignFolder(arg0: $data) {
      success
      folders {
        name
        type
        hasSubFolders
        folderId
        itemCount
        folderItems {
          templateId
        }
        folders {
          name
          type
          hasSubFolders
          folderId
          itemCount
          folderItems {
            templateId
          }
        }
      }
      errors {
        code
        message
        severity
      }
    }
  }
`;

/**
 * __useGetDocuSignFoldersQuery__
 *
 * To run a query within a React component, call `useGetDocuSignFoldersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocuSignFoldersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocuSignFoldersQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetDocuSignFoldersQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDocuSignFoldersQuery,
    GetDocuSignFoldersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDocuSignFoldersQuery,
    GetDocuSignFoldersQueryVariables
  >(GetDocuSignFoldersDocument, options);
}
export function useGetDocuSignFoldersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDocuSignFoldersQuery,
    GetDocuSignFoldersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDocuSignFoldersQuery,
    GetDocuSignFoldersQueryVariables
  >(GetDocuSignFoldersDocument, options);
}
export type GetDocuSignFoldersQueryHookResult = ReturnType<
  typeof useGetDocuSignFoldersQuery
>;
export type GetDocuSignFoldersLazyQueryHookResult = ReturnType<
  typeof useGetDocuSignFoldersLazyQuery
>;
export type GetDocuSignFoldersQueryResult = Apollo.QueryResult<
  GetDocuSignFoldersQuery,
  GetDocuSignFoldersQueryVariables
>;
export const GetDocuSignTemplatesDocument = gql`
  query GetDocuSignTemplates($data: EmptyObject!) {
    getDocusignTemplates(arg0: $data) {
      success
      templates {
        templateId
        name
        folderIds
        folderName
      }
      errors {
        code
        message
        severity
      }
    }
  }
`;

/**
 * __useGetDocuSignTemplatesQuery__
 *
 * To run a query within a React component, call `useGetDocuSignTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocuSignTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocuSignTemplatesQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetDocuSignTemplatesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDocuSignTemplatesQuery,
    GetDocuSignTemplatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDocuSignTemplatesQuery,
    GetDocuSignTemplatesQueryVariables
  >(GetDocuSignTemplatesDocument, options);
}
export function useGetDocuSignTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDocuSignTemplatesQuery,
    GetDocuSignTemplatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDocuSignTemplatesQuery,
    GetDocuSignTemplatesQueryVariables
  >(GetDocuSignTemplatesDocument, options);
}
export type GetDocuSignTemplatesQueryHookResult = ReturnType<
  typeof useGetDocuSignTemplatesQuery
>;
export type GetDocuSignTemplatesLazyQueryHookResult = ReturnType<
  typeof useGetDocuSignTemplatesLazyQuery
>;
export type GetDocuSignTemplatesQueryResult = Apollo.QueryResult<
  GetDocuSignTemplatesQuery,
  GetDocuSignTemplatesQueryVariables
>;
export const AdminGetComplianceEntitiesDocument = gql`
  query AdminGetComplianceEntities($data: EmptyObject!) {
    adminGetDocusignTemplates(arg0: $data) {
      success
      errors {
        code
        message
        severity
      }
      docusignTemplatesList {
        entityTypePk
        name
        enable
        uploadDocumentOptions {
          options {
            label
            checked
          }
        }
        count
        templates {
          templatePk
          name
          reference
          category
          required
          templateId
        }
      }
    }
  }
`;

/**
 * __useAdminGetComplianceEntitiesQuery__
 *
 * To run a query within a React component, call `useAdminGetComplianceEntitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetComplianceEntitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetComplianceEntitiesQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAdminGetComplianceEntitiesQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdminGetComplianceEntitiesQuery,
    AdminGetComplianceEntitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AdminGetComplianceEntitiesQuery,
    AdminGetComplianceEntitiesQueryVariables
  >(AdminGetComplianceEntitiesDocument, options);
}
export function useAdminGetComplianceEntitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminGetComplianceEntitiesQuery,
    AdminGetComplianceEntitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AdminGetComplianceEntitiesQuery,
    AdminGetComplianceEntitiesQueryVariables
  >(AdminGetComplianceEntitiesDocument, options);
}
export type AdminGetComplianceEntitiesQueryHookResult = ReturnType<
  typeof useAdminGetComplianceEntitiesQuery
>;
export type AdminGetComplianceEntitiesLazyQueryHookResult = ReturnType<
  typeof useAdminGetComplianceEntitiesLazyQuery
>;
export type AdminGetComplianceEntitiesQueryResult = Apollo.QueryResult<
  AdminGetComplianceEntitiesQuery,
  AdminGetComplianceEntitiesQueryVariables
>;
export const AdminSaveMyProfileDocument = gql`
  mutation adminSaveMyProfile($data: AdminSaveMyProfile!) {
    adminSaveMyProfile(arg0: $data) {
      success
      errors {
        code
        message
      }
    }
  }
`;
export type AdminSaveMyProfileMutationFn = Apollo.MutationFunction<
  AdminSaveMyProfileMutation,
  AdminSaveMyProfileMutationVariables
>;

/**
 * __useAdminSaveMyProfileMutation__
 *
 * To run a mutation, you first call `useAdminSaveMyProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminSaveMyProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminSaveMyProfileMutation, { data, loading, error }] = useAdminSaveMyProfileMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAdminSaveMyProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminSaveMyProfileMutation,
    AdminSaveMyProfileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AdminSaveMyProfileMutation,
    AdminSaveMyProfileMutationVariables
  >(AdminSaveMyProfileDocument, options);
}
export type AdminSaveMyProfileMutationHookResult = ReturnType<
  typeof useAdminSaveMyProfileMutation
>;
export type AdminSaveMyProfileMutationResult =
  Apollo.MutationResult<AdminSaveMyProfileMutation>;
export type AdminSaveMyProfileMutationOptions = Apollo.BaseMutationOptions<
  AdminSaveMyProfileMutation,
  AdminSaveMyProfileMutationVariables
>;
export const AdminPublishOfferingDocument = gql`
  mutation adminPublishOffering($data: AdminPublishOffering!) {
    adminPublishOffering(arg0: $data) {
      success
      errors {
        code
        message
      }
      status {
        publisher
        status
        updated
      }
    }
  }
`;
export type AdminPublishOfferingMutationFn = Apollo.MutationFunction<
  AdminPublishOfferingMutation,
  AdminPublishOfferingMutationVariables
>;

/**
 * __useAdminPublishOfferingMutation__
 *
 * To run a mutation, you first call `useAdminPublishOfferingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminPublishOfferingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminPublishOfferingMutation, { data, loading, error }] = useAdminPublishOfferingMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAdminPublishOfferingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminPublishOfferingMutation,
    AdminPublishOfferingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AdminPublishOfferingMutation,
    AdminPublishOfferingMutationVariables
  >(AdminPublishOfferingDocument, options);
}
export type AdminPublishOfferingMutationHookResult = ReturnType<
  typeof useAdminPublishOfferingMutation
>;
export type AdminPublishOfferingMutationResult =
  Apollo.MutationResult<AdminPublishOfferingMutation>;
export type AdminPublishOfferingMutationOptions = Apollo.BaseMutationOptions<
  AdminPublishOfferingMutation,
  AdminPublishOfferingMutationVariables
>;
export const AdminUnpublishOfferingDocument = gql`
  mutation adminUnpublishOffering($data: AdminUnpublishOffering!) {
    adminUnpublishOffering(arg0: $data) {
      success
      errors {
        code
        message
      }
      status {
        publisher
        status
        updated
      }
    }
  }
`;
export type AdminUnpublishOfferingMutationFn = Apollo.MutationFunction<
  AdminUnpublishOfferingMutation,
  AdminUnpublishOfferingMutationVariables
>;

/**
 * __useAdminUnpublishOfferingMutation__
 *
 * To run a mutation, you first call `useAdminUnpublishOfferingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUnpublishOfferingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUnpublishOfferingMutation, { data, loading, error }] = useAdminUnpublishOfferingMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAdminUnpublishOfferingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminUnpublishOfferingMutation,
    AdminUnpublishOfferingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AdminUnpublishOfferingMutation,
    AdminUnpublishOfferingMutationVariables
  >(AdminUnpublishOfferingDocument, options);
}
export type AdminUnpublishOfferingMutationHookResult = ReturnType<
  typeof useAdminUnpublishOfferingMutation
>;
export type AdminUnpublishOfferingMutationResult =
  Apollo.MutationResult<AdminUnpublishOfferingMutation>;
export type AdminUnpublishOfferingMutationOptions = Apollo.BaseMutationOptions<
  AdminUnpublishOfferingMutation,
  AdminUnpublishOfferingMutationVariables
>;
export const AdminSaveOfferingDetailsDocument = gql`
  mutation adminSaveOfferingDetails($data: AdminSaveOfferingDetails!) {
    adminSaveOfferingDetails(arg0: $data) {
      success
      errors {
        code
        message
      }
    }
  }
`;
export type AdminSaveOfferingDetailsMutationFn = Apollo.MutationFunction<
  AdminSaveOfferingDetailsMutation,
  AdminSaveOfferingDetailsMutationVariables
>;

/**
 * __useAdminSaveOfferingDetailsMutation__
 *
 * To run a mutation, you first call `useAdminSaveOfferingDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminSaveOfferingDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminSaveOfferingDetailsMutation, { data, loading, error }] = useAdminSaveOfferingDetailsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAdminSaveOfferingDetailsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminSaveOfferingDetailsMutation,
    AdminSaveOfferingDetailsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AdminSaveOfferingDetailsMutation,
    AdminSaveOfferingDetailsMutationVariables
  >(AdminSaveOfferingDetailsDocument, options);
}
export type AdminSaveOfferingDetailsMutationHookResult = ReturnType<
  typeof useAdminSaveOfferingDetailsMutation
>;
export type AdminSaveOfferingDetailsMutationResult =
  Apollo.MutationResult<AdminSaveOfferingDetailsMutation>;
export type AdminSaveOfferingDetailsMutationOptions =
  Apollo.BaseMutationOptions<
    AdminSaveOfferingDetailsMutation,
    AdminSaveOfferingDetailsMutationVariables
  >;
export const AdminDeleteNotesDocument = gql`
  mutation AdminDeleteNotes($data: SalesrepDeleteNotes!) {
    adminDeleteNotes(arg0: $data) {
      success
      errors {
        code
        message
        severity
      }
    }
  }
`;
export type AdminDeleteNotesMutationFn = Apollo.MutationFunction<
  AdminDeleteNotesMutation,
  AdminDeleteNotesMutationVariables
>;

/**
 * __useAdminDeleteNotesMutation__
 *
 * To run a mutation, you first call `useAdminDeleteNotesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminDeleteNotesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminDeleteNotesMutation, { data, loading, error }] = useAdminDeleteNotesMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAdminDeleteNotesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminDeleteNotesMutation,
    AdminDeleteNotesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AdminDeleteNotesMutation,
    AdminDeleteNotesMutationVariables
  >(AdminDeleteNotesDocument, options);
}
export type AdminDeleteNotesMutationHookResult = ReturnType<
  typeof useAdminDeleteNotesMutation
>;
export type AdminDeleteNotesMutationResult =
  Apollo.MutationResult<AdminDeleteNotesMutation>;
export type AdminDeleteNotesMutationOptions = Apollo.BaseMutationOptions<
  AdminDeleteNotesMutation,
  AdminDeleteNotesMutationVariables
>;
export const AdminUpdateNoteDocument = gql`
  mutation AdminUpdateNote($data: SalesrepUpdateNote!) {
    adminUpdateNote(arg0: $data) {
      success
      errors {
        code
        message
        severity
      }
    }
  }
`;
export type AdminUpdateNoteMutationFn = Apollo.MutationFunction<
  AdminUpdateNoteMutation,
  AdminUpdateNoteMutationVariables
>;

/**
 * __useAdminUpdateNoteMutation__
 *
 * To run a mutation, you first call `useAdminUpdateNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateNoteMutation, { data, loading, error }] = useAdminUpdateNoteMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAdminUpdateNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminUpdateNoteMutation,
    AdminUpdateNoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AdminUpdateNoteMutation,
    AdminUpdateNoteMutationVariables
  >(AdminUpdateNoteDocument, options);
}
export type AdminUpdateNoteMutationHookResult = ReturnType<
  typeof useAdminUpdateNoteMutation
>;
export type AdminUpdateNoteMutationResult =
  Apollo.MutationResult<AdminUpdateNoteMutation>;
export type AdminUpdateNoteMutationOptions = Apollo.BaseMutationOptions<
  AdminUpdateNoteMutation,
  AdminUpdateNoteMutationVariables
>;
export const AdminCreateNoteDocument = gql`
  mutation AdminCreateNote($data: SalesrepCreateNote!) {
    adminCreateNote(arg0: $data) {
      success
      errors {
        code
        message
        severity
      }
    }
  }
`;
export type AdminCreateNoteMutationFn = Apollo.MutationFunction<
  AdminCreateNoteMutation,
  AdminCreateNoteMutationVariables
>;

/**
 * __useAdminCreateNoteMutation__
 *
 * To run a mutation, you first call `useAdminCreateNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminCreateNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminCreateNoteMutation, { data, loading, error }] = useAdminCreateNoteMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAdminCreateNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminCreateNoteMutation,
    AdminCreateNoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AdminCreateNoteMutation,
    AdminCreateNoteMutationVariables
  >(AdminCreateNoteDocument, options);
}
export type AdminCreateNoteMutationHookResult = ReturnType<
  typeof useAdminCreateNoteMutation
>;
export type AdminCreateNoteMutationResult =
  Apollo.MutationResult<AdminCreateNoteMutation>;
export type AdminCreateNoteMutationOptions = Apollo.BaseMutationOptions<
  AdminCreateNoteMutation,
  AdminCreateNoteMutationVariables
>;
export const AdminActivateAccountDocument = gql`
  mutation AdminActivateAccount($data: SalesrepActivateAccount!) {
    adminActivateAccount(arg0: $data) {
      success
      errors {
        code
        message
        severity
      }
    }
  }
`;
export type AdminActivateAccountMutationFn = Apollo.MutationFunction<
  AdminActivateAccountMutation,
  AdminActivateAccountMutationVariables
>;

/**
 * __useAdminActivateAccountMutation__
 *
 * To run a mutation, you first call `useAdminActivateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminActivateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminActivateAccountMutation, { data, loading, error }] = useAdminActivateAccountMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAdminActivateAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminActivateAccountMutation,
    AdminActivateAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AdminActivateAccountMutation,
    AdminActivateAccountMutationVariables
  >(AdminActivateAccountDocument, options);
}
export type AdminActivateAccountMutationHookResult = ReturnType<
  typeof useAdminActivateAccountMutation
>;
export type AdminActivateAccountMutationResult =
  Apollo.MutationResult<AdminActivateAccountMutation>;
export type AdminActivateAccountMutationOptions = Apollo.BaseMutationOptions<
  AdminActivateAccountMutation,
  AdminActivateAccountMutationVariables
>;
export const AdminResetPasswordDocument = gql`
  mutation AdminResetPassword($data: SalesrepResetPassword!) {
    adminResetPassword(arg0: $data) {
      success
      errors {
        code
        message
        severity
      }
    }
  }
`;
export type AdminResetPasswordMutationFn = Apollo.MutationFunction<
  AdminResetPasswordMutation,
  AdminResetPasswordMutationVariables
>;

/**
 * __useAdminResetPasswordMutation__
 *
 * To run a mutation, you first call `useAdminResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminResetPasswordMutation, { data, loading, error }] = useAdminResetPasswordMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAdminResetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminResetPasswordMutation,
    AdminResetPasswordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AdminResetPasswordMutation,
    AdminResetPasswordMutationVariables
  >(AdminResetPasswordDocument, options);
}
export type AdminResetPasswordMutationHookResult = ReturnType<
  typeof useAdminResetPasswordMutation
>;
export type AdminResetPasswordMutationResult =
  Apollo.MutationResult<AdminResetPasswordMutation>;
export type AdminResetPasswordMutationOptions = Apollo.BaseMutationOptions<
  AdminResetPasswordMutation,
  AdminResetPasswordMutationVariables
>;
export const AdminPauseAccountDocument = gql`
  mutation AdminPauseAccount($data: SalesrepPauseAccount!) {
    adminPauseAccount(arg0: $data) {
      success
      errors {
        code
        message
        severity
      }
    }
  }
`;
export type AdminPauseAccountMutationFn = Apollo.MutationFunction<
  AdminPauseAccountMutation,
  AdminPauseAccountMutationVariables
>;

/**
 * __useAdminPauseAccountMutation__
 *
 * To run a mutation, you first call `useAdminPauseAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminPauseAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminPauseAccountMutation, { data, loading, error }] = useAdminPauseAccountMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAdminPauseAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminPauseAccountMutation,
    AdminPauseAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AdminPauseAccountMutation,
    AdminPauseAccountMutationVariables
  >(AdminPauseAccountDocument, options);
}
export type AdminPauseAccountMutationHookResult = ReturnType<
  typeof useAdminPauseAccountMutation
>;
export type AdminPauseAccountMutationResult =
  Apollo.MutationResult<AdminPauseAccountMutation>;
export type AdminPauseAccountMutationOptions = Apollo.BaseMutationOptions<
  AdminPauseAccountMutation,
  AdminPauseAccountMutationVariables
>;
export const AdminGetUsersDocument = gql`
  query AdminGetUsers($data: AdminGetUsers!) {
    adminGetUsers(arg0: $data) {
      success
      users {
        pk
        firstName
        lastName
        email
        role
      }
      errors {
        code
        message
        severity
      }
    }
  }
`;

/**
 * __useAdminGetUsersQuery__
 *
 * To run a query within a React component, call `useAdminGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetUsersQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAdminGetUsersQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdminGetUsersQuery,
    AdminGetUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminGetUsersQuery, AdminGetUsersQueryVariables>(
    AdminGetUsersDocument,
    options
  );
}
export function useAdminGetUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminGetUsersQuery,
    AdminGetUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminGetUsersQuery, AdminGetUsersQueryVariables>(
    AdminGetUsersDocument,
    options
  );
}
export type AdminGetUsersQueryHookResult = ReturnType<
  typeof useAdminGetUsersQuery
>;
export type AdminGetUsersLazyQueryHookResult = ReturnType<
  typeof useAdminGetUsersLazyQuery
>;
export type AdminGetUsersQueryResult = Apollo.QueryResult<
  AdminGetUsersQuery,
  AdminGetUsersQueryVariables
>;
export const AdminGetRolesDocument = gql`
  query AdminGetRoles($data: AdminGetRoles!) {
    adminGetRoles(arg0: $data) {
      success
      profiles {
        pk
        role
        description
      }
      errors {
        code
        message
        severity
      }
    }
  }
`;

/**
 * __useAdminGetRolesQuery__
 *
 * To run a query within a React component, call `useAdminGetRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetRolesQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAdminGetRolesQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdminGetRolesQuery,
    AdminGetRolesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminGetRolesQuery, AdminGetRolesQueryVariables>(
    AdminGetRolesDocument,
    options
  );
}
export function useAdminGetRolesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminGetRolesQuery,
    AdminGetRolesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminGetRolesQuery, AdminGetRolesQueryVariables>(
    AdminGetRolesDocument,
    options
  );
}
export type AdminGetRolesQueryHookResult = ReturnType<
  typeof useAdminGetRolesQuery
>;
export type AdminGetRolesLazyQueryHookResult = ReturnType<
  typeof useAdminGetRolesLazyQuery
>;
export type AdminGetRolesQueryResult = Apollo.QueryResult<
  AdminGetRolesQuery,
  AdminGetRolesQueryVariables
>;
export const AdminGetProspectsDocument = gql`
  query AdminGetProspects($data: AdminGetProspects!) {
    adminGetProspectList(arg0: $data) {
      success
      entities
      properties
      status
      prospects {
        pk
        date
        investor
        entity
        amount
        property
        status
      }
      errors {
        code
        message
        severity
      }
    }
  }
`;

/**
 * __useAdminGetProspectsQuery__
 *
 * To run a query within a React component, call `useAdminGetProspectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetProspectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetProspectsQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAdminGetProspectsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdminGetProspectsQuery,
    AdminGetProspectsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AdminGetProspectsQuery,
    AdminGetProspectsQueryVariables
  >(AdminGetProspectsDocument, options);
}
export function useAdminGetProspectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminGetProspectsQuery,
    AdminGetProspectsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AdminGetProspectsQuery,
    AdminGetProspectsQueryVariables
  >(AdminGetProspectsDocument, options);
}
export type AdminGetProspectsQueryHookResult = ReturnType<
  typeof useAdminGetProspectsQuery
>;
export type AdminGetProspectsLazyQueryHookResult = ReturnType<
  typeof useAdminGetProspectsLazyQuery
>;
export type AdminGetProspectsQueryResult = Apollo.QueryResult<
  AdminGetProspectsQuery,
  AdminGetProspectsQueryVariables
>;
export const AdminGetProspectDetailsDocument = gql`
  query AdminGetProspectDetails($data: AdminGetProspectDetails!) {
    adminGetProspectDetails(arg0: $data) {
      success
      result {
        prospectName
        prospectTitle
        prospectTag
        prospectImage
        investedAmount
        investDate
        property {
          label
          image
          url
        }
        complianceDocuments {
          label
          widgets {
            widget
            label
            files {
              label
              url
            }
            apiCheck
          }
        }
        agreementDocuments {
          label
          widgets {
            widget
            label
            file
            status
          }
        }
        investorInformation {
          label
          widgets {
            widget
            label
            value
          }
        }
        distributions {
          label
          widgets {
            widget
            label
            accountInfo {
              label
              protected
            }
          }
        }
        taxInformation {
          label
          widgets {
            widget
            label
            protected
          }
        }
        account {
          label
          widgets {
            widget
            label
            value
          }
        }
        formData {
          agreementDocumentsStatusList
        }
      }
      errors {
        code
        message
        severity
      }
    }
  }
`;

/**
 * __useAdminGetProspectDetailsQuery__
 *
 * To run a query within a React component, call `useAdminGetProspectDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetProspectDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetProspectDetailsQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAdminGetProspectDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdminGetProspectDetailsQuery,
    AdminGetProspectDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AdminGetProspectDetailsQuery,
    AdminGetProspectDetailsQueryVariables
  >(AdminGetProspectDetailsDocument, options);
}
export function useAdminGetProspectDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminGetProspectDetailsQuery,
    AdminGetProspectDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AdminGetProspectDetailsQuery,
    AdminGetProspectDetailsQueryVariables
  >(AdminGetProspectDetailsDocument, options);
}
export type AdminGetProspectDetailsQueryHookResult = ReturnType<
  typeof useAdminGetProspectDetailsQuery
>;
export type AdminGetProspectDetailsLazyQueryHookResult = ReturnType<
  typeof useAdminGetProspectDetailsLazyQuery
>;
export type AdminGetProspectDetailsQueryResult = Apollo.QueryResult<
  AdminGetProspectDetailsQuery,
  AdminGetProspectDetailsQueryVariables
>;
export const AdminGetMyProfileDocument = gql`
  query adminGetMyProfile {
    adminGetMyProfile(arg0: {}) {
      success
      userInfo {
        image
        firstName
        lastName
        email
        phone
        title
        company
        permissions {
          role
          description
        }
      }
      errors {
        code
        message
      }
    }
  }
`;

/**
 * __useAdminGetMyProfileQuery__
 *
 * To run a query within a React component, call `useAdminGetMyProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetMyProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetMyProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminGetMyProfileQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AdminGetMyProfileQuery,
    AdminGetMyProfileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AdminGetMyProfileQuery,
    AdminGetMyProfileQueryVariables
  >(AdminGetMyProfileDocument, options);
}
export function useAdminGetMyProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminGetMyProfileQuery,
    AdminGetMyProfileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AdminGetMyProfileQuery,
    AdminGetMyProfileQueryVariables
  >(AdminGetMyProfileDocument, options);
}
export type AdminGetMyProfileQueryHookResult = ReturnType<
  typeof useAdminGetMyProfileQuery
>;
export type AdminGetMyProfileLazyQueryHookResult = ReturnType<
  typeof useAdminGetMyProfileLazyQuery
>;
export type AdminGetMyProfileQueryResult = Apollo.QueryResult<
  AdminGetMyProfileQuery,
  AdminGetMyProfileQueryVariables
>;
export const AdminGetOfferingsDocument = gql`
  query AdminGetOfferings($data: AdminGetOfferings!) {
    adminGetOfferings(arg0: $data) {
      success
      offerings {
        filterDataWidgets {
          label
          selectedValue
          options
          fieldName
        }
        offerings {
          webOfferingPK
          name
          type
          details
          closeDate
          status {
            status
            updatedBy
            updatedDate
          }
          image
          prospects
          fundingGoal
          fundedAmount
          remainingAmount
          fundedAmountPercent
          remainingPercent
          investors
        }
      }
      errors {
        code
        message
        severity
      }
    }
  }
`;

/**
 * __useAdminGetOfferingsQuery__
 *
 * To run a query within a React component, call `useAdminGetOfferingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetOfferingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetOfferingsQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAdminGetOfferingsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdminGetOfferingsQuery,
    AdminGetOfferingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AdminGetOfferingsQuery,
    AdminGetOfferingsQueryVariables
  >(AdminGetOfferingsDocument, options);
}
export function useAdminGetOfferingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminGetOfferingsQuery,
    AdminGetOfferingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AdminGetOfferingsQuery,
    AdminGetOfferingsQueryVariables
  >(AdminGetOfferingsDocument, options);
}
export type AdminGetOfferingsQueryHookResult = ReturnType<
  typeof useAdminGetOfferingsQuery
>;
export type AdminGetOfferingsLazyQueryHookResult = ReturnType<
  typeof useAdminGetOfferingsLazyQuery
>;
export type AdminGetOfferingsQueryResult = Apollo.QueryResult<
  AdminGetOfferingsQuery,
  AdminGetOfferingsQueryVariables
>;
export const AdminGetOfferingDetailsDocument = gql`
  query AdminGetOfferingDetails($data: AdminGetOfferingDetails!) {
    adminGetOfferingDetails(arg0: $data) {
      success
      offering {
        webOfferingPK
        offeringTitle
        offeringType
        publisher
        created
        updated
        percentFunded
        status
        published
        teaser
        disclosureStatement
        content {
          description
          fundTarget
          closeDate
          investmentType {
            label
            selectedValue
            options
          }
          strategy {
            label
            selectedValue
            options
          }
          flex {
            label
            selectedValue
            options
          }
          sponsorDescription
          introduction
          location {
            address1
            address2
            city
            state
            postalCode
            country
            lat
            long
            mapZoom
          }
          widgets {
            key
            label
            type
            chips {
              chipPk
              UItype
              row
              column
              label
              value
              editable
              selected
              customLabel
              isCustomChip
              displayChips {
                UItype
                row
                column
                label
                value
                value2
                topLine
              }
            }
            docs {
              fileName
              url
              order
              type
              docPk
            }
          }
        }
        galleryImages {
          label
          subLabel
          imageWidgets {
            label
            url
            caption
            altText
            order
          }
        }
        otherOpportunities {
          webOfferingPK
          isSelected
          type
          offeringTitle
          fundTarget
          percentFunded
          closeDate
          imageUrls {
            pk
            label
            caption
            url
            altText
            order
          }
          filterDataWidgets {
            label
            selectedValue
          }
        }
        requirements {
          minInvestment
          maxInvestment
          acceptedDocuments {
            name
            required
            templateId
            templatePk
            reference
            category
          }
          foreignInvestment
          acceptableEntityTypes {
            pk
            entity
            isSelected
            documents {
              name
              isRequired
              pk
            }
          }
        }
      }
      errors {
        code
        message
        severity
      }
    }
  }
`;

/**
 * __useAdminGetOfferingDetailsQuery__
 *
 * To run a query within a React component, call `useAdminGetOfferingDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetOfferingDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetOfferingDetailsQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAdminGetOfferingDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdminGetOfferingDetailsQuery,
    AdminGetOfferingDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AdminGetOfferingDetailsQuery,
    AdminGetOfferingDetailsQueryVariables
  >(AdminGetOfferingDetailsDocument, options);
}
export function useAdminGetOfferingDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminGetOfferingDetailsQuery,
    AdminGetOfferingDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AdminGetOfferingDetailsQuery,
    AdminGetOfferingDetailsQueryVariables
  >(AdminGetOfferingDetailsDocument, options);
}
export type AdminGetOfferingDetailsQueryHookResult = ReturnType<
  typeof useAdminGetOfferingDetailsQuery
>;
export type AdminGetOfferingDetailsLazyQueryHookResult = ReturnType<
  typeof useAdminGetOfferingDetailsLazyQuery
>;
export type AdminGetOfferingDetailsQueryResult = Apollo.QueryResult<
  AdminGetOfferingDetailsQuery,
  AdminGetOfferingDetailsQueryVariables
>;
export const MonitoringGetInvSettingDocument = gql`
  query MonitoringGetInvSetting($data: MonitoringGetInvSetting!) {
    monitoringGetInvSetting(arg0: $data) {
      success
      errors {
        code
        message
        severity
      }
      disclosure
      settings {
        investorEntityPk
        investorEntityName
        method
        contacts
      }
    }
  }
`;

/**
 * __useMonitoringGetInvSettingQuery__
 *
 * To run a query within a React component, call `useMonitoringGetInvSettingQuery` and pass it any options that fit your needs.
 * When your component renders, `useMonitoringGetInvSettingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMonitoringGetInvSettingQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useMonitoringGetInvSettingQuery(
  baseOptions: Apollo.QueryHookOptions<
    MonitoringGetInvSettingQuery,
    MonitoringGetInvSettingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MonitoringGetInvSettingQuery,
    MonitoringGetInvSettingQueryVariables
  >(MonitoringGetInvSettingDocument, options);
}
export function useMonitoringGetInvSettingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MonitoringGetInvSettingQuery,
    MonitoringGetInvSettingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MonitoringGetInvSettingQuery,
    MonitoringGetInvSettingQueryVariables
  >(MonitoringGetInvSettingDocument, options);
}
export type MonitoringGetInvSettingQueryHookResult = ReturnType<
  typeof useMonitoringGetInvSettingQuery
>;
export type MonitoringGetInvSettingLazyQueryHookResult = ReturnType<
  typeof useMonitoringGetInvSettingLazyQuery
>;
export type MonitoringGetInvSettingQueryResult = Apollo.QueryResult<
  MonitoringGetInvSettingQuery,
  MonitoringGetInvSettingQueryVariables
>;
export const AdminGetOfferingsOtherOpportunitiesDocument = gql`
  query AdminGetOfferingsOtherOpportunities(
    $data: AdminGetOfferingsOtherOpportunities!
  ) {
    adminGetOfferingsOtherOpportunities(arg0: $data) {
      success
      otherOpportunities {
        webOfferingPK
        isSelected
        type
        offeringTitle
        fundTarget
        percentFunded
        closeDate
        imageUrls {
          pk
          label
          caption
          url
          altText
          order
        }
        filterDataWidgets {
          label
          selectedValue
        }
      }
      errors {
        code
        message
        severity
      }
    }
  }
`;

/**
 * __useAdminGetOfferingsOtherOpportunitiesQuery__
 *
 * To run a query within a React component, call `useAdminGetOfferingsOtherOpportunitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetOfferingsOtherOpportunitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetOfferingsOtherOpportunitiesQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAdminGetOfferingsOtherOpportunitiesQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdminGetOfferingsOtherOpportunitiesQuery,
    AdminGetOfferingsOtherOpportunitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AdminGetOfferingsOtherOpportunitiesQuery,
    AdminGetOfferingsOtherOpportunitiesQueryVariables
  >(AdminGetOfferingsOtherOpportunitiesDocument, options);
}
export function useAdminGetOfferingsOtherOpportunitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminGetOfferingsOtherOpportunitiesQuery,
    AdminGetOfferingsOtherOpportunitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AdminGetOfferingsOtherOpportunitiesQuery,
    AdminGetOfferingsOtherOpportunitiesQueryVariables
  >(AdminGetOfferingsOtherOpportunitiesDocument, options);
}
export type AdminGetOfferingsOtherOpportunitiesQueryHookResult = ReturnType<
  typeof useAdminGetOfferingsOtherOpportunitiesQuery
>;
export type AdminGetOfferingsOtherOpportunitiesLazyQueryHookResult = ReturnType<
  typeof useAdminGetOfferingsOtherOpportunitiesLazyQuery
>;
export type AdminGetOfferingsOtherOpportunitiesQueryResult = Apollo.QueryResult<
  AdminGetOfferingsOtherOpportunitiesQuery,
  AdminGetOfferingsOtherOpportunitiesQueryVariables
>;
export const AdminGetInvestorDocument = gql`
  query AdminGetInvestor($data: AdminGetInvestor!) {
    adminGetInvestor(arg0: $data) {
      success
      investorInfo {
        id
        name
        title
        mobile
        email
        name
        location {
          street_address
          street_address2
          country
          city
          zipcode
          state
          llc_name
        }
      }
      errors {
        code
        message
        severity
      }
    }
  }
`;

/**
 * __useAdminGetInvestorQuery__
 *
 * To run a query within a React component, call `useAdminGetInvestorQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetInvestorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetInvestorQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAdminGetInvestorQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdminGetInvestorQuery,
    AdminGetInvestorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminGetInvestorQuery, AdminGetInvestorQueryVariables>(
    AdminGetInvestorDocument,
    options
  );
}
export function useAdminGetInvestorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminGetInvestorQuery,
    AdminGetInvestorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AdminGetInvestorQuery,
    AdminGetInvestorQueryVariables
  >(AdminGetInvestorDocument, options);
}
export type AdminGetInvestorQueryHookResult = ReturnType<
  typeof useAdminGetInvestorQuery
>;
export type AdminGetInvestorLazyQueryHookResult = ReturnType<
  typeof useAdminGetInvestorLazyQuery
>;
export type AdminGetInvestorQueryResult = Apollo.QueryResult<
  AdminGetInvestorQuery,
  AdminGetInvestorQueryVariables
>;
export const AdminGetInvestorsDocument = gql`
  query AdminGetInvestors($data: AdminGetInvestors!) {
    adminGetInvestors(arg0: $data) {
      success
      data {
        table {
          title
          filters {
            label
            placeholder
            accessor
            options
          }
          tabs {
            accessor
            options
          }
          headers {
            value
            accessor
            sortable
            placeholder
            type
            hidden
            width {
              fixedWidth
            }
          }
          rows {
            value
            rowData {
              value
              type
              accessor
              isActive
              params {
                pk
                values
                topInvestor
                partnerPk
              }
            }
          }
        }
      }
      errors {
        code
        message
        severity
      }
    }
  }
`;

/**
 * __useAdminGetInvestorsQuery__
 *
 * To run a query within a React component, call `useAdminGetInvestorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetInvestorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetInvestorsQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAdminGetInvestorsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdminGetInvestorsQuery,
    AdminGetInvestorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AdminGetInvestorsQuery,
    AdminGetInvestorsQueryVariables
  >(AdminGetInvestorsDocument, options);
}
export function useAdminGetInvestorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminGetInvestorsQuery,
    AdminGetInvestorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AdminGetInvestorsQuery,
    AdminGetInvestorsQueryVariables
  >(AdminGetInvestorsDocument, options);
}
export type AdminGetInvestorsQueryHookResult = ReturnType<
  typeof useAdminGetInvestorsQuery
>;
export type AdminGetInvestorsLazyQueryHookResult = ReturnType<
  typeof useAdminGetInvestorsLazyQuery
>;
export type AdminGetInvestorsQueryResult = Apollo.QueryResult<
  AdminGetInvestorsQuery,
  AdminGetInvestorsQueryVariables
>;
export const AdminGetInvestmentsDocument = gql`
  query AdminGetInvestments($data: SalesrepGetInvestments!) {
    adminGetInvestments(arg0: $data) {
      success
      errors {
        code
        message
        severity
      }
      offerings
      investorEntities {
        pk
        name
        date
        cmmt
        contrib
        distrib
        nav
        irr
        investments {
          pk
          name
          image
          status
          cmmt
          contrib
          distrib
          nav
          irr
          offeringDocs {
            name
            url
          }
          complianceDocs {
            name
            url
          }
        }
      }
    }
  }
`;

/**
 * __useAdminGetInvestmentsQuery__
 *
 * To run a query within a React component, call `useAdminGetInvestmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetInvestmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetInvestmentsQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAdminGetInvestmentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdminGetInvestmentsQuery,
    AdminGetInvestmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AdminGetInvestmentsQuery,
    AdminGetInvestmentsQueryVariables
  >(AdminGetInvestmentsDocument, options);
}
export function useAdminGetInvestmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminGetInvestmentsQuery,
    AdminGetInvestmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AdminGetInvestmentsQuery,
    AdminGetInvestmentsQueryVariables
  >(AdminGetInvestmentsDocument, options);
}
export type AdminGetInvestmentsQueryHookResult = ReturnType<
  typeof useAdminGetInvestmentsQuery
>;
export type AdminGetInvestmentsLazyQueryHookResult = ReturnType<
  typeof useAdminGetInvestmentsLazyQuery
>;
export type AdminGetInvestmentsQueryResult = Apollo.QueryResult<
  AdminGetInvestmentsQuery,
  AdminGetInvestmentsQueryVariables
>;
export const AdminGetInvestorProspectsDocument = gql`
  query AdminGetInvestorProspects($data: SalesrepGetProspects!) {
    adminGetProspects(arg0: $data) {
      success
      errors {
        code
        message
        severity
      }
      entities
      properties
      status
      investments {
        id
        entityName
        identityType
        entityLogo
        prospectsListByEntity {
          id
          prospectName
          prospectImage
          entityOwner
          status
          submission
          date
          time
          goal {
            amount
            percentage
          }
          raised {
            amount
            percentage
          }
          unfunded {
            amount
            percentage
          }
          close {
            date
            in_days
          }
          offeringDocs {
            name
            url
          }
          complianceDocs {
            name
            url
          }
        }
      }
    }
  }
`;

/**
 * __useAdminGetInvestorProspectsQuery__
 *
 * To run a query within a React component, call `useAdminGetInvestorProspectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetInvestorProspectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetInvestorProspectsQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAdminGetInvestorProspectsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdminGetInvestorProspectsQuery,
    AdminGetInvestorProspectsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AdminGetInvestorProspectsQuery,
    AdminGetInvestorProspectsQueryVariables
  >(AdminGetInvestorProspectsDocument, options);
}
export function useAdminGetInvestorProspectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminGetInvestorProspectsQuery,
    AdminGetInvestorProspectsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AdminGetInvestorProspectsQuery,
    AdminGetInvestorProspectsQueryVariables
  >(AdminGetInvestorProspectsDocument, options);
}
export type AdminGetInvestorProspectsQueryHookResult = ReturnType<
  typeof useAdminGetInvestorProspectsQuery
>;
export type AdminGetInvestorProspectsLazyQueryHookResult = ReturnType<
  typeof useAdminGetInvestorProspectsLazyQuery
>;
export type AdminGetInvestorProspectsQueryResult = Apollo.QueryResult<
  AdminGetInvestorProspectsQuery,
  AdminGetInvestorProspectsQueryVariables
>;
export const AdminGetContactsDocument = gql`
  query AdminGetContacts($data: SalesrepGetContacts!) {
    adminGetContacts(arg0: $data) {
      success
      errors {
        code
        message
        severity
      }
      entitiesFilter
      contacts {
        pk
        contactName
        primary
        company
        title
        email
        mobile
        address {
          name
          address1
          address2
          city
          state
          zip
          country
          block
          line
        }
        entities {
          pk
          entityName
          entityType
          entityLogo
          commPreference
          commTypes
          address {
            name
            address1
            address2
            city
            state
            zip
            country
            block
            line
          }
        }
      }
    }
  }
`;

/**
 * __useAdminGetContactsQuery__
 *
 * To run a query within a React component, call `useAdminGetContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetContactsQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAdminGetContactsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdminGetContactsQuery,
    AdminGetContactsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminGetContactsQuery, AdminGetContactsQueryVariables>(
    AdminGetContactsDocument,
    options
  );
}
export function useAdminGetContactsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminGetContactsQuery,
    AdminGetContactsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AdminGetContactsQuery,
    AdminGetContactsQueryVariables
  >(AdminGetContactsDocument, options);
}
export type AdminGetContactsQueryHookResult = ReturnType<
  typeof useAdminGetContactsQuery
>;
export type AdminGetContactsLazyQueryHookResult = ReturnType<
  typeof useAdminGetContactsLazyQuery
>;
export type AdminGetContactsQueryResult = Apollo.QueryResult<
  AdminGetContactsQuery,
  AdminGetContactsQueryVariables
>;
export const AdminGetEntitiesDocument = gql`
  query AdminGetEntities($data: SalesrepGetEntities!) {
    adminGetEntities(arg0: $data) {
      success
      entities {
        pk
        entityName
        entityType
        address {
          name
          address1
          address2
          city
          state
          zip
          country
          line
          block
          blockFull
        }
        contacts {
          name
          primary
          email
          mobile
          commTypes
        }
      }
    }
  }
`;

/**
 * __useAdminGetEntitiesQuery__
 *
 * To run a query within a React component, call `useAdminGetEntitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetEntitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetEntitiesQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAdminGetEntitiesQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdminGetEntitiesQuery,
    AdminGetEntitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminGetEntitiesQuery, AdminGetEntitiesQueryVariables>(
    AdminGetEntitiesDocument,
    options
  );
}
export function useAdminGetEntitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminGetEntitiesQuery,
    AdminGetEntitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AdminGetEntitiesQuery,
    AdminGetEntitiesQueryVariables
  >(AdminGetEntitiesDocument, options);
}
export type AdminGetEntitiesQueryHookResult = ReturnType<
  typeof useAdminGetEntitiesQuery
>;
export type AdminGetEntitiesLazyQueryHookResult = ReturnType<
  typeof useAdminGetEntitiesLazyQuery
>;
export type AdminGetEntitiesQueryResult = Apollo.QueryResult<
  AdminGetEntitiesQuery,
  AdminGetEntitiesQueryVariables
>;
export const AdminGetNotesDocument = gql`
  query AdminGetNotes($data: SalesrepGetNotes!) {
    adminGetNotes(arg0: $data) {
      success
      notes {
        pk
        date
        note
        subject
        type
      }
      types
    }
  }
`;

/**
 * __useAdminGetNotesQuery__
 *
 * To run a query within a React component, call `useAdminGetNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetNotesQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAdminGetNotesQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdminGetNotesQuery,
    AdminGetNotesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminGetNotesQuery, AdminGetNotesQueryVariables>(
    AdminGetNotesDocument,
    options
  );
}
export function useAdminGetNotesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminGetNotesQuery,
    AdminGetNotesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminGetNotesQuery, AdminGetNotesQueryVariables>(
    AdminGetNotesDocument,
    options
  );
}
export type AdminGetNotesQueryHookResult = ReturnType<
  typeof useAdminGetNotesQuery
>;
export type AdminGetNotesLazyQueryHookResult = ReturnType<
  typeof useAdminGetNotesLazyQuery
>;
export type AdminGetNotesQueryResult = Apollo.QueryResult<
  AdminGetNotesQuery,
  AdminGetNotesQueryVariables
>;
export const GetDocusignAccessTokenDocument = gql`
  query GetDocusignAccessToken($data: EmptyObject!) {
    getDocusignAccessToken(arg0: $data) {
      success
      accessToken
      expiresIn
      errors {
        code
        message
        severity
      }
    }
  }
`;

/**
 * __useGetDocusignAccessTokenQuery__
 *
 * To run a query within a React component, call `useGetDocusignAccessTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocusignAccessTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocusignAccessTokenQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetDocusignAccessTokenQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDocusignAccessTokenQuery,
    GetDocusignAccessTokenQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDocusignAccessTokenQuery,
    GetDocusignAccessTokenQueryVariables
  >(GetDocusignAccessTokenDocument, options);
}
export function useGetDocusignAccessTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDocusignAccessTokenQuery,
    GetDocusignAccessTokenQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDocusignAccessTokenQuery,
    GetDocusignAccessTokenQueryVariables
  >(GetDocusignAccessTokenDocument, options);
}
export type GetDocusignAccessTokenQueryHookResult = ReturnType<
  typeof useGetDocusignAccessTokenQuery
>;
export type GetDocusignAccessTokenLazyQueryHookResult = ReturnType<
  typeof useGetDocusignAccessTokenLazyQuery
>;
export type GetDocusignAccessTokenQueryResult = Apollo.QueryResult<
  GetDocusignAccessTokenQuery,
  GetDocusignAccessTokenQueryVariables
>;
export const CreateAccountAccredDocument = gql`
  mutation CreateAccountAccred($accreditation: CreateAccountAccredParam!) {
    createAccountAccred(arg0: $accreditation) {
      success
      reference
      errors {
        code
        message
        severity
      }
      step {
        maxStepAllowed {
          name
          index
        }
      }
    }
  }
`;
export type CreateAccountAccredMutationFn = Apollo.MutationFunction<
  CreateAccountAccredMutation,
  CreateAccountAccredMutationVariables
>;

/**
 * __useCreateAccountAccredMutation__
 *
 * To run a mutation, you first call `useCreateAccountAccredMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountAccredMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountAccredMutation, { data, loading, error }] = useCreateAccountAccredMutation({
 *   variables: {
 *      accreditation: // value for 'accreditation'
 *   },
 * });
 */
export function useCreateAccountAccredMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAccountAccredMutation,
    CreateAccountAccredMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateAccountAccredMutation,
    CreateAccountAccredMutationVariables
  >(CreateAccountAccredDocument, options);
}
export type CreateAccountAccredMutationHookResult = ReturnType<
  typeof useCreateAccountAccredMutation
>;
export type CreateAccountAccredMutationResult =
  Apollo.MutationResult<CreateAccountAccredMutation>;
export type CreateAccountAccredMutationOptions = Apollo.BaseMutationOptions<
  CreateAccountAccredMutation,
  CreateAccountAccredMutationVariables
>;
export const CreateAccountAccredInvTypeDocument = gql`
  mutation CreateAccountAccredInvType(
    $investorType: CreateAccountInvestorType!
  ) {
    createAccountInvestorType(arg0: $investorType) {
      success
      reference
      errors {
        code
        message
        severity
      }
      step {
        maxStepAllowed {
          name
          index
        }
      }
    }
  }
`;
export type CreateAccountAccredInvTypeMutationFn = Apollo.MutationFunction<
  CreateAccountAccredInvTypeMutation,
  CreateAccountAccredInvTypeMutationVariables
>;

/**
 * __useCreateAccountAccredInvTypeMutation__
 *
 * To run a mutation, you first call `useCreateAccountAccredInvTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountAccredInvTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountAccredInvTypeMutation, { data, loading, error }] = useCreateAccountAccredInvTypeMutation({
 *   variables: {
 *      investorType: // value for 'investorType'
 *   },
 * });
 */
export function useCreateAccountAccredInvTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAccountAccredInvTypeMutation,
    CreateAccountAccredInvTypeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateAccountAccredInvTypeMutation,
    CreateAccountAccredInvTypeMutationVariables
  >(CreateAccountAccredInvTypeDocument, options);
}
export type CreateAccountAccredInvTypeMutationHookResult = ReturnType<
  typeof useCreateAccountAccredInvTypeMutation
>;
export type CreateAccountAccredInvTypeMutationResult =
  Apollo.MutationResult<CreateAccountAccredInvTypeMutation>;
export type CreateAccountAccredInvTypeMutationOptions =
  Apollo.BaseMutationOptions<
    CreateAccountAccredInvTypeMutation,
    CreateAccountAccredInvTypeMutationVariables
  >;
export const CreateAccountCredsDocument = gql`
  mutation CreateAccountCreds($credentials: CreateAccountCreds!) {
    createAccountCreds(arg0: $credentials) {
      success
      reference
      errors {
        code
        message
        severity
      }
      step {
        maxStepAllowed {
          name
          index
        }
      }
    }
  }
`;
export type CreateAccountCredsMutationFn = Apollo.MutationFunction<
  CreateAccountCredsMutation,
  CreateAccountCredsMutationVariables
>;

/**
 * __useCreateAccountCredsMutation__
 *
 * To run a mutation, you first call `useCreateAccountCredsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountCredsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountCredsMutation, { data, loading, error }] = useCreateAccountCredsMutation({
 *   variables: {
 *      credentials: // value for 'credentials'
 *   },
 * });
 */
export function useCreateAccountCredsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAccountCredsMutation,
    CreateAccountCredsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateAccountCredsMutation,
    CreateAccountCredsMutationVariables
  >(CreateAccountCredsDocument, options);
}
export type CreateAccountCredsMutationHookResult = ReturnType<
  typeof useCreateAccountCredsMutation
>;
export type CreateAccountCredsMutationResult =
  Apollo.MutationResult<CreateAccountCredsMutation>;
export type CreateAccountCredsMutationOptions = Apollo.BaseMutationOptions<
  CreateAccountCredsMutation,
  CreateAccountCredsMutationVariables
>;
export const CreateAccountContactDocument = gql`
  mutation CreateAccountContact($contact: CreateAccountContact!) {
    createAccountContact(arg0: $contact) {
      success
      reference
      errors {
        code
        message
        severity
      }
      step {
        maxStepAllowed {
          name
          index
        }
      }
    }
  }
`;
export type CreateAccountContactMutationFn = Apollo.MutationFunction<
  CreateAccountContactMutation,
  CreateAccountContactMutationVariables
>;

/**
 * __useCreateAccountContactMutation__
 *
 * To run a mutation, you first call `useCreateAccountContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountContactMutation, { data, loading, error }] = useCreateAccountContactMutation({
 *   variables: {
 *      contact: // value for 'contact'
 *   },
 * });
 */
export function useCreateAccountContactMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAccountContactMutation,
    CreateAccountContactMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateAccountContactMutation,
    CreateAccountContactMutationVariables
  >(CreateAccountContactDocument, options);
}
export type CreateAccountContactMutationHookResult = ReturnType<
  typeof useCreateAccountContactMutation
>;
export type CreateAccountContactMutationResult =
  Apollo.MutationResult<CreateAccountContactMutation>;
export type CreateAccountContactMutationOptions = Apollo.BaseMutationOptions<
  CreateAccountContactMutation,
  CreateAccountContactMutationVariables
>;
export const CreateAccountQuestionsDocument = gql`
  mutation CreateAccountQuestions($questions: CreateAccountQuestions!) {
    createAccountQuestions(arg0: $questions) {
      success
      userId
      errors {
        code
        message
        severity
      }
      step {
        maxStepAllowed {
          name
          index
        }
      }
    }
  }
`;
export type CreateAccountQuestionsMutationFn = Apollo.MutationFunction<
  CreateAccountQuestionsMutation,
  CreateAccountQuestionsMutationVariables
>;

/**
 * __useCreateAccountQuestionsMutation__
 *
 * To run a mutation, you first call `useCreateAccountQuestionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountQuestionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountQuestionsMutation, { data, loading, error }] = useCreateAccountQuestionsMutation({
 *   variables: {
 *      questions: // value for 'questions'
 *   },
 * });
 */
export function useCreateAccountQuestionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAccountQuestionsMutation,
    CreateAccountQuestionsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateAccountQuestionsMutation,
    CreateAccountQuestionsMutationVariables
  >(CreateAccountQuestionsDocument, options);
}
export type CreateAccountQuestionsMutationHookResult = ReturnType<
  typeof useCreateAccountQuestionsMutation
>;
export type CreateAccountQuestionsMutationResult =
  Apollo.MutationResult<CreateAccountQuestionsMutation>;
export type CreateAccountQuestionsMutationOptions = Apollo.BaseMutationOptions<
  CreateAccountQuestionsMutation,
  CreateAccountQuestionsMutationVariables
>;
export const CreateAccountConfirmEmailDocument = gql`
  mutation CreateAccountConfirmEmail {
    confirmEmail(arg0: {}) {
      success
      reference
      errors {
        code
        message
        severity
      }
      step {
        maxStepAllowed {
          name
          index
        }
      }
    }
  }
`;
export type CreateAccountConfirmEmailMutationFn = Apollo.MutationFunction<
  CreateAccountConfirmEmailMutation,
  CreateAccountConfirmEmailMutationVariables
>;

/**
 * __useCreateAccountConfirmEmailMutation__
 *
 * To run a mutation, you first call `useCreateAccountConfirmEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountConfirmEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountConfirmEmailMutation, { data, loading, error }] = useCreateAccountConfirmEmailMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateAccountConfirmEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAccountConfirmEmailMutation,
    CreateAccountConfirmEmailMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateAccountConfirmEmailMutation,
    CreateAccountConfirmEmailMutationVariables
  >(CreateAccountConfirmEmailDocument, options);
}
export type CreateAccountConfirmEmailMutationHookResult = ReturnType<
  typeof useCreateAccountConfirmEmailMutation
>;
export type CreateAccountConfirmEmailMutationResult =
  Apollo.MutationResult<CreateAccountConfirmEmailMutation>;
export type CreateAccountConfirmEmailMutationOptions =
  Apollo.BaseMutationOptions<
    CreateAccountConfirmEmailMutation,
    CreateAccountConfirmEmailMutationVariables
  >;
export const CreateAccountDigitCodeDocument = gql`
  mutation CreateAccountDigitCode($digitsCode: CheckTfaCodeParams!) {
    checkDigitCodes(arg0: $digitsCode) {
      success
      reference
      errors {
        code
        message
        severity
      }
      step {
        maxStepAllowed {
          name
          index
        }
      }
    }
  }
`;
export type CreateAccountDigitCodeMutationFn = Apollo.MutationFunction<
  CreateAccountDigitCodeMutation,
  CreateAccountDigitCodeMutationVariables
>;

/**
 * __useCreateAccountDigitCodeMutation__
 *
 * To run a mutation, you first call `useCreateAccountDigitCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountDigitCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountDigitCodeMutation, { data, loading, error }] = useCreateAccountDigitCodeMutation({
 *   variables: {
 *      digitsCode: // value for 'digitsCode'
 *   },
 * });
 */
export function useCreateAccountDigitCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAccountDigitCodeMutation,
    CreateAccountDigitCodeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateAccountDigitCodeMutation,
    CreateAccountDigitCodeMutationVariables
  >(CreateAccountDigitCodeDocument, options);
}
export type CreateAccountDigitCodeMutationHookResult = ReturnType<
  typeof useCreateAccountDigitCodeMutation
>;
export type CreateAccountDigitCodeMutationResult =
  Apollo.MutationResult<CreateAccountDigitCodeMutation>;
export type CreateAccountDigitCodeMutationOptions = Apollo.BaseMutationOptions<
  CreateAccountDigitCodeMutation,
  CreateAccountDigitCodeMutationVariables
>;
export const CreateAcountCreateDocDocument = gql`
  mutation CreateAcountCreateDoc($data: CreateDoc!) {
    createDoc(arg0: $data) {
      success
      docReference
      url
    }
  }
`;
export type CreateAcountCreateDocMutationFn = Apollo.MutationFunction<
  CreateAcountCreateDocMutation,
  CreateAcountCreateDocMutationVariables
>;

/**
 * __useCreateAcountCreateDocMutation__
 *
 * To run a mutation, you first call `useCreateAcountCreateDocMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAcountCreateDocMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAcountCreateDocMutation, { data, loading, error }] = useCreateAcountCreateDocMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateAcountCreateDocMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAcountCreateDocMutation,
    CreateAcountCreateDocMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateAcountCreateDocMutation,
    CreateAcountCreateDocMutationVariables
  >(CreateAcountCreateDocDocument, options);
}
export type CreateAcountCreateDocMutationHookResult = ReturnType<
  typeof useCreateAcountCreateDocMutation
>;
export type CreateAcountCreateDocMutationResult =
  Apollo.MutationResult<CreateAcountCreateDocMutation>;
export type CreateAcountCreateDocMutationOptions = Apollo.BaseMutationOptions<
  CreateAcountCreateDocMutation,
  CreateAcountCreateDocMutationVariables
>;
export const CreateAccountDocStatusDocument = gql`
  mutation CreateAccountDocStatus($data: CheckDocStatus!) {
    checkDocStatus(arg0: $data) {
      success
      status
    }
  }
`;
export type CreateAccountDocStatusMutationFn = Apollo.MutationFunction<
  CreateAccountDocStatusMutation,
  CreateAccountDocStatusMutationVariables
>;

/**
 * __useCreateAccountDocStatusMutation__
 *
 * To run a mutation, you first call `useCreateAccountDocStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountDocStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountDocStatusMutation, { data, loading, error }] = useCreateAccountDocStatusMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateAccountDocStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAccountDocStatusMutation,
    CreateAccountDocStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateAccountDocStatusMutation,
    CreateAccountDocStatusMutationVariables
  >(CreateAccountDocStatusDocument, options);
}
export type CreateAccountDocStatusMutationHookResult = ReturnType<
  typeof useCreateAccountDocStatusMutation
>;
export type CreateAccountDocStatusMutationResult =
  Apollo.MutationResult<CreateAccountDocStatusMutation>;
export type CreateAccountDocStatusMutationOptions = Apollo.BaseMutationOptions<
  CreateAccountDocStatusMutation,
  CreateAccountDocStatusMutationVariables
>;
export const CreateAccountResendSmsDocument = gql`
  mutation CreateAccountResendSms {
    resendSms(arg0: {}) {
      success
      reference
      errors {
        code
        message
        severity
      }
      step {
        maxStepAllowed {
          name
          index
        }
      }
    }
  }
`;
export type CreateAccountResendSmsMutationFn = Apollo.MutationFunction<
  CreateAccountResendSmsMutation,
  CreateAccountResendSmsMutationVariables
>;

/**
 * __useCreateAccountResendSmsMutation__
 *
 * To run a mutation, you first call `useCreateAccountResendSmsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountResendSmsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountResendSmsMutation, { data, loading, error }] = useCreateAccountResendSmsMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateAccountResendSmsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAccountResendSmsMutation,
    CreateAccountResendSmsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateAccountResendSmsMutation,
    CreateAccountResendSmsMutationVariables
  >(CreateAccountResendSmsDocument, options);
}
export type CreateAccountResendSmsMutationHookResult = ReturnType<
  typeof useCreateAccountResendSmsMutation
>;
export type CreateAccountResendSmsMutationResult =
  Apollo.MutationResult<CreateAccountResendSmsMutation>;
export type CreateAccountResendSmsMutationOptions = Apollo.BaseMutationOptions<
  CreateAccountResendSmsMutation,
  CreateAccountResendSmsMutationVariables
>;
export const CreateAccountGetUserJobDocument = gql`
  mutation CreateAccountGetUserJob {
    getUserJob(arg0: {}) {
      success
      step {
        name
        index
      }
      userJob {
        accreditation
        step
        investorType
        email
        emailVerified
        phoneVerified
        documentSigned
        contact {
          firstName
          lastName
          phone
          phoneVerified
          title
          company
          firstAddress
          secondAddress
          country
          city
          state
          zipCode
        }
        smsResends {
          attempts
          left
          timeLeft
        }
        smsTries {
          attempts
          left
          timeLeft
        }
        emailResends {
          attempts
          left
          timeLeft
        }
        emailTries {
          attempts
          left
          timeLeft
        }
      }
    }
  }
`;
export type CreateAccountGetUserJobMutationFn = Apollo.MutationFunction<
  CreateAccountGetUserJobMutation,
  CreateAccountGetUserJobMutationVariables
>;

/**
 * __useCreateAccountGetUserJobMutation__
 *
 * To run a mutation, you first call `useCreateAccountGetUserJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountGetUserJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountGetUserJobMutation, { data, loading, error }] = useCreateAccountGetUserJobMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateAccountGetUserJobMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAccountGetUserJobMutation,
    CreateAccountGetUserJobMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateAccountGetUserJobMutation,
    CreateAccountGetUserJobMutationVariables
  >(CreateAccountGetUserJobDocument, options);
}
export type CreateAccountGetUserJobMutationHookResult = ReturnType<
  typeof useCreateAccountGetUserJobMutation
>;
export type CreateAccountGetUserJobMutationResult =
  Apollo.MutationResult<CreateAccountGetUserJobMutation>;
export type CreateAccountGetUserJobMutationOptions = Apollo.BaseMutationOptions<
  CreateAccountGetUserJobMutation,
  CreateAccountGetUserJobMutationVariables
>;
export const CreateAccountEmailCheckValidationDocument = gql`
  mutation CreateAccountEmailCheckValidation {
    checkEmailAddressValidation(arg0: {}) {
      success
      errors {
        code
        message
        severity
      }
      step {
        maxStepAllowed {
          name
          index
        }
      }
    }
  }
`;
export type CreateAccountEmailCheckValidationMutationFn =
  Apollo.MutationFunction<
    CreateAccountEmailCheckValidationMutation,
    CreateAccountEmailCheckValidationMutationVariables
  >;

/**
 * __useCreateAccountEmailCheckValidationMutation__
 *
 * To run a mutation, you first call `useCreateAccountEmailCheckValidationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountEmailCheckValidationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountEmailCheckValidationMutation, { data, loading, error }] = useCreateAccountEmailCheckValidationMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateAccountEmailCheckValidationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAccountEmailCheckValidationMutation,
    CreateAccountEmailCheckValidationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateAccountEmailCheckValidationMutation,
    CreateAccountEmailCheckValidationMutationVariables
  >(CreateAccountEmailCheckValidationDocument, options);
}
export type CreateAccountEmailCheckValidationMutationHookResult = ReturnType<
  typeof useCreateAccountEmailCheckValidationMutation
>;
export type CreateAccountEmailCheckValidationMutationResult =
  Apollo.MutationResult<CreateAccountEmailCheckValidationMutation>;
export type CreateAccountEmailCheckValidationMutationOptions =
  Apollo.BaseMutationOptions<
    CreateAccountEmailCheckValidationMutation,
    CreateAccountEmailCheckValidationMutationVariables
  >;
export const CcGetccContactJobDocument = gql`
  mutation CcGetccContactJob {
    getCcContactJob(arg0: {}) {
      success
      userJob {
        person
        relationship
        organization
        investorType
        emailVerified
        phoneVerified
        checked
        contact {
          email
          firstName
          lastName
          phone
          title
          company
          firstAddress
          secondAddress
          country
          city
          state
          zipCode
        }
        ccTypes
        smsResends {
          attempts
          left
          timeLeft
        }
        smsTries {
          attempts
          left
          timeLeft
        }
      }
      step {
        name
        index
      }
      errors {
        code
        message
        severity
      }
    }
  }
`;
export type CcGetccContactJobMutationFn = Apollo.MutationFunction<
  CcGetccContactJobMutation,
  CcGetccContactJobMutationVariables
>;

/**
 * __useCcGetccContactJobMutation__
 *
 * To run a mutation, you first call `useCcGetccContactJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCcGetccContactJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ccGetccContactJobMutation, { data, loading, error }] = useCcGetccContactJobMutation({
 *   variables: {
 *   },
 * });
 */
export function useCcGetccContactJobMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CcGetccContactJobMutation,
    CcGetccContactJobMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CcGetccContactJobMutation,
    CcGetccContactJobMutationVariables
  >(CcGetccContactJobDocument, options);
}
export type CcGetccContactJobMutationHookResult = ReturnType<
  typeof useCcGetccContactJobMutation
>;
export type CcGetccContactJobMutationResult =
  Apollo.MutationResult<CcGetccContactJobMutation>;
export type CcGetccContactJobMutationOptions = Apollo.BaseMutationOptions<
  CcGetccContactJobMutation,
  CcGetccContactJobMutationVariables
>;
export const CcContactAddCredentialsDocument = gql`
  mutation CcContactAddCredentials($data: CcContactAddCredentials!) {
    ccContactAddCredentials(arg0: $data) {
      success
      errors {
        code
        message
        severity
      }
      step {
        maxStepAllowed {
          name
          index
        }
      }
    }
  }
`;
export type CcContactAddCredentialsMutationFn = Apollo.MutationFunction<
  CcContactAddCredentialsMutation,
  CcContactAddCredentialsMutationVariables
>;

/**
 * __useCcContactAddCredentialsMutation__
 *
 * To run a mutation, you first call `useCcContactAddCredentialsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCcContactAddCredentialsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ccContactAddCredentialsMutation, { data, loading, error }] = useCcContactAddCredentialsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCcContactAddCredentialsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CcContactAddCredentialsMutation,
    CcContactAddCredentialsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CcContactAddCredentialsMutation,
    CcContactAddCredentialsMutationVariables
  >(CcContactAddCredentialsDocument, options);
}
export type CcContactAddCredentialsMutationHookResult = ReturnType<
  typeof useCcContactAddCredentialsMutation
>;
export type CcContactAddCredentialsMutationResult =
  Apollo.MutationResult<CcContactAddCredentialsMutation>;
export type CcContactAddCredentialsMutationOptions = Apollo.BaseMutationOptions<
  CcContactAddCredentialsMutation,
  CcContactAddCredentialsMutationVariables
>;
export const CcCreateAccountResendSmsDocument = gql`
  mutation CcCreateAccountResendSms {
    ccContactResendSms(arg0: {}) {
      success
      errors {
        code
        message
        severity
      }
      step {
        maxStepAllowed {
          name
          index
        }
      }
    }
  }
`;
export type CcCreateAccountResendSmsMutationFn = Apollo.MutationFunction<
  CcCreateAccountResendSmsMutation,
  CcCreateAccountResendSmsMutationVariables
>;

/**
 * __useCcCreateAccountResendSmsMutation__
 *
 * To run a mutation, you first call `useCcCreateAccountResendSmsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCcCreateAccountResendSmsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ccCreateAccountResendSmsMutation, { data, loading, error }] = useCcCreateAccountResendSmsMutation({
 *   variables: {
 *   },
 * });
 */
export function useCcCreateAccountResendSmsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CcCreateAccountResendSmsMutation,
    CcCreateAccountResendSmsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CcCreateAccountResendSmsMutation,
    CcCreateAccountResendSmsMutationVariables
  >(CcCreateAccountResendSmsDocument, options);
}
export type CcCreateAccountResendSmsMutationHookResult = ReturnType<
  typeof useCcCreateAccountResendSmsMutation
>;
export type CcCreateAccountResendSmsMutationResult =
  Apollo.MutationResult<CcCreateAccountResendSmsMutation>;
export type CcCreateAccountResendSmsMutationOptions =
  Apollo.BaseMutationOptions<
    CcCreateAccountResendSmsMutation,
    CcCreateAccountResendSmsMutationVariables
  >;
export const CcCreateAccountSixDigitCodeDocument = gql`
  mutation CcCreateAccountSixDigitCode($data: CheckTfaCodeParams!) {
    checkContactTfaCode(arg0: $data) {
      success
      errors {
        code
        message
        severity
      }
      step {
        maxStepAllowed {
          name
          index
        }
      }
    }
  }
`;
export type CcCreateAccountSixDigitCodeMutationFn = Apollo.MutationFunction<
  CcCreateAccountSixDigitCodeMutation,
  CcCreateAccountSixDigitCodeMutationVariables
>;

/**
 * __useCcCreateAccountSixDigitCodeMutation__
 *
 * To run a mutation, you first call `useCcCreateAccountSixDigitCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCcCreateAccountSixDigitCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ccCreateAccountSixDigitCodeMutation, { data, loading, error }] = useCcCreateAccountSixDigitCodeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCcCreateAccountSixDigitCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CcCreateAccountSixDigitCodeMutation,
    CcCreateAccountSixDigitCodeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CcCreateAccountSixDigitCodeMutation,
    CcCreateAccountSixDigitCodeMutationVariables
  >(CcCreateAccountSixDigitCodeDocument, options);
}
export type CcCreateAccountSixDigitCodeMutationHookResult = ReturnType<
  typeof useCcCreateAccountSixDigitCodeMutation
>;
export type CcCreateAccountSixDigitCodeMutationResult =
  Apollo.MutationResult<CcCreateAccountSixDigitCodeMutation>;
export type CcCreateAccountSixDigitCodeMutationOptions =
  Apollo.BaseMutationOptions<
    CcCreateAccountSixDigitCodeMutation,
    CcCreateAccountSixDigitCodeMutationVariables
  >;
export const CcCreateAccountContactDocument = gql`
  mutation CcCreateAccountContact($data: CreateAccountContact!) {
    ccContactInfo(arg0: $data) {
      success
      errors {
        code
        message
        severity
      }
      step {
        maxStepAllowed {
          name
          index
        }
      }
    }
  }
`;
export type CcCreateAccountContactMutationFn = Apollo.MutationFunction<
  CcCreateAccountContactMutation,
  CcCreateAccountContactMutationVariables
>;

/**
 * __useCcCreateAccountContactMutation__
 *
 * To run a mutation, you first call `useCcCreateAccountContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCcCreateAccountContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ccCreateAccountContactMutation, { data, loading, error }] = useCcCreateAccountContactMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCcCreateAccountContactMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CcCreateAccountContactMutation,
    CcCreateAccountContactMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CcCreateAccountContactMutation,
    CcCreateAccountContactMutationVariables
  >(CcCreateAccountContactDocument, options);
}
export type CcCreateAccountContactMutationHookResult = ReturnType<
  typeof useCcCreateAccountContactMutation
>;
export type CcCreateAccountContactMutationResult =
  Apollo.MutationResult<CcCreateAccountContactMutation>;
export type CcCreateAccountContactMutationOptions = Apollo.BaseMutationOptions<
  CcCreateAccountContactMutation,
  CcCreateAccountContactMutationVariables
>;
export const SigninDocument = gql`
  mutation Signin($data: LoginParams!) {
    login(arg0: $data) {
      success
      userId
      licenses
      availableMfaMethods
      preferredMfaMethod
      errors {
        code
        message
        severity
      }
    }
  }
`;
export type SigninMutationFn = Apollo.MutationFunction<
  SigninMutation,
  SigninMutationVariables
>;

/**
 * __useSigninMutation__
 *
 * To run a mutation, you first call `useSigninMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSigninMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signinMutation, { data, loading, error }] = useSigninMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSigninMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SigninMutation,
    SigninMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SigninMutation, SigninMutationVariables>(
    SigninDocument,
    options
  );
}
export type SigninMutationHookResult = ReturnType<typeof useSigninMutation>;
export type SigninMutationResult = Apollo.MutationResult<SigninMutation>;
export type SigninMutationOptions = Apollo.BaseMutationOptions<
  SigninMutation,
  SigninMutationVariables
>;
export const SigninSendMfaDocument = gql`
  mutation SigninSendMfa($data: AuthSendMfaCodeParams!) {
    authSendMfaCode(arg0: $data) {
      success
      reference
      errors {
        code
        message
        severity
      }
    }
  }
`;
export type SigninSendMfaMutationFn = Apollo.MutationFunction<
  SigninSendMfaMutation,
  SigninSendMfaMutationVariables
>;

/**
 * __useSigninSendMfaMutation__
 *
 * To run a mutation, you first call `useSigninSendMfaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSigninSendMfaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signinSendMfaMutation, { data, loading, error }] = useSigninSendMfaMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSigninSendMfaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SigninSendMfaMutation,
    SigninSendMfaMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SigninSendMfaMutation,
    SigninSendMfaMutationVariables
  >(SigninSendMfaDocument, options);
}
export type SigninSendMfaMutationHookResult = ReturnType<
  typeof useSigninSendMfaMutation
>;
export type SigninSendMfaMutationResult =
  Apollo.MutationResult<SigninSendMfaMutation>;
export type SigninSendMfaMutationOptions = Apollo.BaseMutationOptions<
  SigninSendMfaMutation,
  SigninSendMfaMutationVariables
>;
export const SigninCheckMfaDocument = gql`
  mutation SigninCheckMfa($data: CheckMfaCodeParams!) {
    authCheckMfaCode(arg0: $data) {
      success
      userId
      licenses
      errors {
        code
        message
        severity
      }
    }
  }
`;
export type SigninCheckMfaMutationFn = Apollo.MutationFunction<
  SigninCheckMfaMutation,
  SigninCheckMfaMutationVariables
>;

/**
 * __useSigninCheckMfaMutation__
 *
 * To run a mutation, you first call `useSigninCheckMfaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSigninCheckMfaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signinCheckMfaMutation, { data, loading, error }] = useSigninCheckMfaMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSigninCheckMfaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SigninCheckMfaMutation,
    SigninCheckMfaMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SigninCheckMfaMutation,
    SigninCheckMfaMutationVariables
  >(SigninCheckMfaDocument, options);
}
export type SigninCheckMfaMutationHookResult = ReturnType<
  typeof useSigninCheckMfaMutation
>;
export type SigninCheckMfaMutationResult =
  Apollo.MutationResult<SigninCheckMfaMutation>;
export type SigninCheckMfaMutationOptions = Apollo.BaseMutationOptions<
  SigninCheckMfaMutation,
  SigninCheckMfaMutationVariables
>;
export const SigninResendDocument = gql`
  mutation SigninResend($data: AuthResendMfaCode!) {
    authResendMfaCode(arg0: $data) {
      success
      errors {
        code
        message
        severity
      }
    }
  }
`;
export type SigninResendMutationFn = Apollo.MutationFunction<
  SigninResendMutation,
  SigninResendMutationVariables
>;

/**
 * __useSigninResendMutation__
 *
 * To run a mutation, you first call `useSigninResendMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSigninResendMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signinResendMutation, { data, loading, error }] = useSigninResendMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSigninResendMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SigninResendMutation,
    SigninResendMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SigninResendMutation,
    SigninResendMutationVariables
  >(SigninResendDocument, options);
}
export type SigninResendMutationHookResult = ReturnType<
  typeof useSigninResendMutation
>;
export type SigninResendMutationResult =
  Apollo.MutationResult<SigninResendMutation>;
export type SigninResendMutationOptions = Apollo.BaseMutationOptions<
  SigninResendMutation,
  SigninResendMutationVariables
>;
export const UserLogoutDocument = gql`
  mutation UserLogout {
    logout {
      errors {
        code
        message
        severity
      }
      success
    }
  }
`;
export type UserLogoutMutationFn = Apollo.MutationFunction<
  UserLogoutMutation,
  UserLogoutMutationVariables
>;

/**
 * __useUserLogoutMutation__
 *
 * To run a mutation, you first call `useUserLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userLogoutMutation, { data, loading, error }] = useUserLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useUserLogoutMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserLogoutMutation,
    UserLogoutMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UserLogoutMutation, UserLogoutMutationVariables>(
    UserLogoutDocument,
    options
  );
}
export type UserLogoutMutationHookResult = ReturnType<
  typeof useUserLogoutMutation
>;
export type UserLogoutMutationResult =
  Apollo.MutationResult<UserLogoutMutation>;
export type UserLogoutMutationOptions = Apollo.BaseMutationOptions<
  UserLogoutMutation,
  UserLogoutMutationVariables
>;
export const UserLogoutEverywhereDocument = gql`
  mutation UserLogoutEverywhere($investorPk: String!) {
    logoutEverywhere(investorPk: $investorPk) {
      errors {
        code
        message
        severity
      }
      success
    }
  }
`;
export type UserLogoutEverywhereMutationFn = Apollo.MutationFunction<
  UserLogoutEverywhereMutation,
  UserLogoutEverywhereMutationVariables
>;

/**
 * __useUserLogoutEverywhereMutation__
 *
 * To run a mutation, you first call `useUserLogoutEverywhereMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserLogoutEverywhereMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userLogoutEverywhereMutation, { data, loading, error }] = useUserLogoutEverywhereMutation({
 *   variables: {
 *      investorPk: // value for 'investorPk'
 *   },
 * });
 */
export function useUserLogoutEverywhereMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserLogoutEverywhereMutation,
    UserLogoutEverywhereMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UserLogoutEverywhereMutation,
    UserLogoutEverywhereMutationVariables
  >(UserLogoutEverywhereDocument, options);
}
export type UserLogoutEverywhereMutationHookResult = ReturnType<
  typeof useUserLogoutEverywhereMutation
>;
export type UserLogoutEverywhereMutationResult =
  Apollo.MutationResult<UserLogoutEverywhereMutation>;
export type UserLogoutEverywhereMutationOptions = Apollo.BaseMutationOptions<
  UserLogoutEverywhereMutation,
  UserLogoutEverywhereMutationVariables
>;
export const UserLogoutEverywhereElseDocument = gql`
  mutation UserLogoutEverywhereElse {
    logoutEverywhereElse {
      errors {
        code
        message
        severity
      }
      success
    }
  }
`;
export type UserLogoutEverywhereElseMutationFn = Apollo.MutationFunction<
  UserLogoutEverywhereElseMutation,
  UserLogoutEverywhereElseMutationVariables
>;

/**
 * __useUserLogoutEverywhereElseMutation__
 *
 * To run a mutation, you first call `useUserLogoutEverywhereElseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserLogoutEverywhereElseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userLogoutEverywhereElseMutation, { data, loading, error }] = useUserLogoutEverywhereElseMutation({
 *   variables: {
 *   },
 * });
 */
export function useUserLogoutEverywhereElseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserLogoutEverywhereElseMutation,
    UserLogoutEverywhereElseMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UserLogoutEverywhereElseMutation,
    UserLogoutEverywhereElseMutationVariables
  >(UserLogoutEverywhereElseDocument, options);
}
export type UserLogoutEverywhereElseMutationHookResult = ReturnType<
  typeof useUserLogoutEverywhereElseMutation
>;
export type UserLogoutEverywhereElseMutationResult =
  Apollo.MutationResult<UserLogoutEverywhereElseMutation>;
export type UserLogoutEverywhereElseMutationOptions =
  Apollo.BaseMutationOptions<
    UserLogoutEverywhereElseMutation,
    UserLogoutEverywhereElseMutationVariables
  >;
export const UserRefreshTokenDocument = gql`
  mutation UserRefreshToken {
    refreshTokens {
      success
      errors {
        code
        message
        severity
      }
      userId
    }
  }
`;
export type UserRefreshTokenMutationFn = Apollo.MutationFunction<
  UserRefreshTokenMutation,
  UserRefreshTokenMutationVariables
>;

/**
 * __useUserRefreshTokenMutation__
 *
 * To run a mutation, you first call `useUserRefreshTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserRefreshTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userRefreshTokenMutation, { data, loading, error }] = useUserRefreshTokenMutation({
 *   variables: {
 *   },
 * });
 */
export function useUserRefreshTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserRefreshTokenMutation,
    UserRefreshTokenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UserRefreshTokenMutation,
    UserRefreshTokenMutationVariables
  >(UserRefreshTokenDocument, options);
}
export type UserRefreshTokenMutationHookResult = ReturnType<
  typeof useUserRefreshTokenMutation
>;
export type UserRefreshTokenMutationResult =
  Apollo.MutationResult<UserRefreshTokenMutation>;
export type UserRefreshTokenMutationOptions = Apollo.BaseMutationOptions<
  UserRefreshTokenMutation,
  UserRefreshTokenMutationVariables
>;
export const UserForgotPasswordDocument = gql`
  mutation UserForgotPassword($data: AuthForgotPassword!) {
    authForgotPassword(arg0: $data) {
      success
      errors {
        code
        message
        severity
      }
    }
  }
`;
export type UserForgotPasswordMutationFn = Apollo.MutationFunction<
  UserForgotPasswordMutation,
  UserForgotPasswordMutationVariables
>;

/**
 * __useUserForgotPasswordMutation__
 *
 * To run a mutation, you first call `useUserForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userForgotPasswordMutation, { data, loading, error }] = useUserForgotPasswordMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUserForgotPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserForgotPasswordMutation,
    UserForgotPasswordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UserForgotPasswordMutation,
    UserForgotPasswordMutationVariables
  >(UserForgotPasswordDocument, options);
}
export type UserForgotPasswordMutationHookResult = ReturnType<
  typeof useUserForgotPasswordMutation
>;
export type UserForgotPasswordMutationResult =
  Apollo.MutationResult<UserForgotPasswordMutation>;
export type UserForgotPasswordMutationOptions = Apollo.BaseMutationOptions<
  UserForgotPasswordMutation,
  UserForgotPasswordMutationVariables
>;
export const UserResetPasswordSendMfaDocument = gql`
  mutation UserResetPasswordSendMfa($data: AuthSendMfaCodeParams!) {
    authResetSendMfaCode(arg0: $data) {
      success
      reference
      errors {
        code
        message
        severity
      }
    }
  }
`;
export type UserResetPasswordSendMfaMutationFn = Apollo.MutationFunction<
  UserResetPasswordSendMfaMutation,
  UserResetPasswordSendMfaMutationVariables
>;

/**
 * __useUserResetPasswordSendMfaMutation__
 *
 * To run a mutation, you first call `useUserResetPasswordSendMfaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserResetPasswordSendMfaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userResetPasswordSendMfaMutation, { data, loading, error }] = useUserResetPasswordSendMfaMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUserResetPasswordSendMfaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserResetPasswordSendMfaMutation,
    UserResetPasswordSendMfaMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UserResetPasswordSendMfaMutation,
    UserResetPasswordSendMfaMutationVariables
  >(UserResetPasswordSendMfaDocument, options);
}
export type UserResetPasswordSendMfaMutationHookResult = ReturnType<
  typeof useUserResetPasswordSendMfaMutation
>;
export type UserResetPasswordSendMfaMutationResult =
  Apollo.MutationResult<UserResetPasswordSendMfaMutation>;
export type UserResetPasswordSendMfaMutationOptions =
  Apollo.BaseMutationOptions<
    UserResetPasswordSendMfaMutation,
    UserResetPasswordSendMfaMutationVariables
  >;
export const UserResetPasswordDocument = gql`
  mutation UserResetPassword($data: AuthResetPassword!) {
    authResetPassword(arg0: $data) {
      success
      errors {
        code
        message
        severity
      }
    }
  }
`;
export type UserResetPasswordMutationFn = Apollo.MutationFunction<
  UserResetPasswordMutation,
  UserResetPasswordMutationVariables
>;

/**
 * __useUserResetPasswordMutation__
 *
 * To run a mutation, you first call `useUserResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userResetPasswordMutation, { data, loading, error }] = useUserResetPasswordMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUserResetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserResetPasswordMutation,
    UserResetPasswordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UserResetPasswordMutation,
    UserResetPasswordMutationVariables
  >(UserResetPasswordDocument, options);
}
export type UserResetPasswordMutationHookResult = ReturnType<
  typeof useUserResetPasswordMutation
>;
export type UserResetPasswordMutationResult =
  Apollo.MutationResult<UserResetPasswordMutation>;
export type UserResetPasswordMutationOptions = Apollo.BaseMutationOptions<
  UserResetPasswordMutation,
  UserResetPasswordMutationVariables
>;
export const UserResetPasswordMfaDocument = gql`
  mutation UserResetPasswordMfa($data: CheckMfaCodeParams!) {
    authResetCheckMfaCode(arg0: $data) {
      success
      errors {
        code
        message
        severity
      }
    }
  }
`;
export type UserResetPasswordMfaMutationFn = Apollo.MutationFunction<
  UserResetPasswordMfaMutation,
  UserResetPasswordMfaMutationVariables
>;

/**
 * __useUserResetPasswordMfaMutation__
 *
 * To run a mutation, you first call `useUserResetPasswordMfaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserResetPasswordMfaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userResetPasswordMfaMutation, { data, loading, error }] = useUserResetPasswordMfaMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUserResetPasswordMfaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserResetPasswordMfaMutation,
    UserResetPasswordMfaMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UserResetPasswordMfaMutation,
    UserResetPasswordMfaMutationVariables
  >(UserResetPasswordMfaDocument, options);
}
export type UserResetPasswordMfaMutationHookResult = ReturnType<
  typeof useUserResetPasswordMfaMutation
>;
export type UserResetPasswordMfaMutationResult =
  Apollo.MutationResult<UserResetPasswordMfaMutation>;
export type UserResetPasswordMfaMutationOptions = Apollo.BaseMutationOptions<
  UserResetPasswordMfaMutation,
  UserResetPasswordMfaMutationVariables
>;
export const UserResetPasswordResendMfaDocument = gql`
  mutation UserResetPasswordResendMfa($data: AuthResetResendPasswordMfaCode!) {
    authResetResendMfaCode(arg0: $data) {
      success
      errors {
        code
        message
        severity
      }
    }
  }
`;
export type UserResetPasswordResendMfaMutationFn = Apollo.MutationFunction<
  UserResetPasswordResendMfaMutation,
  UserResetPasswordResendMfaMutationVariables
>;

/**
 * __useUserResetPasswordResendMfaMutation__
 *
 * To run a mutation, you first call `useUserResetPasswordResendMfaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserResetPasswordResendMfaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userResetPasswordResendMfaMutation, { data, loading, error }] = useUserResetPasswordResendMfaMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUserResetPasswordResendMfaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserResetPasswordResendMfaMutation,
    UserResetPasswordResendMfaMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UserResetPasswordResendMfaMutation,
    UserResetPasswordResendMfaMutationVariables
  >(UserResetPasswordResendMfaDocument, options);
}
export type UserResetPasswordResendMfaMutationHookResult = ReturnType<
  typeof useUserResetPasswordResendMfaMutation
>;
export type UserResetPasswordResendMfaMutationResult =
  Apollo.MutationResult<UserResetPasswordResendMfaMutation>;
export type UserResetPasswordResendMfaMutationOptions =
  Apollo.BaseMutationOptions<
    UserResetPasswordResendMfaMutation,
    UserResetPasswordResendMfaMutationVariables
  >;
export const GetUserInfoDocument = gql`
  mutation GetUserInfo {
    getUserInfo(arg0: {}) {
      success
      defaultRoute
      routes
      licenses
      isAdvisor
      investors {
        investor
        pk
        email
      }
      returningInvestor
      errors {
        message
        code
        severity
      }
      userJob {
        accreditation
        investorType
        email
        emailVerified
        phoneVerified
        documentSigned
        contact {
          firstName
          lastName
          phone
          phoneVerified
          title
          company
          firstAddress
          secondAddress
          country
          city
          state
          zipCode
        }
      }
    }
  }
`;
export type GetUserInfoMutationFn = Apollo.MutationFunction<
  GetUserInfoMutation,
  GetUserInfoMutationVariables
>;

/**
 * __useGetUserInfoMutation__
 *
 * To run a mutation, you first call `useGetUserInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetUserInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getUserInfoMutation, { data, loading, error }] = useGetUserInfoMutation({
 *   variables: {
 *   },
 * });
 */
export function useGetUserInfoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GetUserInfoMutation,
    GetUserInfoMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<GetUserInfoMutation, GetUserInfoMutationVariables>(
    GetUserInfoDocument,
    options
  );
}
export type GetUserInfoMutationHookResult = ReturnType<
  typeof useGetUserInfoMutation
>;
export type GetUserInfoMutationResult =
  Apollo.MutationResult<GetUserInfoMutation>;
export type GetUserInfoMutationOptions = Apollo.BaseMutationOptions<
  GetUserInfoMutation,
  GetUserInfoMutationVariables
>;
export const MonitoringGetInvOverviewDocument = gql`
  mutation MonitoringGetInvOverview($data: MonitoringGetInvOverview!) {
    monitoringGetInvOverview(arg0: $data) {
      success
      location {
        address1
        address2
        city
        state
        postalCode
        country
        latitude
        longitude
      }
      overview {
        overviewSections {
          label
          content
        }
        images
      }
      document {
        title
        url
      }
      dateOverview
      image
      milestones {
        date
        title
        note
      }
      investorEntities
    }
  }
`;
export type MonitoringGetInvOverviewMutationFn = Apollo.MutationFunction<
  MonitoringGetInvOverviewMutation,
  MonitoringGetInvOverviewMutationVariables
>;

/**
 * __useMonitoringGetInvOverviewMutation__
 *
 * To run a mutation, you first call `useMonitoringGetInvOverviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMonitoringGetInvOverviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [monitoringGetInvOverviewMutation, { data, loading, error }] = useMonitoringGetInvOverviewMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useMonitoringGetInvOverviewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MonitoringGetInvOverviewMutation,
    MonitoringGetInvOverviewMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MonitoringGetInvOverviewMutation,
    MonitoringGetInvOverviewMutationVariables
  >(MonitoringGetInvOverviewDocument, options);
}
export type MonitoringGetInvOverviewMutationHookResult = ReturnType<
  typeof useMonitoringGetInvOverviewMutation
>;
export type MonitoringGetInvOverviewMutationResult =
  Apollo.MutationResult<MonitoringGetInvOverviewMutation>;
export type MonitoringGetInvOverviewMutationOptions =
  Apollo.BaseMutationOptions<
    MonitoringGetInvOverviewMutation,
    MonitoringGetInvOverviewMutationVariables
  >;
export const MonitoringGetDatePickerDocument = gql`
  mutation MonitoringGetDatePicker {
    monitoringGetDatePicker(arg0: {}) {
      success
      datePicker {
        date
        name
      }
    }
  }
`;
export type MonitoringGetDatePickerMutationFn = Apollo.MutationFunction<
  MonitoringGetDatePickerMutation,
  MonitoringGetDatePickerMutationVariables
>;

/**
 * __useMonitoringGetDatePickerMutation__
 *
 * To run a mutation, you first call `useMonitoringGetDatePickerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMonitoringGetDatePickerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [monitoringGetDatePickerMutation, { data, loading, error }] = useMonitoringGetDatePickerMutation({
 *   variables: {
 *   },
 * });
 */
export function useMonitoringGetDatePickerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MonitoringGetDatePickerMutation,
    MonitoringGetDatePickerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MonitoringGetDatePickerMutation,
    MonitoringGetDatePickerMutationVariables
  >(MonitoringGetDatePickerDocument, options);
}
export type MonitoringGetDatePickerMutationHookResult = ReturnType<
  typeof useMonitoringGetDatePickerMutation
>;
export type MonitoringGetDatePickerMutationResult =
  Apollo.MutationResult<MonitoringGetDatePickerMutation>;
export type MonitoringGetDatePickerMutationOptions = Apollo.BaseMutationOptions<
  MonitoringGetDatePickerMutation,
  MonitoringGetDatePickerMutationVariables
>;
export const MonitoringGetInvestorEntitiesDocument = gql`
  mutation MonitoringGetInvestorEntities(
    $data: MonitoringGetInvestorEntitiesParams!
  ) {
    monitoringGetInvestorEntities(arg0: $data) {
      success
      investorEntities {
        pk
        name
      }
      errors {
        code
        message
        severity
      }
    }
  }
`;
export type MonitoringGetInvestorEntitiesMutationFn = Apollo.MutationFunction<
  MonitoringGetInvestorEntitiesMutation,
  MonitoringGetInvestorEntitiesMutationVariables
>;

/**
 * __useMonitoringGetInvestorEntitiesMutation__
 *
 * To run a mutation, you first call `useMonitoringGetInvestorEntitiesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMonitoringGetInvestorEntitiesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [monitoringGetInvestorEntitiesMutation, { data, loading, error }] = useMonitoringGetInvestorEntitiesMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useMonitoringGetInvestorEntitiesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MonitoringGetInvestorEntitiesMutation,
    MonitoringGetInvestorEntitiesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MonitoringGetInvestorEntitiesMutation,
    MonitoringGetInvestorEntitiesMutationVariables
  >(MonitoringGetInvestorEntitiesDocument, options);
}
export type MonitoringGetInvestorEntitiesMutationHookResult = ReturnType<
  typeof useMonitoringGetInvestorEntitiesMutation
>;
export type MonitoringGetInvestorEntitiesMutationResult =
  Apollo.MutationResult<MonitoringGetInvestorEntitiesMutation>;
export type MonitoringGetInvestorEntitiesMutationOptions =
  Apollo.BaseMutationOptions<
    MonitoringGetInvestorEntitiesMutation,
    MonitoringGetInvestorEntitiesMutationVariables
  >;
export const GetDocumentsByUserDocument = gql`
  mutation GetDocumentsByUser($data: MonitoringGetInvDocuments!) {
    getDocumentsByUser(arg0: $data) {
      success
      errors {
        code
        message
        severity
      }
      table {
        title
        headers {
          value
          accessor
          sortable
          placeholder
          type
          hidden
          width {
            fixedWidth
          }
        }
        filters {
          label
          placeholder
          accessor
          options
        }
        tabs {
          accessor
          options
        }
        rows {
          value
          rowData {
            value
            type
            accessor
            isActive
            params {
              pk
              values
              topInvestor
              partnerPk
              url
            }
          }
        }
      }
    }
  }
`;
export type GetDocumentsByUserMutationFn = Apollo.MutationFunction<
  GetDocumentsByUserMutation,
  GetDocumentsByUserMutationVariables
>;

/**
 * __useGetDocumentsByUserMutation__
 *
 * To run a mutation, you first call `useGetDocumentsByUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentsByUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getDocumentsByUserMutation, { data, loading, error }] = useGetDocumentsByUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetDocumentsByUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GetDocumentsByUserMutation,
    GetDocumentsByUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GetDocumentsByUserMutation,
    GetDocumentsByUserMutationVariables
  >(GetDocumentsByUserDocument, options);
}
export type GetDocumentsByUserMutationHookResult = ReturnType<
  typeof useGetDocumentsByUserMutation
>;
export type GetDocumentsByUserMutationResult =
  Apollo.MutationResult<GetDocumentsByUserMutation>;
export type GetDocumentsByUserMutationOptions = Apollo.BaseMutationOptions<
  GetDocumentsByUserMutation,
  GetDocumentsByUserMutationVariables
>;
export const GetTaxDocumentsByUserDocument = gql`
  mutation GetTaxDocumentsByUser($data: MonitoringGetInvDocuments!) {
    getTaxDocumentsByUser(arg0: $data) {
      success
      errors {
        code
        message
        severity
      }
      table {
        title
        tabs {
          accessor
          options
        }
        filters {
          label
          placeholder
          accessor
          options
        }
        headers {
          value
          accessor
          sortable
          placeholder
          type
          hidden
          width {
            fixedWidth
          }
        }
        rows {
          value
          rowData {
            value
            type
            accessor
            isActive
            params {
              pk
              values
              topInvestor
              partnerPk
            }
          }
        }
      }
    }
  }
`;
export type GetTaxDocumentsByUserMutationFn = Apollo.MutationFunction<
  GetTaxDocumentsByUserMutation,
  GetTaxDocumentsByUserMutationVariables
>;

/**
 * __useGetTaxDocumentsByUserMutation__
 *
 * To run a mutation, you first call `useGetTaxDocumentsByUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetTaxDocumentsByUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getTaxDocumentsByUserMutation, { data, loading, error }] = useGetTaxDocumentsByUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetTaxDocumentsByUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GetTaxDocumentsByUserMutation,
    GetTaxDocumentsByUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GetTaxDocumentsByUserMutation,
    GetTaxDocumentsByUserMutationVariables
  >(GetTaxDocumentsByUserDocument, options);
}
export type GetTaxDocumentsByUserMutationHookResult = ReturnType<
  typeof useGetTaxDocumentsByUserMutation
>;
export type GetTaxDocumentsByUserMutationResult =
  Apollo.MutationResult<GetTaxDocumentsByUserMutation>;
export type GetTaxDocumentsByUserMutationOptions = Apollo.BaseMutationOptions<
  GetTaxDocumentsByUserMutation,
  GetTaxDocumentsByUserMutationVariables
>;
export const UserOnboardingConfigureContactMfaDocument = gql`
  mutation UserOnboardingConfigureContactMFA(
    $data: OnboardingConfigureContactMFAParam!
  ) {
    onboardingConfigureContactMFA(arg0: $data) {
      success
      reference
      errors {
        code
        message
        severity
      }
    }
  }
`;
export type UserOnboardingConfigureContactMfaMutationFn =
  Apollo.MutationFunction<
    UserOnboardingConfigureContactMfaMutation,
    UserOnboardingConfigureContactMfaMutationVariables
  >;

/**
 * __useUserOnboardingConfigureContactMfaMutation__
 *
 * To run a mutation, you first call `useUserOnboardingConfigureContactMfaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserOnboardingConfigureContactMfaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userOnboardingConfigureContactMfaMutation, { data, loading, error }] = useUserOnboardingConfigureContactMfaMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUserOnboardingConfigureContactMfaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserOnboardingConfigureContactMfaMutation,
    UserOnboardingConfigureContactMfaMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UserOnboardingConfigureContactMfaMutation,
    UserOnboardingConfigureContactMfaMutationVariables
  >(UserOnboardingConfigureContactMfaDocument, options);
}
export type UserOnboardingConfigureContactMfaMutationHookResult = ReturnType<
  typeof useUserOnboardingConfigureContactMfaMutation
>;
export type UserOnboardingConfigureContactMfaMutationResult =
  Apollo.MutationResult<UserOnboardingConfigureContactMfaMutation>;
export type UserOnboardingConfigureContactMfaMutationOptions =
  Apollo.BaseMutationOptions<
    UserOnboardingConfigureContactMfaMutation,
    UserOnboardingConfigureContactMfaMutationVariables
  >;
export const UserOnboardingCheckMfaCodeDocument = gql`
  mutation UserOnboardingCheckMfaCode($data: CheckMfaCodeParams!) {
    onboardingCheckMfaCode(arg0: $data) {
      success
      errors {
        code
        message
        severity
      }
    }
  }
`;
export type UserOnboardingCheckMfaCodeMutationFn = Apollo.MutationFunction<
  UserOnboardingCheckMfaCodeMutation,
  UserOnboardingCheckMfaCodeMutationVariables
>;

/**
 * __useUserOnboardingCheckMfaCodeMutation__
 *
 * To run a mutation, you first call `useUserOnboardingCheckMfaCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserOnboardingCheckMfaCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userOnboardingCheckMfaCodeMutation, { data, loading, error }] = useUserOnboardingCheckMfaCodeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUserOnboardingCheckMfaCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserOnboardingCheckMfaCodeMutation,
    UserOnboardingCheckMfaCodeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UserOnboardingCheckMfaCodeMutation,
    UserOnboardingCheckMfaCodeMutationVariables
  >(UserOnboardingCheckMfaCodeDocument, options);
}
export type UserOnboardingCheckMfaCodeMutationHookResult = ReturnType<
  typeof useUserOnboardingCheckMfaCodeMutation
>;
export type UserOnboardingCheckMfaCodeMutationResult =
  Apollo.MutationResult<UserOnboardingCheckMfaCodeMutation>;
export type UserOnboardingCheckMfaCodeMutationOptions =
  Apollo.BaseMutationOptions<
    UserOnboardingCheckMfaCodeMutation,
    UserOnboardingCheckMfaCodeMutationVariables
  >;
export const UserOnboardingResendMfaCodeDocument = gql`
  mutation UserOnboardingResendMfaCode($data: AuthResendMfaCode!) {
    onboardingResendMfaCode(arg0: $data) {
      success
      errors {
        code
        message
        severity
      }
    }
  }
`;
export type UserOnboardingResendMfaCodeMutationFn = Apollo.MutationFunction<
  UserOnboardingResendMfaCodeMutation,
  UserOnboardingResendMfaCodeMutationVariables
>;

/**
 * __useUserOnboardingResendMfaCodeMutation__
 *
 * To run a mutation, you first call `useUserOnboardingResendMfaCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserOnboardingResendMfaCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userOnboardingResendMfaCodeMutation, { data, loading, error }] = useUserOnboardingResendMfaCodeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUserOnboardingResendMfaCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserOnboardingResendMfaCodeMutation,
    UserOnboardingResendMfaCodeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UserOnboardingResendMfaCodeMutation,
    UserOnboardingResendMfaCodeMutationVariables
  >(UserOnboardingResendMfaCodeDocument, options);
}
export type UserOnboardingResendMfaCodeMutationHookResult = ReturnType<
  typeof useUserOnboardingResendMfaCodeMutation
>;
export type UserOnboardingResendMfaCodeMutationResult =
  Apollo.MutationResult<UserOnboardingResendMfaCodeMutation>;
export type UserOnboardingResendMfaCodeMutationOptions =
  Apollo.BaseMutationOptions<
    UserOnboardingResendMfaCodeMutation,
    UserOnboardingResendMfaCodeMutationVariables
  >;
export const UserOnboardingResetPasswordDocument = gql`
  mutation UserOnboardingResetPassword($data: AuthResetPassword!) {
    onboardingResetPassword(arg0: $data) {
      success
      reference
      userId
      errors {
        code
        message
        severity
      }
    }
  }
`;
export type UserOnboardingResetPasswordMutationFn = Apollo.MutationFunction<
  UserOnboardingResetPasswordMutation,
  UserOnboardingResetPasswordMutationVariables
>;

/**
 * __useUserOnboardingResetPasswordMutation__
 *
 * To run a mutation, you first call `useUserOnboardingResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserOnboardingResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userOnboardingResetPasswordMutation, { data, loading, error }] = useUserOnboardingResetPasswordMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUserOnboardingResetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserOnboardingResetPasswordMutation,
    UserOnboardingResetPasswordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UserOnboardingResetPasswordMutation,
    UserOnboardingResetPasswordMutationVariables
  >(UserOnboardingResetPasswordDocument, options);
}
export type UserOnboardingResetPasswordMutationHookResult = ReturnType<
  typeof useUserOnboardingResetPasswordMutation
>;
export type UserOnboardingResetPasswordMutationResult =
  Apollo.MutationResult<UserOnboardingResetPasswordMutation>;
export type UserOnboardingResetPasswordMutationOptions =
  Apollo.BaseMutationOptions<
    UserOnboardingResetPasswordMutation,
    UserOnboardingResetPasswordMutationVariables
  >;
export const SalesrepUpdateNoteDocument = gql`
  mutation SalesrepUpdateNote($data: SalesrepUpdateNote!) {
    salesrepUpdateNote(arg0: $data) {
      success
      errors {
        code
        message
        severity
      }
    }
  }
`;
export type SalesrepUpdateNoteMutationFn = Apollo.MutationFunction<
  SalesrepUpdateNoteMutation,
  SalesrepUpdateNoteMutationVariables
>;

/**
 * __useSalesrepUpdateNoteMutation__
 *
 * To run a mutation, you first call `useSalesrepUpdateNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSalesrepUpdateNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [salesrepUpdateNoteMutation, { data, loading, error }] = useSalesrepUpdateNoteMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSalesrepUpdateNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SalesrepUpdateNoteMutation,
    SalesrepUpdateNoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SalesrepUpdateNoteMutation,
    SalesrepUpdateNoteMutationVariables
  >(SalesrepUpdateNoteDocument, options);
}
export type SalesrepUpdateNoteMutationHookResult = ReturnType<
  typeof useSalesrepUpdateNoteMutation
>;
export type SalesrepUpdateNoteMutationResult =
  Apollo.MutationResult<SalesrepUpdateNoteMutation>;
export type SalesrepUpdateNoteMutationOptions = Apollo.BaseMutationOptions<
  SalesrepUpdateNoteMutation,
  SalesrepUpdateNoteMutationVariables
>;
export const SalesrepCreateNoteDocument = gql`
  mutation SalesrepCreateNote($data: SalesrepCreateNote!) {
    salesrepCreateNote(arg0: $data) {
      success
      errors {
        code
        message
        severity
      }
    }
  }
`;
export type SalesrepCreateNoteMutationFn = Apollo.MutationFunction<
  SalesrepCreateNoteMutation,
  SalesrepCreateNoteMutationVariables
>;

/**
 * __useSalesrepCreateNoteMutation__
 *
 * To run a mutation, you first call `useSalesrepCreateNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSalesrepCreateNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [salesrepCreateNoteMutation, { data, loading, error }] = useSalesrepCreateNoteMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSalesrepCreateNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SalesrepCreateNoteMutation,
    SalesrepCreateNoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SalesrepCreateNoteMutation,
    SalesrepCreateNoteMutationVariables
  >(SalesrepCreateNoteDocument, options);
}
export type SalesrepCreateNoteMutationHookResult = ReturnType<
  typeof useSalesrepCreateNoteMutation
>;
export type SalesrepCreateNoteMutationResult =
  Apollo.MutationResult<SalesrepCreateNoteMutation>;
export type SalesrepCreateNoteMutationOptions = Apollo.BaseMutationOptions<
  SalesrepCreateNoteMutation,
  SalesrepCreateNoteMutationVariables
>;
export const SalesrepDeleteNotesDocument = gql`
  mutation SalesrepDeleteNotes($data: SalesrepDeleteNotes!) {
    salesrepDeleteNotes(arg0: $data) {
      success
      errors {
        code
        message
        severity
      }
    }
  }
`;
export type SalesrepDeleteNotesMutationFn = Apollo.MutationFunction<
  SalesrepDeleteNotesMutation,
  SalesrepDeleteNotesMutationVariables
>;

/**
 * __useSalesrepDeleteNotesMutation__
 *
 * To run a mutation, you first call `useSalesrepDeleteNotesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSalesrepDeleteNotesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [salesrepDeleteNotesMutation, { data, loading, error }] = useSalesrepDeleteNotesMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSalesrepDeleteNotesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SalesrepDeleteNotesMutation,
    SalesrepDeleteNotesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SalesrepDeleteNotesMutation,
    SalesrepDeleteNotesMutationVariables
  >(SalesrepDeleteNotesDocument, options);
}
export type SalesrepDeleteNotesMutationHookResult = ReturnType<
  typeof useSalesrepDeleteNotesMutation
>;
export type SalesrepDeleteNotesMutationResult =
  Apollo.MutationResult<SalesrepDeleteNotesMutation>;
export type SalesrepDeleteNotesMutationOptions = Apollo.BaseMutationOptions<
  SalesrepDeleteNotesMutation,
  SalesrepDeleteNotesMutationVariables
>;
export const SalesrepSetNewPasswordDocument = gql`
  mutation SalesrepSetNewPassword($data: SalesrepSetNewPassword!) {
    salesrepSetNewPassword(arg0: $data) {
      success
      errors {
        code
        message
        severity
      }
    }
  }
`;
export type SalesrepSetNewPasswordMutationFn = Apollo.MutationFunction<
  SalesrepSetNewPasswordMutation,
  SalesrepSetNewPasswordMutationVariables
>;

/**
 * __useSalesrepSetNewPasswordMutation__
 *
 * To run a mutation, you first call `useSalesrepSetNewPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSalesrepSetNewPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [salesrepSetNewPasswordMutation, { data, loading, error }] = useSalesrepSetNewPasswordMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSalesrepSetNewPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SalesrepSetNewPasswordMutation,
    SalesrepSetNewPasswordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SalesrepSetNewPasswordMutation,
    SalesrepSetNewPasswordMutationVariables
  >(SalesrepSetNewPasswordDocument, options);
}
export type SalesrepSetNewPasswordMutationHookResult = ReturnType<
  typeof useSalesrepSetNewPasswordMutation
>;
export type SalesrepSetNewPasswordMutationResult =
  Apollo.MutationResult<SalesrepSetNewPasswordMutation>;
export type SalesrepSetNewPasswordMutationOptions = Apollo.BaseMutationOptions<
  SalesrepSetNewPasswordMutation,
  SalesrepSetNewPasswordMutationVariables
>;
export const SalesrepCreateProspectDocument = gql`
  mutation SalesrepCreateProspect($data: SalesrepCreateProspect!) {
    salesrepCreateProspect(arg0: $data) {
      success
      info
      errors {
        code
        message
        severity
      }
    }
  }
`;
export type SalesrepCreateProspectMutationFn = Apollo.MutationFunction<
  SalesrepCreateProspectMutation,
  SalesrepCreateProspectMutationVariables
>;

/**
 * __useSalesrepCreateProspectMutation__
 *
 * To run a mutation, you first call `useSalesrepCreateProspectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSalesrepCreateProspectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [salesrepCreateProspectMutation, { data, loading, error }] = useSalesrepCreateProspectMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSalesrepCreateProspectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SalesrepCreateProspectMutation,
    SalesrepCreateProspectMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SalesrepCreateProspectMutation,
    SalesrepCreateProspectMutationVariables
  >(SalesrepCreateProspectDocument, options);
}
export type SalesrepCreateProspectMutationHookResult = ReturnType<
  typeof useSalesrepCreateProspectMutation
>;
export type SalesrepCreateProspectMutationResult =
  Apollo.MutationResult<SalesrepCreateProspectMutation>;
export type SalesrepCreateProspectMutationOptions = Apollo.BaseMutationOptions<
  SalesrepCreateProspectMutation,
  SalesrepCreateProspectMutationVariables
>;
export const SalesrepCreateAdvisorDocument = gql`
  mutation SalesrepCreateAdvisor($data: SalesrepCreateAdvisor!) {
    salesrepCreateAdvisor(arg0: $data) {
      success
      info
      errors {
        code
        message
        severity
      }
    }
  }
`;
export type SalesrepCreateAdvisorMutationFn = Apollo.MutationFunction<
  SalesrepCreateAdvisorMutation,
  SalesrepCreateAdvisorMutationVariables
>;

/**
 * __useSalesrepCreateAdvisorMutation__
 *
 * To run a mutation, you first call `useSalesrepCreateAdvisorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSalesrepCreateAdvisorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [salesrepCreateAdvisorMutation, { data, loading, error }] = useSalesrepCreateAdvisorMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSalesrepCreateAdvisorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SalesrepCreateAdvisorMutation,
    SalesrepCreateAdvisorMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SalesrepCreateAdvisorMutation,
    SalesrepCreateAdvisorMutationVariables
  >(SalesrepCreateAdvisorDocument, options);
}
export type SalesrepCreateAdvisorMutationHookResult = ReturnType<
  typeof useSalesrepCreateAdvisorMutation
>;
export type SalesrepCreateAdvisorMutationResult =
  Apollo.MutationResult<SalesrepCreateAdvisorMutation>;
export type SalesrepCreateAdvisorMutationOptions = Apollo.BaseMutationOptions<
  SalesrepCreateAdvisorMutation,
  SalesrepCreateAdvisorMutationVariables
>;
export const SalesrepCreateContactDocument = gql`
  mutation SalesrepCreateContact($data: SalesrepCreateContact!) {
    salesrepCreateContact(arg0: $data) {
      success
      errors {
        code
        message
        severity
      }
    }
  }
`;
export type SalesrepCreateContactMutationFn = Apollo.MutationFunction<
  SalesrepCreateContactMutation,
  SalesrepCreateContactMutationVariables
>;

/**
 * __useSalesrepCreateContactMutation__
 *
 * To run a mutation, you first call `useSalesrepCreateContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSalesrepCreateContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [salesrepCreateContactMutation, { data, loading, error }] = useSalesrepCreateContactMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSalesrepCreateContactMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SalesrepCreateContactMutation,
    SalesrepCreateContactMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SalesrepCreateContactMutation,
    SalesrepCreateContactMutationVariables
  >(SalesrepCreateContactDocument, options);
}
export type SalesrepCreateContactMutationHookResult = ReturnType<
  typeof useSalesrepCreateContactMutation
>;
export type SalesrepCreateContactMutationResult =
  Apollo.MutationResult<SalesrepCreateContactMutation>;
export type SalesrepCreateContactMutationOptions = Apollo.BaseMutationOptions<
  SalesrepCreateContactMutation,
  SalesrepCreateContactMutationVariables
>;
export const SalesrepDeleteInvestorDocument = gql`
  mutation SalesrepDeleteInvestor($data: SalesrepDeleteInvestor!) {
    salesrepDeleteInvestor(arg0: $data) {
      success
      errors {
        code
        message
        severity
      }
    }
  }
`;
export type SalesrepDeleteInvestorMutationFn = Apollo.MutationFunction<
  SalesrepDeleteInvestorMutation,
  SalesrepDeleteInvestorMutationVariables
>;

/**
 * __useSalesrepDeleteInvestorMutation__
 *
 * To run a mutation, you first call `useSalesrepDeleteInvestorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSalesrepDeleteInvestorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [salesrepDeleteInvestorMutation, { data, loading, error }] = useSalesrepDeleteInvestorMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSalesrepDeleteInvestorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SalesrepDeleteInvestorMutation,
    SalesrepDeleteInvestorMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SalesrepDeleteInvestorMutation,
    SalesrepDeleteInvestorMutationVariables
  >(SalesrepDeleteInvestorDocument, options);
}
export type SalesrepDeleteInvestorMutationHookResult = ReturnType<
  typeof useSalesrepDeleteInvestorMutation
>;
export type SalesrepDeleteInvestorMutationResult =
  Apollo.MutationResult<SalesrepDeleteInvestorMutation>;
export type SalesrepDeleteInvestorMutationOptions = Apollo.BaseMutationOptions<
  SalesrepDeleteInvestorMutation,
  SalesrepDeleteInvestorMutationVariables
>;
export const SalesrepGetInvestorDocument = gql`
  query SalesrepGetInvestor($data: SalesrepGetInvestor!) {
    salesrepGetInvestor(arg0: $data) {
      success
      errors {
        code
        message
        severity
      }
      investorInfo {
        title
        tag
        content {
          title
          type
          values
        }
      }
    }
  }
`;

/**
 * __useSalesrepGetInvestorQuery__
 *
 * To run a query within a React component, call `useSalesrepGetInvestorQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesrepGetInvestorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesrepGetInvestorQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSalesrepGetInvestorQuery(
  baseOptions: Apollo.QueryHookOptions<
    SalesrepGetInvestorQuery,
    SalesrepGetInvestorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SalesrepGetInvestorQuery,
    SalesrepGetInvestorQueryVariables
  >(SalesrepGetInvestorDocument, options);
}
export function useSalesrepGetInvestorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SalesrepGetInvestorQuery,
    SalesrepGetInvestorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SalesrepGetInvestorQuery,
    SalesrepGetInvestorQueryVariables
  >(SalesrepGetInvestorDocument, options);
}
export type SalesrepGetInvestorQueryHookResult = ReturnType<
  typeof useSalesrepGetInvestorQuery
>;
export type SalesrepGetInvestorLazyQueryHookResult = ReturnType<
  typeof useSalesrepGetInvestorLazyQuery
>;
export type SalesrepGetInvestorQueryResult = Apollo.QueryResult<
  SalesrepGetInvestorQuery,
  SalesrepGetInvestorQueryVariables
>;
export const SalesrepGetEntityDocument = gql`
  query SalesrepGetEntity($data: SalesrepGetEntity!) {
    salesrepGetEntity(arg0: $data) {
      success
      errors {
        code
        message
        severity
      }
      entityName
      entity {
        title
        tag
        content {
          title
          type
          values
          customValues {
            title
            type
            values
          }
        }
      }
    }
  }
`;

/**
 * __useSalesrepGetEntityQuery__
 *
 * To run a query within a React component, call `useSalesrepGetEntityQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesrepGetEntityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesrepGetEntityQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSalesrepGetEntityQuery(
  baseOptions: Apollo.QueryHookOptions<
    SalesrepGetEntityQuery,
    SalesrepGetEntityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SalesrepGetEntityQuery,
    SalesrepGetEntityQueryVariables
  >(SalesrepGetEntityDocument, options);
}
export function useSalesrepGetEntityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SalesrepGetEntityQuery,
    SalesrepGetEntityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SalesrepGetEntityQuery,
    SalesrepGetEntityQueryVariables
  >(SalesrepGetEntityDocument, options);
}
export type SalesrepGetEntityQueryHookResult = ReturnType<
  typeof useSalesrepGetEntityQuery
>;
export type SalesrepGetEntityLazyQueryHookResult = ReturnType<
  typeof useSalesrepGetEntityLazyQuery
>;
export type SalesrepGetEntityQueryResult = Apollo.QueryResult<
  SalesrepGetEntityQuery,
  SalesrepGetEntityQueryVariables
>;
export const SalesrepGetInvestorsDocument = gql`
  query SalesrepGetInvestors($data: SalesrepGetInvestors!) {
    salesrepGetInvestors(arg0: $data) {
      success
      errors {
        code
        message
        severity
      }
      data {
        table {
          title
          filters {
            label
            placeholder
            accessor
            options
          }
          tabs {
            accessor
            options
          }
          headers {
            value
            accessor
            sortable
            placeholder
            type
            hidden
            width {
              fixedWidth
            }
          }
          rows {
            value
            rowData {
              value
              type
              accessor
              isActive
              params {
                pk
                values
                topInvestor
                partnerPk
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useSalesrepGetInvestorsQuery__
 *
 * To run a query within a React component, call `useSalesrepGetInvestorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesrepGetInvestorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesrepGetInvestorsQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSalesrepGetInvestorsQuery(
  baseOptions: Apollo.QueryHookOptions<
    SalesrepGetInvestorsQuery,
    SalesrepGetInvestorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SalesrepGetInvestorsQuery,
    SalesrepGetInvestorsQueryVariables
  >(SalesrepGetInvestorsDocument, options);
}
export function useSalesrepGetInvestorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SalesrepGetInvestorsQuery,
    SalesrepGetInvestorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SalesrepGetInvestorsQuery,
    SalesrepGetInvestorsQueryVariables
  >(SalesrepGetInvestorsDocument, options);
}
export type SalesrepGetInvestorsQueryHookResult = ReturnType<
  typeof useSalesrepGetInvestorsQuery
>;
export type SalesrepGetInvestorsLazyQueryHookResult = ReturnType<
  typeof useSalesrepGetInvestorsLazyQuery
>;
export type SalesrepGetInvestorsQueryResult = Apollo.QueryResult<
  SalesrepGetInvestorsQuery,
  SalesrepGetInvestorsQueryVariables
>;
export const SalesrepGetAdvisorsDocument = gql`
  query SalesrepGetAdvisors($data: SalesrepGetAdvisors!) {
    salesrepGetAdvisors(arg0: $data) {
      success
      errors {
        code
        message
        severity
      }
      data {
        table {
          title
          filters {
            label
            placeholder
            accessor
            options
          }
          tabs {
            accessor
            options
          }
          headers {
            value
            accessor
            sortable
            placeholder
            type
            hidden
            width {
              fixedWidth
            }
          }
          rows {
            value
            rowData {
              value
              type
              accessor
              isActive
              params {
                pk
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useSalesrepGetAdvisorsQuery__
 *
 * To run a query within a React component, call `useSalesrepGetAdvisorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesrepGetAdvisorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesrepGetAdvisorsQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSalesrepGetAdvisorsQuery(
  baseOptions: Apollo.QueryHookOptions<
    SalesrepGetAdvisorsQuery,
    SalesrepGetAdvisorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SalesrepGetAdvisorsQuery,
    SalesrepGetAdvisorsQueryVariables
  >(SalesrepGetAdvisorsDocument, options);
}
export function useSalesrepGetAdvisorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SalesrepGetAdvisorsQuery,
    SalesrepGetAdvisorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SalesrepGetAdvisorsQuery,
    SalesrepGetAdvisorsQueryVariables
  >(SalesrepGetAdvisorsDocument, options);
}
export type SalesrepGetAdvisorsQueryHookResult = ReturnType<
  typeof useSalesrepGetAdvisorsQuery
>;
export type SalesrepGetAdvisorsLazyQueryHookResult = ReturnType<
  typeof useSalesrepGetAdvisorsLazyQuery
>;
export type SalesrepGetAdvisorsQueryResult = Apollo.QueryResult<
  SalesrepGetAdvisorsQuery,
  SalesrepGetAdvisorsQueryVariables
>;
export const SalesrepGetInvestmentsDocument = gql`
  query SalesrepGetInvestments($data: SalesrepGetInvestments!) {
    salesrepGetInvestments(arg0: $data) {
      success
      errors {
        code
        message
        severity
      }
      offerings
      investorEntities {
        pk
        name
        date
        cmmt
        contrib
        distrib
        nav
        irr
        multiple
        investmentsLength
        investments {
          pk
          name
          image
          status
          cmmt
          contrib
          distrib
          nav
          irr
          multiple
          offeringDocs {
            name
            url
          }
          complianceDocs {
            name
            url
          }
        }
      }
    }
  }
`;

/**
 * __useSalesrepGetInvestmentsQuery__
 *
 * To run a query within a React component, call `useSalesrepGetInvestmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesrepGetInvestmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesrepGetInvestmentsQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSalesrepGetInvestmentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    SalesrepGetInvestmentsQuery,
    SalesrepGetInvestmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SalesrepGetInvestmentsQuery,
    SalesrepGetInvestmentsQueryVariables
  >(SalesrepGetInvestmentsDocument, options);
}
export function useSalesrepGetInvestmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SalesrepGetInvestmentsQuery,
    SalesrepGetInvestmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SalesrepGetInvestmentsQuery,
    SalesrepGetInvestmentsQueryVariables
  >(SalesrepGetInvestmentsDocument, options);
}
export type SalesrepGetInvestmentsQueryHookResult = ReturnType<
  typeof useSalesrepGetInvestmentsQuery
>;
export type SalesrepGetInvestmentsLazyQueryHookResult = ReturnType<
  typeof useSalesrepGetInvestmentsLazyQuery
>;
export type SalesrepGetInvestmentsQueryResult = Apollo.QueryResult<
  SalesrepGetInvestmentsQuery,
  SalesrepGetInvestmentsQueryVariables
>;
export const SalesrepGetInvestmentDocument = gql`
  query SalesrepGetInvestment($data: SalesrepGetInvestment!) {
    salesrepGetInvestment(arg0: $data) {
      success
      errors {
        code
        message
        severity
      }
      investments {
        pk
        name
        image
        cmmt
        contrib
        distrib
        nav
        multiple
        irr
      }
    }
  }
`;

/**
 * __useSalesrepGetInvestmentQuery__
 *
 * To run a query within a React component, call `useSalesrepGetInvestmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesrepGetInvestmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesrepGetInvestmentQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSalesrepGetInvestmentQuery(
  baseOptions: Apollo.QueryHookOptions<
    SalesrepGetInvestmentQuery,
    SalesrepGetInvestmentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SalesrepGetInvestmentQuery,
    SalesrepGetInvestmentQueryVariables
  >(SalesrepGetInvestmentDocument, options);
}
export function useSalesrepGetInvestmentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SalesrepGetInvestmentQuery,
    SalesrepGetInvestmentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SalesrepGetInvestmentQuery,
    SalesrepGetInvestmentQueryVariables
  >(SalesrepGetInvestmentDocument, options);
}
export type SalesrepGetInvestmentQueryHookResult = ReturnType<
  typeof useSalesrepGetInvestmentQuery
>;
export type SalesrepGetInvestmentLazyQueryHookResult = ReturnType<
  typeof useSalesrepGetInvestmentLazyQuery
>;
export type SalesrepGetInvestmentQueryResult = Apollo.QueryResult<
  SalesrepGetInvestmentQuery,
  SalesrepGetInvestmentQueryVariables
>;
export const SalesrepResetPasswordDocument = gql`
  mutation SalesrepResetPassword($data: SalesrepResetPassword!) {
    salesrepResetPassword(arg0: $data) {
      success
      errors {
        code
        message
        severity
      }
    }
  }
`;
export type SalesrepResetPasswordMutationFn = Apollo.MutationFunction<
  SalesrepResetPasswordMutation,
  SalesrepResetPasswordMutationVariables
>;

/**
 * __useSalesrepResetPasswordMutation__
 *
 * To run a mutation, you first call `useSalesrepResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSalesrepResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [salesrepResetPasswordMutation, { data, loading, error }] = useSalesrepResetPasswordMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSalesrepResetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SalesrepResetPasswordMutation,
    SalesrepResetPasswordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SalesrepResetPasswordMutation,
    SalesrepResetPasswordMutationVariables
  >(SalesrepResetPasswordDocument, options);
}
export type SalesrepResetPasswordMutationHookResult = ReturnType<
  typeof useSalesrepResetPasswordMutation
>;
export type SalesrepResetPasswordMutationResult =
  Apollo.MutationResult<SalesrepResetPasswordMutation>;
export type SalesrepResetPasswordMutationOptions = Apollo.BaseMutationOptions<
  SalesrepResetPasswordMutation,
  SalesrepResetPasswordMutationVariables
>;
export const SalesrepPauseAccountDocument = gql`
  mutation SalesrepPauseAccount($data: SalesrepPauseAccount!) {
    salesrepPauseAccount(arg0: $data) {
      success
      errors {
        code
        message
        severity
      }
    }
  }
`;
export type SalesrepPauseAccountMutationFn = Apollo.MutationFunction<
  SalesrepPauseAccountMutation,
  SalesrepPauseAccountMutationVariables
>;

/**
 * __useSalesrepPauseAccountMutation__
 *
 * To run a mutation, you first call `useSalesrepPauseAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSalesrepPauseAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [salesrepPauseAccountMutation, { data, loading, error }] = useSalesrepPauseAccountMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSalesrepPauseAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SalesrepPauseAccountMutation,
    SalesrepPauseAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SalesrepPauseAccountMutation,
    SalesrepPauseAccountMutationVariables
  >(SalesrepPauseAccountDocument, options);
}
export type SalesrepPauseAccountMutationHookResult = ReturnType<
  typeof useSalesrepPauseAccountMutation
>;
export type SalesrepPauseAccountMutationResult =
  Apollo.MutationResult<SalesrepPauseAccountMutation>;
export type SalesrepPauseAccountMutationOptions = Apollo.BaseMutationOptions<
  SalesrepPauseAccountMutation,
  SalesrepPauseAccountMutationVariables
>;
export const SalesrepActivateAccountDocument = gql`
  mutation SalesrepActivateAccount($data: SalesrepActivateAccount!) {
    salesrepActivateAccount(arg0: $data) {
      success
      errors {
        code
        message
        severity
      }
    }
  }
`;
export type SalesrepActivateAccountMutationFn = Apollo.MutationFunction<
  SalesrepActivateAccountMutation,
  SalesrepActivateAccountMutationVariables
>;

/**
 * __useSalesrepActivateAccountMutation__
 *
 * To run a mutation, you first call `useSalesrepActivateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSalesrepActivateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [salesrepActivateAccountMutation, { data, loading, error }] = useSalesrepActivateAccountMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSalesrepActivateAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SalesrepActivateAccountMutation,
    SalesrepActivateAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SalesrepActivateAccountMutation,
    SalesrepActivateAccountMutationVariables
  >(SalesrepActivateAccountDocument, options);
}
export type SalesrepActivateAccountMutationHookResult = ReturnType<
  typeof useSalesrepActivateAccountMutation
>;
export type SalesrepActivateAccountMutationResult =
  Apollo.MutationResult<SalesrepActivateAccountMutation>;
export type SalesrepActivateAccountMutationOptions = Apollo.BaseMutationOptions<
  SalesrepActivateAccountMutation,
  SalesrepActivateAccountMutationVariables
>;
export const SalesrepGetProspectsDocument = gql`
  query SalesrepGetProspects($data: SalesrepGetProspects!) {
    salesrepGetProspects(arg0: $data) {
      success
      errors {
        code
        message
        severity
      }
      entities
      properties
      status
      investments {
        id
        entityName
        identityType
        entityLogo
        prospectsListByEntity {
          id
          prospectName
          prospectImage
          entityOwner
          status
          submission
          date
          time
          goal {
            amount
            percentage
          }
          raised {
            amount
            percentage
          }
          unfunded {
            amount
            percentage
          }
          close {
            date
            in_days
          }
          offeringDocs {
            name
            url
          }
          complianceDocs {
            name
            url
          }
        }
      }
    }
  }
`;

/**
 * __useSalesrepGetProspectsQuery__
 *
 * To run a query within a React component, call `useSalesrepGetProspectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesrepGetProspectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesrepGetProspectsQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSalesrepGetProspectsQuery(
  baseOptions: Apollo.QueryHookOptions<
    SalesrepGetProspectsQuery,
    SalesrepGetProspectsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SalesrepGetProspectsQuery,
    SalesrepGetProspectsQueryVariables
  >(SalesrepGetProspectsDocument, options);
}
export function useSalesrepGetProspectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SalesrepGetProspectsQuery,
    SalesrepGetProspectsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SalesrepGetProspectsQuery,
    SalesrepGetProspectsQueryVariables
  >(SalesrepGetProspectsDocument, options);
}
export type SalesrepGetProspectsQueryHookResult = ReturnType<
  typeof useSalesrepGetProspectsQuery
>;
export type SalesrepGetProspectsLazyQueryHookResult = ReturnType<
  typeof useSalesrepGetProspectsLazyQuery
>;
export type SalesrepGetProspectsQueryResult = Apollo.QueryResult<
  SalesrepGetProspectsQuery,
  SalesrepGetProspectsQueryVariables
>;
export const SalesrepGetContactsDocument = gql`
  query SalesrepGetContacts($data: SalesrepGetContacts!) {
    salesrepGetContacts(arg0: $data) {
      success
      errors {
        code
        message
        severity
      }
      entitiesFilter
      contacts {
        pk
        contactName
        primary
        company
        title
        linkedIn
        email
        mobile
        address {
          name
          address1
          address2
          city
          state
          zip
          country
          block
          line
        }
        entities {
          pk
          entityName
          entityType
          entityLogo
          commPreference
          commTypes
          address {
            name
            address1
            address2
            city
            state
            zip
            country
            block
            line
          }
        }
      }
    }
  }
`;

/**
 * __useSalesrepGetContactsQuery__
 *
 * To run a query within a React component, call `useSalesrepGetContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesrepGetContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesrepGetContactsQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSalesrepGetContactsQuery(
  baseOptions: Apollo.QueryHookOptions<
    SalesrepGetContactsQuery,
    SalesrepGetContactsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SalesrepGetContactsQuery,
    SalesrepGetContactsQueryVariables
  >(SalesrepGetContactsDocument, options);
}
export function useSalesrepGetContactsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SalesrepGetContactsQuery,
    SalesrepGetContactsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SalesrepGetContactsQuery,
    SalesrepGetContactsQueryVariables
  >(SalesrepGetContactsDocument, options);
}
export type SalesrepGetContactsQueryHookResult = ReturnType<
  typeof useSalesrepGetContactsQuery
>;
export type SalesrepGetContactsLazyQueryHookResult = ReturnType<
  typeof useSalesrepGetContactsLazyQuery
>;
export type SalesrepGetContactsQueryResult = Apollo.QueryResult<
  SalesrepGetContactsQuery,
  SalesrepGetContactsQueryVariables
>;
export const SalesrepGetOfferingsDocument = gql`
  query SalesrepGetOfferings($data: SalesrepGetOfferings!) {
    salesrepGetOfferings(arg0: $data) {
      success
      errors {
        code
        message
        severity
      }
      table {
        title
        filters {
          label
          placeholder
          accessor
          options
        }
        tabs {
          accessor
          options
        }
        headers {
          value
          accessor
          sortable
          placeholder
          type
          hidden
          width {
            fixedWidth
          }
        }
        rows {
          value
          rowData {
            value
            type
            accessor
            isActive
            params {
              pk
              url
              goal
              fundAmount
              fundAmountPercent
              fundRemainingPercent
              color
            }
          }
        }
      }
    }
  }
`;

/**
 * __useSalesrepGetOfferingsQuery__
 *
 * To run a query within a React component, call `useSalesrepGetOfferingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesrepGetOfferingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesrepGetOfferingsQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSalesrepGetOfferingsQuery(
  baseOptions: Apollo.QueryHookOptions<
    SalesrepGetOfferingsQuery,
    SalesrepGetOfferingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SalesrepGetOfferingsQuery,
    SalesrepGetOfferingsQueryVariables
  >(SalesrepGetOfferingsDocument, options);
}
export function useSalesrepGetOfferingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SalesrepGetOfferingsQuery,
    SalesrepGetOfferingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SalesrepGetOfferingsQuery,
    SalesrepGetOfferingsQueryVariables
  >(SalesrepGetOfferingsDocument, options);
}
export type SalesrepGetOfferingsQueryHookResult = ReturnType<
  typeof useSalesrepGetOfferingsQuery
>;
export type SalesrepGetOfferingsLazyQueryHookResult = ReturnType<
  typeof useSalesrepGetOfferingsLazyQuery
>;
export type SalesrepGetOfferingsQueryResult = Apollo.QueryResult<
  SalesrepGetOfferingsQuery,
  SalesrepGetOfferingsQueryVariables
>;
export const SalesrepGetNotesDocument = gql`
  query SalesrepGetNotes($data: SalesrepGetNotes!) {
    salesrepGetNotes(arg0: $data) {
      success
      errors {
        code
        message
        severity
      }
      notes {
        pk
        date
        note
        subject
        type
        initials
        followUpType
        followUpDate
        followUpDone
        lastSaved
      }
      types
      followUpTypes
      filterTypes
      filterFollowUpTypes
    }
  }
`;

/**
 * __useSalesrepGetNotesQuery__
 *
 * To run a query within a React component, call `useSalesrepGetNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesrepGetNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesrepGetNotesQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSalesrepGetNotesQuery(
  baseOptions: Apollo.QueryHookOptions<
    SalesrepGetNotesQuery,
    SalesrepGetNotesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SalesrepGetNotesQuery, SalesrepGetNotesQueryVariables>(
    SalesrepGetNotesDocument,
    options
  );
}
export function useSalesrepGetNotesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SalesrepGetNotesQuery,
    SalesrepGetNotesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SalesrepGetNotesQuery,
    SalesrepGetNotesQueryVariables
  >(SalesrepGetNotesDocument, options);
}
export type SalesrepGetNotesQueryHookResult = ReturnType<
  typeof useSalesrepGetNotesQuery
>;
export type SalesrepGetNotesLazyQueryHookResult = ReturnType<
  typeof useSalesrepGetNotesLazyQuery
>;
export type SalesrepGetNotesQueryResult = Apollo.QueryResult<
  SalesrepGetNotesQuery,
  SalesrepGetNotesQueryVariables
>;
export const SalesrepGetEntitiesDocument = gql`
  query SalesrepGetEntities($data: SalesrepGetEntities!) {
    salesrepGetEntities(arg0: $data) {
      success
      errors {
        code
        message
        severity
      }
      entities {
        pk
        entityName
        entityType
        contactsLength
        address {
          name
          address1
          address2
          city
          state
          zip
          country
          line
          block
          blockFull
        }
        contacts {
          name
          primary
          email
          mobile
          commTypes
        }
        table {
          title
          filters {
            label
            placeholder
            accessor
            options
          }
          tabs {
            accessor
            options
          }
          headers {
            value
            accessor
            sortable
            placeholder
            type
            hidden
            width {
              fixedWidth
            }
          }
          rows {
            value
            rowData {
              value
              type
              accessor
              isActive
              params {
                pk
                values
                topInvestor
                partnerPk
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useSalesrepGetEntitiesQuery__
 *
 * To run a query within a React component, call `useSalesrepGetEntitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesrepGetEntitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesrepGetEntitiesQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSalesrepGetEntitiesQuery(
  baseOptions: Apollo.QueryHookOptions<
    SalesrepGetEntitiesQuery,
    SalesrepGetEntitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SalesrepGetEntitiesQuery,
    SalesrepGetEntitiesQueryVariables
  >(SalesrepGetEntitiesDocument, options);
}
export function useSalesrepGetEntitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SalesrepGetEntitiesQuery,
    SalesrepGetEntitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SalesrepGetEntitiesQuery,
    SalesrepGetEntitiesQueryVariables
  >(SalesrepGetEntitiesDocument, options);
}
export type SalesrepGetEntitiesQueryHookResult = ReturnType<
  typeof useSalesrepGetEntitiesQuery
>;
export type SalesrepGetEntitiesLazyQueryHookResult = ReturnType<
  typeof useSalesrepGetEntitiesLazyQuery
>;
export type SalesrepGetEntitiesQueryResult = Apollo.QueryResult<
  SalesrepGetEntitiesQuery,
  SalesrepGetEntitiesQueryVariables
>;
export const SalesrepGetWatchListDocument = gql`
  query SalesrepGetWatchList($data: SalesrepGetWatchList!) {
    salesrepGetWatchList(arg0: $data) {
      success
      errors {
        code
        message
        severity
      }
      properties {
        id
        propertyName
        propertyImage
        added {
          date
          inDays
        }
        lastViewed {
          date
          inDays
        }
        goal {
          amount
          percentage
        }
        raised {
          amount
          percentage
        }
        unfunded {
          amount
          percentage
        }
        close {
          date
          inDays
        }
      }
    }
  }
`;

/**
 * __useSalesrepGetWatchListQuery__
 *
 * To run a query within a React component, call `useSalesrepGetWatchListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesrepGetWatchListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesrepGetWatchListQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSalesrepGetWatchListQuery(
  baseOptions: Apollo.QueryHookOptions<
    SalesrepGetWatchListQuery,
    SalesrepGetWatchListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SalesrepGetWatchListQuery,
    SalesrepGetWatchListQueryVariables
  >(SalesrepGetWatchListDocument, options);
}
export function useSalesrepGetWatchListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SalesrepGetWatchListQuery,
    SalesrepGetWatchListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SalesrepGetWatchListQuery,
    SalesrepGetWatchListQueryVariables
  >(SalesrepGetWatchListDocument, options);
}
export type SalesrepGetWatchListQueryHookResult = ReturnType<
  typeof useSalesrepGetWatchListQuery
>;
export type SalesrepGetWatchListLazyQueryHookResult = ReturnType<
  typeof useSalesrepGetWatchListLazyQuery
>;
export type SalesrepGetWatchListQueryResult = Apollo.QueryResult<
  SalesrepGetWatchListQuery,
  SalesrepGetWatchListQueryVariables
>;
export const SalesrepGetOfferingDetailsDocument = gql`
  query SalesrepGetOfferingDetails($data: GetOfferingDetails!) {
    salesrepGetOfferingDetails(arg0: $data) {
      success
      offering {
        webOfferingPK
        offeringTitle
        offeringType
        percentFunded
        content {
          description
          fundTarget
          fundedAmount
          closeDate
          investmentType {
            label
            selectedValue
            options
          }
          strategy {
            label
            selectedValue
            options
          }
          flex {
            label
            selectedValue
            options
          }
          sponsorDescription
          introduction
          location {
            address1
            address2
            city
            state
            postalCode
            country
            lat
            long
            mapZoom
          }
          widgets {
            label
            type
            selected {
              label
              value
              percentage
              row
              col
              topLine
              fileName
              docPk
              type
              url
              order
            }
          }
        }
        galleryImages {
          label
          subLabel
          imageWidgets {
            label
            url
            caption
            altText
            order
          }
        }
        otherOpportunities {
          webOfferingPK
          type
          offeringTitle
          fundTarget
          percentFunded
          closeDate
          imageUrls {
            pk
            label
            caption
            url
            altText
            order
          }
          filterDataWidgets {
            label
            selectedValue
          }
        }
      }
      errors {
        code
        message
        severity
      }
    }
  }
`;

/**
 * __useSalesrepGetOfferingDetailsQuery__
 *
 * To run a query within a React component, call `useSalesrepGetOfferingDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesrepGetOfferingDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesrepGetOfferingDetailsQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSalesrepGetOfferingDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    SalesrepGetOfferingDetailsQuery,
    SalesrepGetOfferingDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SalesrepGetOfferingDetailsQuery,
    SalesrepGetOfferingDetailsQueryVariables
  >(SalesrepGetOfferingDetailsDocument, options);
}
export function useSalesrepGetOfferingDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SalesrepGetOfferingDetailsQuery,
    SalesrepGetOfferingDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SalesrepGetOfferingDetailsQuery,
    SalesrepGetOfferingDetailsQueryVariables
  >(SalesrepGetOfferingDetailsDocument, options);
}
export type SalesrepGetOfferingDetailsQueryHookResult = ReturnType<
  typeof useSalesrepGetOfferingDetailsQuery
>;
export type SalesrepGetOfferingDetailsLazyQueryHookResult = ReturnType<
  typeof useSalesrepGetOfferingDetailsLazyQuery
>;
export type SalesrepGetOfferingDetailsQueryResult = Apollo.QueryResult<
  SalesrepGetOfferingDetailsQuery,
  SalesrepGetOfferingDetailsQueryVariables
>;
export const SalesrepGetContactDetailsDocument = gql`
  query SalesrepGetContactDetails($data: SalesrepGetContact!) {
    salesrepGetContact(arg0: $data) {
      success
      pk
      contactName
      parentName
      groups {
        title
        content {
          title
          type
          values
        }
      }
      errors {
        code
        message
        severity
      }
    }
  }
`;

/**
 * __useSalesrepGetContactDetailsQuery__
 *
 * To run a query within a React component, call `useSalesrepGetContactDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesrepGetContactDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesrepGetContactDetailsQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSalesrepGetContactDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    SalesrepGetContactDetailsQuery,
    SalesrepGetContactDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SalesrepGetContactDetailsQuery,
    SalesrepGetContactDetailsQueryVariables
  >(SalesrepGetContactDetailsDocument, options);
}
export function useSalesrepGetContactDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SalesrepGetContactDetailsQuery,
    SalesrepGetContactDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SalesrepGetContactDetailsQuery,
    SalesrepGetContactDetailsQueryVariables
  >(SalesrepGetContactDetailsDocument, options);
}
export type SalesrepGetContactDetailsQueryHookResult = ReturnType<
  typeof useSalesrepGetContactDetailsQuery
>;
export type SalesrepGetContactDetailsLazyQueryHookResult = ReturnType<
  typeof useSalesrepGetContactDetailsLazyQuery
>;
export type SalesrepGetContactDetailsQueryResult = Apollo.QueryResult<
  SalesrepGetContactDetailsQuery,
  SalesrepGetContactDetailsQueryVariables
>;
export const SalesrepGetAdvisorDocument = gql`
  query SalesrepGetAdvisor($data: SalesrepGetAdvisor!) {
    salesrepGetAdvisor(arg0: $data) {
      success
      errors {
        code
        message
        severity
      }
      investorInfo {
        title
        tag
        content {
          title
          type
          values
        }
      }
    }
  }
`;

/**
 * __useSalesrepGetAdvisorQuery__
 *
 * To run a query within a React component, call `useSalesrepGetAdvisorQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesrepGetAdvisorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesrepGetAdvisorQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSalesrepGetAdvisorQuery(
  baseOptions: Apollo.QueryHookOptions<
    SalesrepGetAdvisorQuery,
    SalesrepGetAdvisorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SalesrepGetAdvisorQuery,
    SalesrepGetAdvisorQueryVariables
  >(SalesrepGetAdvisorDocument, options);
}
export function useSalesrepGetAdvisorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SalesrepGetAdvisorQuery,
    SalesrepGetAdvisorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SalesrepGetAdvisorQuery,
    SalesrepGetAdvisorQueryVariables
  >(SalesrepGetAdvisorDocument, options);
}
export type SalesrepGetAdvisorQueryHookResult = ReturnType<
  typeof useSalesrepGetAdvisorQuery
>;
export type SalesrepGetAdvisorLazyQueryHookResult = ReturnType<
  typeof useSalesrepGetAdvisorLazyQuery
>;
export type SalesrepGetAdvisorQueryResult = Apollo.QueryResult<
  SalesrepGetAdvisorQuery,
  SalesrepGetAdvisorQueryVariables
>;
export const SalesrepDesktopGetEntitiesDocument = gql`
  query SalesrepDesktopGetEntities($data: SalesrepDesktopGetEntities!) {
    salesrepDesktopGetEntities(arg0: $data) {
      success
      errors {
        code
        message
        severity
      }
      entities {
        entityName
        entityDetails {
          title
          content {
            title
            type
            values
          }
        }
        table {
          title
          filters {
            label
            placeholder
            accessor
            options
          }
          tabs {
            accessor
            options
          }
          headers {
            value
            accessor
            sortable
            placeholder
            type
            hidden
            width {
              fixedWidth
            }
          }
          rows {
            value
            rowData {
              value
              type
              accessor
              isActive
              params {
                pk
                values
                topInvestor
                partnerPk
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useSalesrepDesktopGetEntitiesQuery__
 *
 * To run a query within a React component, call `useSalesrepDesktopGetEntitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesrepDesktopGetEntitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesrepDesktopGetEntitiesQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSalesrepDesktopGetEntitiesQuery(
  baseOptions: Apollo.QueryHookOptions<
    SalesrepDesktopGetEntitiesQuery,
    SalesrepDesktopGetEntitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SalesrepDesktopGetEntitiesQuery,
    SalesrepDesktopGetEntitiesQueryVariables
  >(SalesrepDesktopGetEntitiesDocument, options);
}
export function useSalesrepDesktopGetEntitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SalesrepDesktopGetEntitiesQuery,
    SalesrepDesktopGetEntitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SalesrepDesktopGetEntitiesQuery,
    SalesrepDesktopGetEntitiesQueryVariables
  >(SalesrepDesktopGetEntitiesDocument, options);
}
export type SalesrepDesktopGetEntitiesQueryHookResult = ReturnType<
  typeof useSalesrepDesktopGetEntitiesQuery
>;
export type SalesrepDesktopGetEntitiesLazyQueryHookResult = ReturnType<
  typeof useSalesrepDesktopGetEntitiesLazyQuery
>;
export type SalesrepDesktopGetEntitiesQueryResult = Apollo.QueryResult<
  SalesrepDesktopGetEntitiesQuery,
  SalesrepDesktopGetEntitiesQueryVariables
>;
export const SalesrepDesktopGetContactsDocument = gql`
  query SalesrepDesktopGetContacts($data: SalesrepDesktopGetContacts!) {
    salesrepDesktopGetContacts(arg0: $data) {
      success
      errors {
        code
        message
        severity
      }
      data {
        table {
          title
          filters {
            label
            placeholder
            accessor
            options
          }
          tabs {
            accessor
            options
          }
          headers {
            value
            accessor
            sortable
            placeholder
            type
            hidden
            width {
              fixedWidth
            }
          }
          rows {
            value
            rowData {
              value
              type
              accessor
              isActive
              params {
                pk
                values
                topInvestor
                partnerPk
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useSalesrepDesktopGetContactsQuery__
 *
 * To run a query within a React component, call `useSalesrepDesktopGetContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesrepDesktopGetContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesrepDesktopGetContactsQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSalesrepDesktopGetContactsQuery(
  baseOptions: Apollo.QueryHookOptions<
    SalesrepDesktopGetContactsQuery,
    SalesrepDesktopGetContactsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SalesrepDesktopGetContactsQuery,
    SalesrepDesktopGetContactsQueryVariables
  >(SalesrepDesktopGetContactsDocument, options);
}
export function useSalesrepDesktopGetContactsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SalesrepDesktopGetContactsQuery,
    SalesrepDesktopGetContactsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SalesrepDesktopGetContactsQuery,
    SalesrepDesktopGetContactsQueryVariables
  >(SalesrepDesktopGetContactsDocument, options);
}
export type SalesrepDesktopGetContactsQueryHookResult = ReturnType<
  typeof useSalesrepDesktopGetContactsQuery
>;
export type SalesrepDesktopGetContactsLazyQueryHookResult = ReturnType<
  typeof useSalesrepDesktopGetContactsLazyQuery
>;
export type SalesrepDesktopGetContactsQueryResult = Apollo.QueryResult<
  SalesrepDesktopGetContactsQuery,
  SalesrepDesktopGetContactsQueryVariables
>;
export const SalesrepDesktopGetInvestmentsDocument = gql`
  query SalesrepDesktopGetInvestments($data: SalesrepDesktopGetInvestments!) {
    salesrepDesktopGetInvestments(arg0: $data) {
      success
      errors {
        code
        message
        severity
      }
      investorEntities {
        entityName
        subTitle
        tags
        table {
          title
          filters {
            label
            placeholder
            accessor
            options
          }
          tabs {
            accessor
            options
          }
          headers {
            value
            accessor
            sortable
            placeholder
            type
            hidden
            width {
              fixedWidth
            }
          }
          rows {
            value
            rowData {
              value
              type
              accessor
              isActive
              params {
                pk
                values
                topInvestor
                partnerPk
              }
            }
          }
        }
      }
      table {
        title
        filters {
          label
          placeholder
          accessor
          options
        }
        tabs {
          accessor
          options
        }
        headers {
          value
          accessor
          sortable
          placeholder
          type
          hidden
          width {
            fixedWidth
          }
        }
        rows {
          value
          rowData {
            value
            type
            accessor
            isActive
            params {
              pk
              values
              topInvestor
              partnerPk
            }
          }
        }
      }
    }
  }
`;

/**
 * __useSalesrepDesktopGetInvestmentsQuery__
 *
 * To run a query within a React component, call `useSalesrepDesktopGetInvestmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesrepDesktopGetInvestmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesrepDesktopGetInvestmentsQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSalesrepDesktopGetInvestmentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    SalesrepDesktopGetInvestmentsQuery,
    SalesrepDesktopGetInvestmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SalesrepDesktopGetInvestmentsQuery,
    SalesrepDesktopGetInvestmentsQueryVariables
  >(SalesrepDesktopGetInvestmentsDocument, options);
}
export function useSalesrepDesktopGetInvestmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SalesrepDesktopGetInvestmentsQuery,
    SalesrepDesktopGetInvestmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SalesrepDesktopGetInvestmentsQuery,
    SalesrepDesktopGetInvestmentsQueryVariables
  >(SalesrepDesktopGetInvestmentsDocument, options);
}
export type SalesrepDesktopGetInvestmentsQueryHookResult = ReturnType<
  typeof useSalesrepDesktopGetInvestmentsQuery
>;
export type SalesrepDesktopGetInvestmentsLazyQueryHookResult = ReturnType<
  typeof useSalesrepDesktopGetInvestmentsLazyQuery
>;
export type SalesrepDesktopGetInvestmentsQueryResult = Apollo.QueryResult<
  SalesrepDesktopGetInvestmentsQuery,
  SalesrepDesktopGetInvestmentsQueryVariables
>;
export const SalesrepCheckContactNotDuplicateDocument = gql`
  query SalesrepCheckContactNotDuplicate(
    $data: SalesrepCheckContactNotDuplicate!
  ) {
    salesrepCheckContactNotDuplicate(arg0: $data) {
      success
      errors {
        code
        message
        severity
      }
      status
      info
    }
  }
`;

/**
 * __useSalesrepCheckContactNotDuplicateQuery__
 *
 * To run a query within a React component, call `useSalesrepCheckContactNotDuplicateQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesrepCheckContactNotDuplicateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesrepCheckContactNotDuplicateQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSalesrepCheckContactNotDuplicateQuery(
  baseOptions: Apollo.QueryHookOptions<
    SalesrepCheckContactNotDuplicateQuery,
    SalesrepCheckContactNotDuplicateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SalesrepCheckContactNotDuplicateQuery,
    SalesrepCheckContactNotDuplicateQueryVariables
  >(SalesrepCheckContactNotDuplicateDocument, options);
}
export function useSalesrepCheckContactNotDuplicateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SalesrepCheckContactNotDuplicateQuery,
    SalesrepCheckContactNotDuplicateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SalesrepCheckContactNotDuplicateQuery,
    SalesrepCheckContactNotDuplicateQueryVariables
  >(SalesrepCheckContactNotDuplicateDocument, options);
}
export type SalesrepCheckContactNotDuplicateQueryHookResult = ReturnType<
  typeof useSalesrepCheckContactNotDuplicateQuery
>;
export type SalesrepCheckContactNotDuplicateLazyQueryHookResult = ReturnType<
  typeof useSalesrepCheckContactNotDuplicateLazyQuery
>;
export type SalesrepCheckContactNotDuplicateQueryResult = Apollo.QueryResult<
  SalesrepCheckContactNotDuplicateQuery,
  SalesrepCheckContactNotDuplicateQueryVariables
>;
export const SalesrepCheckCompanyAndContactNotDuplicateDocument = gql`
  query SalesrepCheckCompanyAndContactNotDuplicate(
    $data: SalesrepCheckCompanyAndContactNotDuplicate!
  ) {
    salesrepCheckCompanyAndContactNotDuplicate(arg0: $data) {
      success
      errors {
        code
        message
        severity
      }
      status
      info
    }
  }
`;

/**
 * __useSalesrepCheckCompanyAndContactNotDuplicateQuery__
 *
 * To run a query within a React component, call `useSalesrepCheckCompanyAndContactNotDuplicateQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesrepCheckCompanyAndContactNotDuplicateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesrepCheckCompanyAndContactNotDuplicateQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSalesrepCheckCompanyAndContactNotDuplicateQuery(
  baseOptions: Apollo.QueryHookOptions<
    SalesrepCheckCompanyAndContactNotDuplicateQuery,
    SalesrepCheckCompanyAndContactNotDuplicateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SalesrepCheckCompanyAndContactNotDuplicateQuery,
    SalesrepCheckCompanyAndContactNotDuplicateQueryVariables
  >(SalesrepCheckCompanyAndContactNotDuplicateDocument, options);
}
export function useSalesrepCheckCompanyAndContactNotDuplicateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SalesrepCheckCompanyAndContactNotDuplicateQuery,
    SalesrepCheckCompanyAndContactNotDuplicateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SalesrepCheckCompanyAndContactNotDuplicateQuery,
    SalesrepCheckCompanyAndContactNotDuplicateQueryVariables
  >(SalesrepCheckCompanyAndContactNotDuplicateDocument, options);
}
export type SalesrepCheckCompanyAndContactNotDuplicateQueryHookResult =
  ReturnType<typeof useSalesrepCheckCompanyAndContactNotDuplicateQuery>;
export type SalesrepCheckCompanyAndContactNotDuplicateLazyQueryHookResult =
  ReturnType<typeof useSalesrepCheckCompanyAndContactNotDuplicateLazyQuery>;
export type SalesrepCheckCompanyAndContactNotDuplicateQueryResult =
  Apollo.QueryResult<
    SalesrepCheckCompanyAndContactNotDuplicateQuery,
    SalesrepCheckCompanyAndContactNotDuplicateQueryVariables
  >;
export const GetOfferingsDocument = gql`
  query GetOfferings($data: EmptyObject!) {
    getOfferings(arg0: $data) {
      success
      offerings {
        webOfferingPK
        offeringTitle
        type
        fundTarget
        percentFunded
        closeDate
        inWatchlist
        imageUrls {
          label
          url
          caption
          altText
          order
        }
        filterDataWidgets {
          label
          selectedValue
        }
        teaser
      }
      errors {
        code
        message
        severity
      }
    }
  }
`;

/**
 * __useGetOfferingsQuery__
 *
 * To run a query within a React component, call `useGetOfferingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOfferingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOfferingsQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetOfferingsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOfferingsQuery,
    GetOfferingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOfferingsQuery, GetOfferingsQueryVariables>(
    GetOfferingsDocument,
    options
  );
}
export function useGetOfferingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOfferingsQuery,
    GetOfferingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOfferingsQuery, GetOfferingsQueryVariables>(
    GetOfferingsDocument,
    options
  );
}
export type GetOfferingsQueryHookResult = ReturnType<
  typeof useGetOfferingsQuery
>;
export type GetOfferingsLazyQueryHookResult = ReturnType<
  typeof useGetOfferingsLazyQuery
>;
export type GetOfferingsQueryResult = Apollo.QueryResult<
  GetOfferingsQuery,
  GetOfferingsQueryVariables
>;
export const MonitoringGetMyListDocument = gql`
  mutation MonitoringGetMyList($data: MonitoringGetMyList!) {
    monitoringGetMyList(arg0: $data) {
      success
      offerings {
        pk
        name
        fundedAmountPercent
        closedDate
        investmentType
        strategy
        flexField
        images {
          url
          label
          order
          altText
          caption
        }
      }
    }
  }
`;
export type MonitoringGetMyListMutationFn = Apollo.MutationFunction<
  MonitoringGetMyListMutation,
  MonitoringGetMyListMutationVariables
>;

/**
 * __useMonitoringGetMyListMutation__
 *
 * To run a mutation, you first call `useMonitoringGetMyListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMonitoringGetMyListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [monitoringGetMyListMutation, { data, loading, error }] = useMonitoringGetMyListMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useMonitoringGetMyListMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MonitoringGetMyListMutation,
    MonitoringGetMyListMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MonitoringGetMyListMutation,
    MonitoringGetMyListMutationVariables
  >(MonitoringGetMyListDocument, options);
}
export type MonitoringGetMyListMutationHookResult = ReturnType<
  typeof useMonitoringGetMyListMutation
>;
export type MonitoringGetMyListMutationResult =
  Apollo.MutationResult<MonitoringGetMyListMutation>;
export type MonitoringGetMyListMutationOptions = Apollo.BaseMutationOptions<
  MonitoringGetMyListMutation,
  MonitoringGetMyListMutationVariables
>;
export const MonitoringGetInvTableDistribsDocument = gql`
  mutation MonitoringGetInvTableDistribs(
    $data: MonitoringGetInvTableDistribs!
  ) {
    monitoringGetInvTableDistribs(arg0: $data) {
      success
      cashflows {
        date
        name
        amt
      }
    }
  }
`;
export type MonitoringGetInvTableDistribsMutationFn = Apollo.MutationFunction<
  MonitoringGetInvTableDistribsMutation,
  MonitoringGetInvTableDistribsMutationVariables
>;

/**
 * __useMonitoringGetInvTableDistribsMutation__
 *
 * To run a mutation, you first call `useMonitoringGetInvTableDistribsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMonitoringGetInvTableDistribsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [monitoringGetInvTableDistribsMutation, { data, loading, error }] = useMonitoringGetInvTableDistribsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useMonitoringGetInvTableDistribsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MonitoringGetInvTableDistribsMutation,
    MonitoringGetInvTableDistribsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MonitoringGetInvTableDistribsMutation,
    MonitoringGetInvTableDistribsMutationVariables
  >(MonitoringGetInvTableDistribsDocument, options);
}
export type MonitoringGetInvTableDistribsMutationHookResult = ReturnType<
  typeof useMonitoringGetInvTableDistribsMutation
>;
export type MonitoringGetInvTableDistribsMutationResult =
  Apollo.MutationResult<MonitoringGetInvTableDistribsMutation>;
export type MonitoringGetInvTableDistribsMutationOptions =
  Apollo.BaseMutationOptions<
    MonitoringGetInvTableDistribsMutation,
    MonitoringGetInvTableDistribsMutationVariables
  >;
export const MonitoringGetInvTableContribsDocument = gql`
  mutation MonitoringGetInvTableContribs(
    $data: MonitoringGetInvTableContribs!
  ) {
    monitoringGetInvTableContribs(arg0: $data) {
      success
      cashflows {
        date
        name
        amt
      }
    }
  }
`;
export type MonitoringGetInvTableContribsMutationFn = Apollo.MutationFunction<
  MonitoringGetInvTableContribsMutation,
  MonitoringGetInvTableContribsMutationVariables
>;

/**
 * __useMonitoringGetInvTableContribsMutation__
 *
 * To run a mutation, you first call `useMonitoringGetInvTableContribsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMonitoringGetInvTableContribsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [monitoringGetInvTableContribsMutation, { data, loading, error }] = useMonitoringGetInvTableContribsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useMonitoringGetInvTableContribsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MonitoringGetInvTableContribsMutation,
    MonitoringGetInvTableContribsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MonitoringGetInvTableContribsMutation,
    MonitoringGetInvTableContribsMutationVariables
  >(MonitoringGetInvTableContribsDocument, options);
}
export type MonitoringGetInvTableContribsMutationHookResult = ReturnType<
  typeof useMonitoringGetInvTableContribsMutation
>;
export type MonitoringGetInvTableContribsMutationResult =
  Apollo.MutationResult<MonitoringGetInvTableContribsMutation>;
export type MonitoringGetInvTableContribsMutationOptions =
  Apollo.BaseMutationOptions<
    MonitoringGetInvTableContribsMutation,
    MonitoringGetInvTableContribsMutationVariables
  >;
export const MonitoringGetInvTableCommittedCapDocument = gql`
  mutation MonitoringGetInvTableCommittedCap(
    $data: MonitoringGetInvTableCommittedCap!
  ) {
    monitoringGetInvTableCommittedCap(arg0: $data) {
      success
      cashflows {
        date
        name
        amt
      }
    }
  }
`;
export type MonitoringGetInvTableCommittedCapMutationFn =
  Apollo.MutationFunction<
    MonitoringGetInvTableCommittedCapMutation,
    MonitoringGetInvTableCommittedCapMutationVariables
  >;

/**
 * __useMonitoringGetInvTableCommittedCapMutation__
 *
 * To run a mutation, you first call `useMonitoringGetInvTableCommittedCapMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMonitoringGetInvTableCommittedCapMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [monitoringGetInvTableCommittedCapMutation, { data, loading, error }] = useMonitoringGetInvTableCommittedCapMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useMonitoringGetInvTableCommittedCapMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MonitoringGetInvTableCommittedCapMutation,
    MonitoringGetInvTableCommittedCapMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MonitoringGetInvTableCommittedCapMutation,
    MonitoringGetInvTableCommittedCapMutationVariables
  >(MonitoringGetInvTableCommittedCapDocument, options);
}
export type MonitoringGetInvTableCommittedCapMutationHookResult = ReturnType<
  typeof useMonitoringGetInvTableCommittedCapMutation
>;
export type MonitoringGetInvTableCommittedCapMutationResult =
  Apollo.MutationResult<MonitoringGetInvTableCommittedCapMutation>;
export type MonitoringGetInvTableCommittedCapMutationOptions =
  Apollo.BaseMutationOptions<
    MonitoringGetInvTableCommittedCapMutation,
    MonitoringGetInvTableCommittedCapMutationVariables
  >;
export const MonitoringGetInvTableDocument = gql`
  mutation MonitoringGetInvTable($data: MonitoringGetInvTable!) {
    monitoringGetInvTable(arg0: $data) {
      success
      types
      statuses
      data {
        title
        tabs {
          accessor
          options
        }
        filters {
          options
          label
          accessor
          placeholder
        }
        headers {
          value
          inactiveValue
          accessor
          sortable
          placeholder
          type
          hidden
          width {
            fixedWidth
          }
          group
        }
        sections {
          title
          isActive
          isUnallocated
          rows {
            value
            rowData {
              value
              type
              accessor
              isActive
              params {
                fundPk
                values
                topInvestor
                partnerPk
                offeringType
                portfolioPk
                portfolio
                color
                investorEntityPk
                cashflows {
                  date
                  name
                  amt
                }
              }
            }
          }
        }
        footnotes
      }
    }
  }
`;
export type MonitoringGetInvTableMutationFn = Apollo.MutationFunction<
  MonitoringGetInvTableMutation,
  MonitoringGetInvTableMutationVariables
>;

/**
 * __useMonitoringGetInvTableMutation__
 *
 * To run a mutation, you first call `useMonitoringGetInvTableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMonitoringGetInvTableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [monitoringGetInvTableMutation, { data, loading, error }] = useMonitoringGetInvTableMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useMonitoringGetInvTableMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MonitoringGetInvTableMutation,
    MonitoringGetInvTableMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MonitoringGetInvTableMutation,
    MonitoringGetInvTableMutationVariables
  >(MonitoringGetInvTableDocument, options);
}
export type MonitoringGetInvTableMutationHookResult = ReturnType<
  typeof useMonitoringGetInvTableMutation
>;
export type MonitoringGetInvTableMutationResult =
  Apollo.MutationResult<MonitoringGetInvTableMutation>;
export type MonitoringGetInvTableMutationOptions = Apollo.BaseMutationOptions<
  MonitoringGetInvTableMutation,
  MonitoringGetInvTableMutationVariables
>;
export const MonitoringGetInvestmentOverviewDocument = gql`
  mutation MonitoringGetInvestmentOverview(
    $data: MonitoringGetInvestmentOverviewParams!
  ) {
    monitoringGetInvestmentOverview(arg0: $data) {
      success
      errors {
        code
        message
        severity
      }
      investmentOverview {
        card {
          quickLinkCard {
            title
            type
            footnote
            details {
              title
              type
              subTitle
              linkDetails {
                title
                link
                icon
                linkDetails {
                  title
                  link
                  icon
                  linkDetails {
                    title
                    link
                    icon
                    linkDetails {
                      title
                      link
                      icon
                    }
                  }
                }
              }
            }
          }
          donutChartCard {
            title
            titleOptions {
              dataPoint
              label
              hoverLabel
              key
            }
            donuts {
              title
              values {
                title
                value
                color
                legendIndex
                details {
                  title
                  subTitle
                }
              }
              key
              footnote
            }
          }
          lineGraphCard {
            title
            lines {
              title
              value {
                date
                amt
              }
            }
          }
          barGraphCard {
            title
            type
            legend {
              id
              title
              color
              textColor
            }
            bars {
              title
              subTitle1
              subTitle2
              values {
                value1
                value2
                value3
                irr
                multiple
                nav
                cmmt
              }
            }
          }
          kpiCard {
            title
            value
            subValue
            linkDetails {
              title
              link
              icon
            }
          }
        }
      }
    }
  }
`;
export type MonitoringGetInvestmentOverviewMutationFn = Apollo.MutationFunction<
  MonitoringGetInvestmentOverviewMutation,
  MonitoringGetInvestmentOverviewMutationVariables
>;

/**
 * __useMonitoringGetInvestmentOverviewMutation__
 *
 * To run a mutation, you first call `useMonitoringGetInvestmentOverviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMonitoringGetInvestmentOverviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [monitoringGetInvestmentOverviewMutation, { data, loading, error }] = useMonitoringGetInvestmentOverviewMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useMonitoringGetInvestmentOverviewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MonitoringGetInvestmentOverviewMutation,
    MonitoringGetInvestmentOverviewMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MonitoringGetInvestmentOverviewMutation,
    MonitoringGetInvestmentOverviewMutationVariables
  >(MonitoringGetInvestmentOverviewDocument, options);
}
export type MonitoringGetInvestmentOverviewMutationHookResult = ReturnType<
  typeof useMonitoringGetInvestmentOverviewMutation
>;
export type MonitoringGetInvestmentOverviewMutationResult =
  Apollo.MutationResult<MonitoringGetInvestmentOverviewMutation>;
export type MonitoringGetInvestmentOverviewMutationOptions =
  Apollo.BaseMutationOptions<
    MonitoringGetInvestmentOverviewMutation,
    MonitoringGetInvestmentOverviewMutationVariables
  >;
export const MonitoringGetCashFlowChartDocument = gql`
  query MonitoringGetCashFlowChart($data: MonitoringGetCashFlowChartParams!) {
    monitoringGetCashFlowChart(arg0: $data) {
      success
      cmmts {
        date
        amt
      }
      contribs {
        date
        amt
      }
      distribs {
        date
        amt
      }
    }
  }
`;

/**
 * __useMonitoringGetCashFlowChartQuery__
 *
 * To run a query within a React component, call `useMonitoringGetCashFlowChartQuery` and pass it any options that fit your needs.
 * When your component renders, `useMonitoringGetCashFlowChartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMonitoringGetCashFlowChartQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useMonitoringGetCashFlowChartQuery(
  baseOptions: Apollo.QueryHookOptions<
    MonitoringGetCashFlowChartQuery,
    MonitoringGetCashFlowChartQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MonitoringGetCashFlowChartQuery,
    MonitoringGetCashFlowChartQueryVariables
  >(MonitoringGetCashFlowChartDocument, options);
}
export function useMonitoringGetCashFlowChartLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MonitoringGetCashFlowChartQuery,
    MonitoringGetCashFlowChartQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MonitoringGetCashFlowChartQuery,
    MonitoringGetCashFlowChartQueryVariables
  >(MonitoringGetCashFlowChartDocument, options);
}
export type MonitoringGetCashFlowChartQueryHookResult = ReturnType<
  typeof useMonitoringGetCashFlowChartQuery
>;
export type MonitoringGetCashFlowChartLazyQueryHookResult = ReturnType<
  typeof useMonitoringGetCashFlowChartLazyQuery
>;
export type MonitoringGetCashFlowChartQueryResult = Apollo.QueryResult<
  MonitoringGetCashFlowChartQuery,
  MonitoringGetCashFlowChartQueryVariables
>;
export const MonitoringGetAllocationChartDocument = gql`
  query MonitoringGetAllocationChart(
    $data: MonitoringGetAllocationChartParams!
  ) {
    monitoringGetAllocationChart(arg0: $data) {
      success
      commercial
      industrial
      multifamily
      retail
      other
    }
  }
`;

/**
 * __useMonitoringGetAllocationChartQuery__
 *
 * To run a query within a React component, call `useMonitoringGetAllocationChartQuery` and pass it any options that fit your needs.
 * When your component renders, `useMonitoringGetAllocationChartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMonitoringGetAllocationChartQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useMonitoringGetAllocationChartQuery(
  baseOptions: Apollo.QueryHookOptions<
    MonitoringGetAllocationChartQuery,
    MonitoringGetAllocationChartQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MonitoringGetAllocationChartQuery,
    MonitoringGetAllocationChartQueryVariables
  >(MonitoringGetAllocationChartDocument, options);
}
export function useMonitoringGetAllocationChartLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MonitoringGetAllocationChartQuery,
    MonitoringGetAllocationChartQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MonitoringGetAllocationChartQuery,
    MonitoringGetAllocationChartQueryVariables
  >(MonitoringGetAllocationChartDocument, options);
}
export type MonitoringGetAllocationChartQueryHookResult = ReturnType<
  typeof useMonitoringGetAllocationChartQuery
>;
export type MonitoringGetAllocationChartLazyQueryHookResult = ReturnType<
  typeof useMonitoringGetAllocationChartLazyQuery
>;
export type MonitoringGetAllocationChartQueryResult = Apollo.QueryResult<
  MonitoringGetAllocationChartQuery,
  MonitoringGetAllocationChartQueryVariables
>;
export const MonitoringGetOccupancyChartDocument = gql`
  query MonitoringGetOccupancyChart($data: MonitoringGetOccupancyChartParams!) {
    monitoringGetOccupancyChart(arg0: $data) {
      success
      offerings {
        pk
        name
      }
    }
  }
`;

/**
 * __useMonitoringGetOccupancyChartQuery__
 *
 * To run a query within a React component, call `useMonitoringGetOccupancyChartQuery` and pass it any options that fit your needs.
 * When your component renders, `useMonitoringGetOccupancyChartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMonitoringGetOccupancyChartQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useMonitoringGetOccupancyChartQuery(
  baseOptions: Apollo.QueryHookOptions<
    MonitoringGetOccupancyChartQuery,
    MonitoringGetOccupancyChartQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MonitoringGetOccupancyChartQuery,
    MonitoringGetOccupancyChartQueryVariables
  >(MonitoringGetOccupancyChartDocument, options);
}
export function useMonitoringGetOccupancyChartLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MonitoringGetOccupancyChartQuery,
    MonitoringGetOccupancyChartQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MonitoringGetOccupancyChartQuery,
    MonitoringGetOccupancyChartQueryVariables
  >(MonitoringGetOccupancyChartDocument, options);
}
export type MonitoringGetOccupancyChartQueryHookResult = ReturnType<
  typeof useMonitoringGetOccupancyChartQuery
>;
export type MonitoringGetOccupancyChartLazyQueryHookResult = ReturnType<
  typeof useMonitoringGetOccupancyChartLazyQuery
>;
export type MonitoringGetOccupancyChartQueryResult = Apollo.QueryResult<
  MonitoringGetOccupancyChartQuery,
  MonitoringGetOccupancyChartQueryVariables
>;
export const CreateInvestmentNewInvestmentDocument = gql`
  mutation CreateInvestmentNewInvestment($data: CreateInvestmentInput!) {
    newInvestment(arg0: $data) {
      success
      errors {
        code
        message
        severity
      }
      step {
        maxStepAllowed {
          index
          name
        }
      }
    }
  }
`;
export type CreateInvestmentNewInvestmentMutationFn = Apollo.MutationFunction<
  CreateInvestmentNewInvestmentMutation,
  CreateInvestmentNewInvestmentMutationVariables
>;

/**
 * __useCreateInvestmentNewInvestmentMutation__
 *
 * To run a mutation, you first call `useCreateInvestmentNewInvestmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInvestmentNewInvestmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInvestmentNewInvestmentMutation, { data, loading, error }] = useCreateInvestmentNewInvestmentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateInvestmentNewInvestmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateInvestmentNewInvestmentMutation,
    CreateInvestmentNewInvestmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateInvestmentNewInvestmentMutation,
    CreateInvestmentNewInvestmentMutationVariables
  >(CreateInvestmentNewInvestmentDocument, options);
}
export type CreateInvestmentNewInvestmentMutationHookResult = ReturnType<
  typeof useCreateInvestmentNewInvestmentMutation
>;
export type CreateInvestmentNewInvestmentMutationResult =
  Apollo.MutationResult<CreateInvestmentNewInvestmentMutation>;
export type CreateInvestmentNewInvestmentMutationOptions =
  Apollo.BaseMutationOptions<
    CreateInvestmentNewInvestmentMutation,
    CreateInvestmentNewInvestmentMutationVariables
  >;
export const CheckInvest2faCodeDocument = gql`
  mutation CheckInvest2faCode($data: CreateInvestmentCheckTFA!) {
    checkTfaCode(arg0: $data) {
      success
      errors {
        code
        message
        severity
      }
      step {
        maxStepAllowed {
          name
          index
        }
      }
    }
  }
`;
export type CheckInvest2faCodeMutationFn = Apollo.MutationFunction<
  CheckInvest2faCodeMutation,
  CheckInvest2faCodeMutationVariables
>;

/**
 * __useCheckInvest2faCodeMutation__
 *
 * To run a mutation, you first call `useCheckInvest2faCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckInvest2faCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkInvest2faCodeMutation, { data, loading, error }] = useCheckInvest2faCodeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCheckInvest2faCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CheckInvest2faCodeMutation,
    CheckInvest2faCodeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CheckInvest2faCodeMutation,
    CheckInvest2faCodeMutationVariables
  >(CheckInvest2faCodeDocument, options);
}
export type CheckInvest2faCodeMutationHookResult = ReturnType<
  typeof useCheckInvest2faCodeMutation
>;
export type CheckInvest2faCodeMutationResult =
  Apollo.MutationResult<CheckInvest2faCodeMutation>;
export type CheckInvest2faCodeMutationOptions = Apollo.BaseMutationOptions<
  CheckInvest2faCodeMutation,
  CheckInvest2faCodeMutationVariables
>;
export const InvResendSmsDocument = gql`
  mutation InvResendSms {
    invResendSms(arg0: {}) {
      success
      errors {
        code
        message
        severity
      }
      step {
        maxStepAllowed {
          index
          name
        }
      }
    }
  }
`;
export type InvResendSmsMutationFn = Apollo.MutationFunction<
  InvResendSmsMutation,
  InvResendSmsMutationVariables
>;

/**
 * __useInvResendSmsMutation__
 *
 * To run a mutation, you first call `useInvResendSmsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvResendSmsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [invResendSmsMutation, { data, loading, error }] = useInvResendSmsMutation({
 *   variables: {
 *   },
 * });
 */
export function useInvResendSmsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InvResendSmsMutation,
    InvResendSmsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InvResendSmsMutation,
    InvResendSmsMutationVariables
  >(InvResendSmsDocument, options);
}
export type InvResendSmsMutationHookResult = ReturnType<
  typeof useInvResendSmsMutation
>;
export type InvResendSmsMutationResult =
  Apollo.MutationResult<InvResendSmsMutation>;
export type InvResendSmsMutationOptions = Apollo.BaseMutationOptions<
  InvResendSmsMutation,
  InvResendSmsMutationVariables
>;
export const GetInvestmentJobDocument = gql`
  mutation GetInvestmentJob($data: GetInvestmentJob!) {
    getInvestmentJob(arg0: $data) {
      success
      errors {
        code
        message
        severity
      }
      investmentJob {
        webOfferingPK
        amount
        userReference
        phoneVerified
        smsResends {
          attempts
          left
          timeLeft
        }
        tfaCode_timestamp
        maxStepAllowed
        investorEntityInfo {
          investorEntityPK
          entityType
          name
          country
          city
          state
          address1
          address2
          zipCode
          taxId
          communications {
            contactPK
            ccRoles
            email
            firstName
            lastName
            middle
            phone
            relationship
          }
          distributions {
            paymentType
            routingNumber
            bankName
            accountNumber
            accountType
            address1
            address2
            city
            state
            zipCode
          }
        }
        accreditation {
          type
        }
        identificationDocument {
          type
          documentId
          expirationDate
          picture1 {
            name
            s3Url
          }
          picture2 {
            name
            s3Url
          }
        }
        sign {
          isSigned
          documentTemplatePk
          docJobReference
        }
      }
      investmentData {
        investorEntities {
          investorEntityPK
          name
          entityType
          taxId
          distributionMethod
          bankName
          accountNumber
          isTaxDomicile
        }
        investorCommunications {
          contactPk
          ccRoles
          email
          first
          middle
          last
          phone
          relationship
        }
        investorCompliance {
          type
          entityTypePk
          templates {
            templatePk
            name
            reference
            category
            required
            templateId
          }
          additionalDocuments {
            options {
              label
              checked
            }
          }
        }
        investorAdditionalDocuments {
          options {
            label
            checked
          }
        }
        allowedRelationship
        allowedccRoles
        allowedInvestorEntities
        signDocument {
          name
          required
          templateId
        }
      }
      step {
        name
        index
      }
    }
  }
`;
export type GetInvestmentJobMutationFn = Apollo.MutationFunction<
  GetInvestmentJobMutation,
  GetInvestmentJobMutationVariables
>;

/**
 * __useGetInvestmentJobMutation__
 *
 * To run a mutation, you first call `useGetInvestmentJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetInvestmentJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getInvestmentJobMutation, { data, loading, error }] = useGetInvestmentJobMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetInvestmentJobMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GetInvestmentJobMutation,
    GetInvestmentJobMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GetInvestmentJobMutation,
    GetInvestmentJobMutationVariables
  >(GetInvestmentJobDocument, options);
}
export type GetInvestmentJobMutationHookResult = ReturnType<
  typeof useGetInvestmentJobMutation
>;
export type GetInvestmentJobMutationResult =
  Apollo.MutationResult<GetInvestmentJobMutation>;
export type GetInvestmentJobMutationOptions = Apollo.BaseMutationOptions<
  GetInvestmentJobMutation,
  GetInvestmentJobMutationVariables
>;
export const CreateInvestmentAllowInvestmentDocument = gql`
  mutation CreateInvestmentAllowInvestment($data: AllowInvestmentParams!) {
    allowInvestment(arg0: $data) {
      success
      errors {
        code
        message
        severity
      }
    }
  }
`;
export type CreateInvestmentAllowInvestmentMutationFn = Apollo.MutationFunction<
  CreateInvestmentAllowInvestmentMutation,
  CreateInvestmentAllowInvestmentMutationVariables
>;

/**
 * __useCreateInvestmentAllowInvestmentMutation__
 *
 * To run a mutation, you first call `useCreateInvestmentAllowInvestmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInvestmentAllowInvestmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInvestmentAllowInvestmentMutation, { data, loading, error }] = useCreateInvestmentAllowInvestmentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateInvestmentAllowInvestmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateInvestmentAllowInvestmentMutation,
    CreateInvestmentAllowInvestmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateInvestmentAllowInvestmentMutation,
    CreateInvestmentAllowInvestmentMutationVariables
  >(CreateInvestmentAllowInvestmentDocument, options);
}
export type CreateInvestmentAllowInvestmentMutationHookResult = ReturnType<
  typeof useCreateInvestmentAllowInvestmentMutation
>;
export type CreateInvestmentAllowInvestmentMutationResult =
  Apollo.MutationResult<CreateInvestmentAllowInvestmentMutation>;
export type CreateInvestmentAllowInvestmentMutationOptions =
  Apollo.BaseMutationOptions<
    CreateInvestmentAllowInvestmentMutation,
    CreateInvestmentAllowInvestmentMutationVariables
  >;
export const NewInvEntityInvestorInfoDocument = gql`
  mutation NewInvEntityInvestorInfo($data: NewInvEntityInvestorInfo!) {
    newInvEntityInvestorInfo(arg0: $data) {
      success
      errors {
        code
        message
        severity
      }
      step {
        maxStepAllowed {
          name
          index
        }
      }
    }
  }
`;
export type NewInvEntityInvestorInfoMutationFn = Apollo.MutationFunction<
  NewInvEntityInvestorInfoMutation,
  NewInvEntityInvestorInfoMutationVariables
>;

/**
 * __useNewInvEntityInvestorInfoMutation__
 *
 * To run a mutation, you first call `useNewInvEntityInvestorInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewInvEntityInvestorInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newInvEntityInvestorInfoMutation, { data, loading, error }] = useNewInvEntityInvestorInfoMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useNewInvEntityInvestorInfoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    NewInvEntityInvestorInfoMutation,
    NewInvEntityInvestorInfoMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    NewInvEntityInvestorInfoMutation,
    NewInvEntityInvestorInfoMutationVariables
  >(NewInvEntityInvestorInfoDocument, options);
}
export type NewInvEntityInvestorInfoMutationHookResult = ReturnType<
  typeof useNewInvEntityInvestorInfoMutation
>;
export type NewInvEntityInvestorInfoMutationResult =
  Apollo.MutationResult<NewInvEntityInvestorInfoMutation>;
export type NewInvEntityInvestorInfoMutationOptions =
  Apollo.BaseMutationOptions<
    NewInvEntityInvestorInfoMutation,
    NewInvEntityInvestorInfoMutationVariables
  >;
export const CreateInvestmentInvAccreditationDocument = gql`
  mutation CreateInvestmentInvAccreditation($data: InvAccreditation!) {
    invAccreditation(arg0: $data) {
      success
      errors {
        code
        message
        severity
      }
      step {
        maxStepAllowed {
          name
          index
        }
      }
    }
  }
`;
export type CreateInvestmentInvAccreditationMutationFn =
  Apollo.MutationFunction<
    CreateInvestmentInvAccreditationMutation,
    CreateInvestmentInvAccreditationMutationVariables
  >;

/**
 * __useCreateInvestmentInvAccreditationMutation__
 *
 * To run a mutation, you first call `useCreateInvestmentInvAccreditationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInvestmentInvAccreditationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInvestmentInvAccreditationMutation, { data, loading, error }] = useCreateInvestmentInvAccreditationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateInvestmentInvAccreditationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateInvestmentInvAccreditationMutation,
    CreateInvestmentInvAccreditationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateInvestmentInvAccreditationMutation,
    CreateInvestmentInvAccreditationMutationVariables
  >(CreateInvestmentInvAccreditationDocument, options);
}
export type CreateInvestmentInvAccreditationMutationHookResult = ReturnType<
  typeof useCreateInvestmentInvAccreditationMutation
>;
export type CreateInvestmentInvAccreditationMutationResult =
  Apollo.MutationResult<CreateInvestmentInvAccreditationMutation>;
export type CreateInvestmentInvAccreditationMutationOptions =
  Apollo.BaseMutationOptions<
    CreateInvestmentInvAccreditationMutation,
    CreateInvestmentInvAccreditationMutationVariables
  >;
export const CreateInvestmentCreateInvDocDocument = gql`
  mutation CreateInvestmentCreateInvDoc($data: CreateDoc!) {
    createInvDoc(arg0: $data) {
      success
      docReference
      url
    }
  }
`;
export type CreateInvestmentCreateInvDocMutationFn = Apollo.MutationFunction<
  CreateInvestmentCreateInvDocMutation,
  CreateInvestmentCreateInvDocMutationVariables
>;

/**
 * __useCreateInvestmentCreateInvDocMutation__
 *
 * To run a mutation, you first call `useCreateInvestmentCreateInvDocMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInvestmentCreateInvDocMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInvestmentCreateInvDocMutation, { data, loading, error }] = useCreateInvestmentCreateInvDocMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateInvestmentCreateInvDocMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateInvestmentCreateInvDocMutation,
    CreateInvestmentCreateInvDocMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateInvestmentCreateInvDocMutation,
    CreateInvestmentCreateInvDocMutationVariables
  >(CreateInvestmentCreateInvDocDocument, options);
}
export type CreateInvestmentCreateInvDocMutationHookResult = ReturnType<
  typeof useCreateInvestmentCreateInvDocMutation
>;
export type CreateInvestmentCreateInvDocMutationResult =
  Apollo.MutationResult<CreateInvestmentCreateInvDocMutation>;
export type CreateInvestmentCreateInvDocMutationOptions =
  Apollo.BaseMutationOptions<
    CreateInvestmentCreateInvDocMutation,
    CreateInvestmentCreateInvDocMutationVariables
  >;
export const CreateInvestmentCheckInvDocStatusDocument = gql`
  mutation CreateInvestmentCheckInvDocStatus($data: CheckDocStatus!) {
    checkInvDocStatus(arg0: $data) {
      success
      status
      step {
        maxStepAllowed {
          name
          index
        }
      }
    }
  }
`;
export type CreateInvestmentCheckInvDocStatusMutationFn =
  Apollo.MutationFunction<
    CreateInvestmentCheckInvDocStatusMutation,
    CreateInvestmentCheckInvDocStatusMutationVariables
  >;

/**
 * __useCreateInvestmentCheckInvDocStatusMutation__
 *
 * To run a mutation, you first call `useCreateInvestmentCheckInvDocStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInvestmentCheckInvDocStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInvestmentCheckInvDocStatusMutation, { data, loading, error }] = useCreateInvestmentCheckInvDocStatusMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateInvestmentCheckInvDocStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateInvestmentCheckInvDocStatusMutation,
    CreateInvestmentCheckInvDocStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateInvestmentCheckInvDocStatusMutation,
    CreateInvestmentCheckInvDocStatusMutationVariables
  >(CreateInvestmentCheckInvDocStatusDocument, options);
}
export type CreateInvestmentCheckInvDocStatusMutationHookResult = ReturnType<
  typeof useCreateInvestmentCheckInvDocStatusMutation
>;
export type CreateInvestmentCheckInvDocStatusMutationResult =
  Apollo.MutationResult<CreateInvestmentCheckInvDocStatusMutation>;
export type CreateInvestmentCheckInvDocStatusMutationOptions =
  Apollo.BaseMutationOptions<
    CreateInvestmentCheckInvDocStatusMutation,
    CreateInvestmentCheckInvDocStatusMutationVariables
  >;
export const CreateInvestmentCommunicationsDocument = gql`
  mutation CreateInvestmentCommunications($data: InvCommunicationsInput!) {
    invCommunications(arg0: $data) {
      success
      errors {
        code
        message
        severity
      }
      step {
        maxStepAllowed {
          name
          index
        }
      }
    }
  }
`;
export type CreateInvestmentCommunicationsMutationFn = Apollo.MutationFunction<
  CreateInvestmentCommunicationsMutation,
  CreateInvestmentCommunicationsMutationVariables
>;

/**
 * __useCreateInvestmentCommunicationsMutation__
 *
 * To run a mutation, you first call `useCreateInvestmentCommunicationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInvestmentCommunicationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInvestmentCommunicationsMutation, { data, loading, error }] = useCreateInvestmentCommunicationsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateInvestmentCommunicationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateInvestmentCommunicationsMutation,
    CreateInvestmentCommunicationsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateInvestmentCommunicationsMutation,
    CreateInvestmentCommunicationsMutationVariables
  >(CreateInvestmentCommunicationsDocument, options);
}
export type CreateInvestmentCommunicationsMutationHookResult = ReturnType<
  typeof useCreateInvestmentCommunicationsMutation
>;
export type CreateInvestmentCommunicationsMutationResult =
  Apollo.MutationResult<CreateInvestmentCommunicationsMutation>;
export type CreateInvestmentCommunicationsMutationOptions =
  Apollo.BaseMutationOptions<
    CreateInvestmentCommunicationsMutation,
    CreateInvestmentCommunicationsMutationVariables
  >;
export const CreateInvestmentInvAddIdentificationDocDocument = gql`
  mutation CreateInvestmentInvAddIdentificationDoc(
    $data: InvAddIdentificationDoc!
  ) {
    invAddIdentificationDoc(arg0: $data) {
      success
      errors {
        code
        message
        severity
      }
      step {
        maxStepAllowed {
          name
          index
        }
      }
    }
  }
`;
export type CreateInvestmentInvAddIdentificationDocMutationFn =
  Apollo.MutationFunction<
    CreateInvestmentInvAddIdentificationDocMutation,
    CreateInvestmentInvAddIdentificationDocMutationVariables
  >;

/**
 * __useCreateInvestmentInvAddIdentificationDocMutation__
 *
 * To run a mutation, you first call `useCreateInvestmentInvAddIdentificationDocMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInvestmentInvAddIdentificationDocMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInvestmentInvAddIdentificationDocMutation, { data, loading, error }] = useCreateInvestmentInvAddIdentificationDocMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateInvestmentInvAddIdentificationDocMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateInvestmentInvAddIdentificationDocMutation,
    CreateInvestmentInvAddIdentificationDocMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateInvestmentInvAddIdentificationDocMutation,
    CreateInvestmentInvAddIdentificationDocMutationVariables
  >(CreateInvestmentInvAddIdentificationDocDocument, options);
}
export type CreateInvestmentInvAddIdentificationDocMutationHookResult =
  ReturnType<typeof useCreateInvestmentInvAddIdentificationDocMutation>;
export type CreateInvestmentInvAddIdentificationDocMutationResult =
  Apollo.MutationResult<CreateInvestmentInvAddIdentificationDocMutation>;
export type CreateInvestmentInvAddIdentificationDocMutationOptions =
  Apollo.BaseMutationOptions<
    CreateInvestmentInvAddIdentificationDocMutation,
    CreateInvestmentInvAddIdentificationDocMutationVariables
  >;
export const InvAddAdditionalDocDocument = gql`
  mutation InvAddAdditionalDoc($data: InvAddAdditionalDoc!) {
    invAddAdditionalDoc(arg0: $data) {
      success
      errors {
        code
        message
        severity
      }
      step {
        maxStepAllowed {
          name
          index
        }
      }
    }
  }
`;
export type InvAddAdditionalDocMutationFn = Apollo.MutationFunction<
  InvAddAdditionalDocMutation,
  InvAddAdditionalDocMutationVariables
>;

/**
 * __useInvAddAdditionalDocMutation__
 *
 * To run a mutation, you first call `useInvAddAdditionalDocMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvAddAdditionalDocMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [invAddAdditionalDocMutation, { data, loading, error }] = useInvAddAdditionalDocMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useInvAddAdditionalDocMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InvAddAdditionalDocMutation,
    InvAddAdditionalDocMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InvAddAdditionalDocMutation,
    InvAddAdditionalDocMutationVariables
  >(InvAddAdditionalDocDocument, options);
}
export type InvAddAdditionalDocMutationHookResult = ReturnType<
  typeof useInvAddAdditionalDocMutation
>;
export type InvAddAdditionalDocMutationResult =
  Apollo.MutationResult<InvAddAdditionalDocMutation>;
export type InvAddAdditionalDocMutationOptions = Apollo.BaseMutationOptions<
  InvAddAdditionalDocMutation,
  InvAddAdditionalDocMutationVariables
>;
export const CreateInvestmentDistributionsDocument = gql`
  mutation CreateInvestmentDistributions($data: InvDistributionsInput!) {
    invDistributions(arg0: $data) {
      success
      errors {
        code
        message
        severity
      }
      step {
        maxStepAllowed {
          index
          name
        }
      }
    }
  }
`;
export type CreateInvestmentDistributionsMutationFn = Apollo.MutationFunction<
  CreateInvestmentDistributionsMutation,
  CreateInvestmentDistributionsMutationVariables
>;

/**
 * __useCreateInvestmentDistributionsMutation__
 *
 * To run a mutation, you first call `useCreateInvestmentDistributionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInvestmentDistributionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInvestmentDistributionsMutation, { data, loading, error }] = useCreateInvestmentDistributionsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateInvestmentDistributionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateInvestmentDistributionsMutation,
    CreateInvestmentDistributionsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateInvestmentDistributionsMutation,
    CreateInvestmentDistributionsMutationVariables
  >(CreateInvestmentDistributionsDocument, options);
}
export type CreateInvestmentDistributionsMutationHookResult = ReturnType<
  typeof useCreateInvestmentDistributionsMutation
>;
export type CreateInvestmentDistributionsMutationResult =
  Apollo.MutationResult<CreateInvestmentDistributionsMutation>;
export type CreateInvestmentDistributionsMutationOptions =
  Apollo.BaseMutationOptions<
    CreateInvestmentDistributionsMutation,
    CreateInvestmentDistributionsMutationVariables
  >;
export const CreateInvReviewDocument = gql`
  mutation CreateInvReview {
    invReview(arg0: {}) {
      success
      errors {
        code
        message
        severity
      }
      step {
        maxStepAllowed {
          name
          index
        }
      }
    }
  }
`;
export type CreateInvReviewMutationFn = Apollo.MutationFunction<
  CreateInvReviewMutation,
  CreateInvReviewMutationVariables
>;

/**
 * __useCreateInvReviewMutation__
 *
 * To run a mutation, you first call `useCreateInvReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInvReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInvReviewMutation, { data, loading, error }] = useCreateInvReviewMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateInvReviewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateInvReviewMutation,
    CreateInvReviewMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateInvReviewMutation,
    CreateInvReviewMutationVariables
  >(CreateInvReviewDocument, options);
}
export type CreateInvReviewMutationHookResult = ReturnType<
  typeof useCreateInvReviewMutation
>;
export type CreateInvReviewMutationResult =
  Apollo.MutationResult<CreateInvReviewMutation>;
export type CreateInvReviewMutationOptions = Apollo.BaseMutationOptions<
  CreateInvReviewMutation,
  CreateInvReviewMutationVariables
>;
export const CreateInvSignDocument = gql`
  mutation CreateInvSign {
    invSign(arg0: {}) {
      success
      errors {
        code
        message
        severity
      }
      step {
        maxStepAllowed {
          name
          index
        }
      }
    }
  }
`;
export type CreateInvSignMutationFn = Apollo.MutationFunction<
  CreateInvSignMutation,
  CreateInvSignMutationVariables
>;

/**
 * __useCreateInvSignMutation__
 *
 * To run a mutation, you first call `useCreateInvSignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInvSignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInvSignMutation, { data, loading, error }] = useCreateInvSignMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateInvSignMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateInvSignMutation,
    CreateInvSignMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateInvSignMutation,
    CreateInvSignMutationVariables
  >(CreateInvSignDocument, options);
}
export type CreateInvSignMutationHookResult = ReturnType<
  typeof useCreateInvSignMutation
>;
export type CreateInvSignMutationResult =
  Apollo.MutationResult<CreateInvSignMutation>;
export type CreateInvSignMutationOptions = Apollo.BaseMutationOptions<
  CreateInvSignMutation,
  CreateInvSignMutationVariables
>;
export const GetInvestorReviewDataDocument = gql`
  mutation GetInvestorReviewData {
    getInvestorReviewData(arg0: {}) {
      success
      errors {
        code
        message
        severity
      }
      investorInfo {
        entityLegalName
        name
        entityType
        taxId
      }
      distribution {
        distributionMethod
      }
      communications {
        primary {
          name
          email
        }
        cc {
          name
          email
        }
      }
    }
  }
`;
export type GetInvestorReviewDataMutationFn = Apollo.MutationFunction<
  GetInvestorReviewDataMutation,
  GetInvestorReviewDataMutationVariables
>;

/**
 * __useGetInvestorReviewDataMutation__
 *
 * To run a mutation, you first call `useGetInvestorReviewDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetInvestorReviewDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getInvestorReviewDataMutation, { data, loading, error }] = useGetInvestorReviewDataMutation({
 *   variables: {
 *   },
 * });
 */
export function useGetInvestorReviewDataMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GetInvestorReviewDataMutation,
    GetInvestorReviewDataMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GetInvestorReviewDataMutation,
    GetInvestorReviewDataMutationVariables
  >(GetInvestorReviewDataDocument, options);
}
export type GetInvestorReviewDataMutationHookResult = ReturnType<
  typeof useGetInvestorReviewDataMutation
>;
export type GetInvestorReviewDataMutationResult =
  Apollo.MutationResult<GetInvestorReviewDataMutation>;
export type GetInvestorReviewDataMutationOptions = Apollo.BaseMutationOptions<
  GetInvestorReviewDataMutation,
  GetInvestorReviewDataMutationVariables
>;
export const CreateUrlForDocuSignDocument = gql`
  mutation CreateUrlForDocuSign($data: CreateUrlForDocuSign!) {
    createUrlForDocuSign(arg0: $data) {
      success
      docReference
      url
      errors {
        code
        message
        severity
      }
    }
  }
`;
export type CreateUrlForDocuSignMutationFn = Apollo.MutationFunction<
  CreateUrlForDocuSignMutation,
  CreateUrlForDocuSignMutationVariables
>;

/**
 * __useCreateUrlForDocuSignMutation__
 *
 * To run a mutation, you first call `useCreateUrlForDocuSignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUrlForDocuSignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUrlForDocuSignMutation, { data, loading, error }] = useCreateUrlForDocuSignMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateUrlForDocuSignMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUrlForDocuSignMutation,
    CreateUrlForDocuSignMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateUrlForDocuSignMutation,
    CreateUrlForDocuSignMutationVariables
  >(CreateUrlForDocuSignDocument, options);
}
export type CreateUrlForDocuSignMutationHookResult = ReturnType<
  typeof useCreateUrlForDocuSignMutation
>;
export type CreateUrlForDocuSignMutationResult =
  Apollo.MutationResult<CreateUrlForDocuSignMutation>;
export type CreateUrlForDocuSignMutationOptions = Apollo.BaseMutationOptions<
  CreateUrlForDocuSignMutation,
  CreateUrlForDocuSignMutationVariables
>;
export const CheckDocuSignStatusDocument = gql`
  mutation CheckDocuSignStatus($data: CheckDocuSignStatus!) {
    checkDocuSignStatus(arg0: $data) {
      success
      status
      errors {
        code
        message
        severity
      }
    }
  }
`;
export type CheckDocuSignStatusMutationFn = Apollo.MutationFunction<
  CheckDocuSignStatusMutation,
  CheckDocuSignStatusMutationVariables
>;

/**
 * __useCheckDocuSignStatusMutation__
 *
 * To run a mutation, you first call `useCheckDocuSignStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckDocuSignStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkDocuSignStatusMutation, { data, loading, error }] = useCheckDocuSignStatusMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCheckDocuSignStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CheckDocuSignStatusMutation,
    CheckDocuSignStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CheckDocuSignStatusMutation,
    CheckDocuSignStatusMutationVariables
  >(CheckDocuSignStatusDocument, options);
}
export type CheckDocuSignStatusMutationHookResult = ReturnType<
  typeof useCheckDocuSignStatusMutation
>;
export type CheckDocuSignStatusMutationResult =
  Apollo.MutationResult<CheckDocuSignStatusMutation>;
export type CheckDocuSignStatusMutationOptions = Apollo.BaseMutationOptions<
  CheckDocuSignStatusMutation,
  CheckDocuSignStatusMutationVariables
>;
export const SaveMakeInvestmentDocument = gql`
  mutation SaveMakeInvestment($data: SaveMakeInvestment!) {
    saveMakeInvestmentData(arg0: $data) {
      success
      errors {
        code
        message
        severity
      }
      step {
        maxStepAllowed {
          name
          index
        }
      }
    }
  }
`;
export type SaveMakeInvestmentMutationFn = Apollo.MutationFunction<
  SaveMakeInvestmentMutation,
  SaveMakeInvestmentMutationVariables
>;

/**
 * __useSaveMakeInvestmentMutation__
 *
 * To run a mutation, you first call `useSaveMakeInvestmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveMakeInvestmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveMakeInvestmentMutation, { data, loading, error }] = useSaveMakeInvestmentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSaveMakeInvestmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveMakeInvestmentMutation,
    SaveMakeInvestmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveMakeInvestmentMutation,
    SaveMakeInvestmentMutationVariables
  >(SaveMakeInvestmentDocument, options);
}
export type SaveMakeInvestmentMutationHookResult = ReturnType<
  typeof useSaveMakeInvestmentMutation
>;
export type SaveMakeInvestmentMutationResult =
  Apollo.MutationResult<SaveMakeInvestmentMutation>;
export type SaveMakeInvestmentMutationOptions = Apollo.BaseMutationOptions<
  SaveMakeInvestmentMutation,
  SaveMakeInvestmentMutationVariables
>;
export const GetOfferingInvestmentInfoDocument = gql`
  query GetOfferingInvestmentInfo($arg0: GetOfferingInvestmentInfo!) {
    getOfferingInvestmentInfo(arg0: $arg0) {
      success
      errors {
        code
        message
        severity
      }
      offering {
        name
        images
        min
        max
        phone
        email
        disclaimer
        fundTarget
        fundCurrent
      }
    }
  }
`;

/**
 * __useGetOfferingInvestmentInfoQuery__
 *
 * To run a query within a React component, call `useGetOfferingInvestmentInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOfferingInvestmentInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOfferingInvestmentInfoQuery({
 *   variables: {
 *      arg0: // value for 'arg0'
 *   },
 * });
 */
export function useGetOfferingInvestmentInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOfferingInvestmentInfoQuery,
    GetOfferingInvestmentInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetOfferingInvestmentInfoQuery,
    GetOfferingInvestmentInfoQueryVariables
  >(GetOfferingInvestmentInfoDocument, options);
}
export function useGetOfferingInvestmentInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOfferingInvestmentInfoQuery,
    GetOfferingInvestmentInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOfferingInvestmentInfoQuery,
    GetOfferingInvestmentInfoQueryVariables
  >(GetOfferingInvestmentInfoDocument, options);
}
export type GetOfferingInvestmentInfoQueryHookResult = ReturnType<
  typeof useGetOfferingInvestmentInfoQuery
>;
export type GetOfferingInvestmentInfoLazyQueryHookResult = ReturnType<
  typeof useGetOfferingInvestmentInfoLazyQuery
>;
export type GetOfferingInvestmentInfoQueryResult = Apollo.QueryResult<
  GetOfferingInvestmentInfoQuery,
  GetOfferingInvestmentInfoQueryVariables
>;
export const MonitoringAddMyListDocument = gql`
  mutation MonitoringAddMyList($data: MonitoringAddMyList!) {
    monitoringAddMyList(arg0: $data) {
      success
    }
  }
`;
export type MonitoringAddMyListMutationFn = Apollo.MutationFunction<
  MonitoringAddMyListMutation,
  MonitoringAddMyListMutationVariables
>;

/**
 * __useMonitoringAddMyListMutation__
 *
 * To run a mutation, you first call `useMonitoringAddMyListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMonitoringAddMyListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [monitoringAddMyListMutation, { data, loading, error }] = useMonitoringAddMyListMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useMonitoringAddMyListMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MonitoringAddMyListMutation,
    MonitoringAddMyListMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MonitoringAddMyListMutation,
    MonitoringAddMyListMutationVariables
  >(MonitoringAddMyListDocument, options);
}
export type MonitoringAddMyListMutationHookResult = ReturnType<
  typeof useMonitoringAddMyListMutation
>;
export type MonitoringAddMyListMutationResult =
  Apollo.MutationResult<MonitoringAddMyListMutation>;
export type MonitoringAddMyListMutationOptions = Apollo.BaseMutationOptions<
  MonitoringAddMyListMutation,
  MonitoringAddMyListMutationVariables
>;
export const MonitoringDeleteFromMyListDocument = gql`
  mutation MonitoringDeleteFromMyList($data: MonitoringDeleteFromMyList!) {
    monitoringDeleteFromMyList(arg0: $data) {
      success
    }
  }
`;
export type MonitoringDeleteFromMyListMutationFn = Apollo.MutationFunction<
  MonitoringDeleteFromMyListMutation,
  MonitoringDeleteFromMyListMutationVariables
>;

/**
 * __useMonitoringDeleteFromMyListMutation__
 *
 * To run a mutation, you first call `useMonitoringDeleteFromMyListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMonitoringDeleteFromMyListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [monitoringDeleteFromMyListMutation, { data, loading, error }] = useMonitoringDeleteFromMyListMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useMonitoringDeleteFromMyListMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MonitoringDeleteFromMyListMutation,
    MonitoringDeleteFromMyListMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MonitoringDeleteFromMyListMutation,
    MonitoringDeleteFromMyListMutationVariables
  >(MonitoringDeleteFromMyListDocument, options);
}
export type MonitoringDeleteFromMyListMutationHookResult = ReturnType<
  typeof useMonitoringDeleteFromMyListMutation
>;
export type MonitoringDeleteFromMyListMutationResult =
  Apollo.MutationResult<MonitoringDeleteFromMyListMutation>;
export type MonitoringDeleteFromMyListMutationOptions =
  Apollo.BaseMutationOptions<
    MonitoringDeleteFromMyListMutation,
    MonitoringDeleteFromMyListMutationVariables
  >;
export const MonitoringGetInvInteriorPageHeaderMenuDocument = gql`
  mutation MonitoringGetInvInteriorPageHeaderMenu($data: EmptyObject!) {
    invPortalGetPageHeaderMenu(arg0: $data) {
      success
      errors {
        code
        message
        severity
      }
      options {
        pk
        name
      }
    }
  }
`;
export type MonitoringGetInvInteriorPageHeaderMenuMutationFn =
  Apollo.MutationFunction<
    MonitoringGetInvInteriorPageHeaderMenuMutation,
    MonitoringGetInvInteriorPageHeaderMenuMutationVariables
  >;

/**
 * __useMonitoringGetInvInteriorPageHeaderMenuMutation__
 *
 * To run a mutation, you first call `useMonitoringGetInvInteriorPageHeaderMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMonitoringGetInvInteriorPageHeaderMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [monitoringGetInvInteriorPageHeaderMenuMutation, { data, loading, error }] = useMonitoringGetInvInteriorPageHeaderMenuMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useMonitoringGetInvInteriorPageHeaderMenuMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MonitoringGetInvInteriorPageHeaderMenuMutation,
    MonitoringGetInvInteriorPageHeaderMenuMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MonitoringGetInvInteriorPageHeaderMenuMutation,
    MonitoringGetInvInteriorPageHeaderMenuMutationVariables
  >(MonitoringGetInvInteriorPageHeaderMenuDocument, options);
}
export type MonitoringGetInvInteriorPageHeaderMenuMutationHookResult =
  ReturnType<typeof useMonitoringGetInvInteriorPageHeaderMenuMutation>;
export type MonitoringGetInvInteriorPageHeaderMenuMutationResult =
  Apollo.MutationResult<MonitoringGetInvInteriorPageHeaderMenuMutation>;
export type MonitoringGetInvInteriorPageHeaderMenuMutationOptions =
  Apollo.BaseMutationOptions<
    MonitoringGetInvInteriorPageHeaderMenuMutation,
    MonitoringGetInvInteriorPageHeaderMenuMutationVariables
  >;
export const MonitoringGetInvInteriorTabDocument = gql`
  mutation MonitoringGetInvInteriorTab(
    $data: MonitoringGetInvInteriorTabParams!
  ) {
    invPortalGetInteriorTab(arg0: $data) {
      success
      errors {
        code
        message
        severity
      }
      tab {
        datePicker {
          date
          name
        }
        settings {
          investorEntityPk
          investorEntityName
          contacts
          method
        }
        investorEntitiesPicker {
          pk
          name
        }
        logoLink {
          s3key
          note
        }
        addressMap {
          address1
          address2
          city
          state
          postalCode
          country
          latitude
          longitude
        }
        pcoWebsite
        overview {
          label
          content
        }
        milestones {
          date
          title
          note
        }
        investmentBarChart {
          type
          bars {
            title
            subTitle1
            subTitle2
            values {
              value1
              value2
              value3
              irr
              multiple
              nav
              cmmt
            }
          }
          legend {
            id
            title
            color
            textColor
          }
        }
        myPerformance {
          tables {
            title
            tabs {
              accessor
              options
            }
            filters {
              options
              label
              accessor
              placeholder
            }
            headers {
              value
              accessor
              sortable
              placeholder
              type
              hidden
              width {
                fixedWidth
              }
              group
            }
            rows {
              value
              rowData {
                value
                type
                accessor
                isActive
                params {
                  fundPk
                  values
                  topInvestor
                  partnerPk
                  offeringType
                  portfolioPk
                  portfolio
                  color
                }
              }
            }
            sections {
              title
              rows {
                value
                rowData {
                  value
                  type
                  accessor
                  isActive
                  params {
                    fundPk
                    values
                    topInvestor
                    partnerPk
                    offeringType
                    portfolioPk
                    portfolio
                    color
                  }
                }
              }
            }
          }
        }
        performance {
          tables {
            title
            tabs {
              accessor
              options
            }
            filters {
              options
              label
              accessor
              placeholder
            }
            headers {
              value
              accessor
              sortable
              placeholder
              type
              hidden
              width {
                fixedWidth
              }
              group
            }
            rows {
              value
              rowData {
                value
                type
                accessor
                isActive
                params {
                  fundPk
                  values
                  topInvestor
                  partnerPk
                  offeringType
                  portfolioPk
                  portfolio
                  color
                }
              }
            }
            sections {
              title
              rows {
                value
                rowData {
                  value
                  type
                  accessor
                  isActive
                  params {
                    fundPk
                    values
                    topInvestor
                    partnerPk
                    offeringType
                    portfolioPk
                    portfolio
                    color
                  }
                }
              }
            }
          }
        }
        footnotes
        productImages {
          label
          content
          images
        }
        deck {
          title
          url
        }
        investmentDocuments {
          title
          tabs {
            accessor
            options
          }
          filters {
            options
            label
            accessor
            placeholder
          }
          headers {
            value
            accessor
            sortable
            placeholder
            type
            hidden
            width {
              fixedWidth
            }
            group
          }
          rows {
            value
            rowData {
              value
              type
              accessor
              isActive
              params {
                fundPk
                values
                topInvestor
                partnerPk
                offeringType
                portfolioPk
                portfolio
                color
                url
              }
            }
          }
          sections {
            title
            rows {
              value
              rowData {
                value
                type
                accessor
                isActive
                params {
                  fundPk
                  values
                  topInvestor
                  partnerPk
                  offeringType
                  portfolioPk
                  portfolio
                  color
                }
              }
            }
          }
        }
      }
    }
  }
`;
export type MonitoringGetInvInteriorTabMutationFn = Apollo.MutationFunction<
  MonitoringGetInvInteriorTabMutation,
  MonitoringGetInvInteriorTabMutationVariables
>;

/**
 * __useMonitoringGetInvInteriorTabMutation__
 *
 * To run a mutation, you first call `useMonitoringGetInvInteriorTabMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMonitoringGetInvInteriorTabMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [monitoringGetInvInteriorTabMutation, { data, loading, error }] = useMonitoringGetInvInteriorTabMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useMonitoringGetInvInteriorTabMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MonitoringGetInvInteriorTabMutation,
    MonitoringGetInvInteriorTabMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MonitoringGetInvInteriorTabMutation,
    MonitoringGetInvInteriorTabMutationVariables
  >(MonitoringGetInvInteriorTabDocument, options);
}
export type MonitoringGetInvInteriorTabMutationHookResult = ReturnType<
  typeof useMonitoringGetInvInteriorTabMutation
>;
export type MonitoringGetInvInteriorTabMutationResult =
  Apollo.MutationResult<MonitoringGetInvInteriorTabMutation>;
export type MonitoringGetInvInteriorTabMutationOptions =
  Apollo.BaseMutationOptions<
    MonitoringGetInvInteriorTabMutation,
    MonitoringGetInvInteriorTabMutationVariables
  >;
export const GetOfferingDetailsDocument = gql`
  query GetOfferingDetails($data: GetOfferingDetails!) {
    getOfferingDetails(arg0: $data) {
      success
      offering {
        webOfferingPK
        offeringTitle
        offeringType
        percentFunded
        content {
          description
          fundTarget
          fundedAmount
          closeDate
          investmentType {
            label
            selectedValue
            options
          }
          strategy {
            label
            selectedValue
            options
          }
          flex {
            label
            selectedValue
            options
          }
          sponsorDescription
          introduction
          location {
            address1
            address2
            city
            state
            postalCode
            country
            lat
            long
            mapZoom
          }
          widgets {
            label
            type
            selected {
              label
              value
              percentage
              row
              col
              topLine
              fileName
              docPk
              type
              url
              order
            }
          }
        }
        galleryImages {
          label
          subLabel
          imageWidgets {
            label
            url
            caption
            altText
            order
          }
        }
        otherOpportunities {
          webOfferingPK
          type
          offeringTitle
          fundTarget
          percentFunded
          closeDate
          imageUrls {
            pk
            label
            caption
            url
            altText
            order
          }
          filterDataWidgets {
            label
            selectedValue
          }
        }
      }
      errors {
        code
        message
        severity
      }
    }
  }
`;

/**
 * __useGetOfferingDetailsQuery__
 *
 * To run a query within a React component, call `useGetOfferingDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOfferingDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOfferingDetailsQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetOfferingDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOfferingDetailsQuery,
    GetOfferingDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetOfferingDetailsQuery,
    GetOfferingDetailsQueryVariables
  >(GetOfferingDetailsDocument, options);
}
export function useGetOfferingDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOfferingDetailsQuery,
    GetOfferingDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOfferingDetailsQuery,
    GetOfferingDetailsQueryVariables
  >(GetOfferingDetailsDocument, options);
}
export type GetOfferingDetailsQueryHookResult = ReturnType<
  typeof useGetOfferingDetailsQuery
>;
export type GetOfferingDetailsLazyQueryHookResult = ReturnType<
  typeof useGetOfferingDetailsLazyQuery
>;
export type GetOfferingDetailsQueryResult = Apollo.QueryResult<
  GetOfferingDetailsQuery,
  GetOfferingDetailsQueryVariables
>;
export const MonitoringGetMyPerformanceDocument = gql`
  query MonitoringGetMyPerformance($data: MonitoringGetMyPerformanceParams!) {
    monitoringGetMyPerformance(arg0: $data) {
      success
      fundPk
      contribs
      distribs
      image
      performanceCharts {
        title
        type
        legend {
          id
          title
          color
        }
        bars {
          title
          subTitle1
          subTitle2
          values {
            value1
            value2
            value3
            irr
            multiple
          }
        }
      }
      tables {
        title
        filters {
          label
          placeholder
          accessor
          options
        }
        tabs {
          accessor
          options
        }
        headers {
          value
          accessor
          sortable
          placeholder
          type
          width {
            fixedWidth
          }
          group
        }
        rows {
          value
          rowData {
            value
            type
            accessor
            isActive
            params {
              pk
              fundPk
              values
            }
          }
        }
      }
      errors {
        code
        message
        severity
      }
    }
  }
`;

/**
 * __useMonitoringGetMyPerformanceQuery__
 *
 * To run a query within a React component, call `useMonitoringGetMyPerformanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useMonitoringGetMyPerformanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMonitoringGetMyPerformanceQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useMonitoringGetMyPerformanceQuery(
  baseOptions: Apollo.QueryHookOptions<
    MonitoringGetMyPerformanceQuery,
    MonitoringGetMyPerformanceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MonitoringGetMyPerformanceQuery,
    MonitoringGetMyPerformanceQueryVariables
  >(MonitoringGetMyPerformanceDocument, options);
}
export function useMonitoringGetMyPerformanceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MonitoringGetMyPerformanceQuery,
    MonitoringGetMyPerformanceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MonitoringGetMyPerformanceQuery,
    MonitoringGetMyPerformanceQueryVariables
  >(MonitoringGetMyPerformanceDocument, options);
}
export type MonitoringGetMyPerformanceQueryHookResult = ReturnType<
  typeof useMonitoringGetMyPerformanceQuery
>;
export type MonitoringGetMyPerformanceLazyQueryHookResult = ReturnType<
  typeof useMonitoringGetMyPerformanceLazyQuery
>;
export type MonitoringGetMyPerformanceQueryResult = Apollo.QueryResult<
  MonitoringGetMyPerformanceQuery,
  MonitoringGetMyPerformanceQueryVariables
>;
export const SettingGetPersonalInfoDocument = gql`
  mutation SettingGetPersonalInfo {
    settingsGetPersonalInfo(arg0: {}) {
      success
      errors {
        code
        message
        severity
      }
      userInfo {
        firstName
        lastName
        middle
        email
        phone
        title
        company
        address1
        address2
        country
        city
        state
        zipCode
        addressBlock
      }
    }
  }
`;
export type SettingGetPersonalInfoMutationFn = Apollo.MutationFunction<
  SettingGetPersonalInfoMutation,
  SettingGetPersonalInfoMutationVariables
>;

/**
 * __useSettingGetPersonalInfoMutation__
 *
 * To run a mutation, you first call `useSettingGetPersonalInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSettingGetPersonalInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [settingGetPersonalInfoMutation, { data, loading, error }] = useSettingGetPersonalInfoMutation({
 *   variables: {
 *   },
 * });
 */
export function useSettingGetPersonalInfoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SettingGetPersonalInfoMutation,
    SettingGetPersonalInfoMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SettingGetPersonalInfoMutation,
    SettingGetPersonalInfoMutationVariables
  >(SettingGetPersonalInfoDocument, options);
}
export type SettingGetPersonalInfoMutationHookResult = ReturnType<
  typeof useSettingGetPersonalInfoMutation
>;
export type SettingGetPersonalInfoMutationResult =
  Apollo.MutationResult<SettingGetPersonalInfoMutation>;
export type SettingGetPersonalInfoMutationOptions = Apollo.BaseMutationOptions<
  SettingGetPersonalInfoMutation,
  SettingGetPersonalInfoMutationVariables
>;
export const SettingsResetPasswordTfaDocument = gql`
  mutation SettingsResetPasswordTfa($data: CheckTfaCodeParams!) {
    settingsCheckTfaCode(arg0: $data) {
      success
      errors {
        code
        message
        severity
      }
    }
  }
`;
export type SettingsResetPasswordTfaMutationFn = Apollo.MutationFunction<
  SettingsResetPasswordTfaMutation,
  SettingsResetPasswordTfaMutationVariables
>;

/**
 * __useSettingsResetPasswordTfaMutation__
 *
 * To run a mutation, you first call `useSettingsResetPasswordTfaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSettingsResetPasswordTfaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [settingsResetPasswordTfaMutation, { data, loading, error }] = useSettingsResetPasswordTfaMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSettingsResetPasswordTfaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SettingsResetPasswordTfaMutation,
    SettingsResetPasswordTfaMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SettingsResetPasswordTfaMutation,
    SettingsResetPasswordTfaMutationVariables
  >(SettingsResetPasswordTfaDocument, options);
}
export type SettingsResetPasswordTfaMutationHookResult = ReturnType<
  typeof useSettingsResetPasswordTfaMutation
>;
export type SettingsResetPasswordTfaMutationResult =
  Apollo.MutationResult<SettingsResetPasswordTfaMutation>;
export type SettingsResetPasswordTfaMutationOptions =
  Apollo.BaseMutationOptions<
    SettingsResetPasswordTfaMutation,
    SettingsResetPasswordTfaMutationVariables
  >;
export const SettingsResetPasswordResendTfaDocument = gql`
  mutation SettingsResetPasswordResendTfa {
    settingsResendTfaCode(arg0: {}) {
      success
      errors {
        code
        message
        severity
      }
    }
  }
`;
export type SettingsResetPasswordResendTfaMutationFn = Apollo.MutationFunction<
  SettingsResetPasswordResendTfaMutation,
  SettingsResetPasswordResendTfaMutationVariables
>;

/**
 * __useSettingsResetPasswordResendTfaMutation__
 *
 * To run a mutation, you first call `useSettingsResetPasswordResendTfaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSettingsResetPasswordResendTfaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [settingsResetPasswordResendTfaMutation, { data, loading, error }] = useSettingsResetPasswordResendTfaMutation({
 *   variables: {
 *   },
 * });
 */
export function useSettingsResetPasswordResendTfaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SettingsResetPasswordResendTfaMutation,
    SettingsResetPasswordResendTfaMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SettingsResetPasswordResendTfaMutation,
    SettingsResetPasswordResendTfaMutationVariables
  >(SettingsResetPasswordResendTfaDocument, options);
}
export type SettingsResetPasswordResendTfaMutationHookResult = ReturnType<
  typeof useSettingsResetPasswordResendTfaMutation
>;
export type SettingsResetPasswordResendTfaMutationResult =
  Apollo.MutationResult<SettingsResetPasswordResendTfaMutation>;
export type SettingsResetPasswordResendTfaMutationOptions =
  Apollo.BaseMutationOptions<
    SettingsResetPasswordResendTfaMutation,
    SettingsResetPasswordResendTfaMutationVariables
  >;
export const SettingsIsEmailVerifiedDocument = gql`
  mutation SettingsIsEmailVerified {
    settingsCheckEmailVerified(arg0: {}) {
      success
      errors {
        code
        message
        severity
      }
      step {
        name
      }
    }
  }
`;
export type SettingsIsEmailVerifiedMutationFn = Apollo.MutationFunction<
  SettingsIsEmailVerifiedMutation,
  SettingsIsEmailVerifiedMutationVariables
>;

/**
 * __useSettingsIsEmailVerifiedMutation__
 *
 * To run a mutation, you first call `useSettingsIsEmailVerifiedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSettingsIsEmailVerifiedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [settingsIsEmailVerifiedMutation, { data, loading, error }] = useSettingsIsEmailVerifiedMutation({
 *   variables: {
 *   },
 * });
 */
export function useSettingsIsEmailVerifiedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SettingsIsEmailVerifiedMutation,
    SettingsIsEmailVerifiedMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SettingsIsEmailVerifiedMutation,
    SettingsIsEmailVerifiedMutationVariables
  >(SettingsIsEmailVerifiedDocument, options);
}
export type SettingsIsEmailVerifiedMutationHookResult = ReturnType<
  typeof useSettingsIsEmailVerifiedMutation
>;
export type SettingsIsEmailVerifiedMutationResult =
  Apollo.MutationResult<SettingsIsEmailVerifiedMutation>;
export type SettingsIsEmailVerifiedMutationOptions = Apollo.BaseMutationOptions<
  SettingsIsEmailVerifiedMutation,
  SettingsIsEmailVerifiedMutationVariables
>;
export const SettingsGetInvestingEntitiesDocument = gql`
  mutation SettingsGetInvestingEntities {
    settingsGetInvestingEntities(arg0: {}) {
      success
      investingEntities {
        investorEntityPK
        name
        image
        title
        offerings
        people
      }
    }
  }
`;
export type SettingsGetInvestingEntitiesMutationFn = Apollo.MutationFunction<
  SettingsGetInvestingEntitiesMutation,
  SettingsGetInvestingEntitiesMutationVariables
>;

/**
 * __useSettingsGetInvestingEntitiesMutation__
 *
 * To run a mutation, you first call `useSettingsGetInvestingEntitiesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSettingsGetInvestingEntitiesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [settingsGetInvestingEntitiesMutation, { data, loading, error }] = useSettingsGetInvestingEntitiesMutation({
 *   variables: {
 *   },
 * });
 */
export function useSettingsGetInvestingEntitiesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SettingsGetInvestingEntitiesMutation,
    SettingsGetInvestingEntitiesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SettingsGetInvestingEntitiesMutation,
    SettingsGetInvestingEntitiesMutationVariables
  >(SettingsGetInvestingEntitiesDocument, options);
}
export type SettingsGetInvestingEntitiesMutationHookResult = ReturnType<
  typeof useSettingsGetInvestingEntitiesMutation
>;
export type SettingsGetInvestingEntitiesMutationResult =
  Apollo.MutationResult<SettingsGetInvestingEntitiesMutation>;
export type SettingsGetInvestingEntitiesMutationOptions =
  Apollo.BaseMutationOptions<
    SettingsGetInvestingEntitiesMutation,
    SettingsGetInvestingEntitiesMutationVariables
  >;
export const SettingsGetEntityInfoDocument = gql`
  mutation SettingsGetEntityInfo($data: SettingsGetEntityInfo!) {
    settingsGetEntityInfo(arg0: $data) {
      success
      errors {
        code
        message
        severity
      }
      entityInfo {
        entityType
        taxId
      }
      distribution {
        method
        routingNumber
        bankName
        accountNumber
        accountType
      }
      offerings
    }
  }
`;
export type SettingsGetEntityInfoMutationFn = Apollo.MutationFunction<
  SettingsGetEntityInfoMutation,
  SettingsGetEntityInfoMutationVariables
>;

/**
 * __useSettingsGetEntityInfoMutation__
 *
 * To run a mutation, you first call `useSettingsGetEntityInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSettingsGetEntityInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [settingsGetEntityInfoMutation, { data, loading, error }] = useSettingsGetEntityInfoMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSettingsGetEntityInfoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SettingsGetEntityInfoMutation,
    SettingsGetEntityInfoMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SettingsGetEntityInfoMutation,
    SettingsGetEntityInfoMutationVariables
  >(SettingsGetEntityInfoDocument, options);
}
export type SettingsGetEntityInfoMutationHookResult = ReturnType<
  typeof useSettingsGetEntityInfoMutation
>;
export type SettingsGetEntityInfoMutationResult =
  Apollo.MutationResult<SettingsGetEntityInfoMutation>;
export type SettingsGetEntityInfoMutationOptions = Apollo.BaseMutationOptions<
  SettingsGetEntityInfoMutation,
  SettingsGetEntityInfoMutationVariables
>;
export const SettingsCheckPasswordDocument = gql`
  mutation SettingsCheckPassword($data: SettingsCheckPassword!) {
    settingsCheckPassword(arg0: $data) {
      success
      errors {
        code
        message
        severity
      }
      step {
        maxStepAllowed {
          name
          index
        }
      }
    }
  }
`;
export type SettingsCheckPasswordMutationFn = Apollo.MutationFunction<
  SettingsCheckPasswordMutation,
  SettingsCheckPasswordMutationVariables
>;

/**
 * __useSettingsCheckPasswordMutation__
 *
 * To run a mutation, you first call `useSettingsCheckPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSettingsCheckPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [settingsCheckPasswordMutation, { data, loading, error }] = useSettingsCheckPasswordMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSettingsCheckPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SettingsCheckPasswordMutation,
    SettingsCheckPasswordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SettingsCheckPasswordMutation,
    SettingsCheckPasswordMutationVariables
  >(SettingsCheckPasswordDocument, options);
}
export type SettingsCheckPasswordMutationHookResult = ReturnType<
  typeof useSettingsCheckPasswordMutation
>;
export type SettingsCheckPasswordMutationResult =
  Apollo.MutationResult<SettingsCheckPasswordMutation>;
export type SettingsCheckPasswordMutationOptions = Apollo.BaseMutationOptions<
  SettingsCheckPasswordMutation,
  SettingsCheckPasswordMutationVariables
>;
export const SettingsUpdateNameDocument = gql`
  mutation SettingsUpdateName($data: SettingsUpdateName!) {
    settingsUpdateName(arg0: $data) {
      success
      errors {
        code
        message
        severity
      }
      step {
        maxStepAllowed {
          name
          index
        }
      }
    }
  }
`;
export type SettingsUpdateNameMutationFn = Apollo.MutationFunction<
  SettingsUpdateNameMutation,
  SettingsUpdateNameMutationVariables
>;

/**
 * __useSettingsUpdateNameMutation__
 *
 * To run a mutation, you first call `useSettingsUpdateNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSettingsUpdateNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [settingsUpdateNameMutation, { data, loading, error }] = useSettingsUpdateNameMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSettingsUpdateNameMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SettingsUpdateNameMutation,
    SettingsUpdateNameMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SettingsUpdateNameMutation,
    SettingsUpdateNameMutationVariables
  >(SettingsUpdateNameDocument, options);
}
export type SettingsUpdateNameMutationHookResult = ReturnType<
  typeof useSettingsUpdateNameMutation
>;
export type SettingsUpdateNameMutationResult =
  Apollo.MutationResult<SettingsUpdateNameMutation>;
export type SettingsUpdateNameMutationOptions = Apollo.BaseMutationOptions<
  SettingsUpdateNameMutation,
  SettingsUpdateNameMutationVariables
>;
export const SettingsUpdateEmailDocument = gql`
  mutation SettingsUpdateEmail($data: SettingsUpdateEmail!) {
    settingsUpdateEmail(arg0: $data) {
      success
      errors {
        code
        message
        severity
      }
      step {
        maxStepAllowed {
          index
          name
        }
      }
    }
  }
`;
export type SettingsUpdateEmailMutationFn = Apollo.MutationFunction<
  SettingsUpdateEmailMutation,
  SettingsUpdateEmailMutationVariables
>;

/**
 * __useSettingsUpdateEmailMutation__
 *
 * To run a mutation, you first call `useSettingsUpdateEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSettingsUpdateEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [settingsUpdateEmailMutation, { data, loading, error }] = useSettingsUpdateEmailMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSettingsUpdateEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SettingsUpdateEmailMutation,
    SettingsUpdateEmailMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SettingsUpdateEmailMutation,
    SettingsUpdateEmailMutationVariables
  >(SettingsUpdateEmailDocument, options);
}
export type SettingsUpdateEmailMutationHookResult = ReturnType<
  typeof useSettingsUpdateEmailMutation
>;
export type SettingsUpdateEmailMutationResult =
  Apollo.MutationResult<SettingsUpdateEmailMutation>;
export type SettingsUpdateEmailMutationOptions = Apollo.BaseMutationOptions<
  SettingsUpdateEmailMutation,
  SettingsUpdateEmailMutationVariables
>;
export const SettingsCheckEmailVerifiedDocument = gql`
  mutation SettingsCheckEmailVerified {
    settingsCheckEmailVerified(arg0: {}) {
      success
      errors {
        code
        message
        severity
      }
      step {
        name
        index
      }
    }
  }
`;
export type SettingsCheckEmailVerifiedMutationFn = Apollo.MutationFunction<
  SettingsCheckEmailVerifiedMutation,
  SettingsCheckEmailVerifiedMutationVariables
>;

/**
 * __useSettingsCheckEmailVerifiedMutation__
 *
 * To run a mutation, you first call `useSettingsCheckEmailVerifiedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSettingsCheckEmailVerifiedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [settingsCheckEmailVerifiedMutation, { data, loading, error }] = useSettingsCheckEmailVerifiedMutation({
 *   variables: {
 *   },
 * });
 */
export function useSettingsCheckEmailVerifiedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SettingsCheckEmailVerifiedMutation,
    SettingsCheckEmailVerifiedMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SettingsCheckEmailVerifiedMutation,
    SettingsCheckEmailVerifiedMutationVariables
  >(SettingsCheckEmailVerifiedDocument, options);
}
export type SettingsCheckEmailVerifiedMutationHookResult = ReturnType<
  typeof useSettingsCheckEmailVerifiedMutation
>;
export type SettingsCheckEmailVerifiedMutationResult =
  Apollo.MutationResult<SettingsCheckEmailVerifiedMutation>;
export type SettingsCheckEmailVerifiedMutationOptions =
  Apollo.BaseMutationOptions<
    SettingsCheckEmailVerifiedMutation,
    SettingsCheckEmailVerifiedMutationVariables
  >;
export const SettingsUpdateMobileDocument = gql`
  mutation SettingsUpdateMobile($data: SettingsUpdateMobile!) {
    settingsUpdateMobile(arg0: $data) {
      success
      errors {
        code
        message
        severity
      }
      step {
        maxStepAllowed {
          index
          name
        }
      }
    }
  }
`;
export type SettingsUpdateMobileMutationFn = Apollo.MutationFunction<
  SettingsUpdateMobileMutation,
  SettingsUpdateMobileMutationVariables
>;

/**
 * __useSettingsUpdateMobileMutation__
 *
 * To run a mutation, you first call `useSettingsUpdateMobileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSettingsUpdateMobileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [settingsUpdateMobileMutation, { data, loading, error }] = useSettingsUpdateMobileMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSettingsUpdateMobileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SettingsUpdateMobileMutation,
    SettingsUpdateMobileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SettingsUpdateMobileMutation,
    SettingsUpdateMobileMutationVariables
  >(SettingsUpdateMobileDocument, options);
}
export type SettingsUpdateMobileMutationHookResult = ReturnType<
  typeof useSettingsUpdateMobileMutation
>;
export type SettingsUpdateMobileMutationResult =
  Apollo.MutationResult<SettingsUpdateMobileMutation>;
export type SettingsUpdateMobileMutationOptions = Apollo.BaseMutationOptions<
  SettingsUpdateMobileMutation,
  SettingsUpdateMobileMutationVariables
>;
export const SettingsUpdateOptionalInfoDocument = gql`
  mutation SettingsUpdateOptionalInfo($data: SettingsPersonalOptInfoInput!) {
    settingsUpdateOptionalInfo(arg0: $data) {
      success
      errors {
        code
        message
        severity
      }
      step {
        maxStepAllowed {
          name
          index
        }
      }
    }
  }
`;
export type SettingsUpdateOptionalInfoMutationFn = Apollo.MutationFunction<
  SettingsUpdateOptionalInfoMutation,
  SettingsUpdateOptionalInfoMutationVariables
>;

/**
 * __useSettingsUpdateOptionalInfoMutation__
 *
 * To run a mutation, you first call `useSettingsUpdateOptionalInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSettingsUpdateOptionalInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [settingsUpdateOptionalInfoMutation, { data, loading, error }] = useSettingsUpdateOptionalInfoMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSettingsUpdateOptionalInfoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SettingsUpdateOptionalInfoMutation,
    SettingsUpdateOptionalInfoMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SettingsUpdateOptionalInfoMutation,
    SettingsUpdateOptionalInfoMutationVariables
  >(SettingsUpdateOptionalInfoDocument, options);
}
export type SettingsUpdateOptionalInfoMutationHookResult = ReturnType<
  typeof useSettingsUpdateOptionalInfoMutation
>;
export type SettingsUpdateOptionalInfoMutationResult =
  Apollo.MutationResult<SettingsUpdateOptionalInfoMutation>;
export type SettingsUpdateOptionalInfoMutationOptions =
  Apollo.BaseMutationOptions<
    SettingsUpdateOptionalInfoMutation,
    SettingsUpdateOptionalInfoMutationVariables
  >;
export const SettingsCheckTfaCodeDocument = gql`
  mutation SettingsCheckTfaCode($data: CheckTfaCodeParams!) {
    settingsCheckTfaCode(arg0: $data) {
      success
      step {
        maxStepAllowed {
          name
          index
        }
      }
      errors {
        code
        message
        severity
      }
    }
  }
`;
export type SettingsCheckTfaCodeMutationFn = Apollo.MutationFunction<
  SettingsCheckTfaCodeMutation,
  SettingsCheckTfaCodeMutationVariables
>;

/**
 * __useSettingsCheckTfaCodeMutation__
 *
 * To run a mutation, you first call `useSettingsCheckTfaCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSettingsCheckTfaCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [settingsCheckTfaCodeMutation, { data, loading, error }] = useSettingsCheckTfaCodeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSettingsCheckTfaCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SettingsCheckTfaCodeMutation,
    SettingsCheckTfaCodeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SettingsCheckTfaCodeMutation,
    SettingsCheckTfaCodeMutationVariables
  >(SettingsCheckTfaCodeDocument, options);
}
export type SettingsCheckTfaCodeMutationHookResult = ReturnType<
  typeof useSettingsCheckTfaCodeMutation
>;
export type SettingsCheckTfaCodeMutationResult =
  Apollo.MutationResult<SettingsCheckTfaCodeMutation>;
export type SettingsCheckTfaCodeMutationOptions = Apollo.BaseMutationOptions<
  SettingsCheckTfaCodeMutation,
  SettingsCheckTfaCodeMutationVariables
>;
export const SettingsUpdateCheckTfaCodeDocument = gql`
  mutation SettingsUpdateCheckTfaCode($data: CheckTfaCodeParams!) {
    settingsUpdateCheckTfaCode(arg0: $data) {
      success
      step {
        maxStepAllowed {
          name
          index
        }
      }
      errors {
        code
        message
        severity
      }
    }
  }
`;
export type SettingsUpdateCheckTfaCodeMutationFn = Apollo.MutationFunction<
  SettingsUpdateCheckTfaCodeMutation,
  SettingsUpdateCheckTfaCodeMutationVariables
>;

/**
 * __useSettingsUpdateCheckTfaCodeMutation__
 *
 * To run a mutation, you first call `useSettingsUpdateCheckTfaCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSettingsUpdateCheckTfaCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [settingsUpdateCheckTfaCodeMutation, { data, loading, error }] = useSettingsUpdateCheckTfaCodeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSettingsUpdateCheckTfaCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SettingsUpdateCheckTfaCodeMutation,
    SettingsUpdateCheckTfaCodeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SettingsUpdateCheckTfaCodeMutation,
    SettingsUpdateCheckTfaCodeMutationVariables
  >(SettingsUpdateCheckTfaCodeDocument, options);
}
export type SettingsUpdateCheckTfaCodeMutationHookResult = ReturnType<
  typeof useSettingsUpdateCheckTfaCodeMutation
>;
export type SettingsUpdateCheckTfaCodeMutationResult =
  Apollo.MutationResult<SettingsUpdateCheckTfaCodeMutation>;
export type SettingsUpdateCheckTfaCodeMutationOptions =
  Apollo.BaseMutationOptions<
    SettingsUpdateCheckTfaCodeMutation,
    SettingsUpdateCheckTfaCodeMutationVariables
  >;
export const SettingsUpdateAddressDocument = gql`
  mutation SettingsUpdateAddress($data: SettingsUpdateAddress!) {
    settingsUpdateAddress(arg0: $data) {
      success
      step {
        maxStepAllowed {
          name
          index
        }
      }
      errors {
        code
        message
        severity
      }
    }
  }
`;
export type SettingsUpdateAddressMutationFn = Apollo.MutationFunction<
  SettingsUpdateAddressMutation,
  SettingsUpdateAddressMutationVariables
>;

/**
 * __useSettingsUpdateAddressMutation__
 *
 * To run a mutation, you first call `useSettingsUpdateAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSettingsUpdateAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [settingsUpdateAddressMutation, { data, loading, error }] = useSettingsUpdateAddressMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSettingsUpdateAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SettingsUpdateAddressMutation,
    SettingsUpdateAddressMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SettingsUpdateAddressMutation,
    SettingsUpdateAddressMutationVariables
  >(SettingsUpdateAddressDocument, options);
}
export type SettingsUpdateAddressMutationHookResult = ReturnType<
  typeof useSettingsUpdateAddressMutation
>;
export type SettingsUpdateAddressMutationResult =
  Apollo.MutationResult<SettingsUpdateAddressMutation>;
export type SettingsUpdateAddressMutationOptions = Apollo.BaseMutationOptions<
  SettingsUpdateAddressMutation,
  SettingsUpdateAddressMutationVariables
>;
export const SettingsUpdateEntityInfoDocument = gql`
  mutation SettingsUpdateEntityInfo($data: SettingsUpdateEntityInfo!) {
    settingsUpdateEntityInfo(arg0: $data) {
      step {
        maxStepAllowed {
          name
          index
        }
      }
      success
      errors {
        code
        message
        severity
      }
      success
    }
  }
`;
export type SettingsUpdateEntityInfoMutationFn = Apollo.MutationFunction<
  SettingsUpdateEntityInfoMutation,
  SettingsUpdateEntityInfoMutationVariables
>;

/**
 * __useSettingsUpdateEntityInfoMutation__
 *
 * To run a mutation, you first call `useSettingsUpdateEntityInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSettingsUpdateEntityInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [settingsUpdateEntityInfoMutation, { data, loading, error }] = useSettingsUpdateEntityInfoMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSettingsUpdateEntityInfoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SettingsUpdateEntityInfoMutation,
    SettingsUpdateEntityInfoMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SettingsUpdateEntityInfoMutation,
    SettingsUpdateEntityInfoMutationVariables
  >(SettingsUpdateEntityInfoDocument, options);
}
export type SettingsUpdateEntityInfoMutationHookResult = ReturnType<
  typeof useSettingsUpdateEntityInfoMutation
>;
export type SettingsUpdateEntityInfoMutationResult =
  Apollo.MutationResult<SettingsUpdateEntityInfoMutation>;
export type SettingsUpdateEntityInfoMutationOptions =
  Apollo.BaseMutationOptions<
    SettingsUpdateEntityInfoMutation,
    SettingsUpdateEntityInfoMutationVariables
  >;
export const SettingsUpdateInvestmentTaxIdDocument = gql`
  mutation SettingsUpdateInvestmentTaxId($data: SettingsUpdateInvTaxId!) {
    settingsUpdateInvestmentTaxId(arg0: $data) {
      errors {
        code
        message
        severity
      }
      success
    }
  }
`;
export type SettingsUpdateInvestmentTaxIdMutationFn = Apollo.MutationFunction<
  SettingsUpdateInvestmentTaxIdMutation,
  SettingsUpdateInvestmentTaxIdMutationVariables
>;

/**
 * __useSettingsUpdateInvestmentTaxIdMutation__
 *
 * To run a mutation, you first call `useSettingsUpdateInvestmentTaxIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSettingsUpdateInvestmentTaxIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [settingsUpdateInvestmentTaxIdMutation, { data, loading, error }] = useSettingsUpdateInvestmentTaxIdMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSettingsUpdateInvestmentTaxIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SettingsUpdateInvestmentTaxIdMutation,
    SettingsUpdateInvestmentTaxIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SettingsUpdateInvestmentTaxIdMutation,
    SettingsUpdateInvestmentTaxIdMutationVariables
  >(SettingsUpdateInvestmentTaxIdDocument, options);
}
export type SettingsUpdateInvestmentTaxIdMutationHookResult = ReturnType<
  typeof useSettingsUpdateInvestmentTaxIdMutation
>;
export type SettingsUpdateInvestmentTaxIdMutationResult =
  Apollo.MutationResult<SettingsUpdateInvestmentTaxIdMutation>;
export type SettingsUpdateInvestmentTaxIdMutationOptions =
  Apollo.BaseMutationOptions<
    SettingsUpdateInvestmentTaxIdMutation,
    SettingsUpdateInvestmentTaxIdMutationVariables
  >;
export const SettingsUpdatePasswordDocument = gql`
  mutation SettingsUpdatePassword($data: SettingsUpdatePassword!) {
    settingsUpdatePassword(arg0: $data) {
      step {
        maxStepAllowed {
          name
          index
        }
      }
      errors {
        code
        message
        severity
      }
      success
    }
  }
`;
export type SettingsUpdatePasswordMutationFn = Apollo.MutationFunction<
  SettingsUpdatePasswordMutation,
  SettingsUpdatePasswordMutationVariables
>;

/**
 * __useSettingsUpdatePasswordMutation__
 *
 * To run a mutation, you first call `useSettingsUpdatePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSettingsUpdatePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [settingsUpdatePasswordMutation, { data, loading, error }] = useSettingsUpdatePasswordMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSettingsUpdatePasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SettingsUpdatePasswordMutation,
    SettingsUpdatePasswordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SettingsUpdatePasswordMutation,
    SettingsUpdatePasswordMutationVariables
  >(SettingsUpdatePasswordDocument, options);
}
export type SettingsUpdatePasswordMutationHookResult = ReturnType<
  typeof useSettingsUpdatePasswordMutation
>;
export type SettingsUpdatePasswordMutationResult =
  Apollo.MutationResult<SettingsUpdatePasswordMutation>;
export type SettingsUpdatePasswordMutationOptions = Apollo.BaseMutationOptions<
  SettingsUpdatePasswordMutation,
  SettingsUpdatePasswordMutationVariables
>;
export const SettingsResendTfaCodeDocument = gql`
  mutation SettingsResendTfaCode {
    settingsResendTfaCode(arg0: {}) {
      step {
        maxStepAllowed {
          name
          index
        }
      }
      errors {
        code
        message
        severity
      }
      success
    }
  }
`;
export type SettingsResendTfaCodeMutationFn = Apollo.MutationFunction<
  SettingsResendTfaCodeMutation,
  SettingsResendTfaCodeMutationVariables
>;

/**
 * __useSettingsResendTfaCodeMutation__
 *
 * To run a mutation, you first call `useSettingsResendTfaCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSettingsResendTfaCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [settingsResendTfaCodeMutation, { data, loading, error }] = useSettingsResendTfaCodeMutation({
 *   variables: {
 *   },
 * });
 */
export function useSettingsResendTfaCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SettingsResendTfaCodeMutation,
    SettingsResendTfaCodeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SettingsResendTfaCodeMutation,
    SettingsResendTfaCodeMutationVariables
  >(SettingsResendTfaCodeDocument, options);
}
export type SettingsResendTfaCodeMutationHookResult = ReturnType<
  typeof useSettingsResendTfaCodeMutation
>;
export type SettingsResendTfaCodeMutationResult =
  Apollo.MutationResult<SettingsResendTfaCodeMutation>;
export type SettingsResendTfaCodeMutationOptions = Apollo.BaseMutationOptions<
  SettingsResendTfaCodeMutation,
  SettingsResendTfaCodeMutationVariables
>;
export const SettingsGetInvestmentTaxIdDocument = gql`
  mutation SettingsGetInvestmentTaxId($data: SettingsGetInvTaxId!) {
    settingsGetInvestmentTaxId(arg0: $data) {
      success
      errors {
        code
        message
        severity
      }
      taxId
    }
  }
`;
export type SettingsGetInvestmentTaxIdMutationFn = Apollo.MutationFunction<
  SettingsGetInvestmentTaxIdMutation,
  SettingsGetInvestmentTaxIdMutationVariables
>;

/**
 * __useSettingsGetInvestmentTaxIdMutation__
 *
 * To run a mutation, you first call `useSettingsGetInvestmentTaxIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSettingsGetInvestmentTaxIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [settingsGetInvestmentTaxIdMutation, { data, loading, error }] = useSettingsGetInvestmentTaxIdMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSettingsGetInvestmentTaxIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SettingsGetInvestmentTaxIdMutation,
    SettingsGetInvestmentTaxIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SettingsGetInvestmentTaxIdMutation,
    SettingsGetInvestmentTaxIdMutationVariables
  >(SettingsGetInvestmentTaxIdDocument, options);
}
export type SettingsGetInvestmentTaxIdMutationHookResult = ReturnType<
  typeof useSettingsGetInvestmentTaxIdMutation
>;
export type SettingsGetInvestmentTaxIdMutationResult =
  Apollo.MutationResult<SettingsGetInvestmentTaxIdMutation>;
export type SettingsGetInvestmentTaxIdMutationOptions =
  Apollo.BaseMutationOptions<
    SettingsGetInvestmentTaxIdMutation,
    SettingsGetInvestmentTaxIdMutationVariables
  >;
export const SettingsUpdateDistributionsDocument = gql`
  mutation SettingsUpdateDistributions($data: SettingsUpdateDistributions!) {
    settingsUpdateDistributions(arg0: $data) {
      success
      errors {
        code
        message
        severity
      }
    }
  }
`;
export type SettingsUpdateDistributionsMutationFn = Apollo.MutationFunction<
  SettingsUpdateDistributionsMutation,
  SettingsUpdateDistributionsMutationVariables
>;

/**
 * __useSettingsUpdateDistributionsMutation__
 *
 * To run a mutation, you first call `useSettingsUpdateDistributionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSettingsUpdateDistributionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [settingsUpdateDistributionsMutation, { data, loading, error }] = useSettingsUpdateDistributionsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSettingsUpdateDistributionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SettingsUpdateDistributionsMutation,
    SettingsUpdateDistributionsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SettingsUpdateDistributionsMutation,
    SettingsUpdateDistributionsMutationVariables
  >(SettingsUpdateDistributionsDocument, options);
}
export type SettingsUpdateDistributionsMutationHookResult = ReturnType<
  typeof useSettingsUpdateDistributionsMutation
>;
export type SettingsUpdateDistributionsMutationResult =
  Apollo.MutationResult<SettingsUpdateDistributionsMutation>;
export type SettingsUpdateDistributionsMutationOptions =
  Apollo.BaseMutationOptions<
    SettingsUpdateDistributionsMutation,
    SettingsUpdateDistributionsMutationVariables
  >;
export const SettingsColumnStateDocument = gql`
  mutation SettingsColumnState($data: SettingsColumnState!) {
    settingColumnState(arg0: $data) {
      success
      errors {
        code
        message
        severity
      }
    }
  }
`;
export type SettingsColumnStateMutationFn = Apollo.MutationFunction<
  SettingsColumnStateMutation,
  SettingsColumnStateMutationVariables
>;

/**
 * __useSettingsColumnStateMutation__
 *
 * To run a mutation, you first call `useSettingsColumnStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSettingsColumnStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [settingsColumnStateMutation, { data, loading, error }] = useSettingsColumnStateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSettingsColumnStateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SettingsColumnStateMutation,
    SettingsColumnStateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SettingsColumnStateMutation,
    SettingsColumnStateMutationVariables
  >(SettingsColumnStateDocument, options);
}
export type SettingsColumnStateMutationHookResult = ReturnType<
  typeof useSettingsColumnStateMutation
>;
export type SettingsColumnStateMutationResult =
  Apollo.MutationResult<SettingsColumnStateMutation>;
export type SettingsColumnStateMutationOptions = Apollo.BaseMutationOptions<
  SettingsColumnStateMutation,
  SettingsColumnStateMutationVariables
>;
export const UtilitiesGetTestDataFilesDocument = gql`
  mutation UtilitiesGetTestDataFiles {
    utilitiesGetTestDataFiles(arg0: {}) {
      success
      datafiles {
        folder
        path
      }
      currentClient
      errors {
        code
        message
        severity
      }
    }
  }
`;
export type UtilitiesGetTestDataFilesMutationFn = Apollo.MutationFunction<
  UtilitiesGetTestDataFilesMutation,
  UtilitiesGetTestDataFilesMutationVariables
>;

/**
 * __useUtilitiesGetTestDataFilesMutation__
 *
 * To run a mutation, you first call `useUtilitiesGetTestDataFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUtilitiesGetTestDataFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [utilitiesGetTestDataFilesMutation, { data, loading, error }] = useUtilitiesGetTestDataFilesMutation({
 *   variables: {
 *   },
 * });
 */
export function useUtilitiesGetTestDataFilesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UtilitiesGetTestDataFilesMutation,
    UtilitiesGetTestDataFilesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UtilitiesGetTestDataFilesMutation,
    UtilitiesGetTestDataFilesMutationVariables
  >(UtilitiesGetTestDataFilesDocument, options);
}
export type UtilitiesGetTestDataFilesMutationHookResult = ReturnType<
  typeof useUtilitiesGetTestDataFilesMutation
>;
export type UtilitiesGetTestDataFilesMutationResult =
  Apollo.MutationResult<UtilitiesGetTestDataFilesMutation>;
export type UtilitiesGetTestDataFilesMutationOptions =
  Apollo.BaseMutationOptions<
    UtilitiesGetTestDataFilesMutation,
    UtilitiesGetTestDataFilesMutationVariables
  >;
export const UtilitiesRestartBackendServerDocument = gql`
  mutation UtilitiesRestartBackendServer(
    $data: UtilitiesRestartBackendServer!
  ) {
    utilitiesRestartBackendServer(arg0: $data) {
      success
      errors {
        code
        message
        severity
      }
    }
  }
`;
export type UtilitiesRestartBackendServerMutationFn = Apollo.MutationFunction<
  UtilitiesRestartBackendServerMutation,
  UtilitiesRestartBackendServerMutationVariables
>;

/**
 * __useUtilitiesRestartBackendServerMutation__
 *
 * To run a mutation, you first call `useUtilitiesRestartBackendServerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUtilitiesRestartBackendServerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [utilitiesRestartBackendServerMutation, { data, loading, error }] = useUtilitiesRestartBackendServerMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUtilitiesRestartBackendServerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UtilitiesRestartBackendServerMutation,
    UtilitiesRestartBackendServerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UtilitiesRestartBackendServerMutation,
    UtilitiesRestartBackendServerMutationVariables
  >(UtilitiesRestartBackendServerDocument, options);
}
export type UtilitiesRestartBackendServerMutationHookResult = ReturnType<
  typeof useUtilitiesRestartBackendServerMutation
>;
export type UtilitiesRestartBackendServerMutationResult =
  Apollo.MutationResult<UtilitiesRestartBackendServerMutation>;
export type UtilitiesRestartBackendServerMutationOptions =
  Apollo.BaseMutationOptions<
    UtilitiesRestartBackendServerMutation,
    UtilitiesRestartBackendServerMutationVariables
  >;
